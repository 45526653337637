import Select from 'components/FormControl/Select';
import Loader from 'components/Loader';
import { getLang } from 'helpers/jwt';
import { countryLang, getLang as getUserLang, setLang } from 'helpers/lang';
import { useCollections } from 'hooks/useCollections';
import { useOptions } from 'hooks/useOptions';
import { useProducts } from 'hooks/useProducts';
import { useSuperCollections } from 'hooks/useSuperCollections';
import { useUser } from 'hooks/useUser';
import i18n from 'i18n';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import classes from './LangSwitcher.module.scss';

const LangSwitcher: React.FC = () => {
	const { t } = useTranslation(['menu']);
	const [value, setValue] = useState<string>();
	const [loading, setLoading] = useState<boolean>(false);
	const { userCountry, changeUserLang } = useUser();
	const { clearProductsValidation } = useProducts();
	const { removeValidation } = useCollections();
	const { removeValidationSup } = useSuperCollections();
	const { clearOptionsValidation } = useOptions();

	const clearValidation = () => {
		clearProductsValidation();
		removeValidation();
		removeValidationSup();
		clearOptionsValidation();
	};

	const handleChangeCountry = async (lang: string) => {
		setValue(lang);
		setLang(lang);
		changeUserLang(lang);
		setLoading(true);
		await i18n.changeLanguage(lang);
		setLoading(false);
		clearValidation();
	};

	useEffect(() => {
		if (!userCountry) return;
		let hasLang: boolean = true;
		const userLang: string = getUserLang() || getLang()!;
		const countryLangs = countryLang[userCountry as keyof typeof countryLang];
		const availableLangs = (currentLang: string) => countryLangs?.find((langCode) => langCode === currentLang);
		if (userLang && !availableLangs(userLang)) hasLang = false;
		const lang: string = hasLang ? userLang : countryLangs[0];
		handleChangeCountry(lang);
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [userCountry]);

	if (!userCountry) return null;

	const viewNaming = countryLang[userCountry as keyof typeof countryLang];

	const options = viewNaming?.map((lang) => ({
		value: lang,
		label: t(`lang.${lang}`),
	})) || [];

	if (options.length === 1) return null;

	if (loading) return <Loader />;

	return (
		<Select
			withoutClear
			value={value}
			options={options}
			onChange={handleChangeCountry}
			rootClassName={classes.rootClassName}
			controlClassName={classes.control}
		/>
	);
};

export default LangSwitcher;
