import SelectedOptions from 'components/SelectedOptions';
import { RoutesEnum } from 'const';
import PopupAddOption from 'containers/PopupAddOrEditOption';
import { getEmptyOptionGroup, getTranslations } from 'helpers/mock';
import { useAppSelector } from 'hooks/redux';
import { useOptions } from 'hooks/useOptions';
import { useProducts } from 'hooks/useProducts';
import { ITranslations } from 'models/IProduct';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import Select, { components } from 'react-select';

import classes from './ProductOptions.module.scss';
import { customStyles } from './selectStyles';
import { IOptionSelect, IProps } from './types';

const ProductOptions: React.FC<IProps> = ({ product, groupId }) => {
	const [openMenu, setOpenMenu] = useState<boolean>(false);
	const selectRef = useRef<any>(null);
	const [open, setOpen] = useState(false);
	const { getActiveOptionGroupsByIds, activeOptionGroups, optionGroups } = useOptions();
	const { updateProduct } = useProducts();
	// eslint-disable-next-line react-hooks/exhaustive-deps
	const optionsProduct = useMemo(() => getActiveOptionGroupsByIds(product.options || []), [product.options, activeOptionGroups]);
	const [selectedItems, setSelectedItems] = useState<IOptionSelect[]>(optionsProduct.map(({ id, title }) => ({ value: id, label: title })));
	const optionsSelect = activeOptionGroups.map(({ id, title }) => ({ value: id, label: title }));
	const { t } = useTranslation(['options', 'form']);
	const { multiLangData } = useAppSelector((state) => state.multiLang);
	const newTranslations: ITranslations = getTranslations(multiLangData.languages);

	const setDefaultSelectedItems = useCallback(() => {
		setSelectedItems(optionsProduct.map(({ id, title }) => ({ value: id, label: title })));
	}, [optionsProduct]);

	useEffect(setDefaultSelectedItems, [setDefaultSelectedItems]);

	const setSelectedItem = (item: any) => {
		const items = [...selectedItems];
		const doubleAddItem = items.findIndex((selectedItem) => selectedItem.value === item.value);
		(doubleAddItem === -1) ? items.push(item) : items.splice(doubleAddItem, 1);
		setSelectedItems(items);
	};

	const menuShow = () => {
		setOpenMenu(true);
	};

	const menuHide = () => {
		setOpenMenu(false);
		setDefaultSelectedItems();
		selectRef.current.blur();
	};

	const cancel = () => {
		menuHide();
	};

	const updateOptions = (items: IOptionSelect[]) => {
		updateProduct(groupId, product.id, 'options', items.map(({ value }) => value));
	};

	const apply = (items: IOptionSelect[]) => () => {
		updateOptions(items);
		menuHide();
	};

	const handleRemoveValue = (id: string) => (e: any) => {
		e.stopPropagation();
		const newState = selectedItems.filter((item: IOptionSelect) => item.value !== id);
		setSelectedItems(newState);
		updateOptions(newState);
	};

	const handleOpenPopup = () => setOpen(true);
	const handleClosePopup = () => setOpen(false);

	const emptyOptions = () => (
		<div className={classes.emptyOptionsText}>
			<Trans i18nKey="options:empty.active" components={{
				a: <Link className={classes.toOptionPage} to={RoutesEnum.OPTIONS} />,
			}} />
		</div>
	);

	const noGroupOptions = () => (
		<div className={classes.emptyOptionsText}>
			{t('options:empty.all')}
			<div className={classes.add}>
				<button onClick={handleOpenPopup}>{t('options:addNewSection')}</button>
			</div>
		</div>
	);

	const MenuList = useCallback((props: any) => {
		if (!optionGroups.length) return <components.MenuList {...props}>{noGroupOptions()}</components.MenuList>;

		return (
			<components.MenuList {...props}>
				{optionsSelect.length ? (
					<>
						{props.children}
						<div className={classes.controls}>
							<div className={classes.add}>
								<button onClick={handleOpenPopup}>{t('options:addNewSection')}</button>
							</div>
						</div>
						<div className={classes.buttons}>
							<button onClick={cancel}>{t('form:cancel')}</button>
							<button className={classes.apply} onClick={apply(props.selectProps.value)}>
								{t('form:apply')}
							</button>
						</div>
					</>
				) : emptyOptions()}
			</components.MenuList>
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [optionsSelect.length]);

	return (
		<>
			<div className={classes.root} data-testid="additionalOptions">
				<Select
					ref={selectRef}
					placeholder={`
						${t('options:additionalOptions')} ${optionsProduct.length ? `(${optionsProduct.length})` : ''}
					`}
					value={selectedItems}
					options={optionsSelect}
					onChange={setSelectedItem}
					controlShouldRenderValue={false}
					styles={customStyles}
					isSearchable={false}
					menuIsOpen={openMenu}
					closeMenuOnSelect={false}
					components={{ MenuList }}
					onFocus={menuShow}
					onBlur={menuHide}
					onMenuOpen={() => setOpenMenu(true)}
					onMenuClose={() => setOpenMenu(false)}
				/>
				{optionsProduct.map((item) => <SelectedOptions key={item.id} item={item} handleRemoveValue={handleRemoveValue} />)}
			</div>
			<PopupAddOption
				open={open}
				onClose={handleClosePopup}
				optionsGroup={getEmptyOptionGroup(multiLangData.languages.length ? newTranslations : undefined)}
			/>
		</>
	);
};

export default ProductOptions;
