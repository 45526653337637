import LangDropDown from 'components/LangDropDown';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as PlusIco } from '../../static/imgs/plus.svg';
import classes from './AddLangButton.module.scss';
import { IProps } from './types';

const AddLangButton: React.FC<IProps> = ({ handleAddLang, popupLangsList }) => {
	const { t } = useTranslation('multiLanguages');
	const [showLangDropDown, setShowLangDropDown] = useState<boolean>(false);

	const handleOpen = () => { setShowLangDropDown(true); };
	const handleClose = () => { setShowLangDropDown(false); };

	return (
		<>
			<div className={classes.addLangButton} data-testid="test_addLangButton" onClick={handleOpen}>
				<span><PlusIco/></span>{t('addLanguage')}
			</div>
			{showLangDropDown && (
				<div className={classes.langDropDownWrap}>
					<LangDropDown handleClose={handleClose} handleSetLang={handleAddLang} popupLangsList={popupLangsList} />
				</div>
			)}
		</>
	);
};

export default AddLangButton;
