import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { changeEntityForce, sortByPosition } from 'helpers';
import { ICollection, ISuperCollection } from 'models/ICollection';
import { IAvailability } from 'models/IProduct';

import { fetchSupCollectionsAvailability, fetchSuperCollections } from './actions';


export interface IState {
	showSuperCollections: boolean;
	superCollections: ISuperCollection[];
	initialSuperCollections: ISuperCollection[];
	loadingSupCollections: boolean;
	supCollectionsAvailability: IAvailability[];
}

const initialState: IState = {
	showSuperCollections: false,
	superCollections: [],
	initialSuperCollections: [],
	loadingSupCollections: true,
	supCollectionsAvailability: [],
};

export const superCollectionsSlice = createSlice({
	name: 'superCollectionsData',
	initialState,
	reducers: {
		createEmptySupCollectionStore(state: IState, action: PayloadAction<ISuperCollection>) {
			state.superCollections = [...state.superCollections, action.payload];
		},
		tieCollectionToSupCollectionStore(
			state: IState,
			action: PayloadAction<{superCollectionId: ISuperCollection['id'], collectionId: ICollection['id']}>,
		) {
			const { superCollectionId, collectionId } = action.payload;

			state.superCollections = state.superCollections.map((superCollection) => {
				if (superCollection.id === superCollectionId) superCollection.menuCollections.push(collectionId);
				return superCollection;
			});
		},
		removeCollectionFromSupCollectionsStore(state: IState, action: PayloadAction<ICollection['id']>) {
			state.superCollections = state.superCollections.map((collection) => {
				collection.menuCollections.forEach((collectionId, index) => {
					if (collectionId === action.payload) collection.menuCollections.splice(index, 1);
				});
				return collection;
			});
		},
		updateSupCollectionStore(
			state: IState,
			action: PayloadAction<{ collectionId: ISuperCollection['id'], key: keyof ISuperCollection, value: any }>,
		) {
			const { collectionId, key, value } = action.payload;
			const changedSupCollection = changeEntityForce(state.superCollections, collectionId, key, value);
			state.superCollections = changedSupCollection;
		},
		update(state: IState, action: PayloadAction<{ items: ISuperCollection[], needSort?: boolean, changedPosition?: boolean }>) {
			const items = action.payload.needSort ? sortByPosition(action.payload.items).map((collection) => ({
				...collection,
				position: collection.position || 0,
			})) : action.payload.items;
			if (!action.payload.changedPosition) state.initialSuperCollections = items;
			state.superCollections = items;
		},
		removeSupCollectionStore(state: IState, action: PayloadAction<{ collectionId: ISuperCollection['id']}>) {
			state.superCollections = state.superCollections.filter((collection) => collection.id !== action.payload.collectionId);
		},
		clearSuperCollections(state: IState) {
			state.superCollections = initialState.superCollections;
		},
		removeValidation(state: IState) {
			state.superCollections = state.superCollections.map(superCollection => {
				delete superCollection.validation;
				delete superCollection.itemsErrorsCount;
				return superCollection;
			});
		},
		updateAvailability(state: IState, action: PayloadAction<IAvailability[]> ) {
			state.supCollectionsAvailability = action.payload;
		},
		updateInitialSupCollections(state: IState) {
			state.initialSuperCollections = state.superCollections;
		},
	},
	extraReducers: {
		[fetchSuperCollections.fulfilled.type]: (state, action: PayloadAction<ISuperCollection[]>) => {
			const collectionsSorted = sortByPosition(action.payload).map((collection, index) => ({
				...collection,
				position: collection.position || 0,
				saveCollapseValue: index !== 0,
			}));
			state.showSuperCollections = collectionsSorted.length ? true : false;
			state.superCollections = collectionsSorted;
			state.initialSuperCollections = collectionsSorted;
			state.loadingSupCollections = false;
		},
		[fetchSupCollectionsAvailability.fulfilled.type]: (state, action: PayloadAction<[]>) => {
			state.supCollectionsAvailability = action.payload;
			state.loadingSupCollections = false;
		},
		[fetchSuperCollections.pending.type]: (state) => {
			state.loadingSupCollections = true;
		},
		[fetchSuperCollections.rejected.type]: (state) => {
			state.loadingSupCollections = false;
		},
		[fetchSupCollectionsAvailability.pending.type]: (state) => {
			state.loadingSupCollections = true;
		},
		[fetchSupCollectionsAvailability.rejected.type]: (state) => {
			state.loadingSupCollections = false;
		},
	},
});

export default superCollectionsSlice.reducer;
