import InputForEnteringInform from 'components/InputForEnteringInform';
import clsx from 'helpers/clsx';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

import classes from '../SendFeedbackPopup.module.scss';
import { IPropsTemplate } from '../types';

const DefaultTemplate: React.FC<IPropsTemplate> = ({handleSend, message, setMessage, errors}) => {
	const { t } = useTranslation(['form', 'feedback']);

	return (
		<div className={classes.default}>
			<div className={classes.title}>{t('feedback:giveFeedback')}</div>
			<div className={classes.message}><Trans t={t} i18nKey="feedback:feedbackDescription" /></div>
			<InputForEnteringInform
				hideLangBar
				isTextarea
				allowedNumberToBottom
				allowedNumber={300}
				value={message}
				onChange={setMessage}
				placeholder={t('feedback:typeFeedback')}
				className={classes.textarea}
				errors={errors}
				label={t('feedback:typeFeedback')}
			/>
			<div className={clsx(classes.sendButton, {[classes.disabled]: !message.length})}>
				<button onClick={handleSend}>{t('send')}</button>
			</div>
		</div>
	);
};

export default DefaultTemplate;
