import clsx from 'helpers/clsx';
import { useStoreAddress } from 'hooks/useStoreAddress';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as ArrowIco } from 'static/imgs/arrow-image.svg';

import classes from './ItemStore.module.scss';
import { IProps } from './types';

const ItemStore: React.FC<IProps> = ({ storeId, storeName, storeAddresses }) => {
	const [showItem, setShowItem] = useState<boolean>(false);
	const { currentStoreId } = useStoreAddress();
	const { t } = useTranslation('accounts');

	return (
		<div className={clsx(classes.root, {
			[classes.active]: currentStoreId === String(storeId),
		})}>
			<div className={classes.container}>
				<div className={classes.listContainer_option}>
					<div className={clsx(classes.listContainer_option_storeId)}>
						{storeId}
					</div>
					<div className={classes.listContainer_option_info}>
						<div className={classes.listContainer_option_name}>
							{storeName}
						</div>
						{storeAddresses.length === 1 && (
							<div className={classes.listContainer_option_address}>
								{storeAddresses[0].address}
							</div>
						)}
					</div>
				</div>
				{storeAddresses.length > 1 && (
					<div
						className={classes.listContainer_option_button}
						onClick={(e) => {
							e.stopPropagation();
							setShowItem(!showItem);
						}}
					>
						{t('storeAddress.institution', {count: storeAddresses.length})}
						<span className={clsx({ [classes.openItem]: showItem })}>
							<ArrowIco />
						</span>
					</div>
				)}
			</div>
			{showItem && (
				<>
					{storeAddresses.map((storeAddress) => {
						return (
							<>
								<div className={classes.store_address_item} key={storeAddress.id}>
									<div className={classes.listContainer_option_storeId_items}>{storeAddress.storeAddressId}</div>
									<div className={classes.listContainer_option_address}>{storeAddress.address}</div>
								</div>
							</>
						);
					})}
				</>
			)}
		</div>
	);
};

export default ItemStore;

