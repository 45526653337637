import ItemIsChecked from 'components/ItemIsChecked';
import SendFeedback from 'components/SendFeedback';
import { maxSendingCount } from 'const';
import LastSaveDraft from 'containers/LastSaveDraft';
import clsx from 'helpers/clsx';
import { useCollections } from 'hooks/useCollections';
import { useOptions } from 'hooks/useOptions';
import { useProducts } from 'hooks/useProducts';
import { useStoreAddress } from 'hooks/useStoreAddress';
import { useSuperCollections } from 'hooks/useSuperCollections';
import useToast from 'hooks/useToast';
import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as Draft } from 'static/imgs/file-check.svg';
import { ReactComponent as Logo } from 'static/imgs/logo.svg';

import classes from './Footer.module.scss';
import { IProps } from './types';

const Footer: React.FC<IProps> = ({ isOptionsPage, productGroups }) => {

	const { sendOptions, isValidOptions } = useOptions();
	const { sendProducts, isValidProducts } = useProducts();
	const { sendCollections, showCollections } = useCollections();
	const { sendSuperCollections, showSuperCollections } = useSuperCollections();
	const { t } = useTranslation('form');
	const { errorToast } = useToast();
	const { storeAddressSendingCount, disableSendButton, setDisableSendButton } = useStoreAddress();
	const isMaxSendCount = storeAddressSendingCount >= maxSendingCount;

	const isValid = () => {
		if (isOptionsPage) return isValidOptions;
		return isValidProducts;
	};

	const handleSend = (sendToGlovo: boolean) => () => {
		if (!isValid()) return;

		if (isMaxSendCount && sendToGlovo) {
			errorToast(t('errMaxSend'));
			return;
		}

		if (!isOptionsPage) setDisableSendButton(true);

		if (isOptionsPage) {
			sendOptions();
		} else if (showCollections) {
			showSuperCollections ? sendSuperCollections(productGroups, sendToGlovo) : sendCollections(productGroups, sendToGlovo);
		} else  {
			sendProducts(productGroups, sendToGlovo);
		};
	};

	return (
		<div className={classes.footer}>
			{!isOptionsPage && (
				<div className={classes.saveAsDraft}>
					<div
						className={clsx(classes.sendButton, classes.sendButton__asDraft)}
						onClick={handleSend(false)}
					>
						<span><Draft /></span>
						{t('saveAsDraft')}
					</div>
					<LastSaveDraft />
				</div>
			)}
			<div
				data-testid="div-valid-test"
				className={clsx(
					classes.sendButton, classes.sendButton__toGlovo,
					{ [classes.disabled]: (!isValid() || isMaxSendCount || disableSendButton)},
					{ [classes.oneToMaxSendingCount]: storeAddressSendingCount === (maxSendingCount - 1)},
					{ [classes.enableClick]: isMaxSendCount},
					{ [classes.isOptionsPage]: isOptionsPage },
				)}
				onClick={handleSend(true)}
			>
				{isOptionsPage
					? t('saveShort')
					: <>
						<span><Logo /></span>
						{t('sendToGlovo')}
						<div className={classes.sendingCount}>
							{storeAddressSendingCount || 0}<i>/</i>{maxSendingCount}
						</div>
						<div className={classes.countNotification}>
							{isMaxSendCount
								? t('attemptsNumberExhausted')
								: <>{t('youHave')} {maxSendingCount - storeAddressSendingCount} {t('from')} {maxSendingCount} {t('attempts')}</>
							}
						</div>
					</>}
			</div>
			<div className={classes.sendFeedback}>
				<SendFeedback/>
			</div>
			{!isOptionsPage && <ItemIsChecked/>}
		</div>
	);
};

export default memo(Footer);
