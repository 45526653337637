import AddressOption from 'components/AddressOption';
import clsx from 'helpers/clsx';
import { useAppSelector } from 'hooks/redux';
import React from 'react';
import ReactDOM from 'react-dom';
import { useTranslation } from 'react-i18next';

import classes from './AvailableAddresses.module.scss';
import { IPropsList } from './types';

const AddressList: React.FC<IPropsList> = ({ data, onChangeActive, handleLabelClick, addressPopupTitle,
	addressPopupSec, collectionsInSup, itemSelector,
}) => {
	const { t } = useTranslation('multiStore');
	const { needsOptimization } = useAppSelector(state => state.collections);

	const renderOverlay = () => <div className={classes.addressListOverlay} onClick={handleLabelClick} />;

	return (
		<>
			{itemSelector === 'super_collection' ? ReactDOM.createPortal(renderOverlay(), document.body) : renderOverlay()}
			<div
				className={clsx(classes.addressList, {[classes.toLeft]: needsOptimization && itemSelector === 'super_collection'})}
			>
				<div className={classes.addressList_title}>
					{addressPopupTitle || t('addressPopupTitle')}
				</div>
				<div className={classes.addressList_info}>
					<div>{t('addressPopupLoc')}</div>
					<div>{addressPopupSec || t('addressPopupSec')}</div>
				</div>
				<div className={clsx(classes.addressList_options,
					// if availability items > 5
					{[classes.needsOptimization]: needsOptimization && data.length > 5},
					// if count collections in the superCollection <= 1
					{[classes.onlyOneCollection]: needsOptimization && collectionsInSup && collectionsInSup.length <= 1},
				)}>
					{data.map(address => <AddressOption key={address.id} onChange={onChangeActive} address={address} />)}
				</div>
			</div>
		</>
	);
};

export default AddressList;
