import formatFn from 'date-fns/format';
import en from 'date-fns/locale/en-US';
import ru from 'date-fns/locale/ru';
import uk from 'date-fns/locale/uk';

import { getLang } from './lang';

const getLocale = () => {
	const lang = getLang();
	switch (lang) {
		case 'uk': return uk;
		case 'ru': return ru;
		case 'en': return en;
		default: return uk;
	}
};

export const format = (date: Date | number, formatDate: string, options?: {
	locale?: Locale
	weekStartsOn?: 0 | 1 | 2 | 3 | 4 | 5 | 6
	firstWeekContainsDate?: number
	useAdditionalWeekYearTokens?: boolean
	useAdditionalDayOfYearTokens?: boolean
}) => {
	const locale = getLocale();
	return formatFn(date, formatDate, {
		locale,
		...options,
	});
};
