import Footer from 'containers/Footer';
import { useAppSelector } from 'hooks/redux';
import React from 'react';

import { IProps } from './types';

const FooterContainer: React.FC<IProps> = ({isOptionsPage}) => {
	const { productGroups } = useAppSelector(state => state.products);

	return <Footer isOptionsPage={isOptionsPage} productGroups={productGroups} />;
};

export default FooterContainer;
