import SendFeedbackPopup from 'components/SendFeedbackPopup';
import React, { useState } from 'react';
import { ReactComponent as FeedbackIco } from 'static/imgs/feedback_ico.svg';

import classes from './SendFeedback.module.scss';

const SendFeedback: React.FC = () => {
	const [showPopup, setShowPopup] = useState<boolean>(false);

	const handleOpen = () => setShowPopup(true);
	const handleClose = () => setShowPopup(false);

	return(
		<>
			<div className={classes.button} onClick={handleOpen}><FeedbackIco/></div>
			<SendFeedbackPopup onClose={handleClose} open={showPopup} />
		</>
	);
};

export default SendFeedback;
