
import { format } from 'helpers/locale';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as CheckMark } from 'static/imgs/checkMark.svg';

import classes from './LastSaveDraft.module.scss';
import { IProps } from './types';

const LastSaveDraft: React.FC<IProps> = ({ date }) => {
	const { t } = useTranslation('products');
	if (!date) return null;

	return (
		<div className={classes.root}>
			<CheckMark className={classes.icon} />
			<div className={classes.text} data-testid="test_lastSaveDraft" >
				{t('lastSaveDraft')} <span>{format(new Date(date), 'dd MMMM, HH:mm')}</span>
			</div>
		</div>
	);
};

export default React.memo(LastSaveDraft);
