import { createAsyncThunk } from '@reduxjs/toolkit';
import { clientGraphql } from 'api/graphql';
import { fetchStoreAddressSendingCountReq, GET_STORE_ADDRESSES_DATA } from 'api/storeAddressesData';
import { IJwt } from 'models/IJwt';

export const fetchStoreAddressesData = createAsyncThunk(
	'storeAddresses/fetchStoreAddresses',
	async (userStoreAddressesIds: IJwt['UserStoreAddressesIds'], thunkAPI) => {
		try {
			return await clientGraphql.query({
				query: GET_STORE_ADDRESSES_DATA,
				variables: { storeAddressIds: userStoreAddressesIds },
			});
		} catch (e) {
			return thunkAPI.rejectWithValue('Failure');
		}
	},
);

export const fetchStoreAddressSendingCountData = createAsyncThunk(
	'products/fetchStoreAddressSendingCount',
	async ({storeAddressId, isMultiStore}: {storeAddressId: string, isMultiStore: boolean}, thunkAPI) => {
		try {
			const result = await fetchStoreAddressSendingCountReq(storeAddressId, isMultiStore).catch(() => undefined);
			if (!result) throw new Error('Result not found');
			return result;
		} catch (e) {
			return thunkAPI.rejectWithValue('Failure');
		}
	},
);
