import './static/styles/index.scss';
import 'helpers/rum';
import './i18n';

import { ApolloProvider } from '@apollo/client';
import { clientGraphql } from 'api/graphql';
import AppContextProvider from 'contexts/App';
import GlobalDndContext from 'dnd-context';
import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import App from './App';
import { setupStore } from './store';

const store = setupStore();

ReactDOM.render(
	<ApolloProvider client={clientGraphql}>
		<GlobalDndContext>
			<Suspense fallback={null}>
				<Provider store={store}>
					<BrowserRouter basename={process.env.PUBLIC_URL}>
						<AppContextProvider><App /></AppContextProvider>
					</BrowserRouter>
				</Provider>
			</Suspense>
		</GlobalDndContext>
	</ApolloProvider>,
	document.getElementById('root'),
);
