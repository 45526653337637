import i18n from 'i18n';

import { IRuleImage } from './types';

export class ImageValidator implements IRuleImage {

	public static maxSizeInMb = 2;
	private static allowTypes = ['jpg', 'jpeg', 'png'];
	private passesBySizeStatus = true;
	private passesBySizeInMbStatus = true;
	private passesByTypeStatus = true;

	constructor(private minWidth: number = 500, private minHeight: number = 500, private uploadImgSize: number, private square?: boolean) {}

	private passesBySizeInMb(size: number) {
		return size < ImageValidator.maxSizeInMb;
	}

	private async passesBySize(value: string) {
		const image = new Image();
		image.src = value;
		await image.decode();
		const correctWidth = image.width >= this.minWidth;
		const correctHeight = !this.minHeight || image.height >= this.minHeight;
		const correctSize = correctWidth && correctHeight;
		const isSquare = !this.square || image.width === image.height;

		return correctSize && isSquare;
	}

	private passesByType(value: string) {
		const [,type] = value.split(';')[0].split('/');
		return ImageValidator.allowTypes.includes(type) || type.length === 0; // empty type this is images from server
	}

	private passesByValue(value: string) {
		return !value.length;
	}

	async passes(value: string): Promise<boolean> {
		this.passesBySizeStatus = await this.passesBySize(value);
		this.passesBySizeInMbStatus = this.passesBySizeInMb(this.uploadImgSize);
		this.passesByTypeStatus = this.passesByType(value);

		return this.passesByValue(value) || (this.passesBySizeStatus && this.passesBySizeInMbStatus && this.passesByTypeStatus);
	}

	message(): string[] {
		let validationStatusesView = [];

		if (!this.passesBySizeStatus) validationStatusesView.push(i18n.t('validation:size'));
		if (!this.passesBySizeInMbStatus) validationStatusesView.push(i18n.t('validation:sizeInMb'));
		if (!this.passesByTypeStatus) validationStatusesView.push(i18n.t('validation:type'));

		return validationStatusesView;
	}
}
