import clsx from 'helpers/clsx';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as EmptyCollectionPhoto } from 'static/imgs/empty_collention_photo.svg';

import classes from '../PopupMoveItems.module.scss';
import { IPropsTemplateSectionMove } from '../types';

const SectionsMove: React.FC<IPropsTemplateSectionMove> = ({ collection, collectionForMoveId, setItemForMove }) => {
	const { t } = useTranslation('menu');

	return (
		<div key={collection.id} className={classes.itemForMove} onClick={setItemForMove('collection', collection)}>
			{collection.image.length ? (
				<img className={classes.itemForMoveImg} src={collection.image} alt="" />
			) : (
				<div className={classes.emptyImg}><EmptyCollectionPhoto/></div>
			)}
			<div className={classes.itemForMoveName}>{!!collection.title.length ? collection.title : t('newCollection')}</div>
			<span className={clsx(
				classes.checkedStatus,
				{[classes.checked]: (collectionForMoveId && collection.id === collectionForMoveId)},
			)}/>
		</div>
	);
};

export default SectionsMove;
