import clsx from 'helpers/clsx';
import { useAppSelector } from 'hooks/redux';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as EmptyCollectionPhoto } from 'static/imgs/empty_collention_photo.svg';
import {ReactComponent as ArrowBottom} from 'static/imgs/keyboard_arrow_down.svg';

import classes from '../PopupMoveItems.module.scss';
import { IPropsTemplateProductMove } from '../types';

const ProductsMove: React.FC<IPropsTemplateProductMove> = ({ collection, setItemForMove, sectionForMoveId }) => {
	const [openList, setOpenList] = useState(collection ? false : true);
	const { productGroups } = useAppSelector(state => state.products);
	const { t } = useTranslation('menu');

	const handleOpen = () => setOpenList(!openList);

	return (
		<>
			{collection && (
				<div key={collection.id} className={classes.itemForMove} onClick={handleOpen}>
					{collection.image.length ? (
						<img className={classes.itemForMoveImg} src={collection.image} alt="" />
					) : (
						<div className={classes.emptyImg}><EmptyCollectionPhoto/></div>
					)}
					<div className={classes.itemForMoveName}>{!!collection.title.length ? collection.title : t('newCollection')}</div>
					<span className={clsx(classes.arr, {[classes.rotate]: openList})}><ArrowBottom/></span>
				</div>
			)}
			{openList && (
				<div className={classes.sectionsWrap}>{productGroups.map((section) => {
					if (collection && !collection.productGroups.includes(section.id)) return null;

					return (
						<div className={classes.sectionForMove} onClick={setItemForMove('section', section)} key={section.id}>
							<div className={classes.sectionForMoveTitle}>{!!section.title.length ? section.title : t('newSection')}</div>
							<span className={clsx(
								classes.checkedStatus,
								{[classes.checked]: (sectionForMoveId && section.id === sectionForMoveId)},
							)}/>
						</div>
					);
				})}</div>
			)}
		</>
	);
};

export default ProductsMove;
