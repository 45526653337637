import ErrorValidation from 'components/ErrorValidation';
import InputForEnteringInform from 'components/InputForEnteringInform';
import PromptIco from 'components/PromptIco';
import MultiLangInput from 'containers/MultiLangInput';
import { bytesToMegaBytes } from 'helpers';
import clsx from 'helpers/clsx';
import { fetch } from 'helpers/fetch';
import { useAppSelector } from 'hooks/redux';
import { useMultiLang } from 'hooks/useMultiLang';
import usePrompts from 'hooks/usePrompts';
import { ITranslations } from 'models/IProduct';
import { ECollectionsPrompts, ESectionPrompts } from 'models/IPrompts';
import React, { useState } from 'react';
import { Cropper } from 'react-cropper';
import { Trans, useTranslation } from 'react-i18next';
import { ImageValidator } from 'services/Validator/ImageValidator';
import { ReactComponent as IIco } from 'static/imgs/i.svg';
import loader from 'static/imgs/loader.gif';
import { ReactComponent as ManageLangIco } from 'static/imgs/manageLangIco.svg';
import { ReactComponent as ProductRemove } from 'static/imgs/productRemove.svg';
import { ReactComponent as UploadIco } from 'static/imgs/uploadIco.svg';

import classes from '../EditCollectionPopup.module.scss';
import { IPropsMultiLangTemplate } from '../types';

const MultiLangTemplate: React.FC<IPropsMultiLangTemplate> = ({ collection, isSuperCollection, setName,
	name, collectionsPrompt, setError, image, error, setImage, langsValues, setLangsValues,
	setCropper, setUploadImgSize, generateIsProgress, setGenerateIsProgress,
}) => {
	const { t } = useTranslation(['collections', 'requirements', 'form', 'hintTexts', 'validation', 'multiLanguages']);
	const [drag, setDrag] = useState<boolean>(false);
	const { togglePromptSection } = usePrompts();
	const { multiLangData } = useMultiLang();
	const [previewSize, setPreviewSize] = useState<[number, number]>([0, 0]);
	const { defaultLanguage, languages } = useAppSelector(state => state.multiLang.multiLangData);
	const [showCheckText, setShowCheckText] = useState<boolean>(false);

	const dragStartHandler = (event: React.DragEvent<HTMLDivElement>) => {
		event.preventDefault();
		setDrag(true);
	};

	const dragLeaveHandler = (event: React.DragEvent<HTMLDivElement>) => {
		event.preventDefault();
		setDrag(false);
	};

	const removeImg = () => {
		setImage('');
		setError([]);
		setUploadImgSize(0);
		setPreviewSize([0, 0]);
	};

	const onCrop = (event: any) => {
		setPreviewSize([parseInt(event.detail.width), parseInt(event.detail.height)]);
	};

	const handleFile = (f: File) => {
		const reader = new FileReader();
		reader.addEventListener('load', (event) => {
			if (typeof event.target?.result === 'string') {
				setImage(event.target.result);
				setUploadImgSize(bytesToMegaBytes(event.total));
			}
		});
		reader.readAsDataURL(f);

		togglePromptSection(ESectionPrompts.SECTIONS_IMG_POPUP_SAVE);
		setError([]);
	};

	const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const files = event.target.files;
		if (files && files.length) handleFile(files[0]);
	};

	const dropHandler = (event: React.DragEvent<HTMLDivElement>) => {
		event.preventDefault();
		const files = event.dataTransfer.files;
		if (files && files.length) handleFile(files[0]);
	};

	const handleCollectionName = (value: string) => setName(value);

	const handleChange = (shortLang: string, value: string) => {
		const copy = Object.keys(langsValues || {}).reduce((acc: ITranslations, key) => {
			if (key === shortLang) {
				acc[key] = {
					...langsValues[key],
					title: value,
					validation: undefined,
				};
			} else {
				acc[key] = {
					...langsValues[key],
					validation: undefined,
				};
			}

			return acc;
		}, {});

		setLangsValues(copy);
	};

	const generateTranslate = () => {
		setGenerateIsProgress(true);
		fetch<{[key: string]: string}>('/multi-lang/translate-text', {
			method: 'post',
			body: JSON.stringify({
				'text': name,
				'current_language': defaultLanguage,
				'translate_to': languages,
			}),
		}).then((res) => {
			const prepareTranslations = {...langsValues};
			Object.keys(res || {}).forEach((langKey) => {
				prepareTranslations[langKey] = {
					...prepareTranslations[langKey],
					title: res[langKey],
					validation: undefined,
				};
			});
			setLangsValues(prepareTranslations);
		}).finally(() => {
			setGenerateIsProgress(false);
			setShowCheckText(true);
		});
	};

	const renderPreviewBlock = (imgIsUpload: boolean = false) => (
		<div className={clsx(classes.previewWrap, classes.isMultiLang)}>
			<div className={classes.miniPreview}>
				{previewSize[0] > 0 && <span className={classes.previewSize}>{previewSize[0]} px</span>}
				{previewSize[1] > 0 && <span className={clsx(classes.previewSize, classes.previewSizeHeight)}>{previewSize[1]} px</span>}
				<div className={clsx(classes.miniPreviewImg, classes.miniPreviewImgPlug)}>
					{imgIsUpload && <div className={clsx(classes.previewBody, 'js_previewBody')} />}
					{isSuperCollection && <div className={classes.previewSupCollectionTitle}>{name}</div>}
				</div>
			</div>
			<div className={classes.description}><IIco/>{t('multiLanguages:translationDescription')}</div>
			<div className={classes.inputNameWrap}>
				<div className={classes.defaultLang}>{multiLangData.defaultLanguage}</div>
				<ErrorValidation className={classes.collectionValid} rootClassName={classes.rootValid} errors={collection.validation?.title}>
					<InputForEnteringInform
						hideLangBar
						showExample
						onChange={handleCollectionName}
						allowedNumber={40}
						value={name}
						showPrompt={collectionsPrompt === ECollectionsPrompts.COLLECTIONS_NAME}
						promptInfo={{
							title: t('hintTexts:collectionName.title'),
							text: t('hintTexts:collectionName.text'),
						}}
						label={isSuperCollection ? t('nameSup') : t('name')}
						placeholder={isSuperCollection ? t('nameSup') : t('name')}
						errors={collection.validation?.title}
					/>
				</ErrorValidation>
			</div>
			<div className={
				clsx(classes.generateTranslateButton,{[classes.generateIsProgress]: generateIsProgress || !name.length})
			}>
				<span onClick={generateTranslate}><ManageLangIco/>{t('multiLanguages:generateTranslation')}</span>
			</div>
			{Object.keys(langsValues || {}).map((item) => {
				return (
					<div className={clsx(classes.inputNameWrap, {[classes.generateIsProgress]: generateIsProgress})} key={item}>
						<MultiLangInput
							rootClass={classes.inputWrap}
							onChange={handleChange}
							shortLang={item}
							value={langsValues[item].title}
							allowedNumber={40}
							errors={langsValues[item].validation}
							label={isSuperCollection ? t('nameSup') : t('name')}
							placeholder={isSuperCollection ? t('nameSup') : t('name')}
						/>
					</div>
				);
			})}
			{generateIsProgress && <div className={classes.generateLoader}><img src={loader} alt="loader" /></div>}
			{showCheckText && <div className={clsx(classes.description, classes.checkTranstalions)}>
				<IIco/>{t('multiLanguages:checkTranslations')}
			</div>}
		</div>
	);

	return (
		<div className={clsx(classes.uploadWrap, classes.isMultiLang, {[classes.isSuperCollection]: isSuperCollection})}>
			{image ? (
				<>
					{renderPreviewBlock(true)}
					<div>
						<ErrorValidation errors={error} className={classes.error} rootClassName={classes.rootValidation}>
							<div className={classes.imgWrap}>
								<div className={classes.removeImg} onClick={removeImg}><ProductRemove /></div>
								<Cropper
									style={{ height: '100%', width: '100%' }}
									initialAspectRatio={isSuperCollection ? 1240/690 : 1}
									preview=".js_previewBody"
									aspectRatio={isSuperCollection ? 1240/690 : 1}
									zoomable={false}
									center={false}
									guides={false}
									src={image}
									viewMode={1}
									minCropBoxHeight={10}
									minCropBoxWidth={10}
									background={false}
									responsive={true}
									autoCropArea={1}
									checkOrientation={false}
									onInitialized={(instance) => {
										setCropper(instance);
									}}
									crop={onCrop}
								/>
							</div>
						</ErrorValidation>
						<div className={classes.infoWrap}>
							<IIco />
							<div className={classes.infoWrapText}>
								<Trans
									i18nKey={'requirements:imageExtend'}
									t={t}
									values={{
										width: isSuperCollection ? 1240 : 690,
										height: 690,
										size: ImageValidator.maxSizeInMb,
									}}
									components={{
										span: <span />,
										div: <div />,
									}}
								/>
							</div>
						</div>
					</div>
				</>
			) : (
				<>
					{renderPreviewBlock()}
					<div>
						<div
							onDragStart={dragStartHandler}
							onDragLeave={dragLeaveHandler}
							onDragOver={dragStartHandler}
							onDrop={dropHandler}
							className={clsx(classes.imgWrap, {
								[classes.imgWrapLoad]: drag,
							})}
						>
							<span><UploadIco /></span>
							<div className={classes.text}>
								<Trans t={t} i18nKey="form:labelUploadFile" />
							</div>
							<label htmlFor="loadInput" className={classes.uploadButton}>{t('form:upload')}</label>
							<input
								className={classes.noneInput}
								type="file"
								id="loadInput"
								accept="image/jpg, image/jpeg, image/png"
								onChange={handleImageChange}
							/>
							{ECollectionsPrompts.COLLECTIONS_IMG === collectionsPrompt && (
								<div className={classes.promptIco}>
									<PromptIco />
								</div>
							)}
						</div>
						<div className={classes.infoWrap}>
							<IIco />
							<div className={classes.infoWrapText}>
								<Trans
									i18nKey={'requirements:imageExtend'}
									t={t}
									values={{
										width: isSuperCollection ? 1240 : 690,
										height: 690,
										size: ImageValidator.maxSizeInMb,
									}}
									components={{
										span: <span />,
										div: <div />,
									}}
								/>
							</div>
						</div>
					</div>
				</>
			)}
		</div>
	);
};

export default MultiLangTemplate;
