import { format } from 'helpers/locale';
import { useUser } from 'hooks/useUser';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as SyncIco } from 'static/imgs/sync_ico.svg';

import classes from './SyncWithPartner.module.scss';

const SyncWithPartner: React.FC = () => {
	const { lastSyncData, fetchSyncWithPartner }  = useUser();
	const { t } = useTranslation('menu');

	return (
		<div className={classes.root}>
			<div className={classes.button} onClick={fetchSyncWithPartner} ><SyncIco/>{t('syncWithPartner')}</div>
			{lastSyncData.last_reimported_at && <div className={classes.lastSync}>
				{t('syncWithPartnerDate')} {format(new Date(lastSyncData.last_reimported_at), 'dd MMMM, HH:mm')}
			</div>}
		</div>
	);
};

export default SyncWithPartner;
