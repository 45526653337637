import clsx from 'helpers/clsx';
import React from 'react';

import classes from './IconButton.module.scss';
import { IProps } from './types';

const IconButton: React.FC<IProps> = ({ children, className, onClick, size = 'default', disabled, elementType = 'button', href }) => {
	const element = React.createElement(elementType);
	return(
		<element.type
			className={clsx(classes.root, className, {
				[classes.average]: size === 'average',
				[classes.small]: size === 'small',
			})}
			data-testid="test_iconButton"
			onClick={onClick}
			disabled={disabled}
			href={href}
			target="_blank"
		>
			{children}
		</element.type>
	);
};

export default IconButton;
