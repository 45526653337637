import clsx from 'helpers/clsx';
import React from 'react';
import { useTranslation } from 'react-i18next';
import {ReactComponent as ArrowBottom} from 'static/imgs/keyboard_arrow_down.svg';

import classes from './CollapseAllMenu.module.scss';
import { IProps } from './types';

const CollapseAllMenu: React.FC<IProps> = ({ onClick, isCollapseAll }) => {
	const { t } = useTranslation('form');
	return (
		<div data-testid="test_CollapseAllMenu" className={clsx(classes.root, {[classes.isCollapseAll]: isCollapseAll})}>
			<div onClick={onClick} className={classes.collapseAllButton}>
				<span><ArrowBottom/></span>{t('collapseAll')}
			</div>
		</div>
	);
};

export default CollapseAllMenu;
