import Loader from 'components/Loader';
import SiteNavigation from 'components/SiteNavigation';
import FooterContainer from 'containers/FooterContaner';
import Header from 'containers/Header';
import PopupProductRemove from 'containers/PopupProductRemove';
import { useAppSelector } from 'hooks/redux';
import { useOptions } from 'hooks/useOptions';
import { useProducts } from 'hooks/useProducts';
import React from 'react';

import classes from './IndexLayout.module.scss';
import { IProps } from './types';

const IndexLayout: React.FC<IProps> = ({ children, isOptionsPage = false, collapseAll }) => {
	const { loadingProducts } = useProducts();
	const { loadingOptions } = useOptions();
	const { removeGroupId, removeItemId, setForceRemovePopupView } = useAppSelector((state) => state.modals);

	if (loadingProducts || loadingOptions) return <Loader />;

	return (
		<>
			<PopupProductRemove
				isOptionsPage={isOptionsPage || !!setForceRemovePopupView}
				removeGroupId={removeGroupId}
				removeItemId={removeItemId}
			/>
			<div className={classes.root}>
				<SiteNavigation />
				<div className={classes.content}>
					<Header isOptionsPage={isOptionsPage} collapseAll={collapseAll} />
					{children}
				</div>
			</div>
			<FooterContainer isOptionsPage={isOptionsPage} />
		</>
	);
};

export default IndexLayout;
