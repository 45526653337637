import InputForEnteringInform from 'components/InputForEnteringInform';
import clsx from 'helpers/clsx';
import React from 'react';

import classes from './MultiLangInput.module.scss';
import { IProps } from './types';

const MultiLangInput: React.FC<IProps> = ({
	shortLang,value, allowedNumber, onChange, isDescription = false,
	errors, label, placeholder, rootClass,
}) => {
	const handleChange = (valueInput: string) => {
		onChange(shortLang, valueInput);
	};

	if (value === undefined) return null;
	return (
		<div className={clsx(classes.root, rootClass)}>
			<span className={classes.shortLang} data-testid="test_shortLang">{shortLang}</span>
			<InputForEnteringInform
				hideLangBar
				isTextarea={isDescription}
				allowedNumber={allowedNumber}
				value={value}
				onChange={handleChange}
				errors={errors}
				label={label}
				placeholder={placeholder}
			/>
		</div>
	);
};

export default MultiLangInput;
