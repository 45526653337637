
import { fetchDateSaveDraft as fetchDateSaveDraftAction } from 'store/reducers/dateSaveDraft/actions';

import { useAppDispatch } from './redux';
import { useStoreAddress } from './useStoreAddress';

interface IUseDateSaveDraft {
	fetchDateSaveDraft(): void;
};

export const useDateSaveDraft = (): IUseDateSaveDraft => {
	const { currentStoreId, isMultiStore, currentStoreAddressId } = useStoreAddress();
	const dispatch = useAppDispatch();

	const fetchDateSaveDraft = () => {
		const storeId = isMultiStore ? currentStoreId : currentStoreAddressId;
		if (storeId) {
			dispatch(fetchDateSaveDraftAction({storeAddressId: storeId, isMultiStore}));
		}
	};

	return {
		fetchDateSaveDraft,
	};
};
