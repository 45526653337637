import Modal from 'components/Modal';
import { sendFeedbackUrl } from 'const';
import { fetch } from 'helpers/fetch';
import { getIdFromToken } from 'helpers/jwt';
import { useStoreAddress } from 'hooks/useStoreAddress';
import { useUser } from 'hooks/useUser';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import classes from './SendFeedbackPopup.module.scss';
import DefaultTemplate from './templates/default';
import FailTemplate from './templates/fail';
import SuccessTemplate from './templates/success';
import { IProps } from './types';

const SendFeedbackPopup: React.FC<IProps> = ({open, onClose}) => {
	const { user } = useUser();
	const { t } = useTranslation('validation');
	const { currentStoreId, currentStoreAddressId, currentStoreData } = useStoreAddress();
	const [message, setMessage] = useState('');
	const [status, setStatus] = useState<'default' | 'success' | 'fail'>('default');
	const [errors, setErrors] = useState<string[]>([]);

	const handleSend = () => {
		if (message) {
			fetch<{messageId?: string}>('/feedbackMenutool', {
				method: 'post',
				body: JSON.stringify({
					message,
					email: user?.email,
					userId: getIdFromToken(),
					currentStoreId: currentStoreId,
					currentStoreAddressId: currentStoreAddressId,
					currentStoreName: currentStoreData?.storeName,
				}),
			}, sendFeedbackUrl).then((res) => {
				res.messageId ? setStatus('success') : setStatus('fail');
			}).catch(() => { setStatus('fail'); });
		} else setErrors([t('required')]);
	};

	const handleChange = (value: string) => {
		setMessage(value);
		setErrors([]);
	};

	const handleClose = () => {
		onClose();
		setMessage('');
		setStatus('default');
		setErrors([]);
	};

	return (
		<Modal onClose={handleClose} open={open}>
			<div className={classes.root}>
				{status === 'default' ? <DefaultTemplate handleSend={handleSend} message={message} setMessage={handleChange} errors={errors} />
					: status === 'success' ? <SuccessTemplate/>
						: <FailTemplate />}
			</div>
		</Modal>
	);
};

export default SendFeedbackPopup;
