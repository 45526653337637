import SuperCollectionHead from 'components/SuperCollectionHead';
import clsx from 'helpers/clsx';
import { useAppSelector } from 'hooks/redux';
import { SuperCollectionsIdsEnum, useSuperCollections } from 'hooks/useSuperCollections';
import React, { useEffect, useMemo, useRef, useState } from 'react';

import classes from './SuperCollection.module.scss';
import { IProps } from './types';

const SuperCollection: React.FC<IProps> = ({
	children, superCollection, defaultCollapse = false,
	isCollapseAll, collapseAll, collapseOtherItems,
}) => {
	const [isCollapse, setIsCollapse] = useState<boolean>(defaultCollapse);
	const [collapseAfterAnim, setCollapseAfterAnimate] = useState<boolean>(isCollapse);
	const { updateSuperCollection } = useSuperCollections();
	const superCollectionRef = useRef<HTMLDivElement>(null);
	const { needsOptimization } = useAppSelector(state => state.collections);
	const childCollapseFlag = useMemo(() => {
		if (needsOptimization) return collapseAfterAnim;
		return isCollapse;
	}, [collapseAfterAnim, isCollapse, needsOptimization]);

	useEffect(() => {
		if (isCollapseAll) setIsCollapse(isCollapseAll);
	}, [isCollapseAll]);

	useEffect(() => {
		setIsCollapse(defaultCollapse);
		if (!defaultCollapse) updateSuperCollection(superCollection.id, 'saveCollapseValue', defaultCollapse);
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [defaultCollapse]);

	const handleCollapse = () => {
		const nextCollapseValue = !isCollapse;
		setIsCollapse(nextCollapseValue);
		updateSuperCollection(superCollection.id, 'saveCollapseValue', nextCollapseValue);

		if (needsOptimization) collapseOtherItems(superCollection.id, 'superCollection');
		collapseAll(false, true);
	};

	useEffect(() => {
		if (isCollapse) {
			setTimeout(() => {
				setCollapseAfterAnimate(isCollapse);
			}, 700);
		} else {
			setCollapseAfterAnimate(isCollapse);
		}
	}, [isCollapse]);

	return (
		<div
			ref={superCollectionRef}
			className={clsx(classes.root, SuperCollectionsIdsEnum.SUPER_COLLECTION, { [classes.disabled]: !superCollection.active })}
			id={superCollection.id}
		>
			<SuperCollectionHead
				superCollection={superCollection}
				isCollapse={isCollapse}
				handleCollapse={handleCollapse}
			/>
			{<div className={clsx(classes.children, {[classes.animCollapse]: (isCollapse && needsOptimization)})}>
				{!childCollapseFlag && children}
			</div>}
		</div>
	);
};

export default SuperCollection;
