import Attention from 'components/Attention';
import { PROMPS_OPTIONS_ARE_DISPLAYED } from 'const';
import OptionsGroup from 'containers/OptionsGroup';
import OptionsContextProvider from 'contexts/Options';
import storage from 'helpers/localStorage';
import { useOptions } from 'hooks/useOptions';
import { useProducts } from 'hooks/useProducts';
import useScroll from 'hooks/useScroll';
import IndexLayout from 'layouts/IndexLayout';
import { EOptionsPrompts } from 'models/IPrompts';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { getStartedSlice } from 'store/reducers/getStarted';

import classes from './Options.module.scss';

const Options: React.FC = () => {
	const { optionGroups, loadingOptions, createEmptyGroupOptions,
		updateOptionGroup, updateOption, createEmptyOption,
		optionsPageScrollPosition, setOptionsPageScrollPosition } = useOptions();
	const dispatch = useDispatch();
	const { setOptionsPromptState } = getStartedSlice.actions;
	const { t } = useTranslation('options');
	const { clearSearchResult } = useProducts();

	useScroll(setOptionsPageScrollPosition);

	useEffect(() => {
		window.scrollTo({top: optionsPageScrollPosition});
		clearSearchResult();
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (!optionGroups.length && !loadingOptions) {
			createEmptyGroupOptions();
			if (!storage.getItem(PROMPS_OPTIONS_ARE_DISPLAYED)) {
				dispatch(setOptionsPromptState(EOptionsPrompts.OPTIONS_GROUP_NAME));
				storage.setItem(PROMPS_OPTIONS_ARE_DISPLAYED, true);
			}
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [loadingOptions]);

	return (
		<IndexLayout isOptionsPage>
			<div className={classes.root}>
				<div className={classes.topBar}>
					<div className={classes.title}>{t('optionSections')}</div>
					<Attention text={t('attentionSave')} />
				</div>
				<OptionsContextProvider
					updateOptionGroup={updateOptionGroup}
					updateOption={updateOption}
					createEmptyOption={createEmptyOption}
				>
					{optionGroups.map((group) => (
						<OptionsGroup group={group} key={group.id}/>
					))}
				</OptionsContextProvider>
			</div>
		</IndexLayout>
	);
};

export default Options;
