import { gql } from '@apollo/client';

export default gql`
	type StoreAddressesDataDto {
		id: ID!
		storeAddressId: String
		storeName: String
		address: String
   }
`;
