import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { calcIsMultiStoreValue, checkIsObject, prepareStoreData } from 'helpers';
import { IJwt } from 'models/IJwt';
import { IStoreAddressesPayload,IStoresData } from 'models/IStoreAddresses';

import { fetchStoreAddressesData, fetchStoreAddressSendingCountData } from './actions';

export interface IState {
	storeAddressesIds: string[];
	userStoreIds: string[];
	currentStoreId?: string;
	currentStoreAddressId?: string;
	storeAddressesData: IStoresData[];
	isMultiStore: boolean;
	currentStoreData: IStoresData | null;
	addressesIdsInStore: number[];
	storeAddressSendingCount: number;
	disableSendButton: boolean;
}

const initialState: IState = {
	storeAddressesIds: [],
	userStoreIds: [],
	storeAddressesData: [],
	isMultiStore: false,
	currentStoreData: null,
	addressesIdsInStore: [],
	storeAddressSendingCount: 0,
	disableSendButton: false,
};

export const storeAddressSlice = createSlice({
	name: 'storeAddress',
	initialState,
	reducers: {
		setCurrent(state: IState, action: PayloadAction<string>) {
			state.currentStoreId = action.payload;
			const currentStoreData = calcIsMultiStoreValue(state.storeAddressesData, action.payload);
			state.currentStoreData = currentStoreData;
			state.addressesIdsInStore = currentStoreData?.storeAddresses.map((item) => item.storeAddressId) || [];
			if (currentStoreData && currentStoreData?.storeAddresses.length > 1) {
				state.isMultiStore = true;
				state.currentStoreAddressId = undefined;
			} else {
				state.isMultiStore = false;
				state.currentStoreAddressId = String(currentStoreData?.storeAddresses[0].storeAddressId);
			}
		},
		setCurrentStoreAddress(state: IState, action: PayloadAction<string>) {
			state.currentStoreAddressId = action.payload;
		},
		setAllStoreAddressesIds(state: IState, action: PayloadAction<IJwt['UserStoreAddressesIds']>) {
			state.storeAddressesIds = action.payload;
		},
		setAllUserStoreIds(state: IState, action: PayloadAction<IJwt['UserStoreIds']>) {
			state.userStoreIds = action.payload;
		},
		clear(state: IState) {
			state.currentStoreId = undefined;
			state.currentStoreAddressId = undefined;
			state.storeAddressesIds = [];
			state.storeAddressesData = [];
			state.userStoreIds = [];
			state.addressesIdsInStore = [];
		},
		incStoreAddressSendingCount(state: IState) {
			state.storeAddressSendingCount = ++state.storeAddressSendingCount;
		},
		setDisableSendButton(state: IState, action: PayloadAction<boolean>) {
			state.disableSendButton = action.payload;
		},
	},
	extraReducers: {
		[fetchStoreAddressesData.fulfilled.type]: (state, action: PayloadAction<IStoreAddressesPayload>) => {
			const storeAddressesData = prepareStoreData(action.payload.data.storeAddressData);
			state.storeAddressesData = storeAddressesData;
			if (state.currentStoreId) {
				const currentStoreData = calcIsMultiStoreValue(state.storeAddressesData, state.currentStoreId);
				state.currentStoreData = currentStoreData;
				state.addressesIdsInStore = currentStoreData?.storeAddresses.map((item) => item.storeAddressId) || [];

				if (currentStoreData && currentStoreData?.storeAddresses.length > 1) {
					state.isMultiStore = true;
					state.currentStoreAddressId = undefined;
				} else {
					state.isMultiStore = false;
					state.currentStoreAddressId = String(currentStoreData?.storeAddresses[0].storeAddressId);
				}
			}
		},
		[fetchStoreAddressSendingCountData.fulfilled.type]: (state, action: PayloadAction<{uploads: number | {}}>) => {
			const { uploads } = action.payload;
			const count = checkIsObject(uploads) ? Math.max(...Object.values(uploads)) : Number(uploads);

			state.storeAddressSendingCount = count;
		},
	},
});

export default storeAddressSlice.reducer;
