import { RefObject, useEffect } from 'react';

export function useClickAwayListener(ref: RefObject<any>, callback: (event: MouseEvent | TouchEvent) => void, additionElSelector: string | null = null) {
	useEffect(() => {
		const handleClickOutside = (event: MouseEvent | TouchEvent) => {
			const tar = event.target as HTMLElement;

			if (ref.current && !ref.current.contains(event.target) && !(additionElSelector && tar.closest(additionElSelector))) {
				callback(event);
			}
		};
		document.addEventListener('click', handleClickOutside);
		return () => {
			document.removeEventListener('click', handleClickOutside);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [ref]);
}
