export enum ESectionPrompts {
	SECTIONS_NAME = 'SECTIONS_NAME',
	SECTIONS_IMG = 'SECTIONS_IMG',
	SECTIONS_IMG_POPUP = 'SECTIONS_IMG_POPUP',
	SECTIONS_IMG_POPUP_SAVE = 'SECTIONS_IMG_POPUP_SAVE',
	PRODUCT_NAME = 'PRODUCT_NAME',
	PRODUCT_INGRADIENT = 'PRODUCT_INGRADIENT',
	PRODUCT_PRICE = 'PRODUCT_PRICE',
	ADD_PRODUCT = 'ADD_PRODUCT',
	ADD_SECTION = 'ADD_SECTION',
}

export enum EOptionsPrompts {
	OPTIONS_GROUP_NAME = 'OPTIONS_GROUP_NAME',
	OPTION_MIN_COUNT = 'OPTION_MIN_COUNT',
	OPTION_MAX_COUNT = 'OPTION_MAX_COUNT',
	OPTION_NAME = 'OPTION_NAME',
	OPTION_PRICE = 'OPTION_PRICE',
}

export enum ECollectionsPrompts {
	COLLECTIONS_EDIT = 'COLLECTIONS_EDIT',
	COLLECTIONS_NAME = 'COLLECTIONS_NAME',
	COLLECTIONS_IMG = 'COLLECTIONS_IMG',
	COLLECTIONS_POPUP_SAVE = 'COLLECTIONS_POPUP_SAVE',
}
