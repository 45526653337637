import { createAsyncThunk } from '@reduxjs/toolkit';
import { fetchLastSyncData as fetchLastSyncDataReq } from 'api/user';
import { fetch } from 'helpers/fetch';
import { IUser } from 'models/IUser';

export const fetchUser = createAsyncThunk(
	'user/fetchUser',
	async (_, thunkAPI) => {
		try {
			return await fetch<IUser>('/user.json');
		} catch (e) {
			return thunkAPI.rejectWithValue('Failure');
		}
	},
);

export const fetchLastSyncData = createAsyncThunk(
	'user/fetchLastSyncData',
	async (
		{storeAddressId, isMultiStore, addressesIdsInStore}: {storeAddressId: string, isMultiStore: boolean, addressesIdsInStore: string},thunkAPI,
	) => {
		try {
			const result = await fetchLastSyncDataReq(storeAddressId, isMultiStore, addressesIdsInStore).catch(() => undefined);
			if (!result) throw new Error('Result not found');
			return result;
		} catch (e) {
			return thunkAPI.rejectWithValue('Failure');
		}
	},
);
