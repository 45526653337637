import { APP_PREFIX, APP_PREFIX_COMMON, prefixApiAuthUrl } from 'const';
import addSeconds from 'date-fns/addSeconds';
import storage from 'helpers/localStorage';
import cookie from 'js-cookie';

const TOKEN_KEY = `${APP_PREFIX}token`;
const REFRESH_TOKEN_KEY = 'refreshToken';

const TOKEN_KEY_TEMP = `${APP_PREFIX_COMMON}tokenTemp`;
const REFRESH_TOKEN_KEY_TEMP = `${APP_PREFIX_COMMON}refreshTokenTemp`;

export const hasToken = (): boolean => !!cookie.get(TOKEN_KEY);
export const setToken = (token: string, expires = addSeconds(new Date(), 720)) => cookie.set(TOKEN_KEY, token, { expires });
export const getToken = (): string | undefined => cookie.get(TOKEN_KEY);
export const removeToken = () => cookie.remove(TOKEN_KEY);

export const hasRefreshToken = () => !!storage.getItem(REFRESH_TOKEN_KEY);
export const setRefreshToken = (token: string) => storage.setItem(REFRESH_TOKEN_KEY, token);
export const getRefreshToken = (): string | undefined => storage.getItem(REFRESH_TOKEN_KEY);
export const removeRefreshToken = () => storage.removeItem(REFRESH_TOKEN_KEY);

interface IResponseRefresh {
	jwt: string;
	expireInSeconds: number;
}

interface IRefreshToken {
	jwt: string;
	expireInSeconds: number;
}

export const refreshToken = (): Promise<IRefreshToken> => {
	return new Promise((resolve, reject) => {
		const token = getRefreshToken();
		fetch(`${prefixApiAuthUrl}/refreshToken`, {
			method: 'post',
			headers: {
				authorization: token ? `Bearer ${token}` : '',
			},
			credentials: 'omit',
		}).then(async (res) => {
			try {
				const { jwt, expireInSeconds }: IResponseRefresh = await res.json();
				setToken(jwt);
				resolve({jwt: jwt, expireInSeconds});
			} catch (_) {
				throw new Error('Can\'t refresh token');
			}
		}).catch(() => reject(undefined));
	});
};


export const saveTempTokens = () => {
	const token = getToken();
	const domain = process.env.NODE_ENV === 'production' ? '.glovoapp.com' : undefined;
	if (token) {
		cookie.set(TOKEN_KEY_TEMP, token, {
			domain,
		});
	}
	const tokenRefresh = getRefreshToken();
	if (tokenRefresh) {
		cookie.set(REFRESH_TOKEN_KEY_TEMP, tokenRefresh, {
			domain,
		});
	}
};

export const readTempTokens = (): Promise<boolean> => {
	return new Promise((resolve) => {
		const token = cookie.get(TOKEN_KEY_TEMP);
		const domain = process.env.NODE_ENV === 'production' ? '.glovoapp.com' : undefined;
		if (token) {
			setToken(token);
			cookie.remove(TOKEN_KEY_TEMP, {
				domain,
			});
		}
		const tokenRefresh = cookie.get(REFRESH_TOKEN_KEY_TEMP);
		if (tokenRefresh) {
			setRefreshToken(tokenRefresh);
			cookie.remove(REFRESH_TOKEN_KEY_TEMP, {
				domain,
			});
		}
		setTimeout(() => {
			resolve(true);
		}, 0);
	});
};
