import EditCollectionPopup from 'components/EditCollectionPopup';
import Checkbox from 'components/FormControl/Checkbox';
import ItemHasErr from 'components/ItemHasErr';
import PromptIco from 'components/PromptIco';
import PromptView from 'components/PromptView';
import SortIdentifier from 'components/SortIdentifier';
import AddElementButton from 'containers/AddElementButton';
import PopupCollectionRemove from 'containers/PopupCollectionRemove';
import clsx from 'helpers/clsx';
import { getGroupById } from 'helpers/products';
import { useAppSelector } from 'hooks/redux';
import { useCollections } from 'hooks/useCollections';
import { useUser } from 'hooks/useUser';
import { ECollectionsPrompts } from 'models/IPrompts';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as ChangeIco } from 'static/imgs/change.svg';
import { ReactComponent as EmptyCollectionPhoto } from 'static/imgs/empty_collention_photo.svg';
import { ReactComponent as ArrowBottom } from 'static/imgs/keyboard_arrow_down.svg';
import { ReactComponent as CollectionRemove } from 'static/imgs/productRemove.svg';

import classes from './CollectionHead.module.scss';
import { IProps } from './types';

const CollectionHead: React.FC<IProps> = ({
	isCollapse, handleCollapseCollection, closestSuperCollection,
	isActive = true, collection, handleAddSection,
}) => {
	const [openEditPopup, setOpenEditPopup] = useState(false);
	const [openRemovePopup, setOpenRemovePopup] = useState(false);
	const { collectionsPrompt } = useAppSelector(state => state.getStarted);
	const { collectionsChildsValid, needsOptimization } = useAppSelector(state => state.collections);
	const { productGroups } = useAppSelector(state => state.products);
	const showCollectionsPrompt = collectionsPrompt === ECollectionsPrompts.COLLECTIONS_EDIT;
	const { t } = useTranslation(['collections', 'validation']);
	const { updateCollection } = useCollections();
	const { incItemsIsChecked, decItemsIsChecked, handleChangeGroupCheckedVal } = useUser();

	const onOpen = () => setOpenEditPopup(true);
	const onClose = () => setOpenEditPopup(false);

	const onOpenRemovePopup = () => setOpenRemovePopup(true);
	const onCloseRemovePopup = () => setOpenRemovePopup(false);

	// temporarily hide
	// const handleUpdateCollection = (key: keyof ICollection) => (value: boolean) => {
	// 	updateCollection(collection.id, key, value);

	// 	if (key === 'active' && closestSuperCollection) {
	// 		const allChildCollectionsActive = closestSuperCollection.menuCollections.find((id) => {
	// 			const isChildCollection = getCollectionById(id);
	// 			if (isChildCollection?.id === collection.id) return value;
	// 			return isChildCollection?.active;
	// 		});

	// 		updateSuperCollection(closestSuperCollection.id, 'active', !!allChildCollectionsActive);
	// 	}
	// };

	const isValidCollection = useMemo(() => Object.keys(collection.validation || {})
		.every((key) => {
			if (collection.translations) {
				const validTranlate = !Object.keys(collection.translations || {})
					.find((translation_key) => collection.translations[translation_key].validation);

				return validTranlate && collection.validation[key].length === 0;
			}

			return collection.validation[key].length === 0;
		}), [collection.translations, collection.validation]);

	const handleClick = (e: React.MouseEvent<HTMLDivElement>, onlyOpen = false) => {
		if (handleCollapseCollection) {
			if (!onlyOpen) {
				handleCollapseCollection();
			} else if (isCollapse) {
				handleCollapseCollection();
			}
		}
		if (isCollapse && needsOptimization) {
			setTimeout(() => { (e.target as HTMLDivElement).scrollIntoView({ behavior: 'smooth', block: 'center' }); }, 0);
		}
	};

	const handleChangeChecked = () => {
		const nextValue = !collection.checked;
		nextValue ? incItemsIsChecked('collection') : decItemsIsChecked('collection');

		updateCollection(collection.id, 'checked', nextValue);

		collection.productGroups.forEach((groupId) => {
			const group = getGroupById(productGroups, groupId);
			if (group && group.checked !== nextValue) handleChangeGroupCheckedVal(group, nextValue, true);
		});
	};

	return (
		<div className={clsx(classes.root, { [classes.disabled]: !isActive })}>
			<div className={classes.leftBar}>
				<div className={classes.checkBoxWrap}>
					<Checkbox isChecked={collection.checked} onChange={handleChangeChecked} icoClassName={classes.checkBoxIco} />
				</div>
				<div className={clsx(classes.collapseSectionArr, { [classes.rotate]: isCollapse })} onClick={handleClick}>
					<ArrowBottom />
				</div>
				<div className={clsx(classes.collectionImg, {[classes.isNotValidCollection]: !isValidCollection})}>
					{collection.image ? <img src={collection.image} alt="Collection Img" /> : <EmptyCollectionPhoto />}
				</div>
				<div className={classes.collectionName} data-testid="test_collectionTitle">
					{showCollectionsPrompt && <span><PromptIco /></span>}
					{collection.title || t('name')}
				</div>
				<div className={classes.changeImg} onClick={onOpen}>
					<div className={classes.changeImg_edit}>
						<ChangeIco />
					</div>
					{showCollectionsPrompt && (
						<div className={classes.promptWrap}>
							<PromptView
								title={t('hintTitle')}
								text={t('hintText')}
							/>
						</div>
					)}
				</div>
			</div>
			<div className={classes.centerBar}>
				<div className={classes.addSectionButtonWrap} data-testid="test_addSection" >
					{isCollapse ? (
						<AddElementButton buttonText={t('addSectionButtonText')} onClick={handleAddSection} />

					) : collection.productGroups.length >= 10 && (
						<AddElementButton buttonText={t('addSectionButtonText')} onClick={handleAddSection} />
					)}
				</div>
			</div>
			<div className={classes.rightBar}>
				{!collectionsChildsValid && <span className={clsx({[classes.addOpacity]: !collection.itemsErrorsCount})}>
					<ItemHasErr
						count={collection.itemsErrorsCount || 0}
					/>
				</span>}
				{/* temporarily hide */}
				{/* {isMultiStore ? (
					<AvailableAddresses parentItem={collection} itemSelector="collection" triggerCollapseParent={handleClick} />
				) : (
					<div className={classes.toggleActive}>
						<SwitchComponent
							revert
							isActive={collection.active}
							onChange={handleUpdateCollection('active')}
							titleAct={t('includeCollection')}
							titleDis={t('includeCollection')}
							className={clsx({ [classes.toggleDis]: (showSuperCollections && !closestSuperCollection?.active) })}
						/>
					</div>
				)} */}
				<div className={classes.collectionRemove} data-testid="test_removeCollection" onClick={onOpenRemovePopup} ><CollectionRemove /></div>
				<SortIdentifier />
			</div>
			<EditCollectionPopup open={openEditPopup} onClose={onClose} collection={collection} />
			<PopupCollectionRemove
				open={openRemovePopup}
				onClose={onCloseRemovePopup}
				collectionId={collection.id}
				collectionIsChecked={collection.checked}
				collectionsProductGroups={collection.productGroups}
			/>
		</div>
	);
};

export default CollectionHead;
