import MenuLanguagesList from 'components/MenuLanguagesList';
import { ECountry } from 'const';
import MenuLanguagesPopup from 'containers/MenuLanguagesPopup';
import { useAppSelector } from 'hooks/redux';
import { useCollections } from 'hooks/useCollections';
import { useMultiLang } from 'hooks/useMultiLang';
import { useOptions } from 'hooks/useOptions';
import { useProducts } from 'hooks/useProducts';
import { useSuperCollections } from 'hooks/useSuperCollections';
import { useUser } from 'hooks/useUser';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as ArrowIco } from 'static/imgs/arrow-image.svg';
import { ReactComponent as MultiLangIco } from 'static/imgs/multiLangIco.svg';

import classes from './MenuLanguages.module.scss';
import { IProps } from './types';

const MenuLanguages: React.FC<IProps> = ({ isOptionsPage = false}) => {
	const { t } = useTranslation('multiLanguages');
	const [openList, setOpenList] = useState<boolean>(false);
	const [openMenuLangPopup, setOpenMenuLangPopup] = useState<boolean>(false);
	const { multiLangData } = useMultiLang();
	const isSelectDefault = multiLangData.defaultLanguage === null;
	const { showSuperCollections, getUserChanges: isSupCollChanged, sendSuperCollections, checkValidSupCollections } = useSuperCollections();
	const { userCountry } = useUser();
	const { showCollections } = useAppSelector(state => state.collections);
	const { getUserChanges: isCollChanged, checkValidCollections } = useCollections();
	const { getUserChanges: isProductsChanged, checkValidProducts } = useProducts();
	const { getUserChanges: isOptionsChanged, checkValidOptions, sendOptions } = useOptions();
	const { productGroups } = useAppSelector(state => state.products);
	const { optionGroups } = useAppSelector(state => state.options);
	const [disableGeneration, setDisableGeneration] = useState(false);

	const handleOpen = () => setOpenList(true);
	const handleClose = () => setOpenList(false);

	const saveAsDraft = (isValidMenu: boolean) => { // save as draft before generate langs
		isOptionsPage ? sendOptions() : sendSuperCollections(productGroups, false);

		if (!isValidMenu) setDisableGeneration(true);
	};

	const handleOpenLangPopup = () => {
		const [, isValidSupCollections] = checkValidSupCollections();
		const [, isValidCollections] = checkValidCollections();
		const [, isValidProducts] = checkValidProducts(productGroups, true);
		const [, isValidOptions] = checkValidOptions(optionGroups, true);

		const isValidMenu = isOptionsPage ? isValidOptions : isValidSupCollections && isValidCollections && isValidProducts;
		const isUserChanges = isProductsChanged(productGroups) || isOptionsChanged() || isCollChanged() || isSupCollChanged();

		if (!isValidMenu) setDisableGeneration(true);
		if (isUserChanges || !isValidMenu) saveAsDraft(isValidMenu);

		handleClose();
		setOpenMenuLangPopup(true);
	};

	const handleCloseLangPopup = () => {
		setOpenMenuLangPopup(false);
		setDisableGeneration(false);
	};

	if (
		(userCountry !== ECountry.Georgian && userCountry !== ECountry.Armenian) ||
		(showSuperCollections && multiLangData.defaultLanguage === null) ||
		!showCollections
	) return null;

	return (
		<div className={classes.root}>
			<div className={classes.button} onClick={isSelectDefault ? handleOpenLangPopup : handleOpen }>
				<span className={classes.ico}><MultiLangIco/></span>
				<div className={classes.buttonMenuLanguages}>{t('menuLanguages')}</div>
				{!isSelectDefault && <ArrowIco />}
			</div>
			{openList && <MenuLanguagesList handleOpenLangPopup={handleOpenLangPopup} onClose={handleClose} />}
			<MenuLanguagesPopup
				disableGeneration={disableGeneration}
				isSelectDefault={isSelectDefault}
				open={openMenuLangPopup}
				onClose={handleCloseLangPopup}
				description={isSelectDefault ? t('selectDefaultLang') : t('menuLanguagesPopupDescription')}
			/>
		</div>
	);
};

export default MenuLanguages;
