
import { FetchResult, useMutation } from '@apollo/client';
import { fetchSyncWithPartner as fetchSyncWithPartnerReq, SET_USER_LANG } from 'api/user';
import { contextAuth } from 'const';
import { getIdFromToken } from 'helpers/jwt';
import { IProduct, IProductGroup } from 'models/IProduct';
import { useTranslation } from 'react-i18next';
import { RootState } from 'store';
import { productsSlice } from 'store/reducers/products';
import { userSlice } from 'store/reducers/user';
import { fetchUser as fetchUserAction } from 'store/reducers/user/actions';
import { fetchLastSyncData as fetchLastSyncDataAction } from 'store/reducers/user/actions';

import { useAppDispatch, useAppSelector } from './redux';
import { useOptions } from './useOptions';
import { useProducts } from './useProducts';
import { useStoreAddress } from './useStoreAddress';
import useToast from './useToast';

type UserType = RootState['user'];
interface IUseUser extends UserType {
	fetchUser(): void;
	setUser(email: string): void;
	setUserCountry(country: string): void;
	clearUser(): void;
	fetchLastSyncData(): void;
	fetchSyncWithPartner(): void;
	changeUserLang(newlang: string): Promise<void | FetchResult>;
	incItemsIsChecked(typeOfItems?: 'product' | 'section' | 'collection'): void;
	decItemsIsChecked(typeOfItems?: 'product' | 'section' | 'collection'): void;
	handleChangeGroupCheckedVal(group: IProductGroup, nextValue: boolean, isCheckedCollection?: boolean): void;
	setItemsIsChecked(value: number): void;
	setTypeOfCheckItems(type: 'product' | 'section' | 'collection', count: number): void;
};

export const useUser = (): IUseUser => {
	const { user, loadingUser, userCountry,
		lastSyncData, itemsIsChecked, collectionsCheckCount,
		sectionsCheckCount, productsCheckCount,
	} = useAppSelector((state) => state.user);
	const { currentStoreId, isMultiStore, currentStoreAddressId, addressesIdsInStore } = useStoreAddress();
	const dispatch = useAppDispatch();
	const { errorToast, successToast } = useToast();
	const { fetchProducts, updateProductGroup, updateProduct } = useProducts();
	const { fetchOptions } = useOptions();
	const { t } = useTranslation(['errors', 'success']);

	const [switchUserLang] = useMutation(SET_USER_LANG, {
		context: contextAuth,
	});

	const fetchUser = () => {
		dispatch(fetchUserAction());
	};

	const setUser = (email: string) => {
		dispatch(userSlice.actions.set({ email }));
	};

	const setUserCountry = (country: string) => {
		dispatch(userSlice.actions.setUserCountry(country));
	};

	const clearUser = () => {
		dispatch(userSlice.actions.clear());
	};

	const fetchLastSyncData = () => {
		const storeId = isMultiStore ? currentStoreId : currentStoreAddressId;
		if (storeId) {
			dispatch(fetchLastSyncDataAction({storeAddressId: storeId, isMultiStore, addressesIdsInStore: addressesIdsInStore.join(',')}));
		}
	};

	const fetchSyncWithPartner = () => {
		const storeId = isMultiStore ? currentStoreId : currentStoreAddressId;
		if (storeId) {
			dispatch(productsSlice.actions.setLoading(true));
			fetchSyncWithPartnerReq(storeId, isMultiStore, addressesIdsInStore).then((ResponseData) => {
				if (ResponseData.ok) {
					successToast(t('success:succesSyncWithPartner'));
					fetchLastSyncData();
					fetchProducts();
					fetchOptions();
				} else {
					errorToast(t('errors:failSyncWithPartner'));
				}
			}).catch(() => {
				errorToast(t('errors:failSyncWithPartner'));
			}).finally(() => {
				dispatch(productsSlice.actions.setLoading(false));
			});
		}
	};

	const changeUserLang = (newLang: string): Promise<void | FetchResult> => {
		return switchUserLang({
			variables: { id: getIdFromToken(), newLang },
		});
	};

	const incItemsIsChecked = (typeOfItems?: 'product' | 'section' | 'collection') => {
		dispatch(userSlice.actions.incItemsIsCheched());
		if (typeOfItems) dispatch(userSlice.actions.incTypeOfCheckItems(typeOfItems));
	};

	const decItemsIsChecked = (typeOfItems?: 'product' | 'section' | 'collection') => {
		dispatch(userSlice.actions.decItemsIsCheched());
		if (typeOfItems) dispatch(userSlice.actions.decTypeOfCheckItems(typeOfItems));
	};

	const setItemsIsChecked = (value: number) => {
		dispatch(userSlice.actions.setItemsIsChecked(value >= 0 ? value : 0));
	};

	const setTypeOfCheckItems = (type: 'product' | 'section' | 'collection', count: number) => {
		dispatch(userSlice.actions.setTypeOfCheckItems({type, count}));
	};

	const handleChangeGroupCheckedVal = (group: IProductGroup, nextValue: boolean, isCheckedCollection = false) => {
		nextValue ? incItemsIsChecked('section') : decItemsIsChecked('section');

		updateProductGroup(group.id, 'checked', nextValue);
		if (isCheckedCollection) updateProductGroup(group.id, 'parentIsChecked', nextValue);

		group.items.forEach((item: IProduct) => {
			updateProduct(group.id, item.id, 'checked', nextValue);
			updateProduct(group.id, item.id, 'parentIsChecked', nextValue);
			if (item.checked !== nextValue) nextValue ? incItemsIsChecked('product') : decItemsIsChecked('product');
		});
	};

	return {
		loadingUser,
		user,
		fetchUser,
		clearUser,
		setUser,
		userCountry,
		setUserCountry,
		lastSyncData,
		fetchLastSyncData,
		fetchSyncWithPartner,
		changeUserLang,
		itemsIsChecked,
		incItemsIsChecked,
		decItemsIsChecked,
		handleChangeGroupCheckedVal,
		setItemsIsChecked,
		collectionsCheckCount,
		sectionsCheckCount,
		productsCheckCount,
		setTypeOfCheckItems,
	};
};
