import React from 'react';
import { useTranslation } from 'react-i18next';

import classes from '../SendFeedbackPopup.module.scss';

const FailTemplate = () => {
	const { t } = useTranslation('feedback');

	return (
		<div className={classes.fail}>
			<div className={classes.title}>{t('feedback:feedbackFailTitle')}</div>
			<div className={classes.message}>{t('feedback:feedbackFailDescription')}</div>
		</div>
	);
};

export default FailTemplate;
