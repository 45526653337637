import { gql } from '@apollo/client';
import { fetch } from 'helpers/fetch';

export const GET_STORE_ADDRESSES_DATA = gql`
	query storeAddressData($storeAddressIds: [Int]) {
		storeAddressData(storeAddressIds: $storeAddressIds) {
			id,
			storeId,
			storeName,
			address,
			storeAddressId,
		}
   }
`;

export const fetchStoreAddressSendingCountReq = (storeAddressId: string, isMultiStore: boolean) => {
	const url = isMultiStore ? `/${storeAddressId}/uploads/used/` : `/uploads/used/${storeAddressId}/`;
	return fetch(url);
};
