import { gql } from '@apollo/client';
import { fetch } from 'helpers/fetch';
import fetchWithToken from 'helpers/fetchWithToken';
import { ILastSync } from 'models/IUser';

export const prefixImportUrl = process.env.REACT_APP_REIMPORT_URL || 'https://contract-db.glovoapp.com/reimport_stage';

export const fetchLastSyncData = (storeAddressId: string, isMultiStore: boolean, addressesIdsInStore: string) => {
	const url = isMultiStore
		? `/${storeAddressId}/reimport/last-reimport-date-at?store-addresses=${addressesIdsInStore}`
		: `/reimport/${storeAddressId}/last-reimport-date-at`;
	return fetch<ILastSync>(url);
};

export const fetchSyncWithPartner = (storeAddressId: string, isMultiStore: boolean, addressesIdsInStore: number[]) => {
	return fetchWithToken(`${prefixImportUrl}/${storeAddressId}`, isMultiStore ? {
		method: 'post',
		body: JSON.stringify({storeAddressIds: addressesIdsInStore,
		}),
	} : {});
};

export const SET_USER_LANG = gql`
	mutation ChangePreferredLang($id: ID, $newLang: String) {
		changePreferredLang(userId: $id, newLang: $newLang)
	}
`;
