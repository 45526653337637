import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface IState {
	openRemovePopup: boolean;
	removeItemId: string;
	removeGroupId: string;
	optionOnProductPage?: boolean,
	setForceRemovePopupView: 'options' | 'product' | undefined;
}

const initialState: IState = {
	openRemovePopup: false,
	removeItemId: '',
	removeGroupId: '',
	setForceRemovePopupView: undefined,
};

export const modalsSlice = createSlice({
	name: 'modals',
	initialState,
	reducers: {
		setRemoveWithIdPopupState(state, action: PayloadAction<{
			modal: boolean, itemId: string, groupId: string, setForceRemovePopupView?: 'options' | 'product'
		}>) {
			state.openRemovePopup = action.payload.modal;
			state.removeItemId = action.payload.itemId;
			state.removeGroupId = action.payload.groupId;
			state.setForceRemovePopupView = action.payload.setForceRemovePopupView;
		},
		setDefaultForForce(state) {
			state.setForceRemovePopupView = undefined;
		},
		setRemovePopupState(state, action: PayloadAction<boolean>) {
			state.openRemovePopup = action.payload;
		},
	},
});

export default modalsSlice.reducer;
