import CollapseAllMenu from 'components/CollapseAllMenu';
import clsx from 'helpers/clsx';
import React from 'react';

import classes from './MenuTitle.module.scss';
import { IProps } from './types';

const MenuTitle: React.FC<IProps> = ({title, collapseAll, isSectionsTitle = false, isCollapseAll}) => {
	return (
		<div className={clsx(classes.title, {[classes.isSectionsTitle]: isSectionsTitle})} data-testid="test_MenuTitle" >
			{title}
			<CollapseAllMenu onClick={collapseAll} isCollapseAll={isCollapseAll} />
		</div>
	);
};

export default MenuTitle;
