import TitleLangsPopup from 'containers/TitleLangsPopup';
import clsx from 'helpers/clsx';
import { ITranslations } from 'models/IProduct';
import React, { useMemo, useState } from 'react';
import { ReactComponent as ManageLangIco } from 'static/imgs/manageLangIco.svg';

import classes from './TitlesLangBar.module.scss';
import { IProps } from './types';

const TitlesLangBar: React.FC<IProps> = ({
	allowedNumber, translations, parentType, parentId, headerTitle,
	groupId, isDescription = false, defaultTitle, handleUpdateDefaultTitle, label, placeholder,
}) => {
	const [openLangsPopup, setOpenLangsPopup] = useState<boolean>(false);

	const handleOpen = () => { setOpenLangsPopup(true); };
	const handleClose = () => { setOpenLangsPopup(false); };
	const translationsAreEmpty = useMemo(() => {
		// if item is product - different title and description
		const objectToCheck = parentType === 'product' ? Object.keys(translations).reduce((acc: ITranslations, key) => {
			acc[key] = {...translations[key], ...(isDescription ? { title: '' } : { description: '' })};
			return acc;
		}, {}) : translations;

		return !Object.values(objectToCheck).reduce((acc, item) => {
			acc += Object.values(item).join('').length;
			return acc;
		}, 0);
	}, [translations, parentType, isDescription]);

	return (
		<>
			<div className={clsx(classes.openPopupButton, {[classes.redCircle]: translationsAreEmpty})} onClick={handleOpen}><ManageLangIco /></div>
			<TitleLangsPopup
				isDescription={isDescription}
				parentType={parentType}
				parentId={parentId}
				groupId={groupId}
				translations={translations}
				open={openLangsPopup}
				onClose={handleClose}
				allowedNumber={allowedNumber}
				defaultTitle={defaultTitle}
				handleUpdateDefaultTitle={handleUpdateDefaultTitle}
				label={label}
				placeholder={placeholder}
				headerTitle={headerTitle}
			/>
		</>
	);
};

export default TitlesLangBar;
