import i18n from 'i18n';

import { IRule } from './types';

export class NoSpecialCharacters implements IRule {

	passes(value: string): boolean {
		return !/[*^$#><]/g.test(value);
	}

	message(): string {
		return i18n.t('validation:noSpecialCharacters');
	}
}
