import clsx from 'helpers/clsx';
import { useAuth } from 'hooks/useAuth';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as LogoutIco } from 'static/imgs/logout.svg';

import classes from './Logout.module.scss';
import { IProps } from './types';


const Logout: React.FC<IProps> = ({ className }) => {
	const { logout } = useAuth();
	const { t } = useTranslation('accounts');

	return (
		<span data-testid="logout-span" className={clsx(className, classes.logout)} onClick={logout}>
			<LogoutIco />
			{t('logout')}
		</span>
	);
};

export default Logout;
