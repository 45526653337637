import ErrorValidation from 'components/ErrorValidation';
import InputForEnteringInform from 'components/InputForEnteringInform';
import PromptIco from 'components/PromptIco';
import { bytesToMegaBytes } from 'helpers';
import clsx from 'helpers/clsx';
import usePrompts from 'hooks/usePrompts';
import { ECollectionsPrompts, ESectionPrompts } from 'models/IPrompts';
import React, { useState } from 'react';
import { Cropper } from 'react-cropper';
import { Trans, useTranslation } from 'react-i18next';
import { ImageValidator } from 'services/Validator/ImageValidator';
import { ReactComponent as IIco } from 'static/imgs/i.svg';
import { ReactComponent as ProductRemove } from 'static/imgs/productRemove.svg';
import { ReactComponent as UploadIco } from 'static/imgs/uploadIco.svg';

import classes from '../EditCollectionPopup.module.scss';
import { IPropsTemplate } from '../types';

const DefaultTemplate: React.FC<IPropsTemplate> = ({ collection, isSuperCollection, setName,
	name, collectionsPrompt, setError, image, error, setImage, setCropper, setUploadImgSize,
}) => {
	const { t } = useTranslation(['collections', 'requirements', 'form', 'hintTexts', 'validation']);
	const [drag, setDrag] = useState<boolean>(false);
	const { togglePromptSection } = usePrompts();
	const [previewSize, setPreviewSize] = useState<[number, number]>([0, 0]);

	const dragStartHandler = (event: React.DragEvent<HTMLDivElement>) => {
		event.preventDefault();
		setDrag(true);
	};

	const dragLeaveHandler = (event: React.DragEvent<HTMLDivElement>) => {
		event.preventDefault();
		setDrag(false);
	};

	const removeImg = () => {
		setImage('');
		setError([]);
		setUploadImgSize(0);
		setPreviewSize([0, 0]);
	};

	const handleFile = (f: File) => {
		const reader = new FileReader();
		reader.addEventListener('load', (event) => {
			if (typeof event.target?.result === 'string') {
				setImage(event.target.result);
				setUploadImgSize(bytesToMegaBytes(event.total));
			}
		});
		reader.readAsDataURL(f);

		togglePromptSection(ESectionPrompts.SECTIONS_IMG_POPUP_SAVE);
		setError([]);
	};

	const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const files = event.target.files;
		if (files && files.length) handleFile(files[0]);
	};

	const dropHandler = (event: React.DragEvent<HTMLDivElement>) => {
		event.preventDefault();
		const files = event.dataTransfer.files;
		if (files && files.length) handleFile(files[0]);
	};

	const handleCollectionName = (value: string) => setName(value);

	const onCrop = (event: any) => {
		setPreviewSize([parseInt(event.detail.width), parseInt(event.detail.height)]);
	};

	const renderPreviewBlock = (imgIsUpload: boolean = false) => (
		<div className={classes.previewWrap}>
			<div className={classes.miniPreview}>
				{previewSize[0] > 0 && <span className={classes.previewSize}>{previewSize[0]} px</span>}
				{previewSize[1] > 0 && <span className={clsx(classes.previewSize, classes.previewSizeHeight)}>{previewSize[1]} px</span>}
				<div className={clsx(classes.miniPreviewImg, classes.miniPreviewImgPlug)}>
					{imgIsUpload && <div className={clsx(classes.previewBody, 'js_previewBody')} />}
					{isSuperCollection && <div className={classes.previewSupCollectionTitle}>{name}</div>}
				</div>
			</div>
			<div className={classes.inputNameWrap}>
				<ErrorValidation className={classes.collectionValid} errors={collection.validation?.title}>
					<InputForEnteringInform
						hideLangBar
						showExample
						onChange={handleCollectionName}
						allowedNumber={40}
						value={name}
						showPrompt={collectionsPrompt === ECollectionsPrompts.COLLECTIONS_NAME}
						promptInfo={{
							title: t('hintTexts:collectionName.title'),
							text: t('hintTexts:collectionName.text'),
						}}
						label={isSuperCollection ? t('nameSup') : t('name')}
						placeholder={isSuperCollection ? t('nameSup') : t('name')}
						errors={collection.validation?.title}
					/>
				</ErrorValidation>
			</div>
		</div>
	);

	return (
		<>
			<div className={clsx(classes.uploadWrap, {[classes.isSuperCollection]: isSuperCollection})}>
				{image ? (
					<>
						{renderPreviewBlock(true)}
						<ErrorValidation errors={error} className={classes.error} rootClassName={classes.rootValidation}>
							<div className={classes.imgWrap}>
								<div className={classes.removeImg} onClick={removeImg}><ProductRemove/></div>
								<Cropper
									style={{ height: '100%', width: '100%' }}
									initialAspectRatio={isSuperCollection ? 1240/690 : 1}
									preview=".js_previewBody"
									aspectRatio={isSuperCollection ? 1240/690 : 1}
									zoomable = {false}
									center={false}
									guides={false}
									src={image}
									viewMode={1}
									minCropBoxHeight={10}
									minCropBoxWidth={10}
									background={false}
									responsive={true}
									autoCropArea={1}
									checkOrientation={false}
									onInitialized={(instance) => {
										setCropper(instance);
									}}
									crop={onCrop}
								/>
							</div>
						</ErrorValidation>
					</>
				) : (
					<>
						{renderPreviewBlock()}
						<div
							onDragStart={dragStartHandler}
							onDragLeave={dragLeaveHandler}
							onDragOver={dragStartHandler}
							onDrop={dropHandler}
							className={clsx(classes.imgWrap, {
								[classes.imgWrapLoad]: drag,
							})}
						>
							<span><UploadIco /></span>
							<div className={classes.text}>
								<Trans t={t} i18nKey="form:labelUploadFile" />
							</div>
							<label htmlFor="loadInput" className={classes.uploadButton}>{t('form:upload')}</label>
							<input
								className={classes.noneInput}
								type="file"
								id="loadInput"
								accept="image/jpg, image/jpeg, image/png"
								onChange={handleImageChange}
							/>
							{ECollectionsPrompts.COLLECTIONS_IMG === collectionsPrompt && (
								<div className={classes.promptIco}>
									<PromptIco />
								</div>
							)}
						</div>
					</>
				)}
			</div>
			<div className={classes.infoWrap}>
				<IIco />
				<div className={classes.infoWrapText}>
					<Trans
						i18nKey={'requirements:imageExtend'}
						t={t}
						values={{
							width: isSuperCollection ? 1240 : 690,
							height: 690,
							size: ImageValidator.maxSizeInMb,
						}}
						components={{
							span: <span />,
							div: <div />,
						}}
					/>
				</div>
			</div>
		</>
	);
};

export default DefaultTemplate;
