import clsx from 'helpers/clsx';
import { useClickAwayListener } from 'hooks/useClickAwayListener';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as Arr } from 'static/imgs/arrow-image.svg';

import Checkbox from './Checkbox';
import classes from './FormControl.module.scss';
import { IPropsSelect, ISelectOption } from './types';

const Select: React.ForwardRefRenderFunction<HTMLDivElement, IPropsSelect> = ({
	rootClassName,
	controlClassName,
	value: valueProps = '',
	defaultValue,
	emptyLabel = '',
	empty = false,
	label,
	options: dataOptions,
	onChange,
	multiple,
	multipleTitle = '',
	forceOpen,
	disabled = false,
	withoutClear = false,
	error,
	renderOption: renderOptionProp,
	onlyView = false,
	testId,
	limit = 100,
	onCloseClear = false,
}, refRoot) => {
	const [open, setOpen] = useState<boolean>(false);
	const ref = useRef(null);
	const [query, setQuery] = useState<string>('');
	const [value, setValue] = useState<any>(valueProps);
	const { t } = useTranslation('form');

	useEffect(() => {
		if (!valueProps && multiple) {
			return setValue([]);
		}
		setValue(valueProps);
	}, [valueProps, multiple]);

	useEffect(() => {
		if (!valueProps && defaultValue) {
			setValue(defaultValue);
		}
	}, [valueProps, defaultValue]);

	const toggleOpen = () => {
		setOpen((state) => !state);
	};

	const closeDropwDown = () => {
		setOpen(false);
		setQuery('');
	};

	useClickAwayListener(ref, closeDropwDown);

	const handleChangeValue = useCallback((v: string | number | boolean) => () => {
		if (!multiple) {
			setValue(v);
			onChange(v);
			closeDropwDown();
		} else if (Array.isArray(value)) {
			if (value.includes(v)) {
				setValue((state: string[]) => [...state.filter((it) => it !== v)]);
			} else {
				setValue((state: string[]) => [...state, v]);
			}
		}
	}, [multiple, onChange, value]);

	const apply = () => {
		if (value.length > 0) onChange(value);
		else onChange(undefined);
		closeDropwDown();
	};

	const cancel = useCallback(() => {
		setValue(valueProps);
		closeDropwDown();
	}, [valueProps]);

	useEffect(() => {
		if (!open && onCloseClear) cancel();
	}, [open, onCloseClear, cancel]);

	const filterOptions = useCallback((option: ISelectOption) => {
		return Object.values(option).map(String).find((v) => v.toLowerCase().includes(query.toLowerCase()));
	}, [query]);

	const renderOption = useCallback((option: ISelectOption) => {
		if (renderOptionProp) return renderOptionProp(option);
		else return option.label;
	}, [renderOptionProp]);

	const createOptions = useCallback(() => {
		return [...dataOptions].filter(filterOptions).slice(0, limit).map((option) => {
			const active = multiple && Array.isArray(value) ? value.includes(option.value) : value === option.value;
			return (
				<div
					className={clsx(classes.selectOption, {
						[classes.selectOption_active]: active && !multiple,
						[classes.selectOption_onlyView]: onlyView,
					})}
					key={`${option.value}${option.additionalLabel || ''}`}
					onClick={handleChangeValue(option.value)}
					aria-label={String(option.value)}
				>
					{!multiple ? renderOption(option) : (
						<Checkbox isChecked={active} rootClassName={classes.selectCheckboxRoot} textClassName={classes.selectCheckboxText}
						>
							{renderOption(option)}
						</Checkbox>
					)}
				</div>
			);
		});
	}, [dataOptions, filterOptions, handleChangeValue, limit, multiple, renderOption, value, onlyView]);

	const options = createOptions();

	const renderValue = () => {
		if (multiple) {
			return value.length ? `${multipleTitle} (${value.length})` : emptyLabel;
		}
		if (defaultValue) {
			return defaultValue;
		}
		return dataOptions?.find((option) => option.value === value)?.label || emptyLabel;
	};

	const renderButtons = () => {
		if (!multiple) return null;
		return (
			<div className={clsx(classes.buttons, 'buttons')}>
				<button onClick={cancel} className={classes.buttonCancel} >{t('cancel')}</button>
				<button onClick={apply} >{t('apply')}</button>
			</div>
		);
	};

	const hasClear = () => {
		if (withoutClear) return false;
		if (multiple) {
			return !!value.length;
		}
		if (!!value) {
			return true;
		}
		return false;
	};

	return (
		<div className={clsx(classes.root, rootClassName)} ref={refRoot}>
			<div ref={ref}>
				{label && <div className={classes.label}>{label}</div>}
				<div className={clsx(classes.inner, {
					[classes.disabled]: disabled,
					[classes.hasClear]: hasClear(),
				})}>
					<div
						className={clsx(classes.control, classes.controlSelect, controlClassName, {
							[classes.focus]: !!open,
							[classes.control_empty]: empty,
							[classes.error]: error,
						})}
						onClick={toggleOpen}
						data-testid={testId}
						aria-label={`${renderValue()}`}
					>
						<span>{renderValue()}</span>
						<Arr className={clsx(classes.icon, classes.selectArr)} />
					</div>
				</div>
				{(open || forceOpen) && !!dataOptions.length && (
					<div className={clsx('Select__inner-dropdown', classes.dropDownSelect)}>
						<div className="options">
							{options}
						</div>
						{renderButtons()}
					</div>
				)}
				{error && <div className={classes.errorText}>{error}</div>}
			</div>
		</div>
	);
};

export default React.forwardRef(Select);
