import { DragableProvider } from 'components/DragableProvider';
import MenuTitle from 'components/MenuTitle';
import SuperCollection from 'components/SuperCollection';
import { ERoles, PROMPS_SECTION_ARE_DISPLAYED, RoutesEnum } from 'const';
import AddCollection from 'containers/AddCollection';
import AddSection from 'containers/AddSection';
import CollectionContainer from 'containers/CollectionContainer';
import Products from 'containers/Products';
import clsx from 'helpers/clsx';
import { getRolesFromToken } from 'helpers/jwt';
import storage from 'helpers/localStorage';
import { checkCollectionHasFoundItems } from 'helpers/products';
import { useAppSelector } from 'hooks/redux';
import { useCollections } from 'hooks/useCollections';
import { useProducts } from 'hooks/useProducts';
import useScroll from 'hooks/useScroll';
import { useSuperCollections } from 'hooks/useSuperCollections';
import CollectionsLayout from 'layouts/CollectionsLayout';
import IndexLayout from 'layouts/IndexLayout';
import { ICollection, ISuperCollection } from 'models/ICollection';
import { IProductGroup } from 'models/IProduct';
import { ESectionPrompts } from 'models/IPrompts';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import { getStartedSlice } from 'store/reducers/getStarted';

import classes from './Home.module.scss';

const Home: React.FC = () => {
	const { loadingProducts, createEmptyGroupProducts,
		productsPageScrollPosition, defaultCollapsSect,
		setProductsPageScrollPosition, updateProductGroup,
	} = useProducts();
	const { productGroups, foundGroups } = useAppSelector(state => state.products);
	const { setSectionsPromptState } = getStartedSlice.actions;
	const { collections, loadingCollections } = useAppSelector(state => state.collections);
	const { loadingSupCollections } = useAppSelector(state => state.superCollectionsData);
	const dispatch = useDispatch();
	const { updatePositionCollections, updateCollection, showCollections, getCollectionById } = useCollections();
	const [ isCollapseAll, setIsCollapseAll ] = useState<boolean>(false);
	const { t } = useTranslation('collections');
	const { updatePositionSuperCollections, updateSuperCollection, showSuperCollections, superCollections } = useSuperCollections();
	const navigate = useNavigate();

	useScroll(setProductsPageScrollPosition);

	useEffect(() => {
		if (getRolesFromToken() && !getRolesFromToken()?.includes(ERoles.MENUTOOL)) navigate(RoutesEnum.LOGIN);
	});

	useEffect(() => {
		window.scrollTo({top: productsPageScrollPosition});
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		setIsCollapseAll(false);
	}, [showCollections]);

	useEffect(() => {
		if (
			!productGroups.length &&
			!loadingProducts &&
			!loadingCollections &&
			!loadingSupCollections &&
			!superCollections.length &&
			!showCollections
		) {
			createEmptyGroupProducts(productGroups.length);
			if (!storage.getItem(PROMPS_SECTION_ARE_DISPLAYED)) {
				dispatch(setSectionsPromptState(ESectionPrompts.SECTIONS_NAME));
				storage.setItem(PROMPS_SECTION_ARE_DISPLAYED, true);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [loadingProducts, productGroups, loadingCollections, loadingSupCollections]);

	const calcDefaultCollapseSection = useCallback((index: number, saveCollapseValue?: boolean): boolean => {
		if (saveCollapseValue === false) return false;
		if (!showCollections && defaultCollapsSect && index === 0) return false;
		return true;
	}, [defaultCollapsSect, showCollections]);

	const saveCollepseToStore = (
		items: ICollection[] | IProductGroup[] | ISuperCollection[],
		update: (id: string, key: 'saveCollapseValue', value: boolean) => void,
	) => {
		items.forEach((item) => {
			if (item.saveCollapseValue === false) update(item.id, 'saveCollapseValue', true);
		});
	};

	const saveCollapsingAfterCollapseAll = useCallback(() => {
		saveCollepseToStore(collections, updateCollection);
		saveCollepseToStore(productGroups, updateProductGroup);
		saveCollepseToStore(superCollections, updateSuperCollection);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const collapseAll = useCallback((forceCollapseAllTrigger = false, isUnblocking?: boolean) => {
		setIsCollapseAll(!!forceCollapseAllTrigger);
		if (!isUnblocking) {
			saveCollapsingAfterCollapseAll();
		}
	}, [saveCollapsingAfterCollapseAll]);

	const renderSections = (collection?: ICollection, closestSuperCollection?: ISuperCollection) => (<>
		{!showCollections && <MenuTitle isSectionsTitle title={t('sectionsProducts')} collapseAll={collapseAll} isCollapseAll={isCollapseAll} />}
		<Products
			isCollapseAll={isCollapseAll}
			collapseAll={collapseAll}
			calcDefaultCollapseSection={calcDefaultCollapseSection}
			collectionId={collection?.id}
			closestSuperCollectionId={closestSuperCollection?.id}
		/>
		<div className={clsx(classes.addSectionWrap, classes.addSectionWithCollection)}>
			<AddSection
				minifiedVersion
				isOptionsPage={false}
				collectionClosestId={collection?.id}
				collapseAll={collapseAll}
				productGroupsCount={productGroups.length}
			/>
		</div>
	</>);

	const renderCollections = (superCollection?: ISuperCollection) => (
		<DragableProvider classSortIdent={classes.sortProductIdent} providerType="collection" onMove={updatePositionCollections}>
			{collections.map(collection => {
				if (superCollection?.menuCollections.indexOf(collection.id) === -1)  return null;

				return (
					<CollectionContainer
						collection={collection}
						key={collection.id}
						isCollapseAll={isCollapseAll}
						collapseAll={collapseAll}
						defaultCollapse={collection.saveCollapseValue || !collection.isNew}
						closestSuperCollection={superCollection}
						collapseOtherItems={collapseOtherItems}
					>
						{renderSections(collection, superCollection)}
					</CollectionContainer>
				);
			})}
		</DragableProvider>
	);

	const collapseOtherItems = (id: ISuperCollection['id'] | ICollection['id'], type: 'collection' | 'superCollection') => {
		const items = type === 'superCollection' ? superCollections : collections ;
		items.forEach((item) => {
			if (item.id !== id && !item.saveCollapseValue) {
				type === 'superCollection'
					? updateSuperCollection(item.id, 'saveCollapseValue', true)
					: updateCollection(item.id, 'saveCollapseValue', true);
			}
		});
	};

	const haveFoundItems = (superCollection: ISuperCollection) => {
		let supCollectionHasFoundItem = false;
		superCollection.menuCollections.forEach((collectionId) => {
			const collectionItem = getCollectionById(collectionId);
			if (collectionItem) {
				supCollectionHasFoundItem = !supCollectionHasFoundItem ? checkCollectionHasFoundItems(collectionItem, foundGroups) : true;
			}
		});
		return supCollectionHasFoundItem;
	};

	return (
		<IndexLayout collapseAll={collapseAll}>
			{showCollections ? (
				<CollectionsLayout
					title={showSuperCollections ? t('titlePageSuper') : t('titlePage')}
					collapseAll={collapseAll}
					isCollapseAll={isCollapseAll}
				>
					{showSuperCollections ? (
						<DragableProvider
							classSortIdent={classes.sortProductIdent}
							providerType="superCollection"
							onMove={updatePositionSuperCollections}
						>
							{superCollections.map((superCollection) => {

								if (foundGroups.length && !haveFoundItems(superCollection)) return null;

								return (
									<SuperCollection
										superCollection={superCollection}
										key={superCollection.id}
										isCollapseAll={isCollapseAll}
										collapseAll={collapseAll}
										defaultCollapse={superCollection.saveCollapseValue}
										collapseOtherItems={collapseOtherItems}
									>
										{renderCollections(superCollection)}
										<div className={classes.addCollectionWrap}>
											<AddCollection minifiedVersion superCollectionClosestId={superCollection?.id} />
										</div>
									</SuperCollection>
								);
							})}
						</DragableProvider>
					) : renderCollections() }
				</CollectionsLayout>
			) : renderSections() }
		</IndexLayout>
	);
};

export default Home;
