import { ECountry } from 'const';
import i18n from 'i18n';

import { IRule } from './types';

export class UpperCase implements IRule {

	passes(value: string, minMultiPrice?: number, allProductGroups?: any[], currentCountry?: string): boolean {
		// off for Georgian and Armenian
		if (currentCountry === ECountry.Georgian || currentCountry === ECountry.Armenian) return true;

		if (!value || /\d/.test(value)) return true;
		return value === value.toUpperCase();
	}

	message(): string {
		return i18n.t('validation:upperCase');
	}
}
