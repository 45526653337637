import Collection from 'components/Collection';
import { checkCollectionHasFoundItems } from 'helpers/products';
import { useAppSelector } from 'hooks/redux';
import React from 'react';

import { IProps } from './types';

const CollectionContainer: React.FC<IProps> = ({
	children, collection, isCollapseAll, collapseAll,
	closestSuperCollection , defaultCollapse = false, collapseOtherItems,
}) => {
	const { productGroups, foundGroups } = useAppSelector(state => state.products);

	if (foundGroups.length && !checkCollectionHasFoundItems(collection, foundGroups)) return null;

	return <Collection
		collection={collection}
		key={collection.id}
		isCollapseAll={isCollapseAll}
		collapseAll={collapseAll}
		defaultCollapse={defaultCollapse}
		closestSuperCollection={closestSuperCollection}
		productGroupsCount={productGroups.length}
		collapseOtherItems={collapseOtherItems}
	>
		{children}
	</Collection>;
};

export default CollectionContainer;
