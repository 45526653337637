import MoveWarning from 'components/MoveWarning';
import SortIdentifier from 'components/SortIdentifier';
import PopupMoveItems from 'containers/PopupMoveItems';
import PopupRemoveCheckedItems from 'containers/PopupRemoveCheckedItems';
import clsx from 'helpers/clsx';
import { useAppSelector } from 'hooks/redux';
import { useCollections } from 'hooks/useCollections';
import { useProducts } from 'hooks/useProducts';
import { useSuperCollections } from 'hooks/useSuperCollections';
import { useUser } from 'hooks/useUser';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as ItemsRemove } from 'static/imgs/productRemove.svg';

import classes from './ItemIsChecked.module.scss';

const ItemIsChecked: React.FC = () => {
	const { t } = useTranslation('menu');
	const { itemsIsChecked, handleChangeGroupCheckedVal, setItemsIsChecked, collectionsCheckCount, sectionsCheckCount } = useUser();
	const [openRemovePopup, setOpenRemovePopup] = useState(false);
	const { updateCollection, removeCollection, removeValidation, showCollections } = useCollections();
	const { removeProductGroup, removeProductItem, clearProductsValidation } = useProducts();
	const { collections } = useAppSelector(state => state.collections);
	const { productGroups } = useAppSelector(state => state.products);
	const { removeValidationSup } = useSuperCollections();
	const cannotMove = (showCollections && collectionsCheckCount > 0) || (!showCollections && sectionsCheckCount > 0);
	const [openMovePopup, setOpenMovePopup] = useState(false);

	const handleOpen = () => setOpenRemovePopup(true);
	const handleClose = () => setOpenRemovePopup(false);

	const handleOpenMovePopup = () => setOpenMovePopup(true);
	const handleCloseMovePopup = () => setOpenMovePopup(false);

	if (!itemsIsChecked) return null;

	const cancelSelection = () => {
		productGroups.forEach((group) => handleChangeGroupCheckedVal(group, false));
		collections.forEach((collection) => updateCollection(collection.id, 'checked', false));
		setItemsIsChecked(0);
	};

	const clearItemsValidation = () => {
		removeValidation();
		clearProductsValidation();
		removeValidationSup();
	};

	const removeCheckedItems = () => {
		clearItemsValidation();

		productGroups.forEach((group) => {
			if (group.checked && !group.parentIsChecked) removeProductGroup(group);

			group.items.forEach((product) => {
				if (product.checked && !product.parentIsChecked) removeProductItem(group.id, product);
			});
		});

		collections.forEach((collection) => {
			if (collection.checked) removeCollection(collection.id);
		});

		handleClose();

		setTimeout(() => {
			setItemsIsChecked(0);
		}, 0);
	};

	return (
		<>
			<div className={classes.root}>
				<div className={classes.button} onClick={cancelSelection} >{t('cancelSelection')}</div>
				<div className={clsx(classes.button, classes.invert)} onClick={handleOpen} ><ItemsRemove/>{t('deleteSelected')}</div>
				<div className={clsx(classes.button, classes.invert, {[classes.disable]: cannotMove})} onClick={handleOpenMovePopup}>
					<SortIdentifier color="#fff" />{t('moveSelected')}
				</div>
				<MoveWarning cannotMove={cannotMove} />
			</div>
			<PopupRemoveCheckedItems open={openRemovePopup} onClose={handleClose} removeCheckedItems={removeCheckedItems} />
			<PopupMoveItems open={openMovePopup} onClose={handleCloseMovePopup} />
		</>
	);
};

export default ItemIsChecked;
