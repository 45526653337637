import Modal from 'components/Modal';
import SwitchComponent from 'components/SwitchComponent';
import OptionsGroup from 'containers/OptionsGroup';
import OptionsContextProvider from 'contexts/Options';
import { changeChildEntity, changeEntity, createEmptyChildEntity, removeOptionInGroupFromId } from 'helpers';
import { getEmptyOption, getTranslations } from 'helpers/mock';
import { checkIsValidEntities } from 'helpers/validation';
import { useAppSelector } from 'hooks/redux';
import { useDeepCompareEffect } from 'hooks/useDeepCompareEffect';
import { useOptions } from 'hooks/useOptions';
import { IOption, IOptionGroup } from 'models/IOption';
import { ITranslations } from 'models/IProduct';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import classes from './PopupAddOption.module.scss';
import { IProps } from './types';

const PopupAddOption: React.FC<IProps> = ({ open, onClose, optionsGroup, isEdit }) => {
	const [data, setData] = useState<IOptionGroup[]>([optionsGroup]);
	const { addOptionGroup, patchOptionGroup } = useOptions();
	const { t } = useTranslation(['options', 'form']);
	const { multiLangData } = useAppSelector((state) => state.multiLang);
	const newTranslations: ITranslations = getTranslations(multiLangData.languages);
	const { userCountry } = useAppSelector(state => state.user);

	useDeepCompareEffect(() => setData([optionsGroup]), [optionsGroup]);

	const updateOptionGroup = (groupId: IOptionGroup['id'], key: keyof IOptionGroup, value: any) => {
		setData((state) => changeEntity(state, groupId, key, value));
	};

	const updateOption = (groupId: IOptionGroup['id'], id: IOption['id'], key: keyof IOption, value: any) => {
		setData((state) => changeChildEntity<IOption, IOptionGroup>(state, groupId, id, key, value));
	};

	const createEmptyOption = (groupId: IOptionGroup['id']) => {
		setData((state) => createEmptyChildEntity(state, groupId, getEmptyOption(multiLangData.languages.length ? newTranslations : undefined)));
	};

	const handleClose = () => {
		setData([optionsGroup]);
		onClose();
	};

	const saveOrCreate = () => {
		const [newOptionsAfterValidation, isValid] = checkIsValidEntities(data, true, true, userCountry);
		if (!isValid) {
			setData(newOptionsAfterValidation);
			return;
		}
		if (isEdit) patchOptionGroup(data[0]);
		else addOptionGroup(data[0]);
		onClose();
	};

	const removeNewOption = (id: IOption['id']) => {
		setData((state) => removeOptionInGroupFromId(state, id));
	};

	const updateTranslations = (translations: ITranslations, type: string, defaultTitleState: string, parentId?: string) => {
		const newOptionGroup = data.map((group) => {
			if (type === 'option_group') return {
				...group,
				title: defaultTitleState,
				translations,
			};

			return {
				...group,
				items:  group.items.map((item) => {
					if (item.id === parentId) {
						return {
							...item,
							title: defaultTitleState,
							translations,
						};
					}

					return item;
				}),
			};
		});

		setData(newOptionGroup);
	};

	const handleActiveOptionGroup = (key: keyof IOptionGroup) => (value: string | boolean) => {
		updateOptionGroup(data[0].id, key, value);
	};

	return (
		<Modal open={open} onClose={handleClose} contentClass={classes.contentClass} >
			<div className={classes.wrapper}>
				<div className={classes.headerline}>
					<h3 data-testid="editOption">
						{isEdit ? t('isEditingSection') : t('isCreatingSection')} {`${optionsGroup.title ? `"${optionsGroup.title}"` : ''}`}
					</h3>
					<SwitchComponent
						revert
						isActive={data[0].active}
						onChange={handleActiveOptionGroup('active')}
						titleAct={t('options:includeSection')}
						className={classes.toggleActive}
					/>
				</div>
				<OptionsContextProvider
					isModal={true}
					updateOptionGroup={updateOptionGroup}
					updateOption={updateOption}
					createEmptyOption={createEmptyOption}
					removeNewOption={removeNewOption}
					updateTranslations={updateTranslations}
				>
					<OptionsGroup group={data[0]} rootClassName={classes.group} listWrapClass={classes.listWrapClass} />
				</OptionsContextProvider>
				<div className={classes.btns}>
					<button onClick={handleClose} data-testid="cancel">{t('form:cancel')}</button>
					<button onClick={saveOrCreate} data-testid="create">
						{isEdit ? t('form:saveShort') : t('form:createNewSection')}
					</button>
				</div>
			</div>
		</Modal >
	);
};

export default PopupAddOption;
