
import { createAsyncThunk } from '@reduxjs/toolkit';
import { fetchMultiLang as fetchMultiLangReq } from 'api/multiLang';

export const fetchMultiLang = createAsyncThunk(
	'multilang/fetchMultiLang',
	async ({storeAddressId, isMultiStore}: {storeAddressId: string, isMultiStore: boolean}, thunkAPI) => {
		try {
			const result = await fetchMultiLangReq(storeAddressId, isMultiStore).catch(() => undefined);
			return result;
		} catch (e) {
			return thunkAPI.rejectWithValue('Failure');
		}
	},
);
