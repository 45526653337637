import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { fetchDateSaveDraft } from './actions';

export interface IState {
	date: string | null;
	loading: boolean;
}

const initialState: IState = {
	date: null,
	loading: true,
};

export const dateSaveDraftSlice = createSlice({
	name: 'dateSaveDraft',
	initialState,
	reducers: {
	},
	extraReducers: {
		[fetchDateSaveDraft.fulfilled.type]: (state, action: PayloadAction<{draft_updated_at: string}>) => {
			state.date = action.payload.draft_updated_at;
			state.loading = false;
		},
		[fetchDateSaveDraft.pending.type]: (state) => {
			state.loading = true;
		},
		[fetchDateSaveDraft.rejected.type]: (state) => {
			state.loading = false;
		},
	},
});

export default dateSaveDraftSlice.reducer;
