import AddElementButton from 'containers/AddElementButton';
import OptionItem from 'containers/OptionItem';
import { useOptionsContext } from 'contexts/Options';
import clsx from 'helpers/clsx';
import React from 'react';
import { useTranslation } from 'react-i18next';

import classes from './OptionsList.module.scss';
import { IProps } from './types';

const OptionsList: React.FC<IProps> = ({ optionGroup, listWrapClass }) => {
	const { createEmptyOption } = useOptionsContext();
	const { t } = useTranslation('options');

	const hanleOnClick = () => {
		createEmptyOption(optionGroup.id);
	};

	return (
		<>
			<div className={clsx(classes.listWrap, listWrapClass)}>
				{optionGroup.items.map((option) => <OptionItem key={option.id} group={optionGroup} option={option} />)}
			</div>
			<div className={classes.addOption}>
				<AddElementButton buttonText={t('addNewOption')} onClick={hanleOnClick} />
			</div>
		</>
	);
};

export default OptionsList;
