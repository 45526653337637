import Modal from 'components/Modal';
import { handleDisableScrolling } from 'helpers';
import clsx from 'helpers/clsx';
import { moveCheckedProductsInToOtherSection, moveCheckedSectionsInToOtherCollection } from 'helpers/products';
import { useAppSelector } from 'hooks/redux';
import { useCollections } from 'hooks/useCollections';
import { useProducts } from 'hooks/useProducts';
import { useSuperCollections } from 'hooks/useSuperCollections';
import useToast from 'hooks/useToast';
import { useUser } from 'hooks/useUser';
import { ICollection } from 'models/ICollection';
import { IProductGroup } from 'models/IProduct';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import ProductsMove from './itemsForMoveTemplates/productsMove.template';
import SectionsMove from './itemsForMoveTemplates/sectionsMove.template';
import classes from './PopupMoveItems.module.scss';
import { IProps } from './types';

const PopupMoveItems: React.FC<IProps> = ({ open, onClose }) => {
	const { collections } = useAppSelector(state => state.collections);
	const { productGroups } = useAppSelector(state => state.products);
	const { sectionsCheckCount, handleChangeGroupCheckedVal, setItemsIsChecked } = useUser();
	const { updateProductGroups, clearProductsValidation } = useProducts();
	const { successToast } = useToast();
	const [collectForMove, setCollectForMove] = useState<ICollection | null>(null);
	const [sectionForMove, setSectionForMove] = useState<IProductGroup | null>(null);
	const { t } = useTranslation(['form', 'menu', 'collections']);
	const { updateCollections, showCollections, removeValidation } = useCollections();
	const { removeValidationSup } = useSuperCollections();
	const isMoveProducts = sectionsCheckCount === 0;
	const disableSaveButton = isMoveProducts ? !sectionForMove : !collectForMove;

	const setItemForMove = (type: 'collection' | 'section', checkedItem: ICollection | IProductGroup) => () => {
		if (type === 'collection') setCollectForMove(checkedItem as ICollection);
		else setSectionForMove(checkedItem as IProductGroup);
	};

	const clearValidation = () => {
		clearProductsValidation();
		removeValidation();
		removeValidationSup();
	};

	const afterMoving = (groups: IProductGroup[], nameFinalItem: string) => {
		groups.forEach((group) => handleChangeGroupCheckedVal(group, false));
		successToast(`${t('menu:successfullyMoved')} ${nameFinalItem}`);
		setItemsIsChecked(0);
		handleClose();
		handleDisableScrolling(false);
		clearValidation();
	};

	const onClickSave = () => {
		if (isMoveProducts && sectionForMove?.id) {
			const groupsAfterMoving = moveCheckedProductsInToOtherSection(productGroups, sectionForMove.id);

			updateProductGroups(groupsAfterMoving);
			afterMoving(groupsAfterMoving, !!sectionForMove.title.length ? sectionForMove.title : t('menu:newSection'));
		} else if (collectForMove?.id) {
			const collectionsAfterMoving = moveCheckedSectionsInToOtherCollection(collections, productGroups, collectForMove?.id);
			updateCollections(collectionsAfterMoving);
			afterMoving(productGroups, !!collectForMove.title.length ? collectForMove.title : t('menu:newCollection'));
		}
	};

	const handleClose = () => {
		setCollectForMove(null);
		setSectionForMove(null);
		onClose();
	};

	return (
		<Modal open={open} onClose={handleClose}>
			<div className={classes.root}>
				<div className={classes.header} data-testid="test_menuManagement">{t('menu:menuManagement')}</div>
				<div className={classes.body}>
					<div className={classes.movingPanel}>
						<div className={classes.movingPanelTitle}>
							{showCollections ? t('collections:structure.collections') : t('collections:structure.sections')}
						</div>
						<div className={classes.itemsForMoveWrap}>
							{showCollections ? <>
								{collections.map((collection) => {
									if (isMoveProducts) return (
										<ProductsMove
											collection={collection}
											setItemForMove={setItemForMove}
											sectionForMoveId={sectionForMove?.id}
											key={collection.id}
										/>
									);

									return <SectionsMove
										collection={collection}
										collectionForMoveId={collectForMove?.id}
										setItemForMove={setItemForMove}
										key={collection.id}
									/>;
								})}
							</> : <>
								<ProductsMove
									setItemForMove={setItemForMove}
									sectionForMoveId={sectionForMove?.id}
								/>
							</>}

						</div>
					</div>
					<div className={classes.buttons}>
						<span onClick={handleClose} data-testid="test_cancel">{t('form:cancel')}</span>
						<span className={clsx(classes.save, {[classes.disable]: disableSaveButton})} onClick={onClickSave} data-testid="test_save">
							{t('form:save')}
						</span>
					</div>
				</div>
			</div>
		</Modal>
	);
};

export default PopupMoveItems;
