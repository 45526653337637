import { handleDisableScrolling } from 'helpers';
import clsx from 'helpers/clsx';
import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';

import { ReactComponent as CloseIco } from '../../static/imgs/x.svg';
import classes from './Modal.module.scss';
import { IProps } from './types';

const Template: React.FC<IProps> = ({ children, onClose, contentClass }) => {
	return (
		<div className={classes.root} id="js_modal_root">
			<div data-testid="close-div" className={classes.overlay} onClick={onClose}></div>
			<div data-testid="root-div" className={classes.root}>
				<div className={clsx(classes.content, contentClass)}>
					<span data-testid="close-ico" onClick={onClose} className={classes.close}><CloseIco /></span>
					{children}
				</div>
			</div>
		</div>
	);
};

const Modal: React.FC<IProps> = (props) => {
	const onKeyDown = (event: KeyboardEvent) => {
		if (event.key === 'Escape') {
			props.onClose();
		}
	};

	useEffect(() => {
		document.addEventListener('keydown', onKeyDown);
		return () => {
			document.removeEventListener('keydown', onKeyDown);
		};
	});

	useEffect(() => handleDisableScrolling(props.open), [props.open]);

	if (!props.open) return null;

	return ReactDOM.createPortal(<Template {...props} />, document.body);
};

export default Modal;
