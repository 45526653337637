import storage from 'helpers/localStorage';
export const USER_LANG = 'userLang';

export const countryLang = {
	UA: ['uk', 'en'],
	KZ: ['ru', 'en'],
	GE: ['en', 'ru'],
	KG: ['ru', 'en'],
	AM: ['en', 'ru'],
};

export const removeLang = () => {
	storage.removeItem(USER_LANG);
};

export const getLang = (): string | undefined => {
	return storage.getItem(USER_LANG);
};

export const setLang = (lang: string) => {
	storage.setItem(USER_LANG, lang);
};

export const getLangByTimeZone = () => {
	try {
		const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
		if (timeZone === 'Asia/Bishkek') return 'ru';
		if (timeZone === 'Europe/Kiev') return 'uk';
		if (['Asia/Yerevan', 'Asia/Tbilisi'].includes(timeZone)) return 'en';
		if (timeZone.includes('Asia')) return 'ru';
		return 'en';
	} catch (_) {
		return 'en';
	}
};
