
import EditCollectionPopup from 'components/EditCollectionPopup';
import ItemHasErr from 'components/ItemHasErr';
import SortIdentifier from 'components/SortIdentifier';
import PopupCollectionRemove from 'containers/PopupCollectionRemove';
import clsx from 'helpers/clsx';
import { useAppSelector } from 'hooks/redux';
import { SuperCollectionsIdsEnum } from 'hooks/useSuperCollections';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as ChangeIco } from 'static/imgs/change.svg';
import { ReactComponent as EmptyCollectionPhoto } from 'static/imgs/empty_collention_photo.svg';
import { ReactComponent as ArrowBottom } from 'static/imgs/keyboard_arrow_down.svg';
import { ReactComponent as CollectionRemove } from 'static/imgs/productRemove.svg';

import classes from './SuperCollectionHead.module.scss';
import { IProps } from './types';

const SuperCollectionHead: React.FC<IProps> = ({ superCollection, isCollapse, handleCollapse }) => {
	const [openEditPopup, setOpenEditPopup] = useState(false);
	const [openRemovePopup, setOpenRemovePopup] = useState(false);
	const { t } = useTranslation(['collections', 'multiStore']);
	const { superCollections } = useAppSelector(state => state.superCollectionsData);
	const { needsOptimization } = useAppSelector(state => state.collections);

	const onOpen = () => setOpenEditPopup(true);
	const onClose = () => setOpenEditPopup(false);

	const onOpenRemovePopup = () => setOpenRemovePopup(true);
	const onCloseRemovePopup = () => setOpenRemovePopup(false);

	// const handleUpdateSupCollection = (key: keyof ISuperCollection) => (value: boolean) => {
	// 	updateSuperCollection(superCollection.id, key, value);
	// };

	const isValidSupCollection = useMemo(() => Object.keys(superCollection.validation || {})
		.every((key) => superCollection.validation[key].length === 0), [superCollection.validation]);

	const handleClick = (e: React.MouseEvent<HTMLDivElement>, onlyOpen = false) => {
		if (handleCollapse) {
			if (!onlyOpen) {
				handleCollapse();
			} else if (isCollapse) {
				handleCollapse();
			}
		}
		if (isCollapse && needsOptimization) {
			setTimeout(() => { (e.target as HTMLDivElement).scrollIntoView({ behavior: 'smooth', block: 'center' }); }, 1500);
		}
	};

	return (
		<div className={clsx(classes.root, SuperCollectionsIdsEnum.SUP_COLLECTION_HEAD, { [classes.disabled]: !superCollection.active })}>
			<div className={classes.leftBar}>
				<div className={clsx(classes.collapseSectionArr, { [classes.rotate]: isCollapse })} onClick={handleClick}>
					<ArrowBottom />
				</div>
				<div className={clsx(classes.collectionImg, {[classes.isNotValidCollection]: !isValidSupCollection})}>
					{superCollection.image
						? <img data-testid="test_SuperCollectionHeadImg" src={superCollection.image} alt="Collection Img" />
						: <EmptyCollectionPhoto />}
				</div>
				<div className={classes.collectionName} data-testid="test_SuperCollectionHeadTitle" >
					{/* {showCollectionsPrompt && <span><PromptIco /></span>} */}
					{superCollection.title || t('superName')}
				</div>
				<div className={classes.changeImg} onClick={onOpen}><ChangeIco /></div>
			</div>
			<div className={classes.rightBar}>
				{!!(superCollection.itemsErrorsCount && superCollection.itemsErrorsCount > 0) && <ItemHasErr
					count={superCollection.itemsErrorsCount}
				/>}
				{/* temporarily hide */}
				{/* {isMultiStore ? (
					<AvailableAddresses
						parentItem={superCollection}
						itemSelector="super_collection"
						addressPopupTitle={t('multiStore:addressPopupTitleSupCollection')}
						addressPopupSec={t('multiStore:addressPopupSupCollection')}
						triggerCollapseParent={handleClick}
					/>
				) : (
					<div className={classes.toggleActive}>
						<SwitchComponent
							revert
							isActive={superCollection.active}
							onChange={handleUpdateSupCollection('active')}
							titleAct={t('includeSuperCollection')}
							titleDis={t('includeSuperCollection')}
						/>
					</div>
				)} */}
				<div
					className={clsx(classes.collectionRemove, {[classes.disabled]: superCollections.length <= 1})}
					onClick={onOpenRemovePopup}
				><CollectionRemove /></div>
				<SortIdentifier />
			</div>
			<EditCollectionPopup isSuperCollection open={openEditPopup} onClose={onClose} collection={superCollection} />
			<PopupCollectionRemove isSuperCollection open={openRemovePopup} onClose={onCloseRemovePopup} collectionId={superCollection.id} />
		</div>
	);
};

export default SuperCollectionHead;
