import InputForEnteringInform from 'components/InputForEnteringInform';
import InputPrice from 'components/InputPrice';
import SwitchComponent from 'components/SwitchComponent';
import TitlesLangBar from 'containers/TitlesLangBar';
import { useOptionsContext } from 'contexts/Options';
import clsx from 'helpers/clsx';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { IOption } from 'models/IOption';
import { EOptionsPrompts } from 'models/IPrompts';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as ProductRemove } from 'static/imgs/productRemove.svg';
import { modalsSlice } from 'store/reducers/modals';

import classes from './OptionItem.module.scss';
import { IProps } from './types';

const OptionItem: React.FC<IProps> = ({ option, group }) => {
	const { optionsPrompt } = useAppSelector(state => state.getStarted);
	const { setRemoveWithIdPopupState } = modalsSlice.actions;
	const dispatch = useAppDispatch();
	const { t } = useTranslation(['options', 'hintTexts', 'multiStore']);

	const { removeNewOption } = useOptionsContext();

	const showRemoveProductPopup = () => {
		if (option.isNew && removeNewOption) {
			removeNewOption(option.id);
		} else {
			dispatch(setRemoveWithIdPopupState({ modal: true, groupId: group.id, itemId: option?.id!, setForceRemovePopupView: 'options' }));
		}
	};

	const { updateOption } = useOptionsContext();

	const handleChangeOption = (key: keyof IOption) => (value: any) => {
		updateOption(group.id, option.id, key, value);
	};

	return (
		<div className={clsx(classes.optionItem, { [classes.disabled]: !option.active })}>
			<div className={classes.inputWrap}>
				<InputForEnteringInform
					showExample
					hideLangBar={!option.translations}
					onChange={handleChangeOption('title')}
					allowedNumber={70}
					placeholder={t('nameOption')}
					showPrompt={optionsPrompt === EOptionsPrompts.OPTION_NAME}
					promptInfo={{
						title: t('hintTexts:optionName.title'),
						text: t('hintTexts:optionName.text'),
					}}
					value={option?.title || ''}
					errors={option?.validation?.title}
					setNextPromptOption={EOptionsPrompts.OPTION_PRICE}
					isActive={option.active}
					label={t('nameOptionLabel')}
				/>
				{option.translations && <div className={classes.titlesLangBarWrap}>
					<TitlesLangBar
						placeholder={t('nameOption')}
						parentType="option"
						translations={option.translations}
						allowedNumber={70}
						parentId={option.id}
						groupId={group.id}
						defaultTitle={option.title}
						handleUpdateDefaultTitle={handleChangeOption('title')}
						label={t('nameOptionLabel')}
						headerTitle={t('nameOptionLabel')}
					/>
				</div>}
			</div>
			<InputPrice
				isOptionsPage
				onChange={handleChangeOption('price')}
				showPrompt={optionsPrompt === EOptionsPrompts.OPTION_PRICE}
				promptInfo={{
					title: t('hintTexts:optionPrice.title'),
					text: t('hintTexts:optionPrice.text'),
				}}
				value={option.price || 0}
				errors={option?.validation?.price}
				rootClassName={classes.priceInput}
				label={t('multiStore:price')}
			/>
			<div className={classes.switch}>
				<SwitchComponent
					revert
					isActive={!!option?.active}
					titleAct={t('includeOption')}
					onChange={handleChangeOption('active')}
					className={classes.toggleActive}
					classNameSpan={clsx({[classes.disabledSwitch]: !group.active})}
				/>
			</div>
			{group.items.length > 1 && (
				<div data-testid="div-showPopUp" className={classes.productRemove} onClick={showRemoveProductPopup}>
					<ProductRemove />
				</div>
			)}
		</div>
	);
};

export default OptionItem;
