import clsx from 'helpers/clsx';
import React, { useEffect, useState } from 'react';
import { DragDropContext, Draggable,Droppable, DropResult } from 'react-beautiful-dnd';

import classes from './DragableProvider.module.scss';
import { IItem, IProps } from './types';

const reorder = (list: IItem[], startIndex: number, endIndex: number) => {
	const result = Array.from(list);
	const [removed] = result.splice(startIndex, 1);
	result.splice(endIndex, 0, removed);

	return result;
};

export const DragableProvider: React.FC<IProps> = ({ children, providerType, onMove, classSortIdent}) => {
	const [isDrag, setIsDrag] = useState(false);
	const childArr = React.Children.map(children, (child) => ({
		id: (child as any)?.key,
		element: child,
	}));

	const [items, setItems] = useState<IItem[]>(childArr || []);

	useEffect(() => {
		if (!isDrag) setItems(childArr || []);
		setIsDrag(false);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [children]);

	const onDragEnd = (result: DropResult) => {
		// dropped outside the list
		if (!result.destination) {
			return;
		}

		const itemsReorder: IItem[] = reorder(
			items,
			result.source.index,
			result.destination.index,
		);
		onMove(result.draggableId, result.destination.index + 1);
		setItems(itemsReorder);
		setIsDrag(true);
	};

	return (
		<DragDropContext onDragEnd={onDragEnd}>
			<Droppable droppableId={providerType}>
				{(provided) => (
					<div {...provided.droppableProps} ref={provided.innerRef} data-testid="wrapper-div" >
						{items.map((item: IItem, index: number) => (
							<Draggable key={item.id || String(index)} draggableId={item.id || String(index)} index={index}>
								{(providedDrag) => (
									<div
										className={classes.sortWrap}
										ref={providedDrag.innerRef}
										{...providedDrag.draggableProps}
									>
										<div className={clsx(classSortIdent, classes.sortIdentBody)} {...providedDrag.dragHandleProps}></div>
										{item.element}
									</div>
								)}
							</Draggable>
						))}
						{provided.placeholder}
					</div>
				)}
			</Droppable>
		</DragDropContext>
	);
};
