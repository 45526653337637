import AddressPrice from 'components/AddressPrice';
import ErrorValidation from 'components/ErrorValidation';
import { formatingPrice, getStoreAddressDataByStoreAddressId } from 'helpers';
import clsx from 'helpers/clsx';
import { getPriceInStoreAvail } from 'helpers/products';
import { useClickAwayListener } from 'hooks/useClickAwayListener';
import { useProducts } from 'hooks/useProducts';
import { useStoreAddress } from 'hooks/useStoreAddress';
import { FC, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as CloseIco } from '../../static/imgs/x.svg';
import classes from './ChangePricesInAddresses.module.scss';
import { IPriceDataShow, IProps } from './types';

const ChangePricesInAddresses: FC<IProps> = ({ product, group, errors, label, updateItemsErrorsCountForItems }) => {
	const [open, setOpen] = useState(false);
	const addressListRef = useRef(null);
	const { t } = useTranslation('multiStore');
	const { productsMultiPrices, updateProductMultiPrice, productsAvailability, updateProduct } = useProducts();
	const { currentStoreData } = useStoreAddress();

	const data = useMemo(() => {
		return productsMultiPrices.reduce((acc: IPriceDataShow[], multiPrice) => {
			if (multiPrice.product_id === product.id) {
				multiPrice.prices.forEach((priceInStoreAddress) => {
					const storeAddressData = getStoreAddressDataByStoreAddressId(currentStoreData, priceInStoreAddress.store_address_id);
					const priceInStoreAvailability = getPriceInStoreAvail(productsAvailability, product.id, priceInStoreAddress.store_address_id);
					acc.push({
						id: priceInStoreAddress.store_address_id,
						price: priceInStoreAddress.price,
						active: priceInStoreAvailability ? priceInStoreAvailability.is_available : true,
						street: storeAddressData?.address || '',
						storeId: priceInStoreAddress.store_address_id,
					});
				});
			};
			return acc;
		}, []);
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [productsMultiPrices, productsAvailability]);

	let inputPricesPrepare = data.map((item) => ({
		store_address_id: item.storeId,
		price: item.price,
	}));

	const [[min, max], setLabel] = useState([0, 0]);
	const [inputPrices, setInputPrices] = useState(inputPricesPrepare);

	useEffect(() => {
		if (data.length) {
			const [minPrice, maxPrice] = [Math.min(...data.map(el => el.price)), Math.max(...data.map(el => el.price))];

			setLabel([Number.isFinite(minPrice) ? minPrice : 0, Number.isFinite(maxPrice) ? maxPrice : 0]);

			if (minPrice !== product.minMultiPrice) {
				updateProduct(group.id, product.id, 'minMultiPrice', minPrice);
			}
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [data]);


	const handleLabelClick = () => {
		setOpen(!open);
	};

	const handleLabelClose = () => {
		setOpen(false);
	};

	const handleChangePrice = (storeId: string, value: number) => {
		const inputPricesChanged = inputPrices.map((item) => {
			if (item.store_address_id === storeId) return { ...item, price: value };
			return item;
		});
		setInputPrices(inputPricesChanged);
	};

	const onSave = () => {
		const pricesValid = !inputPrices.find((item) => Number(item.price) === 0);
		if (pricesValid) {
			updateProductMultiPrice(product, inputPrices);
			updateItemsErrorsCountForItems('price');
			setOpen(false);
		}
	};

	useClickAwayListener(addressListRef, handleLabelClose);

	return (
		<div ref={addressListRef} className={clsx(classes.root)}>
			<ErrorValidation
				rootClassName={classes.errRoot}
				className={clsx(classes.errorText, {[classes.isDisableProduct]: !product.active})}
				errors={errors}
			>
				<div onClick={handleLabelClick} className={classes.labelWrap}>
					<div className={clsx(classes.label, {[classes.error]: errors?.length})} data-testid="test_label" >
						{label && <div className={classes.miniLabel}>{label}</div>}
						{formatingPrice(min)} - {formatingPrice(max)}
					</div>
				</div>
			</ErrorValidation>
			{ open && (
				<div className={clsx(classes.addressList)}>
					<div className={classes.addressList_wrapper}>
						<div className={classes.addressList_title} >
							{t('addressPopupTitlePrice')}
						</div>
						<CloseIco onClick={handleLabelClick} />
					</div>
					<div className={classes.addressList_info}>
						<div>{t('addressPopupLoc')}</div>
					</div>
					<div className={clsx(classes.addressList_options)}>
						{data.map(address => <AddressPrice key={address.id} address={address} handleChangePrice={handleChangePrice} />)}
						<div className={classes.buttons}>
							<div onClick={handleLabelClose} className={classes.button}>{t('popupCancel')}</div>
							<div onClick={onSave} className={clsx(classes.button, classes.confirm)}>{t('popupSave')}</div>
						</div>
					</div>
				</div>
			)}
		</div>
	);
};

export default ChangePricesInAddresses;
