import AreYouSureSetDefaultLang from 'components/AreYouSureSetDefaultLang';
import MenuManagementItem from 'components/MenuManagementItem';
import Modal from 'components/Modal';
import SelectDefaultLang from 'components/SelectDefaultLang';
import AddLangButton from 'containers/AddLangButton';
import { getLangDataByShortLang } from 'helpers';
import clsx from 'helpers/clsx';
import { useMultiLang } from 'hooks/useMultiLang';
import { ILangItemData } from 'models/IMultiLang';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as Attention } from 'static/imgs/attention.svg';

import classes from './MenuLanguagesPopup.module.scss';
import { IProps } from './types';

const MenuLanguagesPopup: React.FC<IProps> = ({ description, isSelectDefault = false, open, onClose, disableGeneration }) => {
	const { t } = useTranslation(['multiLanguages', 'form']);
	const { multiLangData, setLangList } = useMultiLang();
	const defaultLangData = useMemo(() => getLangDataByShortLang(multiLangData.defaultLanguage), [multiLangData]);
	const [popupLangsList, setPopupLangsList] = useState(multiLangData.languages);
	const [areYouSureData, setAreYouSureData] = useState<ILangItemData | null>(null);

	const handleAddLang = (shortLang: ILangItemData['shortLang']) => {
		setPopupLangsList([...popupLangsList, shortLang]);
	};

	const handleClose = () => {
		setAreYouSureData(null);
		onClose();
	};

	const onClickSave = () => {
		setLangList(popupLangsList);
		handleClose();
	};

	const handleClickRemove = (shortLang: ILangItemData['shortLang']) => {
		setPopupLangsList(popupLangsList.filter((item) => item !== shortLang));
	};

	const userIsNotSure = () => setAreYouSureData(null);

	const renderSetDefaultTemplate = () => {
		if (areYouSureData) return <AreYouSureSetDefaultLang
			defaultLangState={areYouSureData}
			userIsNotSure={userIsNotSure}
			onClose={handleClose}
		/>;
		return <SelectDefaultLang
			onClose={handleClose}
			placeholder={t('multiLanguages:selectLanguage')}
			setAreYouSureData={setAreYouSureData}
			disableGeneration={disableGeneration}
		/>;
	};

	return (
		<Modal open={open} onClose={handleClose} contentClass={classes.popupLangContent}>
			<div className={classes.root}>
				<div className={classes.title}>{areYouSureData ? t('multiLanguages:confirmation') : t('menuLanguagesManagement')}</div>
				{areYouSureData
					? <div className={classes.areYouSure}>{t('multiLanguages:areYouSureSetLang', {lang: areYouSureData.lang})}</div>
					: <div className={classes.text} data-testid="test_description" >{description}</div>}
				{isSelectDefault ? <div className={classes.selectDefaultLangWrap}>
					{renderSetDefaultTemplate()}
				</div> : <>
					<div className={classes.menuItemsWrap}>
						{defaultLangData && (
							<div className={classes.menuItem}>
								<MenuManagementItem isDefault lang={defaultLangData.lang} shortLang={defaultLangData.shortLang} />
							</div>
						)}
						{popupLangsList.map((shortLang) => {
							const langData = getLangDataByShortLang(shortLang);
							if (!langData) return null;
							return (
								<div className={classes.menuItem} key={shortLang}>
									<MenuManagementItem lang={langData.lang} shortLang={langData.shortLang} handleClickRemove={handleClickRemove} />
								</div>
							);
						})}
					</div>
					<div className={classes.addLangButton}>
						<AddLangButton handleAddLang={handleAddLang} popupLangsList={[...popupLangsList, multiLangData.defaultLanguage || '']} />
					</div>
					{disableGeneration && <div className={classes.disableGenerationText}>
						<Attention />
						<span>{t('multiLanguages:disableGeneration')}</span>
					</div>}
					<div className={classes.buttons}>
						<span data-testid="test_cancel" onClick={handleClose}>{t('form:cancel')}</span>
						<span
							className={clsx(classes.save, {[classes.disable]: disableGeneration})}
							onClick={onClickSave}
							data-testid="test_save"
						>{t('form:save')}</span>
					</div>
				</>}
			</div>
		</Modal>
	);
};

export default MenuLanguagesPopup;
