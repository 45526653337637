import InputPrice from 'components/InputPrice';
import clsx from 'helpers/clsx';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as Logo } from 'static/imgs/logo.svg';

import classes from './AddressPrice.module.scss';
import { IProps } from './types';


const AddressPrice: FC<IProps> = ({ address, handleChangePrice }) => {
	const { active, street, storeId, price } = address;
	const [isValid, setIsValid] = useState<boolean>(Number(price) > 0);
	const { t } = useTranslation(['validation', 'multiStore']);

	const handleChange = (value: number) => {
		handleChangePrice(storeId, value);
		setIsValid(Number(value) > 0);
	};

	return (
		<div className={classes.root}>
			<div className={clsx(classes.address, { [classes.address_off]: !active })}>
				<div className={classes.address_ico}> <Logo /></div>
				<div data-testid="test_address_id" className={classes.address_id}>{storeId}</div>
				<div data-testid="test_street" className={clsx(classes.address_label)}>{street}</div>
				<div className={classes.address_input}>
					<InputPrice
						data-testid="priceInput"
						errors={!isValid ? [t('price')] : []}
						value={price} onChange={handleChange}
						label={t('multiStore:price')}
					/>
				</div>
			</div>
		</div>
	);
};

export default AddressPrice;
