import InputComponent from 'components/InputComponent';
import { languageList } from 'const';
import MenuLanguagesItem from 'containers/MenuLanguagesItem';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as SearchIco } from 'static/imgs/search.svg';

import classes from './LangDropDown.module.scss';
import { IProps } from './types';

const LangDropDown: React.FC<IProps> = ({ handleClose, handleSetLang, popupLangsList = [] }) => {
	const { t } = useTranslation('multiLanguages');
	const [langsList, setLangsList] = useState(languageList);
	const [searchValue, setSearchValue] = useState('');
	const hangleChangeSearchValue = (value: string) => {
		setSearchValue(value);
		hangleSearch(value);
	};

	const hangleSearch = (value: string) => {
		setLangsList(languageList.filter((item) => item.lang.toLocaleLowerCase().includes(value.toLocaleLowerCase())));
	};

	const clearValue = () => {
		hangleChangeSearchValue('');
	};

	return (
		<>
			<div className={classes.overlay} onClick={handleClose}></div>
			<div className={classes.root}>
				<div className={classes.search} data-testid="test_search" >
					<div className={classes.search_icon}><SearchIco /></div>
					<InputComponent
						showClearButton
						value={searchValue}
						onChange={hangleChangeSearchValue}
						placeholder={t('languageSearch')}
						handleClearSearchQuery={clearValue}
					/>
				</div>
				<div className={classes.lagsList}>
					{langsList.map((langItem) => {
						if (popupLangsList.indexOf(langItem.shortLang) > -1) return null;

						return (
							<div className={classes.lagsListItem} key={langItem.shortLang}>
								<MenuLanguagesItem lang={langItem.lang} shortLang={langItem.shortLang} onClick={handleSetLang} />
							</div>
						);
					})}
				</div>
			</div>
		</>
	);
};

export default LangDropDown;
