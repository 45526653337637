import React from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as Attention } from 'static/imgs/attention.svg';

import classes from './ItemHasErr.module.scss';
import { IProps } from './types';

const ItemHasErr: React.FC<IProps> = ({ count }) => {
	const { t } = useTranslation('validation');

	return (
		<div className={classes.wrap} data-testid="test_itemHasErr" ><Attention/>{t('errors')}:<span>{count}</span></div>
	);
};

export default ItemHasErr;
