import { useCallback, useEffect, useRef } from 'react';

const useScroll = (cb: () => void, timeout: number = 500) => {
	const timer = useRef<any>(null);

	const checkIsScroll = useCallback(() => {
		if (timer.current !== null) {
			clearTimeout(timer.current);
		}

		timer.current = setTimeout(cb, timeout);
	}, [cb, timeout]);

	useEffect(() => {
		window.addEventListener('scroll', checkIsScroll, false);
		return () => {
			window.removeEventListener('scroll', checkIsScroll, false);
		};
	}, [checkIsScroll]);
};

export default useScroll;

