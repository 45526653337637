import React from 'react';

const NotFound: React.FC = () => {
	return (
		<h2 className="App">
			Page not found
		</h2>
	);
};

export default NotFound;
