import { fetch } from 'helpers/fetch';
import { IOptionGroup } from 'models/IOption';

export const fetchOptions = (storeAddressId: string, isMultiStore: boolean) => {
	const url = isMultiStore ? `/${storeAddressId}/options/` : `/options/${storeAddressId}/`;
	return fetch<IOptionGroup[]>(url);
};

export const patchOptions = (storeAddressId: string, options: IOptionGroup[], isMultiStore: boolean) => {
	const url = isMultiStore ? `/${storeAddressId}/options/` : `/options/${storeAddressId}/`;
	return fetch<IOptionGroup[]>(url, {
		method: 'PATCH',
		body: JSON.stringify(options),
	});
};

export const deleteOptionsGroup = (storeAddressId: string, groupId: string, isMultiStore: boolean) => {
	const url = isMultiStore ? `/${storeAddressId}/options/${groupId}/` : `/options/${storeAddressId}/${groupId}/` ;
	return fetch<IOptionGroup[]>(url, { method: 'DELETE' }).catch((e) => { console.log(e); });
};

export const deleteOption = (storeAddressId: string, groupId: string, optionId: string, isMultiStore: boolean) => {
	const url = isMultiStore ? `/${storeAddressId}/options/${groupId}/${optionId}/` : `/options/${storeAddressId}/${groupId}/${optionId}/`;
	return fetch<IOptionGroup[]>(url, { method: 'DELETE' }).catch((e) => { console.log(e); });
};
