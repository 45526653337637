import clsx from 'helpers/clsx';
import React from 'react';
import { ReactComponent as Icon } from 'static/imgs/check_box.svg';

import classes from './FormControl.module.scss';
import { IPropsCheckbox } from './types';

const Checkbox: React.FC<IPropsCheckbox> = ({
	rootClassName,
	textClassName,
	isChecked,
	onChange,
	children,
	disabled,
	icoClassName,
}) => {

	return (
		<div
			data-testid="wrapper-div"
			className={clsx(classes.checkbox, rootClassName, {
				[classes.disabled]: disabled,
			})}
			onClick={!disabled ? onChange : undefined}
		>
			<div
				className={clsx(classes.checkboxIcon, icoClassName, {
					[classes.checkboxIcon_active]: isChecked,
				})}>{isChecked && <Icon />}
			</div>
			<div data-testid="checkbox-text" className={clsx(classes.checkboxText, textClassName)}>
				{children}
			</div>
		</div>
	);
};

export default Checkbox;
