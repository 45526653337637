import { DragableProvider } from 'components/DragableProvider';
import SectionHead from 'components/SectionHead';
import AddElementButton from 'containers/AddElementButton';
import ProductLine from 'containers/ProductLine';
import clsx from 'helpers/clsx';
import { getProductById } from 'helpers/products';
import { scrollToNewItem } from 'helpers/scroll';
import { useAppSelector } from 'hooks/redux';
import { useProducts } from 'hooks/useProducts';
import { IProductGroup } from 'models/IProduct';
import React, { memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import classes from './ProductsSection.module.scss';
import { IProps } from './types';

const ProductsSection: React.FC<IProps> = ({
	productsGroup, collectionActive, isCollapseAll,
	collapseAll, superCollectionActive, defaultCollapse = false,
	updateProductGroup, handleOnMove, collectionId, superCollectionId,
}) => {
	const { createEmptyProduct } = useProducts();
	const [isCollapse, setIsCollapse] = useState<boolean>(defaultCollapse);
	const { t } = useTranslation('products');
	const { foundGroups } = useAppSelector(state => state.products);

	useEffect(() => {
		if (isCollapseAll) setIsCollapse(isCollapseAll);
	}, [isCollapseAll]);

	useEffect(() => {
		setIsCollapse(defaultCollapse);
		if (!defaultCollapse) updateProductGroup(productsGroup, 'saveCollapseValue', defaultCollapse);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [defaultCollapse]);

	const handleUpdateProductGroup = (key: keyof IProductGroup) => (value: any) => {
		updateProductGroup(productsGroup, key, value);
	};

	const handleCreateEmptyProduct = () => {
		setIsCollapse(false);
		createEmptyProduct(productsGroup.id, productsGroup.items.length);
		if (productsGroup.items.length) setTimeout(() => scrollToNewItem(`${productsGroup.items[productsGroup.items.length - 1].id}`, true), 0);
	};

	const countItems = productsGroup.items.filter((it) => it.title && (it.price || it.price === null)).length;

	const handleCollapseSection = () => {
		const nextCollapseValue = !isCollapse;
		setIsCollapse(nextCollapseValue);
		updateProductGroup(productsGroup, 'saveCollapseValue', nextCollapseValue);
		collapseAll(false, true);
	};

	return (
		<div className={clsx( classes.root,{
			[classes.collapse]: isCollapse,
			[classes.groupsChildHaveErrs]: (productsGroup.itemsErrorsCount && productsGroup.itemsErrorsCount > 0),
		})}>
			<SectionHead
				group={productsGroup}
				itemsLength={productsGroup.items.length}
				isActive={productsGroup.active}
				countItems={countItems}
				title={productsGroup.title}
				onChangeTitle={handleUpdateProductGroup('title')}
				onChangeSwitch={handleUpdateProductGroup('active')}
				errors={productsGroup.validation?.title}
				handleCollapseSection={handleCollapseSection}
				isCollapse={isCollapse}
				handleCreateEmptyProduct={handleCreateEmptyProduct}
				collectionActive={collectionActive}
				closestSuperCollectionActive={superCollectionActive}
				translations={productsGroup.translations}
			/>
			{!isCollapse && (
				<div className={classes.collapseWrap}>
					<DragableProvider classSortIdent={classes.sortProductIdent} providerType="products" onMove={handleOnMove}>
						{productsGroup.items.map((product, index) => {
							// if isSearch - render only found products
							if (foundGroups.length && !getProductById(foundGroups, productsGroup.id, product.id)) return null;

							return (
								<div className={clsx({ [classes.isNotFirstProd]: index > 0 })} key={product.id} >
									<ProductLine
										collectionId={collectionId}
										superCollectionId={superCollectionId}
										isActive={productsGroup.active}
										product={product}
										group={productsGroup}
										key={product.id}
										collectionActive={collectionActive}
										closestSuperCollectionActive={superCollectionActive}
									/>
								</div>
							);
						})}
					</DragableProvider>
					<div className={classes.addProductButtonWrap}>
						<AddElementButton buttonText={t('addProduct')} onClick={handleCreateEmptyProduct} />
					</div>
				</div>
			)}
		</div>
	);
};

export default memo(ProductsSection);
