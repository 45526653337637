import { deleteCollection, deleteCollections, patchCollections, patchCollectionsAvailability } from 'api/collections';
import equal from 'fast-deep-equal/react';
import {
	changeAvailability,
	createAvailability,
	getAvailableBlockByEntityId,
	getItemAvailableStatus,
	removeAvailability,
	removePropertiesForCheck,
} from 'helpers';
import { getEmptyCollection, getTranslationsForProduct } from 'helpers/mock';
import { checkIsValidEntities, checkmultiLangTitlesIsUnique } from 'helpers/validation';
import { ICollection, ISuperCollection } from 'models/ICollection';
import { IAvailability, IProductGroup, ITranslations } from 'models/IProduct';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { collectionsSlice } from 'store/reducers/collections';
import {
	fetchCollections as fetchCollectionsAction,
	fetchCollectionsAvailability as fetchCollectionsAvailabilityAction,
} from 'store/reducers/collections/actions';
import { productsSlice } from 'store/reducers/products';

import { useAppSelector } from './redux';
import { useProducts } from './useProducts';
import { useStoreAddress } from './useStoreAddress';
import useToast from './useToast';

interface IUseCollections {
	fetchCollections(): void;
	createEmptyCollection(productGroupsLength: number, name?: string, sectionsId?: string[]): ICollection;
	tieSectionToCollection(collectionId: string, sectionId: string): void;
	removeSectionFromCollections(sectionId: string): void;
	updateCollection(collectionId: ICollection['id'], key: keyof ICollection, value: any): void;
	updatePositionCollections(): void;
	removeCollection(collectionId: ICollection['id']): void;
	getCollectionById(collectionId: ICollection['id']): ICollection | undefined;
	sendCollections(productGroups: IProductGroup[], sendToGlovo: boolean, superCollections?: ISuperCollection[]): void;
	offCollection(): void;
	removeValidation(): void;
	showCollections: boolean;
	setShowCollectionsState(value: boolean): void;
	clearCollections(): void;
	collectionsAvailability: IAvailability[];
	updateCollectionAvailability(storeId: string, status: boolean, collectionId: ICollection['id']): void;
	getUserChanges(): boolean;
	getCollectionByChildSectionId(sectionId: string): ICollection | undefined;
	checkValidCollections(): [ICollection[], boolean];
	updateCollections(items: ICollection[]): void;
};

export enum CollectionsIdsEnum {
	COLLECTION = 'collection-ids',
};

export const useCollections = (): IUseCollections => {
	const dispatch = useDispatch();
	const { errorToast, successToast } = useToast();
	const { createEmptyGroupProducts, sendProducts, isValidProducts, clearSearchResult } = useProducts();
	const { currentStoreId, isMultiStore, currentStoreData, currentStoreAddressId, addressesIdsInStore, setDisableSendButton } = useStoreAddress();
	const { collections, initialCollections, showCollections, collectionsAvailability } = useAppSelector(state => state.collections);
	const { showSuperCollections } = useAppSelector(state => state.superCollectionsData);
	const { t } = useTranslation(['errors', 'success']);
	const { multiLangData } = useAppSelector(state => state.multiLang);
	const { userCountry } = useAppSelector(state => state.user);

	const setShowCollectionsState = (value: boolean) => {
		dispatch(collectionsSlice.actions.setShowCollectionsState(value));
	};

	const clearCollections = () => {
		dispatch(collectionsSlice.actions.clearCollections());
	};

	const fetchCollections = () => {
		const storeId = isMultiStore ? currentStoreId : currentStoreAddressId;

		if (storeId) {
			dispatch(fetchCollectionsAction({ storeAddressId: storeId, isMultiStore }));
			if (isMultiStore) {
				dispatch(fetchCollectionsAvailabilityAction({ storeAddressId: storeId, addressesIdsInStore: addressesIdsInStore.join(',') }));
			}
		}
	};

	const createEmptyCollection = (productGroupsLength: number, name?: string, sectionsIds?: string[]) => {
		const sectionsInCollections = sectionsIds ? sectionsIds : [createEmptyGroupProducts(productGroupsLength).id];
		const newTranslations: ITranslations = getTranslationsForProduct(multiLangData.languages);
		const newCollection = getEmptyCollection(collections.length + 1, sectionsInCollections, name, newTranslations);

		dispatch(collectionsSlice.actions.createEmptyCollectionStore(newCollection));
		createAvailabilityForCollection(newCollection.id);
		clearSearchResult();

		return newCollection;
	};

	const removeValidation = () => {
		dispatch(collectionsSlice.actions.removeValidation());
	};

	const tieSectionToCollection = (collectionId: ICollection['id'], sectionId: IProductGroup['id']) => {
		dispatch(collectionsSlice.actions.tieSectionToCollectionStore({ collectionId, sectionId }));

	};

	const removeSectionFromCollections = (sectionId: IProductGroup['id']) => {
		dispatch(collectionsSlice.actions.removeSectionFromCollectionsStore(sectionId));
	};

	const updateCollection = useCallback((collectionId: ICollection['id'], key: keyof ICollection, value: any) => {
		dispatch(collectionsSlice.actions.updateCollectionStore({ collectionId, key, value }));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const getCollectionById = (collectionId: ICollection['id']) => collections.find(({ id }) => id === collectionId);

	const reorderCollectionsForSupCollections = (newResult: ICollection[]) => {
		if (!showSuperCollections) return newResult;

		let reorderedGroupsIterator = 0;
		return collections.map((group => {
			const isReorderedGroup = newResult.filter((newResultGroup) => newResultGroup.id === group.id);
			if (isReorderedGroup.length) return newResult[reorderedGroupsIterator++];
			return group;
		}));
	};

	const updatePositionCollections = () => {
		setTimeout(() => {
			const collectionsEL = document.querySelectorAll(`.${CollectionsIdsEnum.COLLECTION}`);
			const newResult: ICollection[] = [];
			Array.from(collectionsEL).forEach((collectionEl, index) => {
				const idCollection = collectionEl.getAttribute('id');
				if (!idCollection) return;
				const collection = getCollectionById(idCollection);
				if (!collection) return;
				newResult.push({
					...collection,
					position: index + 1,
				});
			});

			dispatch(collectionsSlice.actions.update({ items: reorderCollectionsForSupCollections(newResult), changedPosition: true }));
		}, 0);
	};

	const removeCollection = (collectionId: ICollection['id']) => {
		const deletedCollection = getCollectionById(collectionId);

		deletedCollection?.productGroups.forEach((childSectionId) => {
			dispatch(productsSlice.actions.removeProductGroup({ groupId: childSectionId }));
		});

		dispatch(collectionsSlice.actions.removeCollectionStore({ collectionId }));
		dispatch(collectionsSlice.actions.updateAvailability(removeAvailability(collectionsAvailability, collectionId)));

		const storeId = isMultiStore ? currentStoreId : currentStoreAddressId;
		if (storeId && !deletedCollection?.isNew) {
			deleteCollection(storeId, collectionId, isMultiStore);
			if (isMultiStore) {
				dispatch(fetchCollectionsAvailabilityAction({ storeAddressId: storeId, addressesIdsInStore: addressesIdsInStore.join(',') }));
			}
		}
	};

	const offCollection = () => {
		const storeId = isMultiStore ? currentStoreId : currentStoreAddressId;
		if (storeId) {
			deleteCollections(storeId, isMultiStore);
			if (isMultiStore) {
				dispatch(fetchCollectionsAvailabilityAction({ storeAddressId: storeId, addressesIdsInStore: addressesIdsInStore.join(',') }));
			}
			dispatch(collectionsSlice.actions.updateInitialCollections());
		}
	};

	const showErrorToast = (sendToGlovo: boolean) => {
		if (sendToGlovo) return errorToast(t('errors:send.collections'));
		errorToast(t('errors:saveAsDraft.collections'));
	};

	const showSuccessToast = (sendToGlovo: boolean) => {
		if (sendToGlovo) return successToast(t('success:send.collections'));
		successToast(t('success:saveAsDraft.collections'));
	};

	const checkValidCollections = (): [ICollection[], boolean] => {
		const [newCollectionsAfterValidation, isValid] = checkIsValidEntities(collections, false, false, userCountry);
		let collectionsTitleIsUnique = true;

		const collectionsAfterUniqueTest = newCollectionsAfterValidation.map((collection) => {
			const nameIsUnique = !newCollectionsAfterValidation.find((item) => (item.id !== collection.id && item.title === collection.title));

			if (!nameIsUnique) {
				collection.validation = {title: [t('validation:isNotUniqueName')]};
				if (collectionsTitleIsUnique) collectionsTitleIsUnique = false;
			}

			if (collection.translations) {
				const [isUniqueMultiTranslate, itemsAfterUniqueTest] = checkmultiLangTitlesIsUnique(
					collection.translations,
					newCollectionsAfterValidation,
					collection.id,
					collection.title,
					[t('validation:isNotUniqueName')],
				);

				if (!isUniqueMultiTranslate) {
					collection.translations = itemsAfterUniqueTest;
					if (collectionsTitleIsUnique) collectionsTitleIsUnique = false;
				}
			}
			return collection;
		});

		return [collectionsAfterUniqueTest, isValid && collectionsTitleIsUnique];
	};

	const sendCollections = async (productGroups: IProductGroup[], sendToGlovo: boolean, superCollections?: ISuperCollection[]) => {
		const storeId = isMultiStore ? currentStoreId : currentStoreAddressId;
		if (storeId) {
			const [newCollectionsAfterValidation, isValid] = checkValidCollections();

			if (!isValid) {
				setDisableSendButton(false);
				showErrorToast(sendToGlovo);
				dispatch(collectionsSlice.actions.update({ items: newCollectionsAfterValidation }));
			} else {
				const newCollections = await patchCollections(storeId, collections, isMultiStore);
				if (isMultiStore) patchCollectionsAvailability(storeId, collectionsAvailability, addressesIdsInStore.join(','));

				if (newCollections && Array.isArray(newCollections)) {
					dispatch(collectionsSlice.actions.update({ items: newCollections, needSort: true }));
					if (isValidProducts){
						sendProducts(productGroups, sendToGlovo, newCollectionsAfterValidation, superCollections);
					} else {
						setDisableSendButton(false);
					}
					showSuccessToast(sendToGlovo);
					dispatch(collectionsSlice.actions.updateInitialCollections());
				}
				else {
					setDisableSendButton(false);
					showErrorToast(sendToGlovo);
				}
			}
		}
	};

	const updateCollectionAvailability = (storeId: string, status: boolean, collectionId: ICollection['id']) => {
		const newAvailability = changeAvailability(collectionsAvailability, collectionId, status, storeId);
		dispatch(collectionsSlice.actions.updateAvailability(newAvailability));

		const currentAvailableCollection = getAvailableBlockByEntityId(newAvailability, collectionId);
		const collectionAvailableStatus = getItemAvailableStatus(currentAvailableCollection);
		updateCollection(collectionId, 'active', collectionAvailableStatus);
	};

	const createAvailabilityForCollection = (id: ICollection['id']) => {
		const createAvailabilityItem = createAvailability(currentStoreData);

		if (createAvailabilityItem) {
			dispatch(collectionsSlice.actions.updateAvailability([...collectionsAvailability, {
				entity_id: id,
				availability: createAvailabilityItem,
			}]));
		}
	};

	const getUserChanges = (): boolean => !equal(removePropertiesForCheck(collections), removePropertiesForCheck(initialCollections));

	const getCollectionByChildSectionId = (sectionId: IProductGroup['id']) => collections.find(({ productGroups }) => productGroups.find(
		(groupId) => groupId === sectionId),
	);

	const updateCollections = (items: ICollection[]) => {
		dispatch(collectionsSlice.actions.update({ items }));
	};

	return {
		fetchCollections,
		createEmptyCollection,
		tieSectionToCollection,
		removeSectionFromCollections,
		updateCollection,
		updatePositionCollections,
		removeCollection,
		sendCollections,
		offCollection,
		removeValidation,
		showCollections,
		setShowCollectionsState,
		clearCollections,
		getCollectionById,
		collectionsAvailability,
		updateCollectionAvailability,
		getUserChanges,
		getCollectionByChildSectionId,
		checkValidCollections,
		updateCollections,
	};
};
