import InputForEnteringInform from 'components/InputForEnteringInform';
import Modal from 'components/Modal';
import MultiLangInput from 'containers/MultiLangInput';
import { useOptionsContext } from 'contexts/Options';
import clsx from 'helpers/clsx';
import { fetch } from 'helpers/fetch';
import { multiLangTitlesValidation } from 'helpers/validation';
import { useAppSelector } from 'hooks/redux';
import { useMultiLang } from 'hooks/useMultiLang';
import { useOptions } from 'hooks/useOptions';
import { useProducts } from 'hooks/useProducts';
import { ITranslations } from 'models/IProduct';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as IIco } from 'static/imgs/i.svg';
import loader from 'static/imgs/loader.gif';
import { ReactComponent as ManageLangIco } from 'static/imgs/manageLangIco.svg';

import classes from './TitleLangsPopup.module.scss';
import { IProps } from './types';

const TitleLangsPopup: React.FC<IProps> = ({
	open, onClose, allowedNumber,
	translations, parentType, parentId, placeholder,
	groupId, isDescription = false, defaultTitle, handleUpdateDefaultTitle, label, headerTitle,
}) => {
	const { t } = useTranslation(['multiLanguages', 'form']);
	const [popupLangsValues, setPopupLangsValues] = useState<ITranslations>(translations);
	const { updateProduct, updateProductGroup } = useProducts();
	const { updateOptionGroup, updateOption } = useOptions();
	const { multiLangData } = useMultiLang();
	const [defaultTitleState, setDefaultTitleState] = useState(defaultTitle);
	const [defaultTitleValidation, setDefaultTitleValidation] = useState<string[] | undefined>(undefined);
	const { isModal, updateTranslations } = useOptionsContext();
	const { userCountry } = useAppSelector(state => state.user);
	const { defaultLanguage, languages } = useAppSelector(state => state.multiLang.multiLangData);
	const [generateIsProgress, setGenerateIsProgress] = useState<boolean>(false);
	const [showCheckText, setShowCheckText] = useState<boolean>(false);

	useEffect(() => {
		setPopupLangsValues(translations);
	}, [translations]);

	useEffect(() => {
		setDefaultTitleState(defaultTitle);
	}, [defaultTitle]);

	const generateTranslate = () => {
		setGenerateIsProgress(true);
		fetch<{[key: string]: string}>('/multi-lang/translate-text', {
			method: 'post',
			body: JSON.stringify({
				'text': defaultTitleState,
				'current_language': defaultLanguage,
				'translate_to': languages,
			}),
		}).then((res) => {
			const prepareTranslations = {...popupLangsValues};
			Object.keys(res || {}).forEach((langKey) => {
				prepareTranslations[langKey] = {
					...prepareTranslations[langKey],
					...(isDescription ? {description: res[langKey]} : {title: res[langKey]}),
					validation: undefined,
				};
			});
			setPopupLangsValues(prepareTranslations);
		}).finally(() => {
			setGenerateIsProgress(false);
			setShowCheckText(true);
		});
	};

	const handleChange = (shortLang: string, value: string) => {
		const copy = Object.keys(popupLangsValues || {}).reduce((acc: ITranslations, key) => {
			if (key === shortLang) {
				acc[key] = {
					...popupLangsValues[key],
					...(isDescription ? {description: value} : {title: value}),
					validation: undefined,
				};
			} else {
				acc[key] = {
					...popupLangsValues[key],
					validation: undefined,
				};
			}

			return acc;
		}, {});

		setPopupLangsValues(copy);
	};

	const handleClose = () => {
		handleChange('', ''); // removing validation
		setDefaultTitleValidation(undefined);
		onClose();
		setPopupLangsValues(translations);
		setShowCheckText(false);
	};

	const onSave = () => {
		const [isValid, itemsAfterValidation] = multiLangTitlesValidation(popupLangsValues, isDescription, parentType, userCountry);
		const [isValidDefaultValue, defaultValueAfterValidation] = multiLangTitlesValidation(
			{
				[multiLangData.defaultLanguage || '']: {
					...(isDescription ? {title: '', description: defaultTitleState} : {title: defaultTitleState}),
				},
			},
			isDescription,
			parentType,
			userCountry,
		);

		if (isValid && isValidDefaultValue) {
			handleUpdateDefaultTitle(defaultTitleState);
			switch (parentType) {
				case 'product': {
					if (groupId) updateProduct(groupId, parentId, 'translations', popupLangsValues);
					break;
				}
				case 'product_group': {
					updateProductGroup(parentId, 'translations', popupLangsValues);
					break;
				}
				case 'option_group': {
					if (isModal && updateTranslations) {
						updateTranslations(popupLangsValues, 'option_group', defaultTitleState);
					} else {
						updateOptionGroup(parentId, 'translations', popupLangsValues);
					}
					break;
				}
				case 'option': {
					if (groupId) {
						if (isModal && updateTranslations) {
							updateTranslations(popupLangsValues, 'option', defaultTitleState, parentId);
						} else {
							updateOption(groupId, parentId, 'translations', popupLangsValues);
						}
					}
					break;
				}
			}

			handleClose();
		} else {
			setPopupLangsValues(itemsAfterValidation);
			setDefaultTitleValidation(defaultValueAfterValidation[multiLangData.defaultLanguage || ''].validation);
		}

	};

	return (
		<Modal open={open} onClose={handleClose} contentClass={isDescription ? classes.isDescriptionPopupLangs : ''}>
			<div className={clsx(classes.root, {[classes.isDescription]: isDescription})}>
				<div className={classes.header}>{headerTitle}</div>
				<div className={classes.body}>
					<div className={classes.description}><IIco/>{t('translationDescription')}</div>
					<div className={clsx(classes.inputWrap, classes.defaultLanguage)}>
						<span className={classes.shortLang}>{multiLangData.defaultLanguage}</span>
						<InputForEnteringInform
							hideLangBar
							isTextarea={isDescription}
							allowedNumber={allowedNumber}
							onChange={setDefaultTitleState}
							value={defaultTitle}
							errors={defaultTitleValidation}
							label={label}
							placeholder={placeholder}
						/>
					</div>
					<div className={
						clsx(classes.generateTranslateButton,{[classes.generateIsProgress]: generateIsProgress || !defaultTitleState.length})
					}>
						<span onClick={generateTranslate}><ManageLangIco/>{t('generateTranslation')}</span>
					</div>
					{Object.keys(popupLangsValues || {}).map((item) => {
						return (
							<div className={clsx(classes.inputWrap, {[classes.generateIsProgress]: generateIsProgress})} key={item}>
								<MultiLangInput
									isDescription={isDescription}
									onChange={handleChange}
									shortLang={item}
									value={isDescription ? popupLangsValues[item].description : popupLangsValues[item].title}
									allowedNumber={allowedNumber}
									errors={popupLangsValues[item].validation}
									label={label}
									placeholder={placeholder}
								/>
							</div>
						);
					})}
					{generateIsProgress && <div className={classes.generateLoader}><img src={loader} alt="loader" /></div>}
					{showCheckText && <div className={clsx(classes.description, classes.checkTranstalions)}><IIco/>{t('checkTranslations')}</div>}
					<div className={classes.buttons}>
						<span onClick={handleClose}>{t('form:cancel')}</span>
						<span className={clsx(classes.save, {[classes.generateIsProgress]: generateIsProgress})} onClick={onSave}>
							{t('form:save')}
						</span>
					</div>
				</div>
			</div>
		</Modal>
	);
};

export default TitleLangsPopup;
