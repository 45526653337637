import checkIco from 'static/imgs/checkIco.svg';

export const customStyles = {
	container: (provided: any, state: any) => ({
		...provided,
		display: 'flex',
		position: 'relative',
		width: '100%',
		maxWidth: '245px',
		marginRight: '10px',
		marginTop: '10px',
	}),
	control: (provided: any, state: any) => ({
		...provided,
		paddingBottom: '2px',
		cursor: 'pointer',
		boxShadow: 'none',
		display: 'flex',
		height: '40px',
		border: '1px solid #E2E2E6',
		position: 'relative',
		borderRadius: '30px',
		width: '100%',
		maxWidth: '245px',
		'&:hover': {
			border: '1px solid #E2E2E6',
		},
	}),
	valueContainer: (provided: any, state: any) => ({
		...provided,
		display: 'flex',
		alignItems: 'center',
		width: '100%',
		paddingLeft: '15px',
	}),
	placeholder: (provided: any, state: any) => ({
		...provided,
		color: '#493F58',
		margin: 0,
	}),
	indicatorSeparator: (provided: any, state: any) => ({
		...provided,
		display: 'none',
	}),
	menu: (provided: any, state: any) => ({
		...provided,
		width: '100%',
		boxShadow: '-4px 4px 14px rgba(78, 69, 92, 0.15)',
		borderRadius: '20px',
		marginBottom: '3px',
		marginTop: '3px',
		overflow: 'hidden',
		minWidth: '300px',
	}),
	dropdownIndicator: (provided: any, state: any) => ({
		...provided,
		color: '#797A8D',
		padding: 0,
		paddingRight: '12px',
		'&:hover': {
			color: '#797A8D',
		},
	}),
	option: (provided: any, state: any) => ({
		...provided,
		color: '#493F58',
		cursor: 'pointer',
		padding: '14px 35px 14px 50px',
		boxSizing: 'border-box',
		fontSize: '14px',
		position: 'relative',
		background: '#ffffff',
		'&:after': {
			content: '""',
			width: '18px',
			height: '18px',
			border: '2px solid #9E9FBA',
			borderRadius: '2px',
			boxSizing: 'border-box',
			position: 'absolute',
			left: '23px',
			top: '50%',
			transform: 'translateY(-50%)',
			display: state.isSelected ? 'none' : 'block',
		},
		'&:before': {
			content: '""',
			display: state.isSelected ? 'block' : 'none',
			width: '18px',
			height: '18px',
			borderRadius: '2px',
			boxSizing: 'border-box',
			position: 'absolute',
			left: '23px',
			top: '50%',
			transform: 'translateY(-50%)',
			background: `url(${checkIco}) no-repeat`,
		},
		'&:hover': {
			color: '#493F58',
			background: '#F2F3F5',
		},
	}),
	menuList: (provided: any, state: any) => ({
		...provided,
		padding: '17px 0 0',
		boxSizing: 'border-box',
		maxWidth: '300px',
		minWidth: '265px',
		position: 'static',
	}),
};
