import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ECollectionsPrompts, EOptionsPrompts, ESectionPrompts } from 'models/IPrompts';
export interface IState {
	sectionsPrompt: ESectionPrompts | null;
	optionsPrompt: EOptionsPrompts | null;
	collectionsPrompt: ECollectionsPrompts | null;
}

const initialState: IState = {
	sectionsPrompt: null,
	optionsPrompt: null,
	collectionsPrompt: null,
};

export const getStartedSlice = createSlice({
	name: 'getStarted',
	initialState,
	reducers: {
		setSectionsPromptState(state, action: PayloadAction<ESectionPrompts | null>) {
			state.sectionsPrompt = action.payload;
		},
		setOptionsPromptState(state, action: PayloadAction<EOptionsPrompts | null>) {
			state.optionsPrompt = action.payload;
		},
		setCollectionsPromptState(state, action: PayloadAction<ECollectionsPrompts | null>) {
			state.collectionsPrompt = action.payload;
		},
	},
});

export default getStartedSlice.reducer;
