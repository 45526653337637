import { datadogRum } from '@datadog/browser-rum';

datadogRum.init({
	applicationId: process.env.REACT_APP_RUM_APPLICATION_ID || '',
	clientToken: process.env.REACT_APP_RUM_CLIENT_TOKEN || '',
	site: process.env.REACT_APP_RUM_SITE || '',
	service: process.env.REACT_APP_RUM_SERVICE || '',
	env: process.env.REACT_APP_ENV,
	sampleRate: 100,
	premiumSampleRate: 100,
	trackInteractions: true,
	defaultPrivacyLevel: 'mask-user-input',
	replaySampleRate: 0,
});

export const setDatadogRumUser = (user: {id: string}) => {
	datadogRum.setUser(user);
};
