import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

import classes from '../SendFeedbackPopup.module.scss';

const SuccessTemplate = () => {
	const { t } = useTranslation('feedback');

	return (
		<div className={classes.success}>
			<div className={classes.title}>{t('feedback:thanks')}</div>
			<div className={classes.message}>
				<Trans t={t} i18nKey="feedback:feedbackSuccessDescription" />
			</div>
		</div>
	);
};

export default SuccessTemplate;
