import { hasRemember, removeRemember } from 'helpers/remember';
import { getToken, refreshToken, removeRefreshToken, removeToken } from 'helpers/token';
import retryMyFetch from 'retry-my-fetch';

const getOptionsWithAuthorization = (options: any, token?: string) => {
	if (!token) token = getToken();
	return {
		...options,
		headers: {
			...options?.headers,
			...(token && {authorization: `Bearer ${token}`}),
		},
	};
};

const logout = () => {
	removeToken();
	removeRefreshToken();
	removeRemember();
	const href = `${process.env.PUBLIC_URL || ''}/login`;
	window.location.href = href;
};

const canRefetchByUrl = (url: string) => {
	const EXCLUDE_URL: string[] = ['refreshToken', 'authenticate'];
	return !EXCLUDE_URL.some((u) => url.includes(u));
};

const beforeRefetch = async (url: string, fetchOptions: any, statusCode: number) => {
	if (statusCode === 401 && canRefetchByUrl(url)) {
		if (!hasRemember()) {
			return logout();
		}
		const response = await refreshToken().catch(() => undefined);
		if (!response || !response.jwt) {
			return logout();
		}
		return getOptionsWithAuthorization(fetchOptions, response.jwt);
	}
	return fetchOptions;
};

const config = {
	maxTryCount: 8,
	beforeRefetch,
	useAbortController: true,
	doNotRetryIfStatuses: [403, 422, 400, 504],
};

const fetchWithRetry = retryMyFetch(fetch as any, config);

export default async function fetchWithToken(url: string, options: any = {}) {
	return fetchWithRetry(url, await getOptionsWithAuthorization(options));
}
