import clsx from 'helpers/clsx';
import React from 'react';
import { ReactComponent as MultiIco } from 'static/imgs/multi-level.svg';

import classes from './SwitchComponent.module.scss';
import { IProps } from './types';

const SwitchComponent: React.FC<IProps> = ({
	titleAct,
	className,
	classNameSpan,
	titleDis = titleAct,
	isActive = true,
	onChange,
	revert = false,
	ico = false,
}) => {

	const handleChange = () => {
		onChange(!isActive);
	};

	return (
		<div
			className={
				clsx(
					{ [classes.toggleActive]: titleAct?.length || titleDis?.length },
					{ [classes.multilevel]: ico },
					{ [classes.multilevel_disabled]: ico && !isActive },
				)
			}
		>
			{ico && <MultiIco />}
			<div
				data-testid="switch-div"
				className={clsx(classes.root, className, { [classes.disable]: !isActive }, { [classes.revert]: revert })}
				onClick={handleChange}
			>
				<span className={clsx(classNameSpan)}><span /></span>
				{isActive ? titleAct : titleDis}
			</div>
		</div>
	);
};

export default SwitchComponent;
