import { ERoles, RoutesEnum } from 'const';
import { fetch } from 'helpers/fetch';
import { getCountryFromToken, getLang, getRolesFromToken, getStoreAddressId, getUserEmail } from 'helpers/jwt';
import storage from 'helpers/localStorage';
import { removeRemember } from 'helpers/remember';
import { removeRefreshToken, removeToken, setRefreshToken, setToken } from 'helpers/token';
import { isValidEmail, isValidPassword } from 'helpers/validation';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

import { useOptions } from './useOptions';
import { useProducts } from './useProducts';
import { useStoreAddress } from './useStoreAddress';
import useToast from './useToast';
import { useUser } from './useUser';

interface IUseAuth {
	loadingLogin: boolean;
	login(email: string, password: string, remember: boolean): void;
	logout(): void;
	handleToken(token: string, refreshToken?: string): void;
};

interface IResponse {
	jwt: string;
	refreshToken: string;
	expireInSeconds: number;
}

export const useAuth = (): IUseAuth => {
	const { clearUser, setUser, setUserCountry } = useUser();
	const [loadingLogin, setLoadingLogin] = useState<boolean>(false);
	const navigate = useNavigate();
	const { errorToast } = useToast();
	const { clearStoreAddressId, setAllStoreAddressesIds } = useStoreAddress();
	const { clearProducts, clearSearchResult } = useProducts();
	const { clearOptions } = useOptions();
	const { t, i18n } = useTranslation('errors');

	const clearData = () => {
		clearStoreAddressId();
		clearProducts();
		clearOptions();
		clearUser();
	};

	const login = (email: string, password: string, remember: boolean) => {
		if (!isValidEmail(email.trim())) {
			return errorToast(t('notValidEmail'));
		}
		if (!isValidPassword(password.trim())) {
			return errorToast(t('notValidPassword'));
		}
		setLoadingLogin(true);
		fetch<IResponse>('/authenticate', {
			method: 'post',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({ username: email, password }),
			auth: true,
		})
			.then(({ jwt, refreshToken }) => {
				const roles = getRolesFromToken(jwt);
				if (!jwt) throw new Error(t('loginOrPasswordIsNotCorrect'));
				const storeAddressIds = getStoreAddressId(jwt);
				if (!storeAddressIds) throw new Error(t('noAccessToService'));
				// eslint-disable-next-line max-len
				handleToken(jwt, refreshToken);
				if (remember) storage.setItem('remember', 'true');
				if (i18n.language !== getLang()) {
					i18n.changeLanguage(getLang());
				}
				if (roles?.includes(ERoles.MENUTOOL)) {
					navigate(RoutesEnum.PRODUCTS, { replace: true });
				} else {
					navigate(RoutesEnum.LOGIN);
				}
			})
			.catch((e: Error) => {
				errorToast(e.message);
			})
			.finally(() => {
				setLoadingLogin(false);
			});
	};

	const handleToken = (token: string, refreshToken?: string) => {
		setToken(token);
		setAllStoreAddressesIds();
		const email = getUserEmail() || '';
		const userCountry = getCountryFromToken(token) || '';
		setUserCountry(userCountry);
		setUser(email);
		if (refreshToken) setRefreshToken(refreshToken);
	};

	const logout = () => {
		removeToken();
		removeRefreshToken();
		removeRemember();
		clearData();
		clearSearchResult();
		storage.removeItem('storeId');
	};

	return {
		loadingLogin,
		login,
		logout,
		handleToken,
	};
};
