import ErrorValidation from 'components/ErrorValidation';
import PromptIco from 'components/PromptIco';
import PromptView from 'components/PromptView';
import { inputUpdateDelay } from 'const';
import clsx from 'helpers/clsx';
import { delay } from 'helpers/utils';
import usePrompts from 'hooks/usePrompts';
import React, { useEffect, useState } from 'react';

import { TooltipAvailable } from './const';
import classes from './InputForEnteringInform.module.scss';
import { IProps } from './types';

const InputForEnteringInform: React.FC<IProps> = ({
	placeholder,
	allowedNumber,
	className,
	promptInfo,
	value: valueProp,
	errors,
	setNextPromptSection,
	setNextPromptOption,
	onChange,
	showPrompt = false,
	showExample = false,
	isActive = true,
	isOptionsPage = false,
	label,
	hideLangBar = false,
	isTextarea = false,
	allowedNumberToBottom = false,
}) => {
	const [value, setValue] = useState(valueProp);
	const { togglePromptSection, togglePromptOption } = usePrompts();
	const [showTooltip, setShowTooltip] = useState<boolean>(true);

	useEffect(() => {
		setValue(valueProp);
	}, [valueProp]);

	const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
		const { value: inputValue } = event.target;

		if (inputValue.length <= allowedNumber || value.length > inputValue.length) {
			setValue(inputValue);
			delay('handleChange', () => {
				onChange(inputValue);
			}, inputUpdateDelay);
		}
	};

	const handlePrompt = () => {
		if (setNextPromptSection && !isOptionsPage) {
			togglePromptSection(setNextPromptSection);
		} else if (setNextPromptOption) {
			togglePromptOption(setNextPromptOption);
		}
	};

	const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
		if (event.charCode === 13) {
			handlePrompt();
		}
	};

	const getLengthForTooltip = (): boolean => {
		if (allowedNumber >= TooltipAvailable.PRODUCT_DESC)
			return value.length > allowedNumber / TooltipAvailable.SHOW_DESC_TOOLTIP;

		return false;
	};

	return (
		<div
			data-value={value}
			className={clsx(className, classes.inputWrap, {
				[classes.error]: errors?.length,
				[classes.disabled]: !isActive,
				[classes.inputWrap_tooltip]: !isTextarea && getLengthForTooltip(),
				[classes.showLangBarInput]: !hideLangBar,
			})}>
			<ErrorValidation errors={errors}>
				<div className={clsx(classes.inner)}>
					{label && value.length > 0 && <div className={classes.label}>{label}</div>}
					{isTextarea ?
						<textarea
							onChange={handleChange}
							onKeyPress={handleKeyPress}
							onBlur={handlePrompt}
							placeholder={placeholder}
							value={value}
						></textarea>
						: <input
							type="text"
							onChange={handleChange}
							onKeyPress={handleKeyPress}
							onBlur={handlePrompt}
							placeholder={placeholder}
							value={value}
							data-testid="input"
						/>
					}
					<div className={clsx(classes.allowedNumberWrap, {[classes.isTextarea]: isTextarea}, {[classes.toBottom]: allowedNumberToBottom})}>
						<span className={clsx(classes.allowedNumber, {[classes.showLangBar]: !hideLangBar})}>{value.length}/{allowedNumber}</span>
					</div>
					{showPrompt && promptInfo && (
						<>
							{showTooltip && value.length === 0 && (
								<div data-id="promptWrap">
									<PromptIco />
									<div className={classes.promptWrap}>
										<PromptView
											title={promptInfo.title}
											text={promptInfo.text}
											showExample={showExample}
											show={() => setShowTooltip(false)}
										/>
									</div>
								</div>
							)}
						</>
					)}
				</div>
			</ErrorValidation>
		</div>
	);
};

export default InputForEnteringInform;
