import MenuLanguages from 'components/MenuLanguages';
import SyncWithPartner from 'components/SyncWithPartner';
import AddCollection from 'containers/AddCollection';
import AddSection from 'containers/AddSection';
import PersonalCabinet from 'containers/PersonalCabinet';
import PopupHandleCollections from 'containers/PopupHandleCollections';
import PresentationLink from 'containers/PresentationLink';
import ProductsSearch from 'containers/ProductsSearch';
import { getIdFromToken } from 'helpers/jwt';
import { setDatadogRumUser } from 'helpers/rum';
import { useAppSelector } from 'hooks/redux';
import { useCollections } from 'hooks/useCollections';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import classes from './Header.module.scss';
import { IProps } from './types';


const Header: React.FC<IProps> = ({ isOptionsPage = false, collapseAll }) => {
	const { t } = useTranslation('menu');
	const { showCollections } = useCollections();
	const [showCollectPopup, setShowCollectPopup] = useState<boolean>(false);
	const title = isOptionsPage ? t('options') : t('menu');
	const { productGroups } = useAppSelector(state => state.products);

	const onOpenPopup = () => setShowCollectPopup(true);
	const onClosePopup = () => setShowCollectPopup(false);

	useEffect(() => {
		const userId = getIdFromToken();
		if (userId) setDatadogRumUser({ id: userId });
	}, []);

	return (
		<div className={classes.wrapper}>
			<div className={classes.root}>
				<span className={classes.title} data-testid="title">{title}</span>
				<div className={classes.syncWithPartnerWrap}>
					<SyncWithPartner/>
				</div>
				<PresentationLink />
				<MenuLanguages isOptionsPage={isOptionsPage} />
				<PersonalCabinet />
			</div>
			<div className={classes.topSettings}>
				<div className={classes.leftBar}>
					{(showCollections && !isOptionsPage) ? <AddCollection onOpenPopup={onOpenPopup} collapseAll={collapseAll} />
						: <AddSection
							productGroupsCount={productGroups.length}
							isOptionsPage={isOptionsPage}
							onOpenPopup={onOpenPopup}
							collapseAll={collapseAll}
						/>}
				</div>
				{!isOptionsPage && <div className={classes.searchWrap}>
					<ProductsSearch />
				</div>}
			</div>
			<PopupHandleCollections open={showCollectPopup} onClose={onClosePopup} />
		</div>
	);
};

export default Header;
