import clsx from 'helpers/clsx';
import React from 'react';

import classes from './ErrorValidation.module.scss';
import { IProps } from './types';

const ErrorValidation: React.FC<IProps> = ({ children, className, rootClassName, errors }) => {
	return (
		<div data-testid="wrapper-div" className={clsx(classes.root, rootClassName)}>
			{children}
			{Array.isArray(errors) && errors.length > 0 && (
				<div data-testid="error-div" className={clsx(classes.errors, className)}>{errors[0]}</div>
			)}
		</div>
	);
};

export default ErrorValidation;
