import SortIdentifier from 'components/SortIdentifier';
import clsx from 'helpers/clsx';
import { useCollections } from 'hooks/useCollections';
import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import { Trans, useTranslation } from 'react-i18next';
import { ReactComponent as CheckIco } from 'static/imgs/check.svg';
import { ReactComponent as IIco } from 'static/imgs/i.svg';
import { ReactComponent as WarningIco } from 'static/imgs/warning_check.svg';
import { ReactComponent as CloseIco } from 'static/imgs/x.svg';

import classes from './MoveWarning.module.scss';
import { IProps } from './types';

const MoveWarning: React.FC<IProps> = ({cannotMove}) => {
	const [openPopup, setOpenPopup] = useState(false);
	const { showCollections } = useCollections();
	const { t } = useTranslation('menu');

	const handleOpen = () => setOpenPopup(true);
	const handleClose = () => setOpenPopup(false);

	return (
		<div className={classes.root}>
			<div className={clsx(classes.ico, {[classes.attention]: cannotMove && !openPopup})} onClick={handleOpen}><IIco/></div>
			{openPopup && (
				<>
					{ReactDOM.createPortal(<div className={classes.overlay} onClick={handleClose}/>, document.body)}
					<div className={classes.popupBody}>
						<div className={classes.closeButton} onClick={handleClose}><CloseIco/></div>
						<div className={classes.title}>{t('movePopupTitle')}</div>
						<div className={classes.table}>
							<div className={classes.tableHeader}>
								<div className={classes.tableHeaderColl} data-testid="test_yCanMove" >
									<CheckIco/>
									<Trans t={t} i18nKey="yCanMove" />
								</div>
								<div className={clsx(classes.tableHeaderColl, classes.cannot)} data-testid="test_yCannotMove" >
									<WarningIco/>
									<Trans t={t} i18nKey="yCannotMove" /></div>
							</div>
							<div className={classes.tableBody}>
								<div className={classes.tableBodyColl} data-testid="test_productsTitleForMove" >{t('productsTitleForMove')}</div>
								<div className={classes.tableBodyColl} data-testid="test_collectionsTitleForMove" >{
									showCollections ? t('collectionsTitleForMove') : t('sectionsTitleForMove')}
								</div>
							</div>
							{showCollections && (
								<div className={clsx(classes.tableBody, classes.border)}>
									<div className={classes.tableBodyColl} data-testid="test_sectionsTitleForMove" >{t('sectionsTitleForMove')}</div>
									<div className={classes.tableBodyColl}>{t('sectionsMicsTitleForMove')}</div>
								</div>
							)}
						</div>
						<div className={classes.text}>{t('moveBottonDescription')} <SortIdentifier/></div>
					</div>
				</>
			)}
		</div>
	);
};

export default MoveWarning;
