import { fetch } from 'helpers/fetch';
import { ICollection } from 'models/ICollection';
import { IAvailability } from 'models/IProduct';

export const fetchCollections = (storeAddressId: string, isMultiStore: boolean) => {
	const url = isMultiStore ? `/${storeAddressId}/collections/` : `/menu-collections/${storeAddressId}/`;
	return fetch<ICollection[]>(url);
};

export const fetchCollectionsAvailability = (storeAddressId: string, addressesIdsInStore: string) => fetch<IAvailability[]>(`/${storeAddressId}/collections/availability?store-addresses=${addressesIdsInStore}`);

export const deleteCollection = (storeAddressId: string, collectionId: string, isMultiStore: boolean) => {
	const url = isMultiStore ? `/${storeAddressId}/collections/${collectionId}/` : `/menu-collections/${storeAddressId}/${collectionId}/`;
	return fetch<ICollection[]>(url, { method: 'DELETE' }).catch((e) => { console.log(e); });
};

export const deleteCollections = (storeAddressId: string, isMultiStore: boolean) => {
	const url = isMultiStore ? `/${storeAddressId}/collections/` : `/menu-collections/${storeAddressId}/`;
	return fetch<ICollection[]>(url, { method: 'DELETE' }).catch((e) => { console.log(e); });
};

export const patchCollections = (storeAddressId: string, collections: Omit<ICollection, 'id'>[], isMultiStore: boolean) => {
	const url = isMultiStore ? `/${storeAddressId}/collections/` : `/menu-collections/${storeAddressId}/`;
	return fetch<ICollection[]>(url, {
		method: 'PATCH',
		body: JSON.stringify(collections),
	});
};

export const patchCollectionsAvailability = (storeAddressId: string, availability: IAvailability[], addressesIdsInStore: string) => fetch(`/${storeAddressId}/collections/availability?store-addresses=${addressesIdsInStore}`, {
	method: 'PATCH',
	body: JSON.stringify({store_availability: availability}),
});

