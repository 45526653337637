import clsx from 'helpers/clsx';
import { IStoresData } from 'models/IStoreAddresses';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as Arr } from 'static/imgs/arrow-image.svg';
import { ReactComponent as UserIco } from 'static/imgs/locationListIcon.svg';

import classes from './SingleAccount.module.scss';

const SingleAccount: FC<IStoresData> = ({ storeName, storeAddresses }) => {
	const { t } = useTranslation('accounts');
	const [showTooltip, setShowTooltip] = useState<boolean>(false);

	const handleOpenTooltip = () => setShowTooltip(true);
	const handleHideTooltip = () => setShowTooltip(false);

	return (
		<div className={classes.root}>
			<div className={classes.rootButton} onClick={handleOpenTooltip}>
				<UserIco className={classes.icon} />
				<div className={classes.name}>{storeName}</div>
				<Arr className={classes.arr} />
			</div>
			{showTooltip && <>
				<div className={classes.tooltipOverlay} onClick={handleHideTooltip}></div>
				<div className={classes.tooltip}>
					{storeAddresses.length > 1 && <div className={classes.tooltipTitle}>
						{t('storeAddressSingle.institution', {count: storeAddresses.length})}
					</div>}
					<div className={classes.addressesList}>{storeAddresses.map((storeAddress) => {
						return (
							<div className={classes.addressesItem} key={storeAddress.id}>
								<UserIco className={clsx(classes.icon, classes.iconInList)} />
								<div className={classes.address}>
									<span className={classes.storeAddressId}>{storeAddress.storeAddressId}</span>
									<span>{storeAddress.address}</span>
								</div>
							</div>
						);
					})}</div>
				</div>
			</>}
		</div>
	);
};
export default SingleAccount;
