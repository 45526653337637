import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as CheckMark } from '../../static/imgs/checkMark.svg';
import { ReactComponent as IIco } from '../../static/imgs/i.svg';
import {ReactComponent as CloseIco} from '../../static/imgs/x.svg';
import classes from './PromptView.module.scss';
import { IProps } from './types';

const PromptView: React.FC<IProps> = ({title, text, showExample = false, show}) => {
	const [showTooltip, setShowTooltip] = useState<boolean>(true);
	const { t } = useTranslation('requirements');
	return (
		<>
			{showTooltip && (
				<div className={classes.promptView}>
					<div className={classes.title}>
						<div className={classes.titleText} data-testid="test_PromptViewTitle" >{title}</div>
						<div
							className={classes.close}
							onClick={() => {
								setShowTooltip(false);
								if (show) show();
							}}
						>
							<CloseIco />
						</div>
					</div>
					<div className={classes.body}>
						<IIco/>
						<div className={classes.text} data-testid="test_PromptViewText" >
							{text}
							{showExample && (
								<div className={classes.examples}>
									<span className={classes.example}>
										<CheckMark/>
										<span>{t('correctName')}</span>
									</span>
									<span className={classes.example}>
										<CloseIco/>
										<span>{t('wrongName')}</span>
									</span>
								</div>
							)}
						</div>
					</div>
				</div>
			)}
		</>
	);
};

export default PromptView;
