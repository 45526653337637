import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { defaultCollapsingNum } from 'const';
import { addCheckedProperty, changeChildEntity, changeEntity, createEmptyChildEntity, sortByPosition } from 'helpers';
import { IAvailability, IProduct, IProductGroup, IProductMultiPrice, IProductSettings } from 'models/IProduct';

import { fetchProductGroupsAvailability, fetchProducts, fetchProductsAvailability, fetchProductsMultiPrices, fetchProductsSettings } from './actions';

export interface IState {
	productGroups: IProductGroup[];
	productsAvailability: IAvailability[];
	productsAvailForSend: string[];
	productGroupsAvailability: IAvailability[];
	initialProductGroups: IProductGroup[];
	loadingProducts: boolean;
	isValidProducts: boolean;
	productsPageScrollPosition: number;
	defaultCollapsSect: boolean;
	productsMultiPrices: IProductMultiPrice[];
	isExternalId: boolean;
	foundGroups: IProductGroup[];
	searchValue: string;
	productGroupsForSendIds: string[]
}

const initialState: IState = {
	productGroups: [],
	productGroupsForSendIds: [],
	productsAvailability: [],
	productsAvailForSend: [],
	productGroupsAvailability: [],
	productsMultiPrices: [],
	initialProductGroups: [],
	loadingProducts: true,
	isValidProducts: true,
	productsPageScrollPosition: 0,
	defaultCollapsSect: false,
	isExternalId: false,
	foundGroups: [],
	searchValue: '',
};

export const productsSlice = createSlice({
	name: 'products',
	initialState,
	reducers: {
		setProductsPageScrollPosition(state: IState, action: PayloadAction<number>) {
			state.productsPageScrollPosition = action.payload;
		},
		createEmptyGroup(state: IState, action: PayloadAction<IProductGroup>) {
			state.productGroups = [...state.productGroups, action.payload];
		},
		createEmptyProduct(state: IState, action: PayloadAction<{groupId: string, newProduct: IProduct}>) {
			const { groupId,  newProduct} = action.payload;
			state.productGroups = createEmptyChildEntity<IProduct, IProductGroup>(
				state.productGroups, groupId, newProduct,
			);
		},
		removeProductGroup(state: IState, action: PayloadAction<{ groupId: IProductGroup['id'] }>) {
			state.productGroups = state.productGroups.filter((group) => group.id !== action.payload.groupId);
		},
		removeProductItem(state: IState, action: PayloadAction<{ groupId: IProductGroup['id'], itemId: IProduct['id'] }>) {
			state.productGroups = state.productGroups.map((group) => {
				if (group.id === action.payload.groupId) {
					return {
						...group,
						items: [
							...group.items.filter(item => item.id !== action.payload.itemId),
						],
					};
				}
				return group;
			});
		},
		updateGroup(state: IState, action: PayloadAction<{ groupId: IProductGroup['id'], key: keyof IProductGroup, value: any }>) {
			const { groupId, key, value } = action.payload;
			const changedGroup = changeEntity(state.productGroups, groupId, key, value);
			state.productGroups = changedGroup;
		},
		update(state: IState, action: PayloadAction<{ items: IProductGroup[], needSort?: boolean, changedPosition?: boolean }>) {
			const items = action.payload.needSort ? sortByPosition(action.payload.items).map((group) => ({
				...group,
				position: group.position || 0,
				items: sortByPosition(group.items),
			})) : action.payload.items;
			if (!action.payload.changedPosition) state.initialProductGroups = items;
			state.productGroups = items;
		},
		updateProduct(state: IState, action: PayloadAction<{ groupId: IProductGroup['id'], id: IProduct['id'], key: keyof IProduct, value: any }>) {
			const { groupId, key, value, id } = action.payload;
			state.productGroups = changeChildEntity<IProduct, IProductGroup>(state.productGroups, groupId, id, key, value);
		},
		checkDeletedOptionsGroup(state: IState, action: PayloadAction<string>) {
			state.productGroups = state.productGroups.map((group) => ({
				...group,
				items: group.items.map((item) => ({
					...item,
					options: item.options.filter((option) => option !== action.payload),
				})),
			}));
		},
		clear(state: IState) {
			state.productGroups = [];
			state.loadingProducts = true;
			state.isValidProducts = true;
		},
		updateMultiPrices(state: IState, action: PayloadAction<{newPrices: IProductMultiPrice[]}> ) {
			state.productsMultiPrices = action.payload.newPrices;
		},
		removeMultiPriceByProductId(state: IState, action: PayloadAction<IProduct['id']>) {
			state.productsMultiPrices = state.productsMultiPrices.filter((priceBlock) => priceBlock.product_id !== action.payload);
		},
		updateAvailability(state: IState, action: PayloadAction<IAvailability[]> ) {
			state.productsAvailability = action.payload;
		},
		updateGroupAvailability(state: IState, action: PayloadAction<IAvailability[]> ) {
			state.productGroupsAvailability = action.payload;
		},
		clearValidation(state: IState) {
			state.productGroups = state.productGroups.map((group: IProductGroup) => ({
				...group,
				itemsErrorsCount: 0,
				validation: undefined,
				items: group.items.map((item) => ({
					...item,
					validation: undefined,
				})),
			}));
		},
		setFoundGroups(state: IState, action: PayloadAction<IProductGroup[]>) {
			state.foundGroups = action.payload;
		},
		setSearchValue(state: IState, action: PayloadAction<string>) {
			state.searchValue = action.payload;
		},
		updateInitialProducts(state: IState) {
			state.initialProductGroups = state.productGroups;
		},
		setLoading(state: IState, action: PayloadAction<boolean>) {
			state.loadingProducts = action.payload;
		},
		addIdInToProductsAvailForSend(state: IState, action: PayloadAction<string>) {
			if (!state.productsAvailForSend.includes(action.payload)) {
				state.productsAvailForSend = [...state.productsAvailForSend, action.payload];
			}
		},
		addIdInToProductGroupsForSendIds(state: IState, action: PayloadAction<string>) {
			if (!state.productGroupsForSendIds.includes(action.payload)) {
				state.productGroupsForSendIds = [...state.productGroupsForSendIds, action.payload];
			}
		},
	},
	extraReducers: {
		[fetchProducts.fulfilled.type]: (state, action: PayloadAction<IProductGroup[]>) => {
			const productGroupsSorted = sortByPosition(action.payload).map((group) => ({
				...group,
				position: group.position || 0,
				checked: false,
				items: addCheckedProperty(sortByPosition(group.items)),
			}));
			state.productGroups = productGroupsSorted;
			state.initialProductGroups = productGroupsSorted;
			state.defaultCollapsSect = productGroupsSorted.length <= defaultCollapsingNum;
			state.loadingProducts = false;
		},
		[fetchProductsMultiPrices.fulfilled.type]: (state, action: PayloadAction<[]>) => {
			state.productsMultiPrices = action.payload;
			state.loadingProducts = false;
		},
		[fetchProductsAvailability.fulfilled.type]: (state, action: PayloadAction<[]>) => {
			state.productsAvailability = action.payload;
			state.loadingProducts = false;
		},
		[fetchProductGroupsAvailability.fulfilled.type]: (state, action: PayloadAction<[]>) => {
			state.productGroupsAvailability = action.payload;
			state.loadingProducts = false;
		},
		[fetchProductsSettings.fulfilled.type]: (state, action: PayloadAction<IProductSettings>) => {
			state.isExternalId = action.payload.has_external_id;
			state.loadingProducts = false;
		},
		[fetchProducts.pending.type]: (state) => {
			state.loadingProducts = true;
		},
		[fetchProducts.rejected.type]: (state) => {
			state.loadingProducts = false;
		},
		[fetchProductsMultiPrices.pending.type]: (state) => {
			state.loadingProducts = true;
		},
		[fetchProductsMultiPrices.rejected.type]: (state) => {
			state.loadingProducts = false;
		},
		[fetchProductsAvailability.pending.type]: (state) => {
			state.loadingProducts = true;
		},
		[fetchProductsAvailability.rejected.type]: (state) => {
			state.loadingProducts = false;
		},
		[fetchProductGroupsAvailability.pending.type]: (state) => {
			state.loadingProducts = true;
		},
		[fetchProductGroupsAvailability.rejected.type]: (state) => {
			state.loadingProducts = false;
		},
		[fetchProductsSettings.pending.type]: (state) => {
			state.loadingProducts = true;
		},
		[fetchProductsSettings.rejected.type]: (state) => {
			state.loadingProducts = false;
		},
	},
});

export default productsSlice.reducer;
