import { DotCheckAtTheEnd } from './DotCheckAtTheEnd';
import { ExternalId } from './ExternalId';
import { NoSpecialCharacters } from './NoSpecialCharacters';
import { NotUpperCase } from './NotUpperCase';
import { NumberValidator } from './Number';
import { OptionMin } from './OptionMin';
import { Price } from './Price';
import { Required } from './Required';
import { ValidationTypeEnum } from './types';
import { UpperCase } from './UpperCase';

export class Rules {

	protected getRules() {
		return {
			[ValidationTypeEnum.GROUP_TITLE]: [
				new Required(),
				new NoSpecialCharacters(),
				new DotCheckAtTheEnd(),
				new UpperCase(),
			],
			[ValidationTypeEnum.OPTION_GROUP_TITLE]: [
				new NotUpperCase(),
				new Required(),
				new NoSpecialCharacters(),
				new DotCheckAtTheEnd(),
			],
			[ValidationTypeEnum.PRODUCT_TITLE]: [
				new Required(),
				new NoSpecialCharacters(),
				new DotCheckAtTheEnd(),
			],
			[ValidationTypeEnum.OPTION_TITLE]: [
				new NotUpperCase(),
				new Required(),
				new NoSpecialCharacters(),
				new DotCheckAtTheEnd(),
			],
			[ValidationTypeEnum.PRICE]: [
				new Price(),
			],
			[ValidationTypeEnum.NUMBER]: [
				new NumberValidator(),
			],
			[ValidationTypeEnum.DESCRIPTION]: [
				new NoSpecialCharacters(),
				new DotCheckAtTheEnd(),
			],
			[ValidationTypeEnum.OPTION_MIN]: [
				new OptionMin(),
			],
			[ValidationTypeEnum.EXTERNAL_ID]: [
				new ExternalId(),
			],
		};
	}
}
