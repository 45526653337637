import jwtDecode from 'jwt-decode';
import { IJwt } from 'models/IJwt';

import { getToken } from './token';

export const getDecodedJwt = (jwt?: string): IJwt | undefined => {
	const token = jwt || getToken();
	if (!token) return undefined;
	try {
		return jwtDecode(token);
	} catch (_) {
		return undefined;
	}
};

export const getStoreAddressId = (token?: string) => {
	const jwt = getDecodedJwt(token);
	if (!jwt) return undefined;
	if (Array.isArray(jwt.UserStoreAddressesIds)) {
		return `${jwt.UserStoreAddressesIds[0]}`;
	}
	return `${jwt.UserStoreAddressesIds}`;
};

export const getAllStoreAddressesIds = (token?: string): IJwt['UserStoreAddressesIds'] | undefined => {
	const jwt = getDecodedJwt(token);
	if (!jwt) return undefined;
	return jwt.UserStoreAddressesIds;
};

export const getUserEmail = (token?: string) => {
	const jwt = getDecodedJwt(token);
	return jwt?.sub;
};

export const getLangFromToken = () => {
	const jwt = getDecodedJwt();
	return jwt?.Lang;
};

export const getCountryFromToken = (token?: string) => {
	const jwt = getDecodedJwt(token);
	return jwt?.Country;
};

export const getIdFromToken = () => {
	const jwt = getDecodedJwt();
	return jwt?.id;
};

export const getRolesFromToken = (token?: string) => {
	const jwt = getDecodedJwt(token);
	return jwt?.Roles;
};

export const getLang = (): string | undefined => getLangFromToken();
