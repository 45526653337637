import { useAuth } from 'hooks/useAuth';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { ReactComponent as IIco } from 'static/imgs/i.svg';
import { ReactComponent as UserDontHaveRolesIco } from 'static/imgs/userDontHaveRoles.svg';

import { IProps } from './types';
import classes from './UserDontHaveRoleMenu.module.scss';

const UserDontHaveRoleMenu: React.FC<IProps> = ({ backToInputLogin }) => {
	const { t } = useTranslation('login');
	const { logout } = useAuth();

	const backToLogin = () => {
		logout();
		backToInputLogin();
	};

	return (
		<div className={classes.root}>
			<div className={classes.title} data-testid="test_userDontHaveRoleMenu" ><Trans t={t} i18nKey="userDontHaveRoleMenu" /></div>
			<div className={classes.ico}>
				<UserDontHaveRolesIco/>
			</div>
			<div className={classes.description}>
				<span className={classes.icoI}><IIco/></span>
				<span data-testid="test_userDontHaveRoleMenuDescription">
					<Trans
						t={t}
						i18nKey="userDontHaveRoleMenuDescription" components={{
							// eslint-disable-next-line jsx-a11y/anchor-has-content, jsx-a11y/anchor-is-valid
							a: <a/>,
							span: <span/>,
						}} />
				</span>
			</div>
			<div className={classes.backToLogin} data-testid="test_backToLogin" onClick={backToLogin} ><Trans t={t} i18nKey="backToLogin" /></div>
		</div>
	);
};

export default UserDontHaveRoleMenu;
