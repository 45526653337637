import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ILastSync, IUser } from 'models/IUser';

import { fetchLastSyncData, fetchUser } from './actions';

export interface IState {
	user?: IUser;
	loadingUser: boolean;
	userCountry: string;
	lastSyncData: ILastSync;
	itemsIsChecked: number;
	collectionsCheckCount: number;
	sectionsCheckCount: number;
	productsCheckCount: number;
}

const initialState: IState = {
	loadingUser: false,
	userCountry: '',
	itemsIsChecked: 0,
	collectionsCheckCount: 0,
	sectionsCheckCount: 0,
	productsCheckCount: 0,
	lastSyncData: {
		last_reimported_at: null,
		store_address_id: null,
	},
};

export const userSlice = createSlice({
	name: 'user',
	initialState,
	reducers: {
		clear(state) {
			state.user = undefined;
		},
		set(state, action: PayloadAction<IUser>) {
			state.user = action.payload;
		},
		setUserCountry(state, action: PayloadAction<string>) {
			state.userCountry = action.payload;
		},
		incItemsIsCheched(state) {
			state.itemsIsChecked++;
		},
		decItemsIsCheched(state) {
			state.itemsIsChecked--;
		},
		setItemsIsChecked(state, action: PayloadAction<number>) {
			state.itemsIsChecked = action.payload;

			if (action.payload === 0) {
				state.productsCheckCount = action.payload;
				state.sectionsCheckCount = action.payload;
				state.collectionsCheckCount = action.payload;
			}
		},
		incTypeOfCheckItems(state, action: PayloadAction<'product' | 'section' | 'collection'>) {
			switch (action.payload) {
				case 'product': {
					state.productsCheckCount++;
					break;
				}
				case 'section': {
					state.sectionsCheckCount++;
					break;
				}
				case 'collection': {
					state.collectionsCheckCount++;
					break;
				}
			}
		},
		decTypeOfCheckItems(state, action: PayloadAction<'product' | 'section' | 'collection'>) {
			switch (action.payload) {
				case 'product': {
					state.productsCheckCount--;
					break;
				}
				case 'section': {
					state.sectionsCheckCount--;
					break;
				}
				case 'collection': {
					state.collectionsCheckCount--;
					break;
				}
			}
		},
		setTypeOfCheckItems(state, action: PayloadAction<{type: 'product' | 'section' | 'collection', count: number}>) {
			switch (action.payload.type) {
				case 'product': {
					state.productsCheckCount--;
					break;
				}
				case 'section': {
					state.sectionsCheckCount--;
					break;
				}
				case 'collection': {
					state.collectionsCheckCount--;
					break;
				}
			}
		},
	},
	extraReducers: {
		[fetchUser.fulfilled.type]: (state, action: PayloadAction<IUser>) => {
			state.loadingUser = false;
			state.user = action.payload;
		},
		[fetchLastSyncData.fulfilled.type]: (state, action: PayloadAction<ILastSync>) => {
			state.lastSyncData = action.payload;
		},
		[fetchUser.pending.type]: (state) => {
			state.loadingUser = true;
		},
		[fetchUser.rejected.type]: (state, action: PayloadAction<string>) => {
			state.loadingUser = false;
		},
	},
});

export default userSlice.reducer;
