
const delayedMap: Map<string, any> = new Map();

export const delay = (name: string, callback: () => void, timeout = 300) => {
	if (delayedMap.get(name)) clearTimeout(delayedMap.get(name));

	delayedMap.set(name, setTimeout(() => {
		if (typeof callback === 'function') callback();
		delayedMap.delete(name);
	}, timeout));
};
