
import React from 'react';
import { Id, toast as toastFn, ToastOptions } from 'react-toastify';

export interface IUseToast {
	toast(title: string, options?: ToastOptions): void;
	errorToast(title: string, options?: ToastOptions): void;
	successToast(title: string, options?: ToastOptions): void;
}

const useToast = (): IUseToast => {
	const toastId = React.useRef<Id>();
	const toast = (title: string, options?: ToastOptions) => {
		if (!toastId.current || !toastFn.isActive(toastId.current)) {
			toastId.current = toastFn(title, options);
		}
	};

	const errorToast = (title: string, options?: ToastOptions) => toast(title, {
		...options,
		type: 'error',
	});

	const successToast = (title: string, options?: ToastOptions) => toast(title, {
		...options,
		type: 'success',
	});

	return {
		toast,
		errorToast,
		successToast,
	};
};

export default useToast;
