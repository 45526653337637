import clsx from 'helpers/clsx';
import React from 'react';

import classes from './MenuLanguagesItem.module.scss';
import { IProps } from './types';

const MenuLanguagesItem: React.FC<IProps> = ({lang, shortLang, onClick, active = false, insert = false}) => {
	const handleClick = () => {
		if (onClick) onClick(shortLang, lang);
	};

	return (
		<div
			className={clsx(classes.root, {[classes.active]: active}, {[classes.insert]: insert})}
			data-testid="test_MenuLanguagesItem"
			onClick={handleClick}
		>
			<span>{shortLang}</span>{lang}
		</div>
	);
};

export default MenuLanguagesItem;
