import i18n from 'i18n';

import { IRule } from './types';

export class Required implements IRule {

	passes(value: any): boolean {
		return !!value;
	}

	message(): string {
		return i18n.t('validation:required');
	}
}
