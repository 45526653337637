import { addSendToGlovoHeader, fetch } from 'helpers/fetch';
import { IAvailability, IProductGroup, IProductGroupFetchErr, IProductMultiPrice, IProductSettings } from 'models/IProduct';

export const fetchProducts = (storeAddressId: string, isMultiStore: boolean) => {
	const url = isMultiStore ? `/${storeAddressId}/products/` : `/products/${storeAddressId}/`;
	return fetch<IProductGroup[]>(url);
};

export const fetchProductsMultiPrices = (storeAddressId: string, addressesIdsInStore: string) => fetch<IProductMultiPrice[]>(`/${storeAddressId}/products/prices?store-addresses=${addressesIdsInStore}`);

export const fetchProductsAvailability = (storeAddressId: string, addressesIdsInStore: string) => fetch<IAvailability[]>(`/${storeAddressId}/products/availability?store-addresses=${addressesIdsInStore}`);

export const fetchProductGroupsAvailability = (storeAddressId: string, addressesIdsInStore: string) => fetch<IAvailability[]>(`/${storeAddressId}/product_groups/availability?store-addresses=${addressesIdsInStore}`);

export const deleteProductsGroup = (storeAddressId: string, groupId: string, isMultiStore: boolean) => {
	const url = isMultiStore ? `/${storeAddressId}/products/${groupId}/` : `/products/${storeAddressId}/${groupId}/` ;
	return fetch<IProductGroup[]>(url, { method: 'DELETE' }).catch((e) => { console.log(e); });
};

export const deleteProduct = (storeAddressId: string, groupId: string, productId: string, isMultiStore: boolean) => {
	const url = isMultiStore ? `/${storeAddressId}/products/${groupId}/${productId}/` : `/products/${storeAddressId}/${groupId}/${productId}/`;
	return fetch<IProductGroup[]>(url, { method: 'DELETE' }).catch((e) => { console.log(e); });
};

export const patchProducts = (storeAddressId: string, products: Omit<IProductGroup, 'id'>[], isMultiStore: boolean, addressesIdsInStore: string, sendToGlovo: boolean) => {
	const url = isMultiStore ? `/${storeAddressId}/products/?store-addresses=${addressesIdsInStore}` : `/products/${storeAddressId}/`;
	return fetch<IProductGroup[] | IProductGroupFetchErr>(url, {
		method: 'PATCH',
		body: JSON.stringify(products),
		headers: {
			...addSendToGlovoHeader(sendToGlovo),
		},
	});
};

export const patchProductsMultiPrice = (storeAddressId: string, newPrices: IProductMultiPrice[], addressesIdsInStore: string) => fetch(`/${storeAddressId}/products/prices?store-addresses=${addressesIdsInStore}`, {
	method: 'PATCH',
	body: JSON.stringify({product_prices: newPrices}),
});

export const patchProductsAvailability = (storeAddressId: string, availability: IAvailability[], addressesIdsInStore: string) => fetch(`/${storeAddressId}/products/availability?store-addresses=${addressesIdsInStore}`, {
	method: 'PATCH',
	body: JSON.stringify({store_availability: availability}),
});

export const patchProductGroupsAvailability = (storeAddressId: string, availability: IAvailability[], addressesIdsInStore: string) => fetch(`/${storeAddressId}/product_groups/availability?store-addresses=${addressesIdsInStore}`, {
	method: 'PATCH',
	body: JSON.stringify({store_availability: availability}),
});

export const fetchDateSaveDraft = (storeAddressId: string, isMultiStore: boolean) => {
	const url = isMultiStore ? `/${storeAddressId}/draft-revision/status/` : `/draft-revision/${storeAddressId}/status/`;
	return fetch(url);
};

export const fetchProductsSettings = (storeAddressId: string, isMultiStore: boolean) => {
	const url = isMultiStore ? `/${storeAddressId}/store-settings/` : `/store-settings/${storeAddressId}/` ;
	return fetch<IProductSettings>(url);
};
