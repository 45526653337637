import Checkbox from 'components/FormControl/Checkbox';
import InputForEnteringInform from 'components/InputForEnteringInform';
import ItemHasErr from 'components/ItemHasErr';
import SortIdentifier from 'components/SortIdentifier';
import SwitchComponent from 'components/SwitchComponent';
import AddElementButton from 'containers/AddElementButton';
import TitlesLangBar from 'containers/TitlesLangBar';
import clsx from 'helpers/clsx';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { useCollections } from 'hooks/useCollections';
import { OptionsIdsEnum } from 'hooks/useOptions';
import { useProducts } from 'hooks/useProducts';
import { useSuperCollections } from 'hooks/useSuperCollections';
import { useUser } from 'hooks/useUser';
import { EOptionsPrompts, ESectionPrompts } from 'models/IPrompts';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as ArrowBottom } from 'static/imgs/keyboard_arrow_down.svg';
import { ReactComponent as ProductRemove } from 'static/imgs/productRemove.svg';
import { modalsSlice } from 'store/reducers/modals';

import classes from './SectionHead.module.scss';
import { IProps } from './types';

const SectionHead: React.FC<IProps> = ({
	isOptionsPage, onChangeTitle, onChangeSwitch,
	title, isActive, countItems, isModal,
	group, errors, handleCollapseSection,
	isCollapse = false, handleCreateEmptyProduct, collectionActive,
	itemsLength, closestSuperCollectionActive, translations,
}) => {
	const { sectionsPrompt, optionsPrompt } = useAppSelector(state => state.getStarted);
	const showSectionsPrompt = sectionsPrompt === ESectionPrompts.SECTIONS_NAME;
	const showOptionsPrompt = optionsPrompt === EOptionsPrompts.OPTIONS_GROUP_NAME;
	const { showCollections, getCollectionByChildSectionId, updateCollection } = useCollections();
	const { showSuperCollections } = useSuperCollections();
	const { setRemoveWithIdPopupState } = modalsSlice.actions;
	const dispatch = useAppDispatch();
	const { t } = useTranslation(['options', 'products', 'hintTexts', 'multiStore', 'validation']);
	const disableSwicher = !isOptionsPage && ((showCollections && !collectionActive) || (showSuperCollections && !closestSuperCollectionActive));
	const { handleChangeGroupCheckedVal, decItemsIsChecked } = useUser();
	const { updateProductGroup } = useProducts();

	const showRemoveProductPopup = () => {
		dispatch(setRemoveWithIdPopupState({ modal: true, groupId: group.id, itemId: '' }));
	};

	const handleChangeChecked = () => {
		const nextValue = !group.checked;
		handleChangeGroupCheckedVal(group, nextValue);

		if (!nextValue) {
			const parentCollection = getCollectionByChildSectionId(group.id);

			if (parentCollection && parentCollection.checked) {
				updateCollection(parentCollection.id, 'checked', false);
				parentCollection.productGroups.forEach((childSectionId) => {
					updateProductGroup(childSectionId, 'parentIsChecked', false);
				});
				decItemsIsChecked('collection');
			}
		}
	};

	return (
		<div
			data-testid="root-div"
			className={clsx(
				classes.root,
				{ [classes.isOptionsPage]: isOptionsPage },
				isOptionsPage && OptionsIdsEnum.GROUP,
			)}>
			<div data-testid="head-wrapper-div" className={classes.changeInformWrap}>
				{!isOptionsPage && (
					<>
						<div className={clsx(classes.checkBoxWrap, {[classes.onlySections]: !showCollections})}>
							<Checkbox isChecked={group.checked} onChange={handleChangeChecked} icoClassName={classes.checkBoxIco} />
						</div>
						<div className={clsx(classes.collapseSectionArr, { [classes.rotate]: isCollapse })} onClick={handleCollapseSection}>
							<ArrowBottom />
						</div>
					</>
				)}
				<div data-testid="input-wrap" className={clsx(classes.inputWrap, {[classes.optionPopup]: isModal})}>
					<div className={classes.inputWrap}>
						<InputForEnteringInform
							showExample
							hideLangBar={!translations}
							isOptionsPage={isOptionsPage}
							allowedNumber={isOptionsPage ? 70 : 40}
							className={classes.headInput}
							placeholder={isOptionsPage ? t('options:nameSections') : t('products:nameSections')}
							showPrompt={isOptionsPage ? showOptionsPrompt : showSectionsPrompt}
							promptInfo={isOptionsPage ? {
								title: t('hintTexts:optionGroupName.title'),
								text: t('hintTexts:optionGroupName.text'),
							} : {
								title: t('hintTexts:sectionName.title'),
								text: t('hintTexts:sectionName.text'),
							}}
							onChange={onChangeTitle}
							value={title}
							errors={errors}
							isActive={isActive}
							setNextPromptSection={ESectionPrompts.SECTIONS_IMG}
							setNextPromptOption={EOptionsPrompts.OPTION_MIN_COUNT}
							label={isOptionsPage ? t('options:nameSectionsLabel') : t('products:nameSectionsLabel')}
						/>
						{translations && <div className={classes.titlesLangBarWrap}>
							<TitlesLangBar
								placeholder={isOptionsPage ? t('options:nameSections') : t('products:nameSections')}
								parentType={isOptionsPage ? 'option_group' : 'product_group'}
								translations={translations}
								allowedNumber={isOptionsPage ? 70 : 40}
								parentId={group.id}
								defaultTitle={title}
								handleUpdateDefaultTitle={onChangeTitle}
								label={isOptionsPage ? t('options:nameSectionsLabel') : t('products:nameSectionsLabel')}
								headerTitle={isOptionsPage ? t('options:nameSectionsLabel') : t('products:nameSectionsLabel')}
							/>
						</div>}
					</div>
				</div>
				{!isOptionsPage && <div data-testid="head-count-item-div" className={classes.productCount}>
					{t('products:countProduct')}: {countItems}
				</div>}
				{isCollapse && handleCreateEmptyProduct ? (
					<div className={classes.addProductButtonWrap}>
						<AddElementButton buttonText={t('products:addProduct')} onClick={handleCreateEmptyProduct} />
					</div>
				) : (itemsLength !== undefined && itemsLength >= 5) && handleCreateEmptyProduct && (
					<div className={classes.addProductButtonWrap}>
						<AddElementButton buttonText={t('products:addProduct')} onClick={handleCreateEmptyProduct} />
					</div>
				)}
			</div>
			<div data-testid="switch-wrapper-div" className={classes.right}>
				{!isOptionsPage && !!(group.itemsErrorsCount && group.itemsErrorsCount > 0) && <ItemHasErr
					count={group.itemsErrorsCount}
				/>}
				{/* temporarily hide */}
				{/* {!isOptionsPage && isMultiStore && group ? (
					<AvailableAddresses
						parentItem={group}
						itemSelector="product_group"
						addressPopupTitle={t('multiStore:addressPopupTitleSection')}
						addressPopupSec={t('multiStore:addressPopupSection')}
					/>
				) : (
					!isModal && <SwitchComponent
						revert
						isActive={isActive}
						onChange={onChangeSwitch}
						titleAct={isOptionsPage ? t('options:includeSection') : t('products:includeSection')}
						className={clsx(classes.toggleActive, { [classes.toggleDis]: disableSwicher })}
					/>
				)} */}
				{isOptionsPage && !isModal && <SwitchComponent
					revert
					isActive={isActive}
					onChange={onChangeSwitch}
					titleAct={isOptionsPage ? t('options:includeSection') : t('products:includeSection')}
					className={clsx(classes.toggleActive, { [classes.toggleDis]: disableSwicher })}
				/>}
				{!isModal && <div data-testid="remove-div" className={classes.productRemove} onClick={showRemoveProductPopup}><ProductRemove /></div>}
				{!isOptionsPage && <SortIdentifier />}
			</div>
		</div>
	);
};

export default SectionHead;
