import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { collectionNeedsOptimizationCount } from 'const';
import { changeEntityForce, sortByPosition } from 'helpers';
import { ICollection } from 'models/ICollection';
import { IAvailability } from 'models/IProduct';

import { fetchCollections, fetchCollectionsAvailability } from './actions';

export interface IState {
	showCollections: boolean;
	collections: ICollection[];
	initialCollections: ICollection[];
	loadingCollections: boolean;
	collectionsAvailability: IAvailability[];
	collectionsChildsValid: boolean;
	needsOptimization: boolean;
}

const initialState: IState = {
	showCollections: false,
	collections: [],
	initialCollections: [],
	loadingCollections: true,
	collectionsAvailability: [],
	collectionsChildsValid: true,
	needsOptimization: false,
};

export const collectionsSlice = createSlice({
	name: 'collections',
	initialState,
	reducers: {
		setShowCollectionsState(state, action: PayloadAction<boolean>) {
			state.showCollections = action.payload;
		},
		setCollectionsValid(state, action: PayloadAction<boolean>) {
			state.collectionsChildsValid = action.payload;
		},
		createEmptyCollectionStore(state: IState, action: PayloadAction<ICollection>) {
			state.collections = [...state.collections, action.payload];
		},
		tieSectionToCollectionStore(state: IState, action: PayloadAction<{ collectionId: string, sectionId: string }>) {
			const { collectionId, sectionId } = action.payload;

			state.collections = state.collections.map((collection) => {
				if (collection.id === collectionId) collection.productGroups.push(sectionId);
				return collection;
			});
		},
		removeSectionFromCollectionsStore(state: IState, action: PayloadAction<string>) {
			state.collections = state.collections.map((collection) => {
				collection.productGroups.forEach((groupId, index) => {
					if (groupId === action.payload) collection.productGroups.splice(index, 1);
				});
				return collection;
			});
		},
		updateCollectionStore(state: IState, action: PayloadAction<{ collectionId: ICollection['id'], key: keyof ICollection, value: any }>) {
			const { collectionId, key, value } = action.payload;
			const changedCollection = changeEntityForce(state.collections, collectionId, key, value);
			state.collections = changedCollection;
		},
		update(state: IState, action: PayloadAction<{ items: ICollection[], needSort?: boolean, changedPosition?: boolean }>) {
			const items = action.payload.needSort ? sortByPosition(action.payload.items).map((collection) => ({
				...collection,
				position: collection.position || 0,
			})) : action.payload.items;
			if (!action.payload.changedPosition) state.initialCollections = items;
			state.collections = items;

		},
		removeCollectionStore(state: IState, action: PayloadAction<{ collectionId: ICollection['id'] }>) {
			state.collections = state.collections.filter((collection) => collection.id !== action.payload.collectionId);
		},
		clearCollections(state: IState) {
			state.collections = initialState.collections;
		},
		removeValidation(state: IState) {
			state.collections = state.collections.map(collection => {
				delete collection.validation;
				delete collection.itemsErrorsCount;
				return collection;
			});
		},
		updateAvailability(state: IState, action: PayloadAction<IAvailability[]> ) {
			state.collectionsAvailability = action.payload;
		},
		updateInitialCollections(state: IState) {
			state.initialCollections = state.collections;
		},
	},
	extraReducers: {
		[fetchCollections.fulfilled.type]: (state, action: PayloadAction<ICollection[]>) => {
			const collectionsSorted = sortByPosition(action.payload).map((collection, index) => ({
				...collection,
				position: collection.position || 0,
				saveCollapseValue: index !== 0,
				checked: false,
			}));
			state.collections = collectionsSorted;
			state.initialCollections = collectionsSorted;
			state.needsOptimization = collectionsSorted.length > collectionNeedsOptimizationCount;
			state.loadingCollections = false;
		},
		[fetchCollectionsAvailability.fulfilled.type]: (state, action: PayloadAction<[]>) => {
			state.collectionsAvailability = action.payload;
			state.loadingCollections = false;
		},
		[fetchCollections.pending.type]: (state) => {
			state.loadingCollections = true;
		},
		[fetchCollections.rejected.type]: (state) => {
			state.loadingCollections = false;
		},
		[fetchCollectionsAvailability.pending.type]: (state) => {
			state.loadingCollections = true;
		},
		[fetchCollectionsAvailability.rejected.type]: (state) => {
			state.loadingCollections = false;
		},
	},
});

export default collectionsSlice.reducer;
