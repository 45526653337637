import MenuTitle from 'components/MenuTitle';
import React from 'react';

import classes from './CollectionsLayout.module.scss';
import { IProps } from './types';

const CollectionsLayout: React.FC<IProps> = ({children, title, collapseAll, isCollapseAll}) => {
	return (
		<div className={classes.root}>
			<MenuTitle title={title} collapseAll={collapseAll} isCollapseAll={isCollapseAll} />
			{children}
		</div>
	);
};

export default CollectionsLayout;
