import { DragableProvider } from 'components/DragableProvider';
import ProductsSection from 'containers/ProductsSection';
import clsx from 'helpers/clsx';
import { getGroupById } from 'helpers/products';
import { useCollections } from 'hooks/useCollections';
import { ProductIdsEnum, useProducts } from 'hooks/useProducts';
import { useSuperCollections } from 'hooks/useSuperCollections';
import { IProductGroup } from 'models/IProduct';
import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import { RootState } from 'store';

import classes from './Products.module.scss';
import { IProps, IPropsWithData } from './types';

const Products: React.FC<IPropsWithData> = ({
	collection, productGroups, closestSuperCollection, collapseAll, isCollapseAll, calcDefaultCollapseSection, foundGroups,
}) => {
	const { updateProductGroup, updatePosition } = useProducts();
	const { updateCollection } = useCollections();
	const { updateSuperCollection } = useSuperCollections();

	const handleUpdateProductGroup = useCallback((group: IProductGroup, key: keyof IProductGroup, value: any) => {
		updateProductGroup(group.id, key, value);

		if (group.validation && group.validation[key]?.length > 0) {
			if (collection && collection?.itemsErrorsCount) {
				updateCollection(collection.id, 'itemsErrorsCount', collection?.itemsErrorsCount - 1);
			}

			if (closestSuperCollection && closestSuperCollection?.itemsErrorsCount) {
				updateSuperCollection(closestSuperCollection.id, 'itemsErrorsCount', closestSuperCollection?.itemsErrorsCount - 1);
			}
		}


		if (key === 'active' && collection) {
			const allColectionsSectionActive = collection.productGroups.find((id) => {
				const groupInCollection = getGroupById(productGroups, id);
				if (groupInCollection?.id === group.id) return value;
				return groupInCollection?.active;
			});
			updateCollection(collection.id, 'active', !!allColectionsSectionActive);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [collection, productGroups, closestSuperCollection]);

	const handleOnMove = useCallback(() => {
		updatePosition(productGroups);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [productGroups]);

	return (
		<DragableProvider providerType="section" onMove={handleOnMove}>
			{productGroups.map((group, index) => {
				if (collection?.productGroups.indexOf(group.id) === -1 || (foundGroups.length && !getGroupById(foundGroups, group.id))) return null;
				return (
					<div
						className={clsx(classes.sectionWrap, ProductIdsEnum.GROUP, {[classes.isFirstSection]: index === 0})}
						key={group.id}
						id={group.id}
					>
						<ProductsSection
							collectionId={collection?.id}
							superCollectionId={closestSuperCollection?.id}
							productsGroup={group}
							collectionActive={collection?.active}
							defaultCollapse={group.saveCollapseValue || (!group.isNew && calcDefaultCollapseSection(index, group.saveCollapseValue))}
							isCollapseAll={isCollapseAll}
							collapseAll={collapseAll}
							superCollectionActive={closestSuperCollection?.active}
							updateProductGroup={handleUpdateProductGroup}
							handleOnMove={handleOnMove}
						/>
					</div>
				);
			})}
		</DragableProvider>
	);
};

const mapStateToProps = (state: RootState, props: IProps) => ({
	collection: state.collections.collections.find(({ id }) => id === props.collectionId),
	productGroups: state.products.productGroups,
	foundGroups: state.products.foundGroups,
	closestSuperCollection: state.superCollectionsData.superCollections.find(({ id }) => id === props.closestSuperCollectionId),
});

export default connect(mapStateToProps)(Products);
