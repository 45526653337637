import CollectionHead from 'components/CollectionHead';
import clsx from 'helpers/clsx';
import { CollectionsIdsEnum, useCollections } from 'hooks/useCollections';
import { ProductIdsEnum, useProducts } from 'hooks/useProducts';
import { useSuperCollections } from 'hooks/useSuperCollections';
import React, { memo, useEffect, useRef, useState } from 'react';

import classes from './Collection.module.scss';
import { IProps } from './types';

const Collection: React.FC<IProps> = ({
	children, collection, isCollapseAll, collapseAll,
	closestSuperCollection , defaultCollapse = false, productGroupsCount, collapseOtherItems,
}) => {
	const [isCollapse, setIsCollapse] = useState<boolean>(defaultCollapse);
	const collectionRef = useRef<HTMLDivElement>(null);
	const { showSuperCollections } = useSuperCollections();

	const { createEmptyGroupProducts } = useProducts();
	const { tieSectionToCollection, updateCollection } = useCollections();

	useEffect(() => {
		if (isCollapseAll) setIsCollapse(isCollapseAll);
	}, [isCollapseAll]);

	useEffect(() => {
		setIsCollapse(defaultCollapse);
		if (!defaultCollapse) updateCollection(collection.id, 'saveCollapseValue', defaultCollapse);
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [defaultCollapse]);

	const handleCollapseCollection = () => {
		const nextCollapseValue = !isCollapse;
		setIsCollapse(nextCollapseValue);
		updateCollection(collection.id, 'saveCollapseValue', nextCollapseValue);

		collapseOtherItems(collection.id, 'collection');
		collapseAll(false, true);
	};

	const handleCreateEmptyGroupProducts = () => {
		const newGroup = createEmptyGroupProducts(productGroupsCount);
		tieSectionToCollection(collection.id, newGroup.id);
		setTimeout(() => {
			if (collectionRef.current) {
				const newSectionDom = collectionRef.current.querySelectorAll(`.${ProductIdsEnum.GROUP}`);
				newSectionDom[newSectionDom.length - 1].scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' });
			}
		}, 0);
	};

	const handleAddSection = () => {
		setIsCollapse(false);
		handleCreateEmptyGroupProducts();
	};

	return (
		<div
			ref={collectionRef}
			className={clsx(
				classes.root,
				CollectionsIdsEnum.COLLECTION,
				{ [classes.disabled]: !collection.active },
				{ [classes.onlyCollections]: !showSuperCollections },
			)}
			id={collection.id}
		>
			<CollectionHead
				collection={collection}
				isActive={collection.active}
				isCollapse={isCollapse}
				handleCollapseCollection={handleCollapseCollection}
				handleAddSection={handleAddSection}
				closestSuperCollection={closestSuperCollection}
			/>
			{!isCollapse && <div className={classes.children}>{children}</div>}
		</div>
	);
};

export default memo(Collection);
