import Modal from 'components/Modal';
import clsx from 'helpers/clsx';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { ReactComponent as RemoveProductIco } from '../../static/imgs/change-store.svg';
import classes from './PopupStoreChange.module.scss';
import { IProps } from './types';

const PopupStoreChange: React.FC<IProps> = ({ open, onClose, handleStore }) => {
	const { t } = useTranslation(['accounts']);

	const handleMove = () => {
		handleStore();
	};

	const handleStay = () => {
		onClose();
	};

	return (
		<Modal open={open} onClose={onClose}>
			<div className={classes.productRemovePopup}>
				<span className={classes.ico}>
					<RemoveProductIco />
				</span>
				<span className={classes.title} data-testid="test_popupTitle" >
					<Trans t={t} i18nKey="accounts:popupTitle" />
				</span>

				<span className={classes.text} data-testid="test_popupSubtitle">
					{t('popupSubtitle')}
				</span>

				<div className={classes.buttons}>
					<div className={classes.button} onClick={handleMove} data-testid="test_popupMove" >{t('popupMove')}</div>
					<div onClick={handleStay} className={clsx(classes.button, classes.confirm)} data-testid="test_popupStayAndEdit" >
						{t('popupStayAndEdit')}
					</div>
				</div>
			</div>
		</Modal>
	);
};

export default PopupStoreChange;

