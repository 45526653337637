import ErrorValidation from 'components/ErrorValidation';
import PromptIco from 'components/PromptIco';
import PromptView from 'components/PromptView';
import { inputUpdateDelay } from 'const';
import clsx from 'helpers/clsx';
import { delay } from 'helpers/utils';
import usePrompts from 'hooks/usePrompts';
import React, { useState } from 'react';
import CurrencyInput from 'react-currency-input-field';
import { useTranslation } from 'react-i18next';

import classes from './InputPrice.module.scss';
import { IProps } from './types';

const InputPrice: React.FC<IProps> = ({
	placeholder = '00.00', showPrompt = false,
	promptInfo, onChange, value: valueProp,
	errors, rootClassName, setNextPromptSection,
	isOptionsPage = false, setNextPromptOption, label,
}) => {
	const [value, setValue] = useState<string>(String(valueProp));
	const { togglePromptSection, togglePromptOption } = usePrompts();
	const { t } = useTranslation('hintTexts');
	const [showTooltip, setShowTooltip] = useState<boolean>(true);

	const onChangeHandler = (val?: string) => {
		const valCheck = val && (isOptionsPage ? val !== '.' : val !== '.' && val !== '0');

		delay('handleChange', () => {
			onChange(valCheck ? +val : '');
		}, inputUpdateDelay);
		setValue(valCheck ? val : '');
	};

	const handlePrompt = () => {
		if (setNextPromptSection && !isOptionsPage) {
			togglePromptSection(setNextPromptSection);
		} else {
			togglePromptOption(setNextPromptOption || null);
		}
	};

	const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
		if (event.charCode === 13) {
			handlePrompt();
		}
	};

	return (
		<div className={clsx(classes.root, rootClassName, {
			[classes.error]: errors?.length,
		})}>
			<ErrorValidation className={classes.errorText} errors={errors}>
				<div className={classes.inner}>
					{label && value.length > 0 && <div className={classes.label}>{label}</div>}
					<CurrencyInput
						disableAbbreviations
						placeholder={placeholder}
						allowNegativeValue={false}
						onKeyPress={handleKeyPress}
						onBlur={handlePrompt}
						value={value || ''}
						onValueChange={onChangeHandler}
						decimalSeparator="."
						maxLength={8}
						groupSeparator="&nbsp;"
					/>
					{showPrompt && promptInfo && (
						<>
							{showTooltip && value.length <= 1 && (
								<div data-id="promptWrap">
									<PromptIco />
									<div className={classes.promptWrap} data-testid="prompt-div">
										<PromptView
											title={t('productPrice.title')}
											text={t('productPrice.text')}
											show={() => setShowTooltip(false)}
										/>
									</div>
								</div>
							)}
						</>
					)}
				</div>
			</ErrorValidation>
		</div>
	);
};

export default InputPrice;
