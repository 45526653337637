
import { createAsyncThunk } from '@reduxjs/toolkit';
import { fetchOptions as fetchOptionsReq } from 'api/options';

export const fetchOptions = createAsyncThunk(
	'options/fetchOptions',
	async ({storeAddressId, isMultiStore}: {storeAddressId: string, isMultiStore: boolean}, thunkAPI) => {
		try {
			const result = await fetchOptionsReq(storeAddressId, isMultiStore).catch(() => undefined);
			if (!result || !Array.isArray(result)) throw new Error('Result not found');
			return result;
		} catch (e) {
			return thunkAPI.rejectWithValue('Failure');
		}
	},
);
