import Modal from 'components/Modal';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { ReactComponent as RemoveIco } from '../../static/imgs/deleteProductIco.svg';
import classes from './PopupRemoveCheckedItems.module.scss';
import { IProps } from './types';

const PopupRemoveCheckedItems: React.FC<IProps> = ({ open, onClose, removeCheckedItems }) => {
	const { t } = useTranslation('menu');

	return (
		<Modal open={open} onClose={onClose}>
			<div className={classes.root}>
				<span className={classes.ico}><RemoveIco/></span>
				<div className={classes.title} data-testid="test_removeCheckedItemsTitle">
					<Trans t={t} i18nKey={'removeCheckedItemsTitle'} />
				</div>
				<div className={classes.text} data-testid="test_removeCheckedItemsText">
					<Trans t={t} i18nKey={'removeCheckedItemsText'} />
				</div>
				<div className={classes.buttons}>
					<button onClick={onClose} data-testid="test_noCancel" >{t('noCancel')}</button>
					<button className={classes.remove} onClick={removeCheckedItems} data-testid="test_yesDelete" >{t('yesDelete')}</button>
				</div>
			</div>
		</Modal>
	);
};

export default PopupRemoveCheckedItems;
