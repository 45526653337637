import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IMultiLangData } from 'models/IMultiLang';

import { fetchMultiLang } from './actions';

export interface IState {
	multiLangData: IMultiLangData;
	translationProcess: boolean;
}

const initialState: IState = {
	multiLangData: {
		defaultLanguage: null,
		languages: [],
	},
	translationProcess: false,
};

export const multiLangSlice = createSlice({
	name: 'multilang',
	initialState,
	reducers: {
		setDefaultLang(state: IState, action: PayloadAction<IMultiLangData['defaultLanguage']>) {
			state.multiLangData.defaultLanguage = action.payload;
		},
		setMultiLangStatus(state: IState, action: PayloadAction<boolean>) {
			state.translationProcess = action.payload;
		},
		setLanguagesList(state: IState, action: PayloadAction<IMultiLangData['languages']>) {
			state.multiLangData.languages = action.payload;
		},
	},
	extraReducers: {
		[fetchMultiLang.fulfilled.type]: (state, action: PayloadAction<IMultiLangData>) => {
			state.multiLangData = action.payload;
		},
	},
});

export default multiLangSlice.reducer;
