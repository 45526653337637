import ErrorValidation from 'components/ErrorValidation';
import Modal from 'components/Modal';
import PromptIco from 'components/PromptIco';
import { bytesToMegaBytes } from 'helpers';
import clsx from 'helpers/clsx';
import { useAppSelector } from 'hooks/redux';
import { useProducts } from 'hooks/useProducts';
import usePrompts from 'hooks/usePrompts';
import { ESectionPrompts } from 'models/IPrompts';
import React, { useEffect, useState } from 'react';
import { Cropper } from 'react-cropper';
import { Trans, useTranslation } from 'react-i18next';
import { ImageValidator } from 'services/Validator/ImageValidator';
import { ReactComponent as IIco } from 'static/imgs/i.svg';
import { ReactComponent as ProductRemove } from 'static/imgs/productRemove.svg';
import { ReactComponent as UploadIco } from 'static/imgs/uploadIco.svg';

import classes from './PopupUploadImg.module.scss';
import { IProps } from './types';

const PopupUploadImg: React.FC<IProps> = ({ children, product, groupId }) => {
	const [drag, setDrag] = useState<boolean>(false);
	const [image, setImage] = useState<string>(product.image || '');
	const [uploadImgSize, setUploadImgSize] = useState<number>(0);
	const [error, setError] = useState<string[]>([]);
	const { sectionsPrompt } = useAppSelector((state) => state.getStarted);
	const [open, setOpen] = useState<boolean>(false);
	const { updateProduct } = useProducts();
	const { togglePromptSection } = usePrompts();
	const { t } = useTranslation(['form', 'requirements', 'products', 'validation']);
	const [previewSize, setPreviewSize] = useState<[number, number]>([0, 0]);
	const [cropper, setCropper] = useState<any>();

	useEffect(() => {
		setImage(product.image);
	}, [product.image]);

	const handleClick = () => {
		setOpen(true);
		if (sectionsPrompt === ESectionPrompts.SECTIONS_NAME) togglePromptSection(ESectionPrompts.SECTIONS_IMG_POPUP);
	};

	const childrenClone = React.Children.map(children, (child) => {
		return React.isValidElement(child) && React.cloneElement(child, {
			onClick: handleClick,
		});
	});

	const handleCloseAfterSave = () => {
		setOpen(false);
		setError([]);
		setUploadImgSize(0);
	};

	const handleClose = () => {
		handleCloseAfterSave();
		setImage(product.image);
	};

	const removeImg = () => {
		setImage('');
		setError([]);
		setUploadImgSize(0);
		setPreviewSize([0, 0]);
	};

	const onCrop = (event: any) => {
		setPreviewSize([parseInt(event.detail.width), parseInt(event.detail.height)]);
	};

	const saveImage = async () => {
		const type = image.split(';')[0].split('/')[1];
		const cropImg = !!image.length ? cropper.getCroppedCanvas().toDataURL(`image/${type}`) : '';
		const validator = new ImageValidator(500, 500, uploadImgSize, true);
		if (!!image.length && !(await validator.passes(cropImg))) {
			return setError(validator.message());
		} else {
			updateProduct(groupId, product.id, 'image', cropImg);
			handleCloseAfterSave();
			togglePromptSection(ESectionPrompts.PRODUCT_NAME);
		}
	};

	const dragStartHandler = (event: React.DragEvent<HTMLDivElement>) => {
		event.preventDefault();
		setDrag(true);
	};

	const dragLeaveHandler = (event: React.DragEvent<HTMLDivElement>) => {
		event.preventDefault();
		setDrag(false);
	};

	const handleFile = (f: File) => {
		const reader = new FileReader();
		reader.addEventListener('load', (event) => {
			if (typeof event.target?.result === 'string') {
				setImage(event.target.result);
				setUploadImgSize(bytesToMegaBytes(event.total));
			}
		});
		reader.readAsDataURL(f);

		togglePromptSection(ESectionPrompts.SECTIONS_IMG_POPUP_SAVE);
		setError([]);
	};

	const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const files = event.target.files;
		if (files && files.length) handleFile(files[0]);
	};

	const dropHandler = (event: React.DragEvent<HTMLDivElement>) => {
		event.preventDefault();
		const files = event.dataTransfer.files;
		if (files && files.length) handleFile(files[0]);
	};

	return (
		<>
			{childrenClone}
			<Modal open={open} onClose={handleClose}>
				<div className={classes.popupUploadImg}>
					<div className={classes.title}>{t('uploadImg.title')}</div>
					<div className={classes.popupUploadBody}>
						<div className={classes.uploadWrap}>
							{image ? (
								<>
									<div className={classes.miniPreview}>
										{previewSize[0] > 0 && <span className={classes.previewSize}>{previewSize[0]} px</span>}
										{previewSize[1] > 0 && <span className={clsx(classes.previewSize, classes.previewSizeHeight)}>
											{previewSize[1]} px
										</span>}
										<div className={classes.miniPreviewImg}>
											<div className={clsx(classes.previewBody, 'js_previewBody')} />
										</div>
									</div>
									<ErrorValidation errors={error} className={classes.error} rootClassName={classes.rootValidation}>
										<div className={classes.imgWrap}>
											<div className={classes.removeImg} onClick={removeImg}><ProductRemove/></div>
											<Cropper
												style={{ height: '100%', width: '100%' }}
												initialAspectRatio={1}
												aspectRatio={1}
												preview=".js_previewBody"
												zoomable = {false}
												center={false}
												guides={false}
												src={image}
												viewMode={1}
												minCropBoxHeight={10}
												minCropBoxWidth={10}
												background={false}
												responsive={true}
												autoCropArea={1}
												checkOrientation={false}
												onInitialized={(instance) => {
													setCropper(instance);
												}}
												crop={onCrop}
											/>
										</div>
									</ErrorValidation>
								</>
							) : (
								<>
									<div className={classes.miniPreview}>
										<div className={clsx(classes.miniPreviewImg, classes.miniPreviewImgPlug)}></div>
									</div>
									<div
										onDragStart={dragStartHandler}
										onDragLeave={dragLeaveHandler}
										onDragOver={dragStartHandler}
										onDrop={dropHandler}
										className={clsx(classes.imgWrap, {
											[classes.imgWrapLoad]: drag,
										})}
									>
										<span><UploadIco /></span>
										<div className={classes.text}>
											<Trans t={t} i18nKey="labelUploadFile" />
										</div>
										<label htmlFor="loadInput" className={classes.uploadButton}>
											{t('upload')}
										</label>
										<input
											className={classes.noneInput}
											type="file"
											id="loadInput"
											accept="image/jpg, image/jpeg, image/png"
											onChange={handleImageChange}
										/>
										{ESectionPrompts.SECTIONS_IMG_POPUP === sectionsPrompt && (
											<div className={classes.promptIco}>
												<PromptIco />
											</div>
										)}
									</div>
								</>
							)}
						</div>
						<div className={classes.infoWrap}>
							<IIco />
							<div className={classes.infoWrapText}>
								<Trans
									i18nKey={'requirements:imageExtend'}
									values={{
										width: 500,
										height: 500,
										size: ImageValidator.maxSizeInMb,
									}}
									t={t}
									components={{
										span: <span />,
										div: <div />,
									}}
								/></div>
						</div>
						<div className={classes.buttons}>
							<button onClick={handleClose}>{t('cancel')}</button>
							<button
								className={clsx(
									classes.saveButton,
									{[classes.savePromp]: sectionsPrompt === ESectionPrompts.SECTIONS_IMG_POPUP_SAVE},
								)}
								onClick={saveImage}
							>
								{sectionsPrompt === ESectionPrompts.SECTIONS_IMG_POPUP_SAVE && <span><PromptIco /></span>}
								{t('uploadImg.save')}
							</button>
						</div>
					</div>
				</div>
			</Modal>
		</>
	);
};

export default PopupUploadImg;
