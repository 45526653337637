import { getStoreAddressDataByStoreAddressId } from 'helpers';
import clsx from 'helpers/clsx';
import { useAppSelector } from 'hooks/redux';
import { useCollections } from 'hooks/useCollections';
import { useProducts } from 'hooks/useProducts';
import { useStoreAddress } from 'hooks/useStoreAddress';
import { SuperCollectionsIdsEnum, useSuperCollections } from 'hooks/useSuperCollections';
import { ISuperCollection } from 'models/ICollection';
import { IAvailability } from 'models/IProduct';
import { FC, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as ArrowBottom } from 'static/imgs/keyboard_arrow_down.svg';

import AddressList from './AddressList.template';
import classes from './AvailableAddresses.module.scss';
import { IAvailabilityDataShow, IProps } from './types';


const AvailableAddresses: FC<IProps> = ({ text, parentItem, itemSelector, productGroupId,
	addressPopupTitle, addressPopupSec, triggerCollapseParent,
}) => {
	const [open, setOpen] = useState(false);
	const {
		productsAvailability,
		productGroupsAvailability,
		updateProductsAvailability,
		updateProductsGroupAvailability,
		addIdInToProductsAvailForSend,
	} = useProducts();
	const { collectionsAvailability, updateCollectionAvailability } = useCollections();
	const { supCollectionsAvailability, updateSuperCollectionAvailability } = useSuperCollections();
	const { currentStoreData } = useStoreAddress();
	const { needsOptimization } = useAppSelector(state => state.collections);

	let parentAvailability: IAvailability[];

	switch (itemSelector) {
		case 'product': {
			parentAvailability = productsAvailability;
			break;
		}
		case 'product_group': {
			parentAvailability = productGroupsAvailability;
			break;
		}
		case 'collection': {
			parentAvailability = collectionsAvailability;
			break;
		}
		case 'super_collection': {
			parentAvailability = supCollectionsAvailability;
			break;
		}
	}

	const data = useMemo(() => {
		return parentAvailability.reduce((acc: IAvailabilityDataShow[], availability) => {
			if (availability.entity_id === parentItem.id) {
				availability.availability.forEach((availabilityData) => {
					const storeAddressData = getStoreAddressDataByStoreAddressId(currentStoreData, availabilityData.store_address_id);
					acc.push({
						id: availabilityData.store_address_id,
						active: availabilityData.is_available,
						street: storeAddressData?.address || '',
						storeId: availabilityData.store_address_id,
					});
				});
			};
			return acc;
		}, []);
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [parentAvailability]);

	const [activeItems, setActiveItems] = useState(data.length - 1);
	const addressListRef = useRef(null);
	const { t } = useTranslation('multiStore');

	useEffect(() => {
		setActiveItems(data.reduce((acc, el) => {
			el.active && ++acc;
			return acc;
		}, 0));
	}, [data]);

	const onChangeActive = (storeId: string, status: boolean) => {
		switch (itemSelector) {
			case 'product': {
				if (productGroupId) updateProductsAvailability(storeId, status, parentItem.id, productGroupId);
				break;
			}
			case 'product_group': {
				updateProductsGroupAvailability(storeId, status, parentItem.id);
				break;
			}
			case 'collection': {
				updateCollectionAvailability(storeId, status, parentItem.id);
				break;
			}
			case 'super_collection': {
				updateSuperCollectionAvailability(storeId, status, parentItem.id);
				break;
			}
		}
	};

	const handleLabelClick = (e: React.MouseEvent<HTMLDivElement>) => {
		const nextStateOpen = !open;

		setOpen(nextStateOpen);

		if (nextStateOpen && itemSelector === 'product') {
			addIdInToProductsAvailForSend(parentItem.id);
		}

		if (nextStateOpen && (itemSelector === 'super_collection' || itemSelector === 'collection') && needsOptimization && triggerCollapseParent) {
			triggerCollapseParent(e, true);
		}


		if (itemSelector === 'super_collection' && needsOptimization) {
			const parentSupCollectionNode = document.getElementById(parentItem.id)?.querySelector(`.${SuperCollectionsIdsEnum.SUP_COLLECTION_HEAD}`);
			if (parentSupCollectionNode) (parentSupCollectionNode as HTMLElement).style.zIndex = open ? '1': '13';
		}
	};

	if (!data.length) return null;
	return (
		<div ref={addressListRef} className={clsx(classes.root)}>

			<div className={clsx(classes.label, { [classes.label_available]: text })} onClick={handleLabelClick}>
				<div className={classes.label_title}>{text || t('availableLocations')}</div>
				<div className={classes.label_ico}><ArrowBottom /></div>
				<div
					className={
						clsx(
							classes.label_counter,
							{ [classes.label_counter_low]: activeItems < data.length },
							{ [classes.label_counter_empty]: activeItems < 1 },
						)
					}
				>
					{activeItems < 0 ? 0 : activeItems}/{data.length}
				</div>
			</div>
			{open && <AddressList
				onChangeActive={onChangeActive}
				data={data}
				handleLabelClick={handleLabelClick}
				addressPopupTitle={addressPopupTitle}
				addressPopupSec={addressPopupSec}
				collectionsInSup={(parentItem as ISuperCollection).menuCollections}
				itemSelector={itemSelector}
			/>}
		</div>
	);
};

export default AvailableAddresses;
