
import { createAsyncThunk } from '@reduxjs/toolkit';
import {
	fetchProductGroupsAvailability as fetchProductGroupsAvailabilityReq,
	fetchProducts as fetchProductsReq,
	fetchProductsAvailability as fetchProductsAvailabilityReq,
	fetchProductsMultiPrices as fetchProductsMultiPricesReq,
	fetchProductsSettings as fetchProductsSettingsReq,
} from 'api/products';

export const fetchProducts = createAsyncThunk(
	'products/fetchProducts',
	async ({storeAddressId, isMultiStore}: {storeAddressId: string, isMultiStore: boolean}, thunkAPI) => {
		try {
			const result = await fetchProductsReq(storeAddressId, isMultiStore).catch(() => undefined);
			if (!result || !Array.isArray(result)) throw new Error('Result not found');
			return result;
		} catch (e) {
			return thunkAPI.rejectWithValue('Failure');
		}
	},
);

export const fetchProductsMultiPrices = createAsyncThunk(
	'products/fetchProductsMultiPrices',
	async ({storeAddressId, addressesIdsInStore}: {storeAddressId: string, addressesIdsInStore: string}, thunkAPI) => {
		try {
			const result = await fetchProductsMultiPricesReq(storeAddressId, addressesIdsInStore).catch(() => undefined);
			if (!result || !Array.isArray(result)) throw new Error('Result not found');
			return result;
		} catch (e) {
			return thunkAPI.rejectWithValue('Failure');
		}
	},
);

export const fetchProductsAvailability = createAsyncThunk(
	'products/fetchProductsAvailability',
	async ({storeAddressId, addressesIdsInStore}: {storeAddressId: string, addressesIdsInStore: string}, thunkAPI) => {
		try {
			const result = await fetchProductsAvailabilityReq(storeAddressId, addressesIdsInStore).catch(() => undefined);
			if (!result || !Array.isArray(result)) throw new Error('Result not found');
			return result;
		} catch (e) {
			return thunkAPI.rejectWithValue('Failure');
		}
	},
);

export const fetchProductGroupsAvailability = createAsyncThunk(
	'products/fetchProductGroupsAvailability',
	async ({storeAddressId, addressesIdsInStore}: {storeAddressId: string, addressesIdsInStore: string}, thunkAPI) => {
		try {
			const result = await fetchProductGroupsAvailabilityReq(storeAddressId, addressesIdsInStore).catch(() => undefined);
			if (!result || !Array.isArray(result)) throw new Error('Result not found');
			return result;
		} catch (e) {
			return thunkAPI.rejectWithValue('Failure');
		}
	},
);

export const fetchProductsSettings = createAsyncThunk(
	'products/fetchProductsSettings',
	async ({storeAddressId, isMultiStore}: {storeAddressId: string, isMultiStore: boolean}, thunkAPI) => {
		try {
			return await fetchProductsSettingsReq(storeAddressId, isMultiStore).catch(() => undefined);
		} catch (e) {
			return thunkAPI.rejectWithValue('Failure');
		}
	},
);
