import PromptIco from 'components/PromptIco';
import PromptView from 'components/PromptView';
import PopupUploadImg from 'containers/PopupUploadImg';
import clsx from 'helpers/clsx';
import { useAppSelector } from 'hooks/redux';
import { ESectionPrompts } from 'models/IPrompts';
import React, { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { ReactComponent as ChangeIco } from 'static/imgs/change.svg';
import { ReactComponent as UploadIco } from 'static/imgs/uploadIco.svg';

import classes from './ProductImg.module.scss';
import { IProps } from './types';

const ProductImg: React.FC<IProps> = ({ product, groupId, isActive = true }) => {
	const [showTooltip, setShowTooltip] = useState<boolean>(true);
	const isEmpty = !product.image || product.image === 'image';
	const { sectionsPrompt } = useAppSelector(state => state.getStarted);
	const { t } = useTranslation('hintTexts');

	if (isEmpty) return (
		<div className={classes.container}>
			<PopupUploadImg product={product} groupId={groupId}>
				<div className={classes.empty}>
					<div className={classes.emptyBody}>
						<UploadIco />
						<span data-testid="upload"><Trans i18nKey="uploadImg.upload" ns="form" /></span>
					</div>
				</div>
			</PopupUploadImg>
			{sectionsPrompt === ESectionPrompts.SECTIONS_IMG && (
				<>
					{showTooltip && (
						<div data-id="promptWrap">
							<span className={classes.promptIco}>
								<PromptIco />
							</span>
							<div className={classes.promptWrap}>
								<PromptView
									title={t('uploadImg.title')}
									text={t('uploadImg.text')}
									show={() => setShowTooltip(false)}
								/>
							</div>
						</div>
					)}
				</>
			)}
		</div>
	);

	return (
		<div className={clsx(classes.root, { [classes.disabled]: !isActive })}>
			<img src={typeof product.image === 'string' ? product.image : ''} alt="Product Img" />
			<PopupUploadImg product={product} groupId={groupId}>
				<div className={classes.changeImg}>
					<ChangeIco />
					<span className={classes.changeImg_edit}>{t('uploadImg.edit')}</span>
				</div>

			</PopupUploadImg>
		</div>
	);
};

export default ProductImg;
