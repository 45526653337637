import Modal from 'components/Modal';
import clsx from 'helpers/clsx';
import { calcErrCountInItem } from 'helpers/products';
import { getGroupById } from 'helpers/products';
import { useAppSelector } from 'hooks/redux';
import { useCollections } from 'hooks/useCollections';
import { useProducts } from 'hooks/useProducts';
import { useSuperCollections } from 'hooks/useSuperCollections';
import { useUser } from 'hooks/useUser';
import { IProduct, IProductGroup } from 'models/IProduct';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { ReactComponent as RemoveProductIco } from 'static/imgs/deleteProductIco.svg';

import classes from './PopupCollectionRemove.module.scss';
import { IProps } from './types';

const PopupCollectionRemove: React.FC<IProps> = ({
	open, onClose, collectionId, isSuperCollection = false,
	collectionIsChecked = false, collectionsProductGroups,
}) => {
	const { removeCollection, getCollectionById, removeValidation } = useCollections();
	const {
		removeSuperCollection,
		removeCollectionFromSupCollections,
		getSupCollectByChildCollectionId,
		updateSuperCollection,
		removeValidationSup,
	} = useSuperCollections();
	const { clearProductsValidation } = useProducts();
	const { t } = useTranslation(['collections', 'form']);
	const isExternalId = useAppSelector(state => state.products.isExternalId);
	const { itemsIsChecked, setItemsIsChecked, sectionsCheckCount, setTypeOfCheckItems, productsCheckCount, collectionsCheckCount } = useUser();
	const { productGroups } = useAppSelector(state => state.products);

	const recalcChechedItemsCount = () => {
		// thisCollection + childSections.length
		let checkedItemsNowCount = 1 + (collectionsProductGroups?.length || 0);
		let productsCheckedCount = 0;
		let childIsChecked: IProductGroup | IProduct | undefined;

		collectionsProductGroups?.forEach((groupId: IProductGroup['id']) => {
			const childGroup = getGroupById(productGroups, groupId);
			// add childProducts.length
			if (childGroup) {
				productsCheckedCount += childGroup.items.length;

				// check if childred sections or products is checked
				if (childGroup.checked) childIsChecked = childGroup;
				if (!childIsChecked) childIsChecked = childGroup.items.find(product => product.checked);
			}
		});

		if (collectionIsChecked || childIsChecked) {
			setItemsIsChecked(itemsIsChecked - (checkedItemsNowCount + productsCheckedCount));

			setTypeOfCheckItems('collection', collectionsCheckCount - 1);
			setTypeOfCheckItems('section', sectionsCheckCount - (collectionsProductGroups?.length || 0));
			setTypeOfCheckItems('product', productsCheckCount - productsCheckedCount);
		}

	};

	const removeItem = () => {
		if (isSuperCollection) {
			removeSuperCollection(collectionId);
		} else {
			removeCollection(collectionId);
			removeCollectionFromSupCollections(collectionId);
			recalcChechedItemsCount();

			const parentSupCollections = getSupCollectByChildCollectionId(collectionId);
			const collectionBody = getCollectionById(collectionId);
			if (parentSupCollections?.itemsErrorsCount && collectionBody) {
				const errCountInItem = calcErrCountInItem(collectionBody) + (collectionBody.itemsErrorsCount || 0);
				updateSuperCollection(parentSupCollections?.id, 'itemsErrorsCount', parentSupCollections?.itemsErrorsCount - errCountInItem);
			}
		}

		if (isExternalId) {
			clearProductsValidation();
			removeValidation();
			removeValidationSup();
		}

		onClose();
	};

	return (
		<Modal
			open={open}
			onClose={onClose}
		>
			<div className={classes.productRemovePopup}>
				<RemoveProductIco />
				<span className={classes.title} data-testid="test_warningDeleteTitle">
					<Trans t={t} i18nKey={isSuperCollection ? 'warningDeleteTitleSup' : 'warningDeleteTitle'} />
				</span>
				<span className={classes.text} data-testid="warningDeleteText">
					{isSuperCollection ? t('warningDeleteTextSup') : t('warningDeleteText')}
				</span>

				<div className={classes.buttons}>
					<div className={classes.button} onClick={onClose} data-testid="test_cancel">{t('form:cancel')}</div>
					<div onClick={removeItem} className={clsx(classes.button, classes.confirm)} data-testid="test_yesDelete" >
						{t('form:yesDelete')}
					</div>
				</div>
			</div>
		</Modal>
	);
};

export default PopupCollectionRemove;
