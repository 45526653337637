import { getLang } from 'helpers/jwt';
import { getLangByTimeZone } from 'helpers/lang';
import { CustomDetector } from 'i18next-browser-languagedetector';

class LangDetector implements CustomDetector {
	public name: string = 'langDetector';
	public lookup() {
		return getLangByTimeZone() ||  getLang();
	}
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new LangDetector();
