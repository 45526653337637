import { EOptionsPrompts, ESectionPrompts } from 'models/IPrompts';
import { useDispatch } from 'react-redux';
import { getStartedSlice } from 'store/reducers/getStarted';

import { useAppSelector } from './redux';

export interface IUsePrompts {
	togglePromptSection: (prompt: ESectionPrompts | null) => void;
	togglePromptOption: (prompt: EOptionsPrompts | null) => void;
}

const usePrompts = (): IUsePrompts => {
	const { sectionsPrompt, optionsPrompt } = useAppSelector((state) => state.getStarted);
	const dispatch = useDispatch();
	const { setSectionsPromptState, setOptionsPromptState } = getStartedSlice.actions;

	const togglePromptSection = (prompt: ESectionPrompts | null) => {
		if (sectionsPrompt !== null) {
			dispatch(setSectionsPromptState(prompt));
		}
	};

	const togglePromptOption = (prompt: EOptionsPrompts | null) => {
		if (optionsPrompt !== null) {
			dispatch(setOptionsPromptState(prompt));
		}
	};

	return {
		togglePromptSection,
		togglePromptOption,
	};
};

export default usePrompts;
