import { ICollection, ISuperCollection } from 'models/ICollection';
import { IMultiLangData } from 'models/IMultiLang';
import { IOption, IOptionGroup } from 'models/IOption';
import { IProduct, IProductGroup, ITranslations } from 'models/IProduct';
import { v4 as uuidv4 } from 'uuid';

export const getEmptyOptionGroup = (translations?: ITranslations): IOptionGroup => ({
	id: uuidv4(),
	title: '',
	active: true,
	moreThanOnce: false,
	items: [getEmptyOption(translations)],
	isNew: true,
	...(translations && {translations}),
});

export const getEmptyOption = (translations?: ITranslations): IOption => ({
	id: uuidv4(),
	title: '',
	price: 0,
	active: true,
	isNew: true,
	...(translations && {translations}),
});

export const getEmptyProductGroup = (position: number, groupTranslations?: ITranslations, productTranslations?: ITranslations, isExternalId?: boolean): IProductGroup => ({
	id: uuidv4(),
	title: '',
	active: true,
	items: [getEmptyProduct(1, productTranslations, isExternalId)],
	position,
	isNew: true,
	checked: false,
	...(groupTranslations && {translations: groupTranslations}),
});

export const getEmptyCollection = (position: number, groupsId: string[], name: string = '', translations?: ITranslations): ICollection => ({
	id: uuidv4(),
	title: name,
	active: true,
	position,
	isNew: true,
	image: '',
	productGroups: groupsId,
	...(translations && {translations}),
	checked: false,
});

export const getEmptySuperCollection = (position: number, collectionsIds: string[], name: string = ''): ISuperCollection => ({
	id: uuidv4(),
	title: name,
	active: true,
	position,
	isNew: true,
	image: '',
	menuCollections: collectionsIds,
});

export const getEmptyProduct = (position: number, translations?: ITranslations, isExternalId?: boolean): IProduct => ({
	id: uuidv4(),
	title: '',
	active: true,
	description: '',
	price: 0,
	hitSales: false,
	image: '',
	options: [],
	position,
	isNew: true,
	checked: false,
	external_id: isExternalId ? '' : null,
	...(translations && {translations}),
});

export const getTranslationsForProduct = (languages: IMultiLangData['languages']): ITranslations => (
	languages.reduce((acc: ITranslations, item) => {
		acc[item] = {
			title: '',
			description: '',
		};
		return acc;
	}, {})
);

export const getTranslations = (languages: IMultiLangData['languages']): ITranslations => (
	languages.reduce((acc: ITranslations, item) => {
		acc[item] = {
			title: '',
		};
		return acc;
	}, {})
);
