import { fetch } from 'helpers/fetch';
import { ILangsPatchData, IMultiLangData } from 'models/IMultiLang';

export const fetchMultiLang = (storeAddressId: string, isMultiStore: boolean) => fetch<IMultiLangData>(`/multi-lang/update/${storeAddressId}/state?storeType=${isMultiStore ? 'store' : 'store_address'}`);

export const getMultiLangStatus = (storeAddressId: string, isMultiStore: boolean) => fetch<{translationProcess: boolean}>(`/multi-lang/update/${storeAddressId}/status/${isMultiStore ? 'store' : 'store_address'}`);

export const patchLangs = (storeAddressId: string, langsData: ILangsPatchData) => fetch(`/multi-lang/update/${storeAddressId}`, {
	method: 'PATCH',
	body: JSON.stringify(langsData),
});
