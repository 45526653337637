import i18n from 'i18n';

import { IRule } from './types';

export class Price implements IRule {

	passes(value: number, minMultiPrice?: number): boolean {
		return (minMultiPrice !== undefined && minMultiPrice > 0)
			|| (typeof value === 'number' && value > 0)
			|| (value === null && minMultiPrice === undefined);
	}

	message(): string {
		return i18n.t('validation:price');
	}
}
