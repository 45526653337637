import React from 'react';
import ReactDOM from 'react-dom';

import Template from './template';
import { IProps } from './types';

const Loader: React.FC<IProps> = ({ ...props }) => {
	const component = <Template {...props} />;
	if (!props.simple && typeof document !== 'undefined') {
		return ReactDOM.createPortal(component, document.body);
	}
	return component;
};

export default Loader;
