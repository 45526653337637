import LastSaveDraft from 'components/LastSaveDraft';
import { useAppSelector } from 'hooks/redux';
import { useDateSaveDraft } from 'hooks/useDateSaveDraft';
import React, { useEffect } from 'react';

const LastSaveDraftContainer: React.FC = () => {

	const { fetchDateSaveDraft } = useDateSaveDraft();
	const { date } = useAppSelector((state) => state.dateSaveDraft);

	// eslint-disable-next-line react-hooks/exhaustive-deps
	useEffect(fetchDateSaveDraft, []);

	return <LastSaveDraft date={date} /> ;
};

export default LastSaveDraftContainer;
