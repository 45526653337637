import { RoutesEnum } from 'const';
import { useUser } from 'hooks/useUser';
import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';


const RequireAuth: React.FC = ({ children }) => {
	const { user } = useUser();
	const location = useLocation();

	if (!user) {
		return <Navigate to={RoutesEnum.LOGIN} state={{ from: location }} />;
	}

	return <>{children}</>;
};

export default RequireAuth;
