import Checkbox from 'components/FormControl/Checkbox';
import ScopeInput from 'components/ScopeInput';
import SectionHead from 'components/SectionHead';
import OptionsList from 'containers/OptionsList';
import { useOptionsContext } from 'contexts/Options';
import clsx from 'helpers/clsx';
import { useAppSelector } from 'hooks/redux';
import { IOptionGroup } from 'models/IOption';
import { EOptionsPrompts } from 'models/IPrompts';
import React from 'react';
import { useTranslation } from 'react-i18next';

import classes from './OptionsGroup.module.scss';
import { IProps } from './types';

const OptionsGroup: React.FC<IProps> = ({ group, rootClassName, listWrapClass }) => {
	const { optionsPrompt } = useAppSelector(state => state.getStarted);
	const { updateOptionGroup, isModal } = useOptionsContext();
	const { t } = useTranslation(['options', 'form', 'hintTexts']);

	const handleChangeGroup = (key: keyof IOptionGroup) => (value: string | boolean) => {
		updateOptionGroup(group.id, key, value);
	};

	const handleChangeCheckBox = () => {
		const nextValue = !group.moreThanOnce;
		updateOptionGroup(group.id, 'moreThanOnce', nextValue);
		if (!nextValue ) {
			if (group.max && group.max > group.items.length) {
				updateOptionGroup(group.id, 'max', group.items.length);
			}

			if (group.min && group.min > group.items.length) {
				updateOptionGroup(group.id, 'min', group.items.length);
			}
		}
	};

	const countItems = group.items.filter((it) => it.title && typeof it.price === 'number').length;

	return (
		<div className={clsx(classes.optionWrap, rootClassName, {[classes.disabled]: !group.active}, {[classes.isModal]: isModal})}>
			<SectionHead
				isOptionsPage
				isActive={group.active}
				countItems={countItems}
				title={group.title}
				group={group}
				onChangeTitle={handleChangeGroup('title')}
				onChangeSwitch={handleChangeGroup('active')}
				errors={group.validation?.title}
				isModal={isModal}
				translations={group.translations}
			/>
			<div className={classes.parametersWrap}>
				<div className={classes.parameter}>
					<div className={classes.parameterTitle}>{t('questionMinMax')}</div>
					<div className={classes.scopeWrap}>
						<div className={classes.scope}>
							<ScopeInput
								title={t('form:min')}
								description={t('attentionNoRequiredSection')}
								placeholder={'0'}
								promptInfo={{
									title: t('hintTexts:optionMinCount.title'),
									text: t('hintTexts:optionMinCount.text'),
								}}
								showPrompt={optionsPrompt === EOptionsPrompts.OPTION_MIN_COUNT}
								onChange={handleChangeGroup('min')}
								value={group.min}
								setNextPromptOption={EOptionsPrompts.OPTION_MAX_COUNT}
								maxNumber={group.moreThanOnce ? undefined : group.items.length }
								validation={group.validation?.min}
							/>
						</div>
						<div className={classes.scope}>
							<ScopeInput
								title={t('form:max')}
								description={!group.moreThanOnce ? t('attentionMax') : null}
								placeholder={'0'}
								promptInfo={{
									title: t('hintTexts:optionMaxCount.title'),
									text: t('hintTexts:optionMaxCount.text'),
								}}
								showPrompt={optionsPrompt === EOptionsPrompts.OPTION_MAX_COUNT}
								onChange={handleChangeGroup('max')}
								value={group.max}
								setNextPromptOption={EOptionsPrompts.OPTION_NAME}
								maxNumber={group.moreThanOnce ? undefined : group.items.length}
							/>
						</div>
						<div className={classes.checkBoxWrap}>
							<Checkbox isChecked={group.moreThanOnce} onChange={handleChangeCheckBox}>{t('questionMoreThanOnce')}</Checkbox>
						</div>
					</div>
				</div>
			</div>
			<div className={classes.list}>
				<OptionsList optionGroup={group} listWrapClass={listWrapClass} />
			</div>
		</div>
	);
};

export default OptionsGroup;
