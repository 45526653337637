import { languageList } from 'const';
import { ICollection } from 'models/ICollection';
import { IMultiLangData } from 'models/IMultiLang';
import { IOption, IOptionGroup } from 'models/IOption';
import {
	IAvailability,
	IAvailabilityData,
	IProduct,
	IProductMultiPrice,
	IProductMultiPriceBody,
	ITranslations,
} from 'models/IProduct';
import { IStoreAddressesData, IStoresData } from 'models/IStoreAddresses';
import { IValidationGroup, IValidationItem } from 'models/IValidation';

export const makeURIParams = (query: { [key: string]: any } = {}): string => {
	const data: any[] = Object.entries(query);
	return data.reduce((acc, el) => {
		if (typeof el[1] === 'boolean' || el[1]) {
			if (Array.isArray(el[1])) {
				el[1].forEach((value) => {
					acc.push([`${el[0]}[]`, value].join('='));
				});
			} else {
				acc.push(el.join('='));
			}
		}
		return acc;
	}, []).join('&');
};
interface IEntity<T = {}> {
	id: string;
	position?: number;
	isNew?: boolean;
	items?: T[];
	validation?: IValidationGroup;
	itemsErrorsCount?: number;
	saveCollapseValue?: boolean;
}

interface IEntityChild {
	id: string;
	position?: number;
	isNew?: boolean;
	validation?: IValidationItem;
	minMultiPrice?: number;
}

export const changeEntity = <T extends IEntity>(items: T[], id: string, key: keyof T, value: any): T[] => {
	return items.map((it) => {
		if (id === it.id) {
			return {
				...it,
				[key]: value,
				validation: {
					...it.validation,
					...it.validation?.hasOwnProperty(key) ? { [key]: true } : {},
				},
			};
		}
		return it;
	});
};

export const changeEntityForce = <T extends IEntity>(items: T[], id: string, key: keyof T, value: any): T[] => {
	return items.map((it) => {
		if (id === it.id) {
			return {
				...it,
				[key]: value,
			};
		}
		return it;
	});
};

export const changeChildEntity = <C extends IEntityChild, T extends IEntity<C>>(items: T[], id: string, childId: string, key: keyof C, value: any): T[] => {
	return items.map((item) => {
		if (id === item.id) {
			return {
				...item,
				items: item.items?.map((child) => {
					if (child.id === childId) {
						return {
							...child,
							[key]: value,
							validation: {
								...child.validation,
								...child.validation?.hasOwnProperty(key) ? { [key]: [] } : {},
								...(key === 'minMultiPrice' ? { 'price': [] } : {}),
							},
						};
					}
					return child;
				}),
			};
		}
		return item;
	});
};

export const createEmptyChildEntity = <C extends IEntity, T extends IEntity<C>>(items: T[], id: string, child: C): T[] => {
	return items.map((item) => {
		if (item.id === id) {
			return {
				...item,
				validation: {
					...child.validation,
					...child.validation?.hasOwnProperty('items') ? { items: true } : {},
				},
				items: [
					...(item.items || []),
					child,
				],
			};
		}
		return item;
	});
};

export const sortByPosition = <T extends IEntity>(items: T[]) => {
	return [...items].sort((a, b) => a.position! - b.position!);
};

export const excludeIdEntities = <C extends IEntity, T extends IEntity<C>>(entries: T[]): Omit<T, 'isNew'>[] => {
	return entries.map((enity) => {
		const { items: itemsOrigin = [] } = enity;
		const items = itemsOrigin.map((item) => {
			if (!item.isNew) return item;
			const { isNew, ...restItem } = item;
			return restItem;
		});
		if (!enity.isNew) return { ...enity, items };
		const { isNew, ...restEnity } = enity;
		return { ...restEnity, items };
	});
};
export const excludeId = <T extends IEntity>(entries: T[]): Omit<T, 'isNew' | 'id'>[] => {
	return entries.map((enity) => {
		if (!enity.isNew) return enity;
		const { isNew, id, ...restEnity } = enity;
		return restEnity;
	});
};

export const onlyValidNumbers = (number: string, lastValid: string): string => {
	if (/^\d*\.?\d*$/.test(number)) {
		return number;
	}
	return lastValid;
};

export const bytesToMegaBytes = (bytes: number) => bytes / (1024 ** 2);

export const removeOptionInGroupFromId = (groupItems: IOptionGroup[], id: IOption['id']) => (groupItems.map(group => {
	return ({
		...group,
		items: group.items.reduce((acc: IOption[], item) => {
			if (item.id !== id) acc.push(item);
			return acc;
		}, []),
	});
}));

export const calcIsMultiStoreValue = (storeAddressesData: IStoresData[], currentStoreId: string): IStoresData | null => storeAddressesData.find((item) => item.storeId === Number(currentStoreId)) || null;

export const changeMultiPrice = (productsMultiPrices: IProductMultiPrice[], productId: IProduct['id'], prices: IProductMultiPriceBody[], minPrice?: number): IProductMultiPrice[] => {
	let priceIndexInArr = -1;
	const newPrices = productsMultiPrices.map((item) => {
		if (item.product_id === productId){
			priceIndexInArr = productsMultiPrices.indexOf(item);
			const single_price = minPrice && Number.isFinite(minPrice) ? minPrice : undefined;

			return {
				...item,
				prices,
				single_price,
			};
		}
		return item;
	});

	if (priceIndexInArr < 0) newPrices.push({
		product_id: productId,
		prices,
	});

	return newPrices;
};

export const changeAvailability = (itemsAvailability: IAvailability[], itemId: string, status: boolean, storeId: string) => {
	return itemsAvailability.map((availBlock) => {
		if (availBlock.entity_id === itemId) {
			const newAvailData = availBlock.availability.map((availDataBlock) => {
				if (availDataBlock.store_address_id === storeId) {
					return { ...availDataBlock, is_available: status};
				}
				return availDataBlock;
			});
			return {...availBlock, availability: newAvailData};
		};
		return availBlock;
	});
};

export const getAvailableBlockByEntityId = (itemsAvailability: IAvailability[], itemId: string) => {
	return itemsAvailability.find((item) => item.entity_id === itemId);
};

export const getStoreAddressDataByStoreAddressId = (currentStoreData: IStoresData | null, storeAddressId: IProductMultiPriceBody['store_address_id']) => currentStoreData?.storeAddresses.find((item) => String(item.storeAddressId) === storeAddressId);

export const getProductMultiPrice = (allPrices: IProductMultiPrice[], product_id: IProduct['id']) => allPrices.find((item) => item.product_id === product_id);

export const getMinInMultiPrices = (multiPrices?: IProductMultiPriceBody[]): number => {
	const prices = multiPrices?.map((item) => item.price);
	if (!prices) return 0;
	return Math.min(...prices);
};

export const getItemAvailableStatus = (currentAvailableItem?: IAvailability) => !!currentAvailableItem?.availability.find((item) => item.is_available);

export const createAvailability = (currentStoreData: IStoresData | null) => currentStoreData?.storeAddresses.map((address): IAvailabilityData => {
	return {
		store_address_id: String(address.storeAddressId),
		is_available: true,
	};
});

export const getAvailableByEntityId = (availabilityArr: IAvailability[], entity_id: string) => availabilityArr.find((item) => item.entity_id === entity_id);

export const removeAvailability = (availabilityList: IAvailability[], entity_id: string) => availabilityList.filter((item) => item.entity_id !== entity_id);

export const prepareStoreData = (storeAddressData: IStoreAddressesData[]): IStoresData[] => storeAddressData.reduce((acc: IStoresData[], item) => {
	const indexStoreId = acc.findIndex((storeIdItem) => storeIdItem.storeId === item.storeId);
	if (indexStoreId < 0) {
		acc.push({
			storeName: item.storeName,
			storeId: item.storeId,
			storeAddresses: [{...item}],
		});
	} else {
		acc[indexStoreId].storeAddresses.push(item);
	}
	return acc;
}, []);

export const isValidURL = (string: string) => {
	// eslint-disable-next-line no-useless-escape
	const res = string.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g);
	return (res !== null);
};

export const checkIsObject = (item: any): boolean => {
	return (
		typeof item === 'object' &&
		!Array.isArray(item) &&
		item !== null
	);
};

export const getLangDataByShortLang = (shortLang: IMultiLangData['defaultLanguage']) => languageList.find((item) => item.shortLang === shortLang);

export const formatingPrice = (x: number) => {
	var parts = x.toString().split('.');
	parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
	return parts.join('.');
};

export const getCollectionById = (collections: ICollection[], collectionId: ICollection['id']) => collections.find(({ id }) => id === collectionId);

export const handleDisableScrolling = (disable: boolean) => {
	const body = document.getElementsByTagName('body')[0];
	disable ? body.style.overflow = 'hidden' : body.style.overflow = 'auto';
};

export const removeSpacesAtTheEndOfTitle = (value: string) => value.trim();

export const removeSpacesAtTheEndOfLangsValues = (langsValues: ITranslations) => Object.keys(langsValues || {}).reduce((acc: ITranslations, key) => {
	acc[key] = {
		...langsValues[key],
		title: langsValues[key].title.trim(),
	};

	return acc;
}, {});

export const removePropertiesForCheck = <T extends IEntity>(items: T[]) => items.map((item) => {
	const {validation, itemsErrorsCount, saveCollapseValue , ...otherProperties} = item;
	return {
		...otherProperties,
		items: item.items?.map((itemChild) => {
			const {validation: childValidation, ...otherChildsProperties} = itemChild as IEntityChild;
			return otherChildsProperties;
		}),
	};
});

export const addCheckedProperty = <T>(items: T[]) => items.map((item) => {
	return {
		...item,
		checked: false,
	};
});
