import PopupAddOption from 'containers/PopupAddOrEditOption';
import SelectedOptionGroup from 'containers/SelectedOptionGroup';
import { useClickAwayListener } from 'hooks/useClickAwayListener';
import React, { useRef, useState } from 'react';
import { ReactComponent as CloseImg } from 'static/imgs/x.svg';

import classes from './SelectedOptions.module.scss';
import { IProps } from './types';

const SelectedOptions: React.FC<IProps> = ({ item, handleRemoveValue }) => {
	const [groupShow, setGroupShow] = useState<boolean>(false);
	const refRoot = useRef<any>(null);
	const [open, setOpen] = useState(false);

	const handleOpen = () => setOpen(true);
	const handleClose = () => setOpen(false);

	const handleGroupShow = () => {
		setGroupShow(true);
	};

	useClickAwayListener(refRoot, () => {
		setGroupShow(false);
	}, '#js_modal_root');

	return (
		<>
			<div onClick={handleGroupShow} ref={refRoot} className={classes.selectedOptions} key={item.id}>
				<span >{item.title}</span>
				<button onClick={handleRemoveValue(item.id)}><CloseImg /></button>
				{groupShow && (
					<div className={classes.selectedOptionGroup}>
						<SelectedOptionGroup optionsGroup={item} handleOpen={handleOpen} />
					</div>
				)}
			</div>
			<PopupAddOption isEdit open={open} onClose={handleClose} optionsGroup={item} />
		</>
	);
};

export default SelectedOptions;
