import {combineReducers, configureStore} from '@reduxjs/toolkit';

import collections from './reducers/collections';
import dateSaveDraft from './reducers/dateSaveDraft';
import getStarted from './reducers/getStarted';
import modals from './reducers/modals';
import multiLang from './reducers/multiLang';
import options from './reducers/options';
import products from './reducers/products';
import storeAddress from './reducers/storeAddress';
import superCollectionsData from './reducers/superCollectionsData';
import user from './reducers/user';

const reducer = combineReducers({
	user,
	getStarted,
	modals,
	options,
	products,
	storeAddress,
	collections,
	superCollectionsData,
	multiLang,
	dateSaveDraft,
});

export const setupStore = () => {
	return configureStore({
		reducer,
	});
};

export type RootState = ReturnType<typeof reducer>;
export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = AppStore['dispatch'];

