import InputComponent from 'components/InputComponent';
import ItemStore from 'components/ItemStoreAddress';
import PopupStoreChange from 'containers/PopupStoreChange';
import clsx from 'helpers/clsx';
import storage from 'helpers/localStorage';
import { useAppSelector } from 'hooks/redux';
import { useClickAwayListener } from 'hooks/useClickAwayListener';
import { useCollections } from 'hooks/useCollections';
import { useOptions } from 'hooks/useOptions';
import { useProducts } from 'hooks/useProducts';
import { useStoreAddress } from 'hooks/useStoreAddress';
import { useSuperCollections } from 'hooks/useSuperCollections';
import { useUser } from 'hooks/useUser';
import { IStoresData } from 'models/IStoreAddresses';
import { FC, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as ArrowIco } from 'static/imgs/arrow-image.svg';
import { ReactComponent as SearchIco } from 'static/imgs/search.svg';

import classes from './MultiAccounts.module.scss';
import { IProps } from './types';

const MultiAccounts: FC<IProps> = ({ stores }) => {
	const [selected, setSelected] = useState<IStoresData | null>(null);
	const [storeForPopup, setStoreForPopup] = useState<IStoresData | null>(null);
	const [open, setOpen] = useState<boolean>(false);
	const [openPopup, setOpenPopup] = useState<boolean>(false);
	const [searchedStores, setSearchedStore] = useState<IStoresData[] | null>(stores);
	const { getUserChanges: isSupCollChanged } = useSuperCollections();
	const { getUserChanges: isCollChanged } = useCollections();
	const { getUserChanges: isProductsChanged, clearSearchResult } = useProducts();
	const { getUserChanges: isOptionsChanged } = useOptions();
	const [query, setQuery] = useState<string>('');
	const selectRef = useRef(null);
	const { currentStoreId, setCurrentStoreId } = useStoreAddress();
	const { t } = useTranslation('accounts');
	const { productGroups } = useAppSelector(state => state.products);
	const { setItemsIsChecked } = useUser();

	const handleSearch = (str: string) => {
		if (!str) setSearchedStore(stores);

		const searchedStore = stores?.filter(store => {
			const storeData = store.storeAddresses[0].address + store.storeId + store.storeName;
			return storeData.toLowerCase().includes(str.toLowerCase()) ? true : false;

		});
		setSearchedStore(searchedStore!);
	};


	useEffect(() => {
		const storeId = storage.getItem('storeId');

		if (storeId) {
			const activeStore = stores.find(store => store.storeId === storeId);
			if (activeStore) return setSelected(activeStore);
		}

		const activeStore = stores.find(store => String(store.storeId) === currentStoreId);
		if (activeStore) return setSelected(activeStore);
		setSelected(stores[0]);
	}, [stores, currentStoreId]);

	const handlePopup = (store: IStoresData) => () => {
		const isUserChanges = isProductsChanged(productGroups) || isOptionsChanged() || isCollChanged() || isSupCollChanged();
		if (isUserChanges) {
			setStoreForPopup(store);
			onClose();
			onOpenPopup();
			return;
		}
		handleStore(store);
	};

	const handleStore = (store?: IStoresData) => {
		const selectStore = store || storeForPopup;
		storage.setItem('storeId',  String(selectStore?.storeId));
		setCurrentStoreId(String(selectStore?.storeId));
		setSelected(selectStore);
		clearSearchResult();
		setItemsIsChecked(0);
	};

	const onClose = () => {
		setOpen(false);
	};

	const handleQuery = (str: string) => {
		setQuery(str);
		handleSearch(str);
	};

	const handleClearSearchQuery = () => {
		handleQuery('');
	};

	const toggleListContainer = () => {
		setOpen(!open);
	};

	const onClosePopup = () => {
		setOpenPopup(false);
	};

	const onOpenPopup = () => {
		setOpenPopup(true);
	};

	useClickAwayListener(selectRef, onClose, '.js_clear_search');

	return (
		<>
			<PopupStoreChange open={openPopup} onClose={onClosePopup} handleStore={handleStore} />
			<div id={'searchContainer'} ref={selectRef} className={classes.container}>
				<div onClick={toggleListContainer} className={classes.valueContainer}>
					<div className={classes.valueContainer_info}>
						<div className={classes.valueContainer_id}>{selected?.storeId}</div>
						<div className={classes.valueContainer_name}>{selected?.storeName}</div>
						{selected && selected?.storeAddresses.length > 1 && (
							<div>({selected?.storeAddresses.length})</div>
						)}
					</div>
					<div className={clsx({ [classes.valueContainer_arrow]: open })}><ArrowIco /></div>
				</div>
				<div className={clsx(classes.listContainer, { [classes.listContainer_open]: open })}>
					<div className={classes.search}>
						<div className={classes.search_icon}><SearchIco /></div>
						<InputComponent
							showClearButton
							value={query}
							onChange={handleQuery}
							placeholder={t('placeholder')}
							handleClearSearchQuery={handleClearSearchQuery}
						/>
					</div>
					{searchedStores?.map(store =>
						<div key={store.storeId} className={classes.listContainer_store} >
							<div
								className={clsx(
									classes.listContainer_option,
								)}
								onClick={handlePopup(store)}
							>
								<ItemStore storeId={store.storeId} storeAddresses={store.storeAddresses} storeName={store.storeName}/>
							</div>
						</div>,
					)}
				</div>
			</div >
		</>
	);
};
export default MultiAccounts;
