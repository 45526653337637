import PromptIco from 'components/PromptIco';
import PromptView from 'components/PromptView';
import SwitchComponent from 'components/SwitchComponent';
import { maxSendingCount } from 'const';
import { scrollToNewItem } from 'helpers/scroll';
import { useAppSelector } from 'hooks/redux';
import { useCollections } from 'hooks/useCollections';
import { OptionsIdsEnum, useOptions } from 'hooks/useOptions';
import { useProducts } from 'hooks/useProducts';
import { ESectionPrompts } from 'models/IPrompts';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as Plus } from '../../static/imgs/plus.svg';
import classes from './AddSection.module.scss';
import { IProps } from './types';

const AddSection: React.FC<IProps> = ({
	minifiedVersion = false, isOptionsPage, onOpenPopup,
	collectionClosestId, collapseAll, productGroupsCount,
}) => {
	const { sectionsPrompt } = useAppSelector(state => state.getStarted);
	const [showTooltip, setShowTooltip] = useState<boolean>(true);
	const { createEmptyGroupOptions } = useOptions();
	const { createEmptyGroupProducts } = useProducts();
	const { tieSectionToCollection } = useCollections();
	const { t } = useTranslation(['products', 'options', 'collections', 'hintTexts']);
	const instructionText = isOptionsPage ? t('options:instructionText') : t('products:instructionSendCount', { count: maxSendingCount });
	const buttonText = isOptionsPage ? t('options:addSection') : t('products:addSection');

	const handleCreateEmptyGroupProducts = () => {
		const newGroup = createEmptyGroupProducts(productGroupsCount);
		if (collectionClosestId) tieSectionToCollection(collectionClosestId, newGroup.id);
		if (collapseAll) collapseAll(false, true);
		setTimeout(() => scrollToNewItem(newGroup.id, true), 0);
	};

	const handleCreateEmptyGroupOptions = () => {
		createEmptyGroupOptions();
		setTimeout(() => scrollToNewItem(`.${OptionsIdsEnum.GROUP}`), 0);
	};

	const handleClick = isOptionsPage ? handleCreateEmptyGroupOptions : handleCreateEmptyGroupProducts;

	if (minifiedVersion) return (
		<div data-testid="minifiedVersion" className={classes.minifiedRoot}>
			<div className={classes.container}>
				<div className={classes.button} onClick={handleClick} data-testid="addSection">
					{buttonText}<span><Plus /></span>
				</div>
				{sectionsPrompt === ESectionPrompts.ADD_SECTION && (
					<>
						{showTooltip && (
							<div data-id="promptWrap" onClick={() => setShowTooltip(false)}>
								<div className={classes.icoWrap}><PromptIco /></div>
								<div className={classes.promptWrap}>
									<PromptView
										title={t('hintTexts:addSection.title')}
										text={t('hintTexts:addSection.text')}
										show={() => setShowTooltip(false)}
									/>
								</div>
							</div>
						)}
					</>
				)}
			</div>
		</div>
	);

	return (
		<>
			<div className={classes.root}>
				<div className={classes.text}>{instructionText}</div>
				<div className={classes.rightBar}>
					<div className={classes.button} onClick={handleClick} data-testid="addSection">
						<span><Plus /></span>{buttonText}
					</div>
					{!isOptionsPage && (
						<>
							<div className={classes.toggleActive}>
								{onOpenPopup && (
									<SwitchComponent
										ico
										revert
										isActive={false}
										onChange={onOpenPopup}
										titleDis={t('collections:includeCollections')}
									/>
								)}
							</div>
						</>
					)}
				</div>
			</div>
		</>
	);
};


export default AddSection;
