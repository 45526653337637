import clsx from 'helpers/clsx';
import React from 'react';
import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';

import classes from './SiteNavigationItem.module.scss';
import { IProps } from './types';

const SiteNavigationItem: React.FC<IProps> = ({ ico, href, text, onClick }) => {
	const location = useLocation();

	const content = (
		<>
			<span>{ico}</span>
			{text}
		</>
	);

	if (!href) {
		return (
			<div className={classes.link} data-testid="test_SiteNavigationItemText" onClick={onClick}>
				{content}
			</div>
		);
	};

	return (
		<Link className={clsx(classes.link, {[classes.active]: location.pathname === href})} to={href} data-testid={`nav${href}`} onClick={onClick}>
			{content}
		</Link>
	);
};

export default SiteNavigationItem;
