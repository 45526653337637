import SwitchComponent from 'components/SwitchComponent';
import clsx from 'helpers/clsx';
import { FC, useState } from 'react';
import { ReactComponent as Logo } from 'static/imgs/logo.svg';

import classes from './AddressOption.module.scss';
import { IProps } from './types';


const AddressOption: FC<IProps> = ({ onChange, address }) => {
	const { active, street, storeId } = address;
	const [isActive, setIsActive] = useState(active);

	const handleSwitchClick = () => {
		setIsActive(!isActive);
		onChange(storeId, !isActive);
	};

	return (
		<div className={classes.root}>
			<div className={clsx(classes.address, { [classes.address_disabled]: !active })}>
				<div className={classes.address_ico}> <Logo /></div>
				<div className={classes.address_id}>{storeId}</div>
				<div className={clsx(classes.address_label)}>{street}</div>
				<div className={classes.address_switch}>
					<SwitchComponent isActive={active} onChange={handleSwitchClick} />
				</div>
			</div>
		</div>
	);
};

export default AddressOption;
