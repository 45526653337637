import i18n from 'i18n';

import { IRule } from './types';

export class NumberValidator implements IRule {

	passes(value: number): boolean {
		return typeof value === 'number';
	}

	message(): string {
		return i18n.t('validation:number');
	}
}
