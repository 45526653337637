import fetchWithToken from 'helpers/fetchWithToken';

export const prefixImportUrl = process.env.REACT_APP_IMPORT_URL || 'https://contract-db.glovoapp.com/import_menu_stage';

export const importMenu = (storeAddressId: string, isMultiStore: boolean, addressesIdsInStore?: number[]) => {
	const url = isMultiStore
		? `${prefixImportUrl}/${storeAddressId}`
		: `${prefixImportUrl}?storeAddressId=${storeAddressId}`;
	return new Promise((resolve, reject) => {
		fetchWithToken(url, isMultiStore ? {method: 'post', body: JSON.stringify({storeAddressIds: addressesIdsInStore})} : {})
			.then((res) => res.json())
			.then(resolve)
			.catch(reject);
	});
};
