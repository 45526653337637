import { IProductGroup } from 'models/IProduct';

export interface IRule {
	passes(value: any, minMultiPrice?: number): boolean;
	message(): string;
}

export interface IRuleExternalId {
	passes(value: any, minMultiPrice?: number, allProductGroups?: IProductGroup[]): boolean;
	message(): string;
}

export interface IRuleImage {
	passes(value: any, minMultiPrice?: number): Promise<boolean>;
	message(): string[];
}

export interface IRules {
	getRules(): Record<ValidationTypeEnum, IRule[]>;
}

export interface IValidator {
	make(type: ValidationTypeEnum, value: any): string[];
}

export enum ValidationTypeEnum {
	GROUP_TITLE = 'groupTitle',
	OPTION_GROUP_TITLE = 'optionGroupTitle',
	PRODUCT_TITLE = 'productTitle',
	OPTION_TITLE = 'optionTitle',
	PRICE = 'price',
	NUMBER = 'number',
	DESCRIPTION = 'description',
	OPTION_MIN = 'optionMin',
	EXTERNAL_ID = 'externalId'
}
