import LangSwitcher from 'components/LangSwitcher';
import Logout from 'containers/Logout';
import MultiAccounts from 'containers/MultiAccounts';
import SingleAccount from 'containers/SingleAccount';
import clsx from 'helpers/clsx';
import { useClickAwayListener } from 'hooks/useClickAwayListener';
import { useStoreAddress } from 'hooks/useStoreAddress';
import { useUser } from 'hooks/useUser';
import React, { useRef, useState } from 'react';

import { ReactComponent as UserIco } from '../../static/imgs/User.svg';
import classes from './PersonalCabinet.module.scss';

const PersonalCabinet: React.FC = () => {
	const { user } = useUser();
	const userModalRef = useRef(null);
	const { storeAddressesData } = useStoreAddress();
	const [openUserModal, setOpenUserModal] = useState<boolean>(false);

	const onCloseUserModal = () => {
		setOpenUserModal(false);
	};

	useClickAwayListener(userModalRef, onCloseUserModal, 'svg');

	return (
		<>
			{storeAddressesData.length && (
				<>
					<div className={classes.root}>
						{storeAddressesData?.length > 1 ? (
							<MultiAccounts stores={storeAddressesData} />
						) : (
							<SingleAccount {...storeAddressesData[0]} />
						)}
						<LangSwitcher />
						<span onClick={() => setOpenUserModal(!openUserModal)} className={classes.userIco}><UserIco /></span>
						<div
							ref={userModalRef}
							className={clsx(classes.userModal, { [classes.userModal_open]: openUserModal })}
						>
							<div className={classes.userModal_mail} data-testid="test_userEmail">{user?.email}</div>
							<div className={classes.userModal_logout}>
								<Logout className={classes.logoutHead} />
							</div>
						</div>
					</div>
				</>
			)}
		</>
	);
};

export default PersonalCabinet;



