import clsx from 'helpers/clsx';
import React from 'react';
import { useTranslation } from 'react-i18next';
import icon from 'static/imgs/loader.gif';

import classes from './Loader.module.scss';
import { IProps } from './types';

const Loader: React.FC<IProps> = ({ className, simple, showText = false }) => {
	const { t } = useTranslation('menu');
	return (
		<div data-testid="modal-div" className={clsx(classes.root, className, {
			[classes.modal]: !simple,
		})}>
			<img data-testid="modal-img" src={icon} alt="" />
			{showText && <span className={classes.loaderText}>{t('loaderText')}</span>}
		</div>
	);
};

export default Loader;
