
import { createAsyncThunk } from '@reduxjs/toolkit';
import {
	fetchSupCollectionsAvailability as fetchSupCollectionsAvailabilityReq,
	fetchSuperCollections as fetchSuperCollectionsReq} from 'api/superCollections';

export const fetchSuperCollections = createAsyncThunk(
	'collections/fetchSuperCollections',
	async ({storeAddressId, isMultiStore}: {storeAddressId: string, isMultiStore: boolean}, thunkAPI) => {
		try {
			const result = await fetchSuperCollectionsReq(storeAddressId, isMultiStore).catch(() => undefined);
			if (!result || !Array.isArray(result)) throw new Error('Result not found');
			return result;
		} catch (e) {
			return thunkAPI.rejectWithValue('Failure');
		}
	},
);

export const fetchSupCollectionsAvailability = createAsyncThunk(
	'collections/fetchSupCollectionsAvailability',
	async ({storeAddressId, addressesIdsInStore}: {storeAddressId: string, addressesIdsInStore: string}, thunkAPI) => {
		try {
			const result = await fetchSupCollectionsAvailabilityReq(storeAddressId, addressesIdsInStore).catch(() => undefined);
			if (!result || !Array.isArray(result)) throw new Error('Result not found');
			return result;
		} catch (e) {
			return thunkAPI.rejectWithValue('Failure');
		}
	},
);
