import React from 'react';

import classes from './PromptIco.module.scss';

const PromptIco: React.FC = () => {
	return (
		<div className={classes.ringContainer}>
			<div className={classes.ringring}></div>
			<div className={classes.circle}></div>
		</div>
	);
};

export default PromptIco;
