
import { createAsyncThunk } from '@reduxjs/toolkit';
import {
	fetchCollections as fetchCollectionsReq,
	fetchCollectionsAvailability as fetchCollectionsAvailabilityReq,
} from 'api/collections';

export const fetchCollections = createAsyncThunk(
	'collections/fetchCollections',
	async ({storeAddressId, isMultiStore}: {storeAddressId: string, isMultiStore: boolean}, thunkAPI) => {
		try {
			const result = await fetchCollectionsReq(storeAddressId, isMultiStore).catch(() => undefined);
			if (!result || !Array.isArray(result)) throw new Error('Result not found');
			return result;
		} catch (e) {
			return thunkAPI.rejectWithValue('Failure');
		}
	},
);

export const fetchCollectionsAvailability = createAsyncThunk(
	'collections/fetchCollectionsAvailability',
	async ({storeAddressId, addressesIdsInStore}: {storeAddressId: string, addressesIdsInStore: string}, thunkAPI) => {
		try {
			const result = await fetchCollectionsAvailabilityReq(storeAddressId, addressesIdsInStore).catch(() => undefined);
			if (!result || !Array.isArray(result)) throw new Error('Result not found');
			return result;
		} catch (e) {
			return thunkAPI.rejectWithValue('Failure');
		}
	},
);
