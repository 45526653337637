import clsx from 'helpers/clsx';
import { useCollections } from 'hooks/useCollections';
import { useProducts } from 'hooks/useProducts';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { ReactComponent as Attention } from 'static/imgs/attention.svg';
import { ReactComponent as ArrowBottom } from 'static/imgs/keyboard_arrow_down.svg';

import classes from '../PopupHandleCollections.module.scss';
import { ITempProps } from '../types';

const TemplateOff: React.FC<ITempProps> = ({ onClose }) => {
	const { offCollection, setShowCollectionsState, clearCollections } = useCollections();
	const { fetchProducts } = useProducts();
	const { t } = useTranslation('collections');

	const collectionsOff = () => {
		setShowCollectionsState(false);
		clearCollections();
		offCollection();
		onClose();
		fetchProducts();
	};

	return (
		<div className={classes.root}>
			<div className={classes.ico}><Attention/></div>
			<div className={classes.title}>{t('off.title')}</div>
			<div className={classes.text}>
				<Trans t={t} i18nKey="off.text" />
			</div>
			<div className={classes.structureWrap}>
				<div className={classes.levelsWrap}>
					<div className={classes.structureTitle}>{t('currentStructure')}</div>
					<div className={clsx(classes.structurePreview, classes.structurePreviewCollections)}>
						<div className={classes.arr}><ArrowBottom /></div>
						<span>{t('structure.collections')}</span>
					</div>
					<div className={classes.twoStructWrap}>
						<div className={classes.structurePreview}>
							<div className={classes.arr}><ArrowBottom /></div>
							<span>{t('structure.sections')}</span>
						</div>
					</div>
					<div className={classes.threeStructWrap}>
						<div className={classes.structurePreview}><span>{t('structure.products')}</span></div>
					</div>
				</div>
				<span className={classes.separator}><ArrowBottom /></span>
				<div className={clsx(classes.levelsWrap, classes.nextLevelsWrap)}>
					<div className={classes.structureTitle}>{t('twoLevelStructure')}</div>
					<div className={classes.twoStructWrap}>
						<div className={classes.structurePreview}>
							<div className={classes.arr}><ArrowBottom /></div>
							<span>{t('structure.sections')}</span>
						</div>
					</div>
					<div className={classes.threeStructWrap}>
						<div className={classes.structurePreview}><span>{t('structure.products')}</span></div>
					</div>
				</div>
			</div>
			<div className={classes.buttonsWrap}>
				<button onClick={onClose}>{t('cancel')}</button>
				<button className={classes.changeStructureView} onClick={collectionsOff}>{t('off.save')}</button>
			</div>
		</div>
	);
};

export default TemplateOff;
