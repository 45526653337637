import Modal from 'components/Modal';
import { useCollections } from 'hooks/useCollections';
import React from 'react';

import TemplateOff from './templates/off.template';
import TemplateOn from './templates/on.template';
import { IProps } from './types';

const PopupHandleCollections: React.FC<IProps> = ({open, onClose}) => {
	const { showCollections } = useCollections();

	return (
		<Modal open={open} onClose={onClose}>
			{showCollections ? <TemplateOff onClose={onClose} /> : <TemplateOn onClose={onClose} />}
		</Modal>
	);
};

export default PopupHandleCollections;
