import { ApolloClient,  createHttpLink,  InMemoryCache, split } from '@apollo/client';
import fetchWithToken from 'helpers/fetchWithToken';
import { getCountryFromToken } from 'helpers/jwt';

import typeDefs from './schema';
const url = process.env.REACT_APP_API_FIN_CAB || 'https://fin-prelive-1.eca-tech.glovoint.com';

export const httpLinkAuth = createHttpLink({
	uri: `${url}/api-auth/graphql`,
	fetch: fetchWithToken,
});

export const httpLinkCommon = createHttpLink({
	uri: () => `${url}/${getCountryFromToken()?.toLocaleLowerCase() || ''}/api/graphql`,
	fetch: fetchWithToken,
});

export const httpLink = split(
	(operation) => operation.getContext().clientName === 'auth',
	httpLinkAuth,
	httpLinkCommon,
);

export const clientGraphqlCache = new InMemoryCache();

export const clientGraphql = new ApolloClient({
	link: httpLink,
	cache: clientGraphqlCache,
	typeDefs,
});
