/* eslint-disable react-hooks/exhaustive-deps */
import 'react-toastify/dist/ReactToastify.css';

import Loader from 'components/Loader';
import { RoutesEnum } from 'const';
import FetchCoreData from 'containers/FetchCoreData';
import { getCountryFromToken, getUserEmail } from 'helpers/jwt';
import storage from 'helpers/localStorage';
import { hasRemember } from 'helpers/remember';
import { hasRefreshToken, hasToken, refreshToken } from 'helpers/token';
import RequireAuth from 'hoc/requireAuth';
import { useAppSelector } from 'hooks/redux';
import { useAuth } from 'hooks/useAuth';
import { useCollections } from 'hooks/useCollections';
import { useMultiLang } from 'hooks/useMultiLang';
import { useStoreAddress } from 'hooks/useStoreAddress';
import { useSuperCollections } from 'hooks/useSuperCollections';
import { useUser } from 'hooks/useUser';
import Home from 'pages/Home';
import Login from 'pages/Login';
import NotFound from 'pages/NotFound';
import Options from 'pages/Options';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';


const App: React.FC = () => {
	const { setUser, setUserCountry } = useUser();
	const { handleToken } = useAuth();
	const { setAllStoreAddressesIds } = useStoreAddress();
	const [loading, setLoading] = useState<boolean>(true);
	const { translationProcess } = useMultiLang();
	const { loadingCollections } = useAppSelector(state => state.collections);
	const { currentStoreId } = useStoreAddress();
	const { setShowCollectionsState } = useCollections();
	const { showSuperCollections, superCollections } = useSuperCollections();
	const { collections } = useAppSelector(state => state.collections);

	useEffect(() => {
		if (hasToken()) {
			const email = getUserEmail() || '';
			const userCountry = getCountryFromToken() || '';
			setAllStoreAddressesIds();
			setUserCountry(userCountry);
			setUser(email);
			setLoading(false);
		} else if (hasRemember() && hasRefreshToken()) {
			refreshToken().then(({ jwt }) => {
				handleToken(jwt);
			}).finally(() => {
				setLoading(false);
			});
		} else {
			storage.removeItem('storeId');
			setLoading(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (!loadingCollections) {
			if (showSuperCollections || collections.length) {
				setShowCollectionsState(true);
			} else {
				setShowCollectionsState(false);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [loadingCollections, collections, currentStoreId, superCollections]);

	if (loading || translationProcess) return <Loader showText={translationProcess} />;

	return (
		<div className="App">
			<FetchCoreData />
			<ToastContainer hideProgressBar autoClose={2000} limit={3} />
			<Routes>
				<Route path={RoutesEnum.LOGIN} element={<Login />} />
				<Route path={RoutesEnum.PRODUCTS} element={
					<RequireAuth>
						<Home />
					</RequireAuth>
				} />
				<Route path={RoutesEnum.OPTIONS} element={
					<RequireAuth>
						<Options />
					</RequireAuth>
				} />
				<Route element={<NotFound />} />
			</Routes>
		</div>
	);
};

export default App;
