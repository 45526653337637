import MenuLanguagesItem from 'containers/MenuLanguagesItem';
import { useMultiLang } from 'hooks/useMultiLang';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { ReactComponent as InfoIcon } from 'static/imgs/info.svg';

import classes from './AreYouSureSetDefaultLang.module.scss';
import { IProps } from './types';

const AreYouSureSetDefaultLang: React.FC<IProps> = ({defaultLangState, userIsNotSure, onClose}) => {
	const { setDefaultLang } = useMultiLang();
	const { t } = useTranslation('multiLanguages');

	const saveDefaultLang = () => {
		if (defaultLangState) setDefaultLang(defaultLangState.shortLang);
		onClose();
	};

	return (
		<>
			<div className={classes.defaultLangWrap}>
				<span className={classes.title}>{t('defaultLanguage')}</span>
				<div className={classes.renderLangWrap}>
					<MenuLanguagesItem lang={defaultLangState.lang} shortLang={defaultLangState.shortLang} />
				</div>
			</div>
			<div className={classes.warningText}>
				<span className={classes.infoIco}><InfoIcon/></span>
				<Trans t={t} i18nKey="setDefaultLangWarning2" />
			</div>
			<div className={classes.buttons}>
				<span onClick={userIsNotSure} >{t('back')}</span>
				<span className={classes.yes} onClick={saveDefaultLang}>{t('save')}</span>
			</div>
		</>
	);
};

export default AreYouSureSetDefaultLang;
