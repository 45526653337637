import React, { useContext } from 'react';

import { IOptionsContext } from './types';

export const OptionsContext: any = React.createContext({});
export const useOptionsContext = () => useContext<IOptionsContext>(OptionsContext);

const Provider: React.FC<IOptionsContext> = ({
	children, updateOption, updateOptionGroup,
	createEmptyOption, removeNewOption, isModal, updateTranslations }) => {
	return (
		<OptionsContext.Provider value={{
			isModal,
			updateOption,
			updateOptionGroup,
			createEmptyOption,
			removeNewOption,
			updateTranslations,
		}}>
			{children}
		</OptionsContext.Provider>
	);
};

export default Provider;
