import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import resourcesToBackend from 'i18next-resources-to-backend';
import { initReactI18next } from 'react-i18next';
import langDetector from 'services/LangDetector';

const ns = [
	'collections', 'errors', 'form', 'hintTexts', 'login',
	'menu', 'options', 'products', 'requirements', 'sections',
	'success', 'validation', 'multiStore', 'accounts', 'feedback',
];

const languageDetector = new LanguageDetector();
languageDetector.addDetector(langDetector);

i18n
	.use(languageDetector)
	.use(initReactI18next)
	.use(resourcesToBackend((language: string, namespace: string, callback: any) => {
		import(`./locales/${language}/${namespace}.json`)
			.then((resources) => {
				callback(null, resources);
			})
			.catch((error) => {
				callback(error, null);
			});
	}))
	.init({
		fallbackLng: 'uk',
		ns,
		detection: {
			order: ['langDetector'],
		},
		interpolation: {
			escapeValue: false,
		},
	});

export default i18n;
