import MenuLanguagesItem from 'containers/MenuLanguagesItem';
import clsx from 'helpers/clsx';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as CloseIco } from 'static/imgs/x.svg';

import classes from './MenuManagementItem.module.scss';
import { IProps } from './types';

const MenuManagementItem: React.FC<IProps> = ({isDefault = false, lang, shortLang, handleClickRemove}) => {
	const { t } = useTranslation('multiLanguages');

	const onClick = () => {
		if (handleClickRemove) handleClickRemove(shortLang);
	};

	return (
		<div className={clsx(classes.root, {[classes.isDefault]: isDefault})}>
			<MenuLanguagesItem insert={isDefault} lang={lang} shortLang={shortLang} />
			{isDefault
				? <span className={classes.default} data-testid="test_MenuManagItemIsDefault" >{t('isDefault')}</span>
				: <span className={classes.closeIco}  data-testid="test_MenuManagItemCloseIco" onClick={onClick}><CloseIco/></span>}
		</div>
	);
};

export default MenuManagementItem;
