import { getAllStoreAddressesIds } from 'helpers/jwt';
import storage from 'helpers/localStorage';
import { IStoresData } from 'models/IStoreAddresses';
import { RootState } from 'store';
import { storeAddressSlice } from 'store/reducers/storeAddress';
import { fetchStoreAddressesData, fetchStoreAddressSendingCountData } from 'store/reducers/storeAddress/actions';

import { useAppDispatch, useAppSelector } from './redux';

type StoreAddressType = RootState['storeAddress'];

interface IUseStoreAddress extends StoreAddressType {
	setCurrentStoreId(id: string): void;
	setDefaultCurrentStoreId(storeId: number): void;
	clearStoreAddressId(): void;
	fetchStoreAddresses(): void;
	setAllStoreAddressesIds(): void;
	isMultiStore: boolean;
	currentStoreData: IStoresData | null;
	fetchStoreAddressSendingCount(): void;
	setDisableSendButton(value: boolean): void;
};

export const useStoreAddress = (): IUseStoreAddress => {
	const { storeAddressesIds, currentStoreId, storeAddressesData,
		isMultiStore, userStoreIds, currentStoreData,
		currentStoreAddressId, addressesIdsInStore, storeAddressSendingCount, disableSendButton,
	} = useAppSelector((state) => state.storeAddress);

	const dispatch = useAppDispatch();

	const setCurrentStoreId = (id: string) => dispatch(storeAddressSlice.actions.setCurrent(id));

	const setDefaultCurrentStoreId = (storeId: number) => {
		const storeIdLocal: string | undefined = storage.getItem('storeId');
		if (!storeIdLocal) {
			storage.setItem('storeId', String(storeId));
			setCurrentStoreId(String(storeId));
		} else {
			setCurrentStoreId(String(storeIdLocal));
		}
	};

	const setAllStoreAddressesIds = () => {
		const storeAddressesAllIds = getAllStoreAddressesIds();
		if (storeAddressesAllIds) dispatch(storeAddressSlice.actions.setAllStoreAddressesIds(storeAddressesAllIds));
	};

	const clearStoreAddressId = () => {
		dispatch(storeAddressSlice.actions.clear());
	};

	const fetchStoreAddresses = () => {
		if (storeAddressesIds.length) dispatch(fetchStoreAddressesData(storeAddressesIds)).then((data: any) => {
			setDefaultCurrentStoreId(data.payload.data.storeAddressData[0].storeId);
		});
	};

	const fetchStoreAddressSendingCount = () => {
		const storeId = isMultiStore ? currentStoreId : currentStoreAddressId;
		if (storeId) dispatch(fetchStoreAddressSendingCountData({storeAddressId: storeId, isMultiStore}));
	};

	const setDisableSendButton = (value: boolean) => {
		dispatch(storeAddressSlice.actions.setDisableSendButton(value));
	};

	return {
		storeAddressesIds,
		currentStoreId,
		setCurrentStoreId,
		setDefaultCurrentStoreId,
		clearStoreAddressId,
		storeAddressesData,
		fetchStoreAddresses,
		setAllStoreAddressesIds,
		isMultiStore,
		userStoreIds,
		currentStoreData,
		currentStoreAddressId,
		addressesIdsInStore,
		fetchStoreAddressSendingCount,
		storeAddressSendingCount,
		disableSendButton,
		setDisableSendButton,
	};
};
