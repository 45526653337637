import Checkbox from 'components/FormControl/Checkbox';
import InputForEnteringInform from 'components/InputForEnteringInform';
import InputPrice from 'components/InputPrice';
import SortIdentifier from 'components/SortIdentifier';
import SwitchComponent from 'components/SwitchComponent';
import { inputUpdateDelay } from 'const';
import AvailableAddresses from 'containers/AvailableAddresses';
import ChangePricesInAddresses from 'containers/ChangePricesInAddresses';
import ProductImg from 'containers/ProductImg';
import ProductOptions from 'containers/ProductOptions';
import TitlesLangBar from 'containers/TitlesLangBar';
import clsx from 'helpers/clsx';
import { getProductsWithTheSameExternalId } from 'helpers/products';
import { delay } from 'helpers/utils';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { useCollections } from 'hooks/useCollections';
import { ProductIdsEnum, useProducts } from 'hooks/useProducts';
import { useStoreAddress } from 'hooks/useStoreAddress';
import { useSuperCollections } from 'hooks/useSuperCollections';
import { useUser } from 'hooks/useUser';
import { IProduct } from 'models/IProduct';
import { ESectionPrompts } from 'models/IPrompts';
import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as ProductRemove } from 'static/imgs/productRemove.svg';
import { modalsSlice } from 'store/reducers/modals';

import classes from './ProductLine.module.scss';
import { IProps } from './types';

const ProductLine: React.FC<IProps> = ({
	product, group, isActive = true, collectionActive,
	closestSuperCollectionActive, collectionId, superCollectionId,
}) => {
	const { sectionsPrompt } = useAppSelector(state => state.getStarted);
	const { getCollectionById, updateCollection, showCollections, removeValidation, getCollectionByChildSectionId } = useCollections();
	const { getSuperCollectionById, updateSuperCollection, showSuperCollections, removeValidationSup } = useSuperCollections();
	const { setRemoveWithIdPopupState } = modalsSlice.actions;
	const { updateProduct, updateProductGroup, changeSwitcherMultiPriceForProduct, clearProductsValidation } = useProducts();
	const dispatch = useAppDispatch();
	const { t } = useTranslation(['products', 'hintTexts', 'multiStore']);
	const disableSwicher = !isActive || (showCollections && !collectionActive) || (showSuperCollections && !closestSuperCollectionActive);
	const { isMultiStore } = useStoreAddress();
	const isExternalId = useAppSelector(state => state.products.isExternalId);
	const { productGroups } = useAppSelector(state => state.products);
	const { incItemsIsChecked, decItemsIsChecked } = useUser();

	const showRemoveProductPopup = () => {
		dispatch(setRemoveWithIdPopupState({ modal: true, groupId: group.id, itemId: product?.id! }));
	};

	const removeValidAfterUpdateLastExtId = () => {
		const productWithTheSameExtId = getProductsWithTheSameExternalId(productGroups, product.external_id || '');
		if (productWithTheSameExtId.length === 2) { // last 2 products with the same external ID
			clearProductsValidation();
			removeValidation();
			removeValidationSup();
		}
	};

	const updateItemsErrorsCountForItems = (key: keyof IProduct) => {
		if (collectionId) {
			const collection = getCollectionById(collectionId);

			if (collection?.itemsErrorsCount && product.validation && product.validation[key].length > 0) {
				updateCollection(collectionId, 'itemsErrorsCount', collection?.itemsErrorsCount - 1);
			}
		}

		if (superCollectionId) {
			const superCollection = getSuperCollectionById(superCollectionId);

			if (superCollection?.itemsErrorsCount && product.validation && product.validation[key].length > 0) {
				updateSuperCollection(superCollectionId, 'itemsErrorsCount', superCollection?.itemsErrorsCount - 1);
			}
		}

		if (group?.itemsErrorsCount && product.validation && product.validation[key].length > 0) {
			updateProductGroup(group.id, 'itemsErrorsCount', group?.itemsErrorsCount - 1);
		}
	};

	const handleUpdateProduct = (key: keyof IProduct) => (value: any) => {
		updateProduct(group.id, product.id, key, value);

		updateItemsErrorsCountForItems(key);

		if (key === 'active') {
			const allItemsActive = group.items.find((item) => {
				if (item.id === product.id) return value;
				return item.active;
			});

			updateProductGroup(group.id, 'active', !!allItemsActive);
		}

		if (key === 'external_id' && !!product.external_id?.length) {
			removeValidAfterUpdateLastExtId();
		}
	};

	const promptInfoProductName = {
		title: t('hintTexts:productName.title'),
		text: t('hintTexts:productName.text'),
	};

	const changeMultiSwicher = () => {
		delay('changeMultiSwicher', () => {
			changeSwitcherMultiPriceForProduct(group.id, product);
			updateItemsErrorsCountForItems('price');
		}, inputUpdateDelay);
	};

	const setCheckParentForProducts = () => {
		const newProducts = group.items.map((item) => {
			return {
				...item,
				parentIsChecked: false,
			};
		});
		updateProductGroup(group.id, 'items', newProducts);
	};

	const resetCheckParents = () => {
		setCheckParentForProducts();
		updateProductGroup(group.id, 'checked', false);
		decItemsIsChecked('section');

		const parentCollection = getCollectionByChildSectionId(group.id);

		if (parentCollection && parentCollection.checked) {
			updateCollection(parentCollection.id, 'checked', false);
			decItemsIsChecked('collection');
		}

	};

	const handleChangeChecked = () => {
		const nextValue = !product.checked;
		if (nextValue) {
			incItemsIsChecked('product');
		} else {
			decItemsIsChecked('product');
			if (group.checked) resetCheckParents();
		}

		updateProduct(group.id, product.id, 'checked', !product.checked);
	};

	return (
		<div className={clsx(classes.root, ProductIdsEnum.PROUDCT_ITEM, { [classes.disabled]: (!product.active) })} id={product.id}>
			<div className={clsx(classes.checkBoxWrap, {[classes.onlySections]: !showCollections})}>
				<Checkbox isChecked={product.checked} onChange={handleChangeChecked} icoClassName={classes.checkBoxIco} />
			</div>
			<div className={classes.imgWrap}>
				<ProductImg isActive={product.active} product={product} groupId={group.id} />
			</div>
			<div className={classes.productNameWrap}>
				<div className={classes.productChangeWrap}>
					<div className={classes.inputWrap}>
						<InputForEnteringInform
							showExample
							hideLangBar={!product.translations}
							className={classes.productName}
							allowedNumber={70}
							placeholder={t('productName')}
							showPrompt={sectionsPrompt === ESectionPrompts.PRODUCT_NAME}
							promptInfo={promptInfoProductName}
							onChange={handleUpdateProduct('title')}
							value={product.title}
							errors={product.validation?.title}
							isActive={product.active}
							setNextPromptSection={ESectionPrompts.PRODUCT_INGRADIENT}
							label={t('productNameLabel')}
						/>
						{product.translations && <div className={classes.titlesLangBarWrap}>
							<TitlesLangBar
								parentType="product"
								placeholder={t('productName')}
								translations={product.translations}
								allowedNumber={70}
								parentId={product.id}
								groupId={group.id}
								defaultTitle={product.title}
								handleUpdateDefaultTitle={handleUpdateProduct('title')}
								label={t('productNameLabel')}
								headerTitle={t('productNameLabel')}
							/>
						</div>}
					</div>
				</div>

				<div className={classes.productPriceWrap}>
					{isMultiStore ? (
						<AvailableAddresses
							text={t('multiStore:availability')}
							parentItem={product}
							productGroupId={group.id}
							itemSelector="product"
							addressPopupTitle={t('multiStore:addressPopupTitleProduct')}
							addressPopupSec={t('multiStore:addressPopupProduct')}
						/>
					) : (
						<SwitchComponent
							isActive={product.active}
							onChange={handleUpdateProduct('active')}
							className={clsx(classes.toggleActive, { [classes.toggleDis]: disableSwicher })}
						/>
					)}
					{product.price === null ? (
						<ChangePricesInAddresses
							product={product}
							group={group}
							errors={product.validation?.price}
							label={t('multiStore:price')}
							updateItemsErrorsCountForItems={updateItemsErrorsCountForItems}
						/>
					) : (
						<InputPrice
							showPrompt={sectionsPrompt === ESectionPrompts.PRODUCT_PRICE}
							promptInfo={promptInfoProductName}
							value={product.price}
							onChange={handleUpdateProduct('price')}
							errors={product.validation?.price}
							setNextPromptSection={ESectionPrompts.ADD_PRODUCT}
							label={t('multiStore:price')}
							rootClassName={isMultiStore ? classes.priceMulti : ''}
						/>
					)}
				</div>
			</div>
			<div className={classes.productInfo}>
				<div className={classes.productIngradient}>
					{isExternalId && <div className={classes.externalIdWrap}>
						<InputForEnteringInform
							hideLangBar
							className={classes.descriptionInput}
							allowedNumber={20}
							placeholder={'External Id'}
							onChange={handleUpdateProduct('external_id')}
							value={product.external_id || ''}
							errors={product.validation?.external_id}
							label={'External Id'}
						/>
					</div>}
					<div className={classes.inputWrap}>
						<InputForEnteringInform
							className={classes.descriptionInput}
							hideLangBar={!product.translations}
							allowedNumber={250}
							placeholder={t('productDescription')}
							showPrompt={sectionsPrompt === ESectionPrompts.PRODUCT_INGRADIENT}
							promptInfo={{
								title: t('hintTexts:productIngradient.title'),
								text: t('hintTexts:productIngradient.text'),
							}}
							onChange={handleUpdateProduct('description')}
							value={product.description}
							errors={product.validation?.description}
							setNextPromptSection={ESectionPrompts.PRODUCT_PRICE}
							label={t('productDescriptionLabel')}
						/>
						{product.translations && <div className={classes.titlesLangBarWrap}>
							<TitlesLangBar
								isDescription
								placeholder={t('productDescription')}
								parentType="product"
								translations={product.translations}
								allowedNumber={250}
								parentId={product.id}
								groupId={group.id}
								defaultTitle={product.description}
								handleUpdateDefaultTitle={handleUpdateProduct('description')}
								label={t('productDescriptionLabel')}
								headerTitle={t('productDescriptionLabel')}
							/>
						</div>}
					</div>
					<span className={classes.sortIdentifierWrap}>
						<SortIdentifier />
					</span>
				</div>
				<div className={classes.productOptionsWrap}>
					{isMultiStore &&
						<div className={classes.differentPrice}>
							<SwitchComponent
								isActive={product.price === null}
								onChange={changeMultiSwicher}
							/>
							{t('multiStore:differentPrice')}
						</div>
					}
					<ProductOptions product={product} groupId={group.id} />
					{group.items.length > 1 && <div className={classes.productRemove} onClick={showRemoveProductPopup}><ProductRemove /></div>}
				</div>
			</div>
		</div>
	);
};

export default memo(ProductLine);
