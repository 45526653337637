import { deleteProduct,
	deleteProductsGroup,
	patchProductGroupsAvailability,
	patchProducts,
	patchProductsAvailability,
	patchProductsMultiPrice,
} from 'api/products';
import { autoCloseLongToast, EProductsPatchErr } from 'const';
import equal from 'fast-deep-equal/react';
import {
	changeAvailability,
	changeMultiPrice,
	createAvailability,
	getAvailableBlockByEntityId,
	getItemAvailableStatus,
	getMinInMultiPrices,
	getProductMultiPrice,
	removeAvailability,
	removePropertiesForCheck,
} from 'helpers';
import { getEmptyProduct, getEmptyProductGroup, getTranslations, getTranslationsForProduct } from 'helpers/mock';
import { getGroupById, prepareProducts, removeSpacesAtTheEnd, searchProductsByValue } from 'helpers/products';
import {
	checkChildErrorsForCollections,
	checkChildErrorsForSupCollections,
	checkIsValidEntities,
	checkValidTranslationsGroup,
} from 'helpers/validation';
import { ICollection, ISuperCollection } from 'models/ICollection';
import { IAvailability, IProduct, IProductGroup, IProductMultiPriceBody, ITranslations } from 'models/IProduct';
import { ESectionPrompts } from 'models/IPrompts';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { RootState } from 'store';
import { collectionsSlice } from 'store/reducers/collections';
import { productsSlice } from 'store/reducers/products';
import {
	fetchProductGroupsAvailability as fetchProductGroupsAvailabilityAction,
	fetchProducts as fetchProductsAction,
	fetchProductsAvailability as fetchProductsAvailabilityAction,
	fetchProductsMultiPrices as fetchProductsMultiPricesAction,
	fetchProductsSettings as fetchProductsSettingsAction,
} from 'store/reducers/products/actions';
import { storeAddressSlice } from 'store/reducers/storeAddress';
import { superCollectionsSlice } from 'store/reducers/superCollectionsData';

import { useAppDispatch, useAppSelector } from './redux';
import { useDateSaveDraft } from './useDateSaveDraft';
import usePrompts from './usePrompts';
import { useStoreAddress } from './useStoreAddress';
import useToast from './useToast';

export enum ProductIdsEnum {
	GROUP = 'product-group',
	PROUDCT_ITEM = 'product-item'
};

type ProductsType = RootState['products'];
interface IUseProducts extends Omit<ProductsType, 'productGroups' | 'foundGroups'| 'searchValue'> {
	createEmptyProduct(groupId: IProduct['id'], countProductsInGroup: number, isExternalId?: boolean): void;
	createEmptyGroupProducts(productGroupsLength: number): IProductGroup;
	fetchProducts(): void;
	updateProductGroup(groupId: IProductGroup['id'], key: keyof IProductGroup, value: any): void;
	updateProduct(groupId: IProductGroup['id'], id: IProduct['id'], key: keyof IProduct, value: any): void;
	updatePosition(productGroups: IProductGroup[]): void;
	removeProductGroup(deletedGroup: IProductGroup): void;
	removeProductItem(groupId: IProductGroup['id'], deletedProduct: IProduct): void;
	checkDeletedOptionsGroup(groupId: string): void;
	sendProducts(productGroups: IProductGroup[], sendToGlovo: boolean, collecitons?: ICollection[], superCollections?: ISuperCollection[]): void;
	clearProducts(): void;
	productsPageScrollPosition: number;
	defaultCollapsSect: boolean;
	setProductsPageScrollPosition(): void;
	changeSwitcherMultiPriceForProduct(groupId: IProductGroup['id'], product: IProduct): void;
	updateProductMultiPrice(product: IProduct, prices: IProductMultiPriceBody[], minPrice?: number): void;
	updateProductsAvailability(storeId: string, status: boolean, itemId: IProduct['id'], productGroupId: IProductGroup['id']): void;
	updateProductsGroupAvailability(storeId: string, status: boolean, itemId: IProduct['id']): void;
	incStoreAddressSendingCount(): void;
	getUserChanges(productGroups: IProductGroup[]): boolean;
	clearProductsValidation(): void;
	fetchProductsSettings(): void;
	clearSearchResult(): void;
	handleSearchProducts(searchValue: string, productGroups?: IProductGroup[]): boolean;
	checkValidProducts(productGroups: IProductGroup[], checkTranslations?: boolean): [IProductGroup[], boolean];
	productsAvailForSend: string[];
	addIdInToProductsAvailForSend(id: string): void;
	productGroupsForSendIds: string[];
	updateProductGroups(items: IProductGroup[]): void;
};

export const useProducts = (): IUseProducts => {
	const loadingProducts = useAppSelector((state) => state.products.loadingProducts);
	const isValidProducts = useAppSelector((state) => state.products.isValidProducts);
	const productsPageScrollPosition = useAppSelector((state) => state.products.productsPageScrollPosition);
	const defaultCollapsSect = useAppSelector((state) => state.products.defaultCollapsSect);
	const initialProductGroups = useAppSelector((state) => state.products.initialProductGroups);
	const productsMultiPrices = useAppSelector((state) => state.products.productsMultiPrices);
	const productsAvailability = useAppSelector((state) => state.products.productsAvailability);
	const productGroupsAvailability = useAppSelector((state) => state.products.productGroupsAvailability);
	const { multiLangData } = useAppSelector((state) => state.multiLang);
	const { userCountry } = useAppSelector(state => state.user);
	const isExternalId = useAppSelector(state => state.products.isExternalId);
	const productsAvailForSend = useAppSelector(state => state.products.productsAvailForSend);
	const productGroupsForSendIds = useAppSelector(state => state.products.productGroupsForSendIds);

	const dispatch = useAppDispatch();
	const { currentStoreId, isMultiStore, currentStoreData, currentStoreAddressId, addressesIdsInStore, setDisableSendButton } = useStoreAddress();
	const { errorToast, successToast } = useToast();
	const { togglePromptSection } = usePrompts();
	const { t } = useTranslation(['errors', 'success']);
	const { showCollections } = useAppSelector(state => state.collections);
	const { fetchDateSaveDraft } = useDateSaveDraft();

	const fetchProducts = () => {
		const storeId = isMultiStore ? currentStoreId : currentStoreAddressId;
		if (storeId) {
			dispatch(fetchProductsAction({storeAddressId: storeId, isMultiStore}));
			if (isMultiStore) {
				updateProductsPricesAndAvailability(storeId);
			}
		}
	};

	const updateProductsPricesAndAvailability = (storeId: string) => {
		dispatch(fetchProductsMultiPricesAction({storeAddressId: storeId, addressesIdsInStore: addressesIdsInStore.join(',')}));
		dispatch(fetchProductsAvailabilityAction({storeAddressId: storeId, addressesIdsInStore: addressesIdsInStore.join(',')}));
		dispatch(fetchProductGroupsAvailabilityAction({storeAddressId: storeId, addressesIdsInStore: addressesIdsInStore.join(',')}));
	};

	const createEmptyGroupProducts = (productGroupsLength: number) => {
		const newProductGroupTranslations: ITranslations = getTranslations(multiLangData.languages);
		const newProductTranslations: ITranslations = getTranslationsForProduct(multiLangData.languages);

		const newGroup = getEmptyProductGroup(
			productGroupsLength,
			multiLangData.languages.length ? newProductGroupTranslations : undefined,
			multiLangData.languages.length ? newProductTranslations : undefined,
			isExternalId,
		);

		dispatch(productsSlice.actions.createEmptyGroup(newGroup));
		createAvailabilityForProductGroups(newGroup.id);
		createAvailabilityForProduct(newGroup.items[0].id);
		togglePromptSection(null);
		clearSearchResult();

		return newGroup;
	};

	const createEmptyProduct = (groupId: IProduct['id'], countProductsInGroup: number) => {
		const newTranslations: ITranslations = getTranslationsForProduct(multiLangData.languages);
		const newProduct = getEmptyProduct(countProductsInGroup + 1, multiLangData.languages.length ? newTranslations : undefined, isExternalId);

		dispatch(productsSlice.actions.createEmptyProduct({groupId, newProduct}));
		createAvailabilityForProduct(newProduct.id);
		togglePromptSection(ESectionPrompts.ADD_SECTION);
		clearSearchResult();
	};

	const updateProductGroup = useCallback((groupId: IProductGroup['id'], key: keyof IProductGroup, value: any) => {
		if (key !== 'saveCollapseValue' && key !== 'checked') addIdInToProductGroupsForSendIds(groupId);
		dispatch(productsSlice.actions.updateGroup({ groupId, key, value }));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const updateProduct = (groupId: IProductGroup['id'], id: IProduct['id'], key: keyof IProduct, value: any) => {
		if (key !== 'checked') addIdInToProductGroupsForSendIds(groupId);
		dispatch(productsSlice.actions.updateProduct({ groupId, id, key, value }));
	};

	const reorderSectionsForCollections = (productGroups: IProductGroup[], newResult: IProductGroup[]) => {
		if (!showCollections) return newResult;
		let reorderedGroupsIterator = 0;
		return productGroups.map((group => {
			const isReorderedGroup = newResult.filter((newResultGroup) => newResultGroup.id === group.id);
			if (isReorderedGroup.length) return newResult[reorderedGroupsIterator++];
			return group;
		}));
	};

	const updatePosition = useCallback((productGroups: IProductGroup[]) => {
		setTimeout(() => {
			const groups = document.querySelectorAll(`.${ProductIdsEnum.GROUP}`);
			const newResult: IProductGroup[] = [];
			Array.from(groups).forEach((groupEl, index) => {
				const idGroup = groupEl.getAttribute('id');
				if (!idGroup) return;
				const group = getGroupById(productGroups, idGroup);
				if (!group) return;
				const { items } = group;
				const productElements = groupEl.querySelectorAll(`.${ProductIdsEnum.PROUDCT_ITEM}`);
				const productsIsShown = !!productElements.length;
				const newItems: IProduct[] = [];
				if (productsIsShown) {
					Array.from(productElements).forEach((productEl, indexEl) => {
						const productId = productEl.getAttribute('id');
						if (!productId) return;
						const product = items.find(({ id }) => id === productId);
						if (!product) return;
						newItems.push({ ...product, position: indexEl + 1 });
					});
				}
				newResult.push({
					...group,
					position: index + 1,
					...(productsIsShown && { items: newItems }),
				});
			});
			dispatch(productsSlice.actions.update({ items: reorderSectionsForCollections(productGroups, newResult), changedPosition: true }));
		}, 0);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const removeProductGroup = (deletedGroup: IProductGroup) => {
		dispatch(productsSlice.actions.removeProductGroup({groupId: deletedGroup.id}));
		const storeId = isMultiStore ? currentStoreId : currentStoreAddressId;

		if (storeId && !deletedGroup?.isNew) deleteProductsGroup(storeId, deletedGroup.id, isMultiStore);

		deletedGroup?.items.forEach((product) => {
			dispatch(productsSlice.actions.updateAvailability(removeAvailability(productsAvailability, product.id)));
			dispatch(productsSlice.actions.removeMultiPriceByProductId(product.id));
		});

		dispatch(productsSlice.actions.updateGroupAvailability(removeAvailability(productGroupsAvailability, deletedGroup.id)));
	};

	const removeProductItem = (groupId: IProductGroup['id'], deletedProduct: IProduct) => {
		dispatch(productsSlice.actions.removeProductItem({ groupId, itemId: deletedProduct.id }));
		dispatch(productsSlice.actions.updateAvailability(removeAvailability(productsAvailability, deletedProduct.id)));
		dispatch(productsSlice.actions.removeMultiPriceByProductId(deletedProduct.id));

		const storeId = isMultiStore ? currentStoreId : currentStoreAddressId;
		if (storeId && !deletedProduct?.isNew) deleteProduct(storeId, groupId, deletedProduct.id, isMultiStore);
	};

	const checkDeletedOptionsGroup = (optionId: string) => {
		dispatch(productsSlice.actions.checkDeletedOptionsGroup(optionId));
	};

	const patchOnlyChangeSections = (storeId: string, groups: IProductGroup[], addressesIds: string, sendToGlovo: boolean) => {
		const groupForSend = groups.reduce((acc: IProductGroup[], item) => {
			if (productGroupsForSendIds.includes(item.id)) acc.push(item);
			return acc;
		}, []);

		return patchProducts(storeId, groupForSend, isMultiStore, addressesIds, sendToGlovo);
	};

	const handlePatchProducts = async (storeId: string, productGroups: IProductGroup[], sendToGlovo: boolean) => {
		const newProducts = await patchOnlyChangeSections(storeId, prepareProducts(productGroups), addressesIdsInStore.join(','), sendToGlovo);

		if (newProducts && Array.isArray(newProducts)) {
			showSuccessToast(sendToGlovo);
			if (sendToGlovo) incStoreAddressSendingCount();
			fetchDateSaveDraft();
			dispatch(productsSlice.actions.updateInitialProducts());
			dispatch(productsSlice.actions.update({items: prepareProducts(productGroups), needSort: false, changedPosition: true}));
		}
		else if (!!newProducts.errors.length) {
			showErrorToast(sendToGlovo, newProducts.errors);
		} else showErrorToast(sendToGlovo);
		setDisableSendButton(false);
	};

	const incStoreAddressSendingCount = () => {
		dispatch(storeAddressSlice.actions.incStoreAddressSendingCount());
	};

	const showErrorToast = (sendToGlovo: boolean, productsPatchErr?: string) => {
		if (productsPatchErr === EProductsPatchErr.NOT_INTEGRATED) return errorToast(
			t('products:disableIntegration'),
			{ autoClose: autoCloseLongToast },
		);

		if (productsPatchErr === EProductsPatchErr.NOT_ACTIVATED) return errorToast(
			t('products:disableActivated'),
			{ autoClose: autoCloseLongToast },
		);

		if (sendToGlovo) return errorToast(t('errors:send.products'));
		errorToast(t('errors:saveAsDraft.products'));
	};

	const showSuccessToast = (sendToGlovo: boolean) => {
		if (sendToGlovo) return successToast(t('success:send.products'));
		successToast(t('success:saveAsDraft.products'));
	};

	const checkValidProducts = (productGroups: IProductGroup[], checkTranslations = false): [IProductGroup[], boolean] => {
		const productsWithoutSpaces: IProductGroup[] = removeSpacesAtTheEnd(productGroups);
		const [newSectionsAfterValidation, isValid] = checkIsValidEntities(productsWithoutSpaces, false, false, userCountry);
		if (checkTranslations) {
			const isValidTranslationsGroup = checkValidTranslationsGroup(productGroups);
			return [newSectionsAfterValidation, (isValid && isValidTranslationsGroup)];
		}

		return [newSectionsAfterValidation, isValid];
	};

	const patchOnlyChangeAvailability = (storeId: string, availability: IAvailability[], addressesIds: string) => {
		const availabilityForSend = availability.reduce((acc: IAvailability[], item) => {
			if (productsAvailForSend.includes(item.entity_id)) acc.push(item);
			return acc;
		}, []);
		if (availabilityForSend.length) patchProductsAvailability(storeId, availabilityForSend, addressesIds);
	};

	const sendProducts = (
		productGroups: IProductGroup[],
		sendToGlovo: boolean,
		collections?: ICollection[],
		superCollections?: ISuperCollection[],
	) => {
		const storeId = isMultiStore ? currentStoreId : currentStoreAddressId;


		if (storeId) {
			const [newSectionsAfterValidation, isValid] = checkValidProducts(productGroups);
			dispatch(collectionsSlice.actions.setCollectionsValid(isValid));

			if (collections) {
				const collectionsAfterCheckChildErr = checkChildErrorsForCollections(collections, newSectionsAfterValidation);
				dispatch(collectionsSlice.actions.update({ items: collectionsAfterCheckChildErr }));
				if (superCollections) {
					const supCollectionsAfterCheckChildErr = checkChildErrorsForSupCollections(superCollections, collectionsAfterCheckChildErr);
					dispatch(superCollectionsSlice.actions.update({ items: supCollectionsAfterCheckChildErr }));
				}
			}

			if (!isValid) {
				showErrorToast(sendToGlovo);
				dispatch(productsSlice.actions.update({ items: newSectionsAfterValidation }));
				setDisableSendButton(false);
			} else {
				if (isMultiStore) {
					Promise.all([
						patchProductsMultiPrice(storeId, productsMultiPrices, addressesIdsInStore.join(',')),
						patchOnlyChangeAvailability(storeId, productsAvailability, addressesIdsInStore.join(',')),
						patchProductGroupsAvailability(storeId, productGroupsAvailability, addressesIdsInStore.join(',')),
					  ]).then( () => {
						handlePatchProducts(storeId, removeSpacesAtTheEnd(productGroups), sendToGlovo);
					  });
				} else {
					handlePatchProducts(storeId, removeSpacesAtTheEnd(productGroups), sendToGlovo);
				}
			}
		}
	};

	const clearProducts = () => {
		dispatch(productsSlice.actions.clear());
	};

	const setProductsPageScrollPosition = () => {
		dispatch(productsSlice.actions.setProductsPageScrollPosition(window.scrollY));
	};

	const updateProductMultiPrice = (product: IProduct, prices: IProductMultiPriceBody[], minPrice?: number) => {
		const pricesIncludesZero = product.price === 0 || (product.price === null && minPrice === 0);
		const storeId = isMultiStore ? currentStoreId : currentStoreAddressId;
		if (storeId) {
			const newPrices = changeMultiPrice(productsMultiPrices, product.id, prices, minPrice);

			if (!product.isNew && !pricesIncludesZero && (product.price > 0 || product.price === null )) {
				patchProductsMultiPrice(storeId, newPrices, addressesIdsInStore.join(','));
			}

			dispatch(productsSlice.actions.updateMultiPrices({newPrices}));
		}
	};

	const changeSwitcherMultiPriceForProduct = (groupId: IProductGroup['id'], product: IProduct) => {
		if (product.price !== null) {
			const productNewMultiPrice = currentStoreData?.storeAddresses.map((address): IProductMultiPriceBody => {
				return {
					store_address_id: String(address.storeAddressId),
					price: product.price,
				};
			});

			if (productNewMultiPrice) {
				updateProduct(groupId, product.id, 'price', null);
				updateProductMultiPrice(product, productNewMultiPrice);
			}
		} else {
			const productMultiPrice = getProductMultiPrice(productsMultiPrices, product.id);
			const minPrice = getMinInMultiPrices(productMultiPrice?.prices);
			updateProductMultiPrice(product, [], minPrice);
			updateProduct(groupId, product.id, 'price', minPrice);
			updateProduct(groupId, product.id, 'minMultiPrice', undefined);
		}
	};

	const updateProductsAvailability = (storeId: string, status: boolean, productId: IProduct['id'], productGroupId: IProductGroup['id']) => {
		const newAvailability = changeAvailability(productsAvailability, productId, status, storeId);
		dispatch(productsSlice.actions.updateAvailability(newAvailability));

		const currentAvailableProduct = getAvailableBlockByEntityId(newAvailability, productId);
		const productAvailableStatus = getItemAvailableStatus(currentAvailableProduct);
		updateProduct(productGroupId, productId, 'active', productAvailableStatus);
	};

	const updateProductsGroupAvailability = (storeId: string, status: boolean, productGroupId: IProductGroup['id']) => {
		const newAvailability = changeAvailability(productGroupsAvailability, productGroupId, status, storeId);
		dispatch(productsSlice.actions.updateGroupAvailability(newAvailability));

		const currentAvailableProductGroup = getAvailableBlockByEntityId(newAvailability, productGroupId);
		const productGroupAvailableStatus = getItemAvailableStatus(currentAvailableProductGroup);
		updateProductGroup(productGroupId, 'active', productGroupAvailableStatus);
	};

	const createAvailabilityForProduct = (id: IProduct['id']) => {
		const createAvailabilityItem = createAvailability(currentStoreData);

		if (createAvailabilityItem) {
			addIdInToProductsAvailForSend(id);
			dispatch(productsSlice.actions.updateAvailability([...productsAvailability, {
				entity_id: id,
				availability: createAvailabilityItem,
			}]));
		}
	};

	const createAvailabilityForProductGroups = (id: IProductGroup['id']) => {
		const createAvailabilityItem = createAvailability(currentStoreData);

		if (createAvailabilityItem) {
			dispatch(productsSlice.actions.updateGroupAvailability([...productGroupsAvailability, {
				entity_id: id,
				availability: createAvailabilityItem,
			}]));
		}
	};

	const getUserChanges = (productGroups: IProductGroup[]): boolean => !equal(
		removePropertiesForCheck(productGroups),
		removePropertiesForCheck(initialProductGroups),
	);

	const clearProductsValidation = () => {
		dispatch(productsSlice.actions.clearValidation());
	};

	const fetchProductsSettings = () => {
		const storeId = isMultiStore ? currentStoreId : currentStoreAddressId;
		if (storeId) {
			dispatch(fetchProductsSettingsAction({storeAddressId: storeId, isMultiStore}));
		}
	};

	const handleSearchProducts = (searchValue: string, productGroups?: IProductGroup[]): boolean => {
		dispatch(productsSlice.actions.setSearchValue(searchValue));

		if (!searchValue.length) {
			dispatch(productsSlice.actions.setFoundGroups([]));
			return false;
		}

		const foundGroups = searchProductsByValue(searchValue, productGroups);
		if (foundGroups) {
			dispatch(productsSlice.actions.setFoundGroups(foundGroups));
		};
		return !!foundGroups?.length;
	};

	const clearSearchResult = () => {
		handleSearchProducts('');
	};

	const addIdInToProductsAvailForSend = (id: string) => {
		dispatch(productsSlice.actions.addIdInToProductsAvailForSend(id));
	};

	const addIdInToProductGroupsForSendIds = (id: string) => {
		dispatch(productsSlice.actions.addIdInToProductGroupsForSendIds(id));
	};

	const updateProductGroups = (items: IProductGroup[]) => {
		dispatch(productsSlice.actions.update({ items }));
	};

	return {
		initialProductGroups,
		loadingProducts,
		isValidProducts,
		createEmptyProduct,
		createEmptyGroupProducts,
		fetchProducts,
		updateProductGroup,
		updateProduct,
		updatePosition,
		removeProductGroup,
		removeProductItem,
		sendProducts,
		checkDeletedOptionsGroup,
		clearProducts,
		productsPageScrollPosition,
		defaultCollapsSect,
		setProductsPageScrollPosition,
		productsMultiPrices,
		productsAvailability,
		productGroupsAvailability,
		changeSwitcherMultiPriceForProduct,
		updateProductMultiPrice,
		updateProductsAvailability,
		updateProductsGroupAvailability,
		incStoreAddressSendingCount,
		getUserChanges,
		isExternalId,
		clearProductsValidation,
		fetchProductsSettings,
		handleSearchProducts,
		clearSearchResult,
		checkValidProducts,
		productsAvailForSend,
		addIdInToProductsAvailForSend,
		productGroupsForSendIds,
		updateProductGroups,
	};
};
