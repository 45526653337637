import MenuLanguagesItem from 'containers/MenuLanguagesItem';
import { getLangDataByShortLang } from 'helpers';
import { useMultiLang } from 'hooks/useMultiLang';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as ManageLangIco } from 'static/imgs/manageLangIco.svg';

import classes from './MenuLanguagesList.module.scss';
import { IProps } from './types';

const MenuLanguagesList: React.FC<IProps> = ({ onClose, handleOpenLangPopup }) => {
	const { t } = useTranslation('multiLanguages');
	const { multiLangData } = useMultiLang();
	const defaultLangData = useMemo(() => getLangDataByShortLang(multiLangData.defaultLanguage), [multiLangData]);

	return (
		<>
			<div className={classes.overlay} onClick={onClose} />
			<div className={classes.wrap}>
				{defaultLangData && (
					<div className={classes.menuLanguagesItem}>
						<MenuLanguagesItem active lang={defaultLangData.lang} shortLang={defaultLangData.shortLang} />
					</div>
				)}
				{multiLangData.languages.map((shortLang) => {
					const langData = getLangDataByShortLang(shortLang);
					if (!langData) return null;
					return (
						<div className={classes.menuLanguagesItem} key={shortLang}>
							<MenuLanguagesItem lang={langData.lang} shortLang={langData.shortLang} />
						</div>
					);
				})}
				<div className={classes.manageLanguagesButton} data-testid="test_manageLanguagesButton" onClick={handleOpenLangPopup}>
					<span><ManageLangIco/></span>{t('manageLanguages')}
				</div>
			</div>
		</>
	);
};

export default MenuLanguagesList;
