import { scrollToNewItem } from 'helpers/scroll';
import { useMultiLang } from 'hooks/useMultiLang';
import { SuperCollectionsIdsEnum, useSuperCollections } from 'hooks/useSuperCollections';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as Plus } from 'static/imgs/plus.svg';

import classes from './AddSuperCollection.module.scss';
import { IProps } from './types';

const AddSuperCollection: React.FC<IProps> = ({productGroupsCount}) => {
	const { t } = useTranslation('collections');
	const { createEmptySuperCollection } = useSuperCollections();
	const { multiLangData } = useMultiLang();

	const handleClick = () => {
		createEmptySuperCollection(productGroupsCount);
		setTimeout(() => scrollToNewItem(`.${SuperCollectionsIdsEnum.SUPER_COLLECTION}`), 0);
	};
	if (multiLangData.defaultLanguage !== null) return null;
	return (
		<div className={classes.button} data-testid="test_addSuperCollection" onClick={handleClick}>
			<span><Plus /></span>{t('addSuperCollection')}
		</div>
	);
};

export default AddSuperCollection;
