import i18n from 'i18n';

import { IRule } from './types';

export class OptionMin implements IRule {

	passes(value: {min: number, max: number}): boolean {
		if (value.min === undefined || value.max === undefined) return true;
		return value.min <= value.max;
	}

	message(): string {
		return i18n.t('validation:optionMin');
	}
}
