import { getCollectionById } from 'helpers';
import { ICollection, ISuperCollection } from 'models/ICollection';
import { IProductGroup, ITranslations } from 'models/IProduct';
import { IValidationGroup, IValidationItem } from 'models/IValidation';
import { ValidationTypeEnum } from 'services/Validator/types';
import { Validator } from 'services/Validator/Validator';

import { getGroupById } from './products';

const REGEXP_PASSWORD = /^(?=.*[A-Za-z@$!%*#?&])(?=.*\d)[A-Za-z\d@$!%*#?&]{6,}$/img;

// eslint-disable-next-line max-len
const REGEXP_EMAIL = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/img;

export const isValidPassword = (password: string): boolean => {
	REGEXP_PASSWORD.lastIndex = 0;
	return REGEXP_PASSWORD.test(password);
};

export const isValidEmail = (email: string): boolean => {
	REGEXP_EMAIL.lastIndex = 0;
	return REGEXP_EMAIL.test(email);
};

interface IEntity<T = {}> {
	items?: T[];
	title: string;
	validation?: IValidationGroup;
	itemsErrorsCount?: number;
	min?: number;
	max?: number;
	translations?: ITranslations;
}

interface IEntityChild {
	validation?: IValidationItem;
	title: string;
	price: number;
	description?: string;
	minMultiPrice?: number
	external_id?: string | null;
	translations?: ITranslations;
}

export const checkIsValidEntities = <C extends IEntityChild, T extends IEntity<C>>(entities: T[], allowZero = false, option = false, currentCountry?: string): [T[], boolean] => {
	const validator = new Validator();
	const newEntities = entities.map((enity) => {
		let itemsErrorsCount = 0;

		const itemsValidation = enity.items?.map((item) => {
			const titleValidValue = validator.make(option ? ValidationTypeEnum.OPTION_TITLE : ValidationTypeEnum.PRODUCT_TITLE, item.title);
			const priceValidValue = validator.make(allowZero ? ValidationTypeEnum.NUMBER : ValidationTypeEnum.PRICE, item.price, item.minMultiPrice);
			const descriptionValidValue = validator.make(ValidationTypeEnum.DESCRIPTION, item.description);
			const externalIdValidValue = validator.make(ValidationTypeEnum.EXTERNAL_ID, item.external_id, undefined, entities);

			itemsErrorsCount +=( titleValidValue.length + priceValidValue.length + descriptionValidValue.length);
			if (!option) itemsErrorsCount += externalIdValidValue.length;

			return ({
				...item,
				validation: {
					title: titleValidValue,
					price: priceValidValue,
					description: descriptionValidValue,
					...(!option && {external_id: externalIdValidValue}),
				},
			});
		});

		return ({
			...enity,
			itemsErrorsCount,
			validation: {
				title: validator.make(
					option ? ValidationTypeEnum.OPTION_GROUP_TITLE : ValidationTypeEnum.GROUP_TITLE,
					enity.title,
					undefined,
					undefined,
					currentCountry,
				),
				...(option && {min: validator.make(ValidationTypeEnum.OPTION_MIN, {min: enity.min, max: enity.max})}),
			},
			items: itemsValidation,
		});
	});
	const isValid = !newEntities.some((enity) => {
		if (!enity.validation) return false;
		const hasNotValidGroup = Object.values(enity.validation).some((value) => value.length);
		if (hasNotValidGroup) return true;
		const hasNotValidItem = enity.items?.some((item) => {
			if (!item.validation) return false;
			return Object.values(item.validation).some((value) => value.length);
		});
		if (hasNotValidItem) return true;
		return false;
	});
	return [newEntities, isValid];
};

export const multiLangTitlesValidation = (multiLangValues: ITranslations, isDescription = false, parentType?: 'product' | 'product_group' | 'option_group' | 'option',  currentCountry?: string): [boolean, ITranslations] => {
	let validationType: ValidationTypeEnum;

	switch (parentType) {
		case 'product': {
			validationType = ValidationTypeEnum.PRODUCT_TITLE;
			break;
		}
		case 'option': {
			validationType = ValidationTypeEnum.OPTION_TITLE;
			break;
		}
		case 'option_group': {
			validationType = ValidationTypeEnum.OPTION_GROUP_TITLE;
			break;
		}
		default: {
			validationType = ValidationTypeEnum.GROUP_TITLE;
		}
	}

	const validator = new Validator();
	let isValid = true;
	const itemsAfterValidation: ITranslations = Object.keys(multiLangValues || {}).reduce((acc: ITranslations, key) => {
		const validation = validator.make(
			validationType,
			isDescription ? multiLangValues[key].description : multiLangValues[key].title,
			undefined,
			undefined,
			currentCountry,
		);

		if (validation.length) {
			isValid = false;
			acc[key] = {
				...multiLangValues[key],
				validation,
			};
		} else {
			acc[key] = {
				...multiLangValues[key],
			};
		}
		return acc;
	}, {});

	return [isValid, itemsAfterValidation];
};

export const checkmultiLangTitlesIsUnique = (multiLangValues: ITranslations, collections: ICollection[], currentCollectionId: ICollection['id'], currentCollectionName: string, errText: string[]) => {
	let isUnique = true;

	const collectionsTitles = collections.reduce((acc: string[], collection) => {
		if (collection.id === currentCollectionId) {
			acc.push(currentCollectionName);
		} else {
			acc.push(collection.title);
			Object.keys(collection.translations || {}).forEach((key) => {
				acc.push(collection.translations[key].title);
			}, {});
		};
		return acc;
	}, []);

	const itemsAfterUniqueTest: ITranslations = Object.keys(multiLangValues || {}).reduce((acc: ITranslations, key) => {
		const currentCollectionsMultiTitles = Object.keys(multiLangValues || {}).reduce((multiTitles: string[], keyMultiTitles) => {
			if (keyMultiTitles !== key) multiTitles.push(multiLangValues[keyMultiTitles].title);
			return multiTitles;
		}, []);

		const nameIsNotUnique = collectionsTitles.indexOf(multiLangValues[key].title) > -1;
		const nameIsNotUniqueInCurrentCollection = currentCollectionsMultiTitles.indexOf(multiLangValues[key].title) > -1;

		if (multiLangValues[key].title.length > 0 && (nameIsNotUnique || nameIsNotUniqueInCurrentCollection)) {
			isUnique = false;
			acc[key] = {
				...multiLangValues[key],
				validation: errText,
			};
		} else {
			acc[key] = {
				...multiLangValues[key],
			};
		}
		return acc;
	}, {});

	return [isUnique, itemsAfterUniqueTest];
};

export const removeValidationFromLags = (langsValues: ITranslations) => Object.keys(langsValues || {}).reduce((acc: ITranslations, key) => {
	acc[key] = {
		...langsValues[key],
		validation: undefined,
	};
	return acc;
}, {});

const setChildErr = (childItemBody?: IProductGroup | ICollection, itemsErrorsCount?: number) => {
	let sectionHasErr = false;

	Object.keys(childItemBody?.validation || {}).forEach((key) => {
		if (childItemBody?.validation[key].length > 0) {
			sectionHasErr = true;
		}
	});

	if (itemsErrorsCount !== undefined && childItemBody?.itemsErrorsCount !== undefined) {
		itemsErrorsCount += childItemBody.itemsErrorsCount;
		if (sectionHasErr) itemsErrorsCount++;
	}

	return itemsErrorsCount;
};

export const checkChildErrorsForCollections = (parentItems: ICollection[], childItems: IProductGroup[]): ICollection[] => parentItems.map((item) => {
	let itemsErrorsCount = item.itemsErrorsCount;
	item.productGroups.forEach((childs_id) => {
		const sectionInCollection = getGroupById(childItems, childs_id);
		itemsErrorsCount = setChildErr(sectionInCollection, itemsErrorsCount);
	});

	return {
		...item,
		itemsErrorsCount,
	};
});

export const checkChildErrorsForSupCollections = (parentItems: ISuperCollection[], childItems: ICollection[]): ISuperCollection[] => parentItems.map((item) => {
	let itemsErrorsCount = item.itemsErrorsCount;
	item.menuCollections.forEach((childs_id) => {
		const sectionInCollection = getCollectionById(childItems, childs_id);
		itemsErrorsCount = setChildErr(sectionInCollection, itemsErrorsCount);
	});

	return {
		...item,
		itemsErrorsCount,
	};
});

export const checkValidTranslations = (item: IEntity) => {
	let isValid = true;
	const entries = Object.entries(item.translations || {});

	entries.forEach((entry) => {
		const titleTranslationIsEmpty = !!entry.find((translations: any) => {
			return translations.title === '';
		});

		if (titleTranslationIsEmpty) isValid = false;
	});

	return isValid;
};

export const checkValidTranslationsGroup = (items: IEntity[]) => {
	let isValid = true;

	items.forEach((item) => {
		if (!checkValidTranslations(item)) isValid = false;

		item.items?.forEach((childItem) => {
			if (!checkValidTranslations(childItem as IEntity)) isValid = false;
		});
	});

	return isValid;
};


