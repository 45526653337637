import { getProductsWithTheSameExternalId } from 'helpers/products';
import i18n from 'i18n';
import { IProductGroup } from 'models/IProduct';

import { IRuleExternalId } from './types';

export class ExternalId implements IRuleExternalId {
	private passedByValue = true;
	private passedByOnlyOne = true;

	private passesByValue(value: string) {
		return !!value || value === null;
	}

	private passesByOnlyOne(value: string, items?: IProductGroup[]) {
		if (!items) return false;

		return getProductsWithTheSameExternalId(items, value).length <= 1;
	}

	passes(value: any, minMultiPrice?: number, allProductGroups?: []): boolean {
		this.passedByValue = this.passesByValue(value);
		this.passedByOnlyOne = this.passesByOnlyOne(value, allProductGroups);
		return this.passedByValue && this.passedByOnlyOne;
	}

	message(): string {
		if (!this.passedByOnlyOne && this.passedByValue) return i18n.t('validation:isNotUniqueExternal');
		return i18n.t('validation:required');
	}
}
