import { inputUpdateDelay } from 'const';
import { onlyValidNumbers } from 'helpers';
import clsx from 'helpers/clsx';
import { delay } from 'helpers/utils';
import usePrompts from 'hooks/usePrompts';
import React, { useEffect, useState } from 'react';
import { ReactComponent as CloseIco } from 'static/imgs/x.svg';

import classes from './InputComponent.module.scss';
import { IProps } from './types';

const InputComponent: React.FC<IProps> = ({ placeholder, onChange, value: valueProp,
	type, maxNumber, setNextPromptOption,
	showClearButton = false, handleClearSearchQuery,
}) => {
	const [value, setValue] = useState<string | number>(String(valueProp));
	const { togglePromptOption } = usePrompts();

	useEffect(() => {
		setValue(valueProp);
	}, [valueProp]);

	const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
		if (event.charCode === 13 && setNextPromptOption) {
			togglePromptOption(setNextPromptOption);
		}
	};

	const handleBlur = () => {
		if (setNextPromptOption) {
			togglePromptOption(setNextPromptOption);
		};
	};

	const handleClear = () => {
		setValue('');
		if (handleClearSearchQuery) handleClearSearchQuery();
	};

	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		if (type === 'number') {
			const newValue = onlyValidNumbers(e.target.value, String(value));
			const res = typeof maxNumber === 'number' && +newValue > maxNumber ? +maxNumber : newValue;
			setValue(res);
			delay('handleChange', () => {
				onChange(+res);
			}, inputUpdateDelay);
		} else {
			setValue(e.target.value);
			delay('handleChange', () => {
				onChange(e.target.value);
			}, inputUpdateDelay);
		}
	};

	return (
		<>
			<input
				value={value}
				className={classes.input}
				type="text"
				placeholder={placeholder}
				onChange={handleChange}
				onKeyPress={handleKeyPress}
				onBlur={handleBlur}
			/>
			{showClearButton && !!value && <div onClick={handleClear} className={clsx(classes.removeIco, 'js_clear_search')}><CloseIco /></div>}
		</>
	);
};

export default InputComponent;
