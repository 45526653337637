import { ILangItemData } from 'models/IMultiLang';

export const breakPoints = {
	MOBILE: 768,
	TABLET: 1024,
};

export const APP_PREFIX = 'GLOVO.MENU.APP.';
export const APP_PREFIX_COMMON = 'GLOVO.APP.';

const firstStage = 'https://contract-db.glovoapp.com/menutool-backend/api';
// const secondStage = 'https://contract-db.glovoapp.com/menutool-backend-stage/api';

export const prefixApiUrl = process.env.REACT_APP_API_URL || firstStage;

export const prefixApiAuthUrl = process.env.REACT_APP_API_AUTH_URL || 'https://fin-prelive-1.eca-tech.glovoint.com';

export const sendFeedbackUrl = 'https://contract-db.glovoapp.com/slack-sender';

export enum RoutesEnum {
	PRODUCTS = '/',
	OPTIONS = '/options/',
	LOGIN = '/login/',
	SWAGGER = '/swagger/',
}

export const mockToken = 'eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJzZXJ2aWNlQGZpbi1jYWIuZ2xvdm9hcHAuY29tIiwiQXZhaWxhYmxlQ291bnRyaWVzIjoiQlksVUEiLCJVc2VyU3RvcmVBZGRyZXNzZXNJZHMiOlszLDIsMV0sIkNvdW50cnkiOiJVQSIsIlJvbGVzIjoiQ1JFREVOVElBTFNfQURNSU4sQURNSU4sUEFSVE5FUixGSU5BTkNFIiwiaWQiOiI1ZGI4MjE3MGM5MGUzYmQ3NDA5MGZjY2VjNTVmM2ZlZiIsImV4cCI6MTY0OTM3ODAwNSwiaWF0IjoxNjQ5MzM0ODA1fQ.b6jXPaZfWuPIBEjsV9rGXO09y_q5lN9XHIBykYs9YsM';

export const PROMPS_SECTION_ARE_DISPLAYED = 'prompts_section_are_displayed';
export const PROMPS_OPTIONS_ARE_DISPLAYED = 'prompts_options_are_displayed';

export const defaultCollapsingNum = 5;

export const fincabUrl = 'https://fin-cab.glovoapp.com';

export const maxSendingCount = 5;

export const languageList: ILangItemData[] = [
	{
		shortLang: 'en',
		lang: 'English',
	},
	{
		shortLang: 'uk',
		lang: 'Ukrainian',
	},
	{
		shortLang: 'ka',
		lang: 'Georgian',
	},
	{
		shortLang: 'hy',
		lang: 'Armenian',
	},
	{
		shortLang: 'ru',
		lang: 'Russian',
	},
];

export enum ECountry {
	Ukrainian = 'UA',
	Georgian = 'GE',
	Armenian = 'AM',
}

export const inputUpdateDelay = 500;

export enum ClientNameEnum {
	AUTH = 'auth'
}

export const contextAuth = {
	clientName: ClientNameEnum.AUTH,
};

export const collectionNeedsOptimizationCount = 100;

export enum EProductsPatchErr {
	NOT_INTEGRATED = 'The store is not integrated',
	NOT_ACTIVATED = 'The store is not activated'
}

export const autoCloseLongToast = 10000;

export enum ERoles {
	MENUTOOL = 'MENUTOOL'
}
