import { excludeIdEntities } from 'helpers';
import { ICollection } from 'models/ICollection';
import { IOptionGroup } from 'models/IOption';
import { IAvailability, IProduct, IProductGroup } from 'models/IProduct';

export const prepareProducts = (productGroups: IProductGroup[]): Omit<IProductGroup, 'isNew'>[] => {
	return excludeIdEntities<IProduct, IProductGroup>(productGroups);
};

export const getPriceInStoreAvail = (productsAvailability: IAvailability[], productId: IProduct['id'], storeId: string) => {
	const productAvailable = productsAvailability.find((item) => item.entity_id === productId);
	return productAvailable?.availability.find((item) => item.store_address_id === storeId);
};

export const getGroupById = (productGroups: IProductGroup[], groupId: IProductGroup['id']) => productGroups.find(({ id }) => id === groupId);

export const getProductById = (productGroups: IProductGroup[], groupId: IProductGroup['id'], productId: IProduct['id']) => {
	const group = getGroupById(productGroups, groupId);
	return group?.items.find(({ id }) => id === productId);
};

export const prepareValueToSearch = (value: string): string => value.toLocaleLowerCase().replace(/\s/g, '');

export const searchProductsByValue = (searchValue: string, productGroups?: IProductGroup[]) => {
	return productGroups?.reduce((accGroup: IProductGroup[], group: IProductGroup) => {
		const filteredOutProducts = group.items.filter((product) => {
			const langsProductValuesForSearch = Object.values(product.translations || {}).map(item => item.title).join('');
			return prepareValueToSearch(product.title + langsProductValuesForSearch).includes(prepareValueToSearch(searchValue));
		});

		if (filteredOutProducts.length) accGroup.push({
			...group,
			items: filteredOutProducts,
		});

		return accGroup;
	}, []);
};

export const checkCollectionHasFoundItems = (collection: ICollection, foundGroups: IProductGroup[]) => {
	let collectionHasFoundGroups = false;
	collection.productGroups.forEach((productGroupId) => {
		collectionHasFoundGroups = !collectionHasFoundGroups ? !!foundGroups.find((item) => item.id === productGroupId) : true;
	});
	return collectionHasFoundGroups;
};

export const getProductsWithTheSameExternalId = (items: IProductGroup[], externalValue: string): IProduct[] => {
	const allProducts = items.reduce((acc: IProduct[], group) => [...acc, ...group.items], []);
	let doubleExternalIds: any = [];

	const values = allProducts.reduce((acc: any, product) => {
		if (externalValue !== null && product.external_id === externalValue) acc.push(product);
		return acc;
	}, []);

	if (values.length) doubleExternalIds = [...values];
	return doubleExternalIds;
};

export const removeSpacesAtTheEnd = (groups: IProductGroup[]) => groups.map((group) => {
	return {
		...group,
		title: group.title.trim(),
		items: group.items.map((item) => {
			return {
				...item,
				title: item.title.trim(),
				description: item.description.trim(),
				external_id: item.external_id ? item.external_id.trim() : item.external_id,
			};
		}),
	};
});

export const removeSpacesAtTheEndOptions = (groups: IOptionGroup[]) => groups.map((group) => {
	return {
		...group,
		title: group.title.trim(),
		items: group.items.map((item) => {
			return {
				...item,
				title: item.title.trim(),
			};
		}),
	};
});

export const calcErrCountInItem = (item: IProduct | IProductGroup | ICollection): number => Object.keys(item.validation || {}).reduce((acc, key) => {
	acc += item.validation[key].length;
	return acc;
}, 0);

export const moveCheckedProductsInToOtherSection = (productGroups: IProductGroup[], finalSectionId: IProductGroup['id']): IProductGroup[] => {
	const checkedProducts: IProduct[] = [];
	const groupsWithoutCheckedProducts = productGroups.map((group: IProductGroup) => {
		return {
			...group,
			items: group.items.reduce((acc: IProduct[], item: IProduct) => {
				item.checked ? checkedProducts.push(item) : acc.push(item);
				return acc;
			}, []),
		};
	});

	return groupsWithoutCheckedProducts.map((group) => {
		if (group.id === finalSectionId) return {
			...group,
			items: [...group.items, ...checkedProducts],
		};
		return group;
	});
};

export const moveCheckedSectionsInToOtherCollection = (collections: ICollection[], productGroups: IProductGroup[], finalCollectionId: ICollection['id']): ICollection[] => {
	const checkedSectionsIds = productGroups.reduce((acc: IProductGroup['id'][], group) => {
		if (group.checked) acc.push(group.id);
		return acc;
	}, []);

	return collections.map((collection) => {
		if (collection.id === finalCollectionId) return {
			...collection,
			productGroups: [...collection.productGroups, ...checkedSectionsIds],
		};

		return {
			...collection,
			productGroups: collection.productGroups.filter((groupId) => !checkedSectionsIds.includes(groupId)),
		};
	});
};
