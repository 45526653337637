import React from 'react';

import { ReactComponent as IIco } from '../../static/imgs/i.svg';
import classes from './Attention.module.scss';
import { IProps } from './types';


const Attention: React.FC<IProps> = ({ text }) => {
	return (
		<div className={classes.attention} data-testid="div">
			<span><IIco data-testid="ico" /></span>{text}
		</div>
	);
};

export default Attention;
