import Checkbox from 'components/FormControl/Checkbox';
import Loader from 'components/Loader';
import UserDontHaveRoleMenu from 'components/UserDontHaveRoleMenu';
import { ERoles, RoutesEnum } from 'const';
import { getRolesFromToken } from 'helpers/jwt';
import storage from 'helpers/localStorage';
import { useUser } from 'hooks/useUser';
import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as Background } from 'static/imgs/back-login.svg';
import { ReactComponent as EyeIco } from 'static/imgs/eye.svg';
import { ReactComponent as Illustration } from 'static/imgs/loginPageIco.svg';
import { ReactComponent as GlovoLogo } from 'static/imgs/loginPageLogo.svg';
import { ReactComponent as EyeIcoVisible } from 'static/imgs/visible_eye.svg';

import { useAuth } from '../../hooks/useAuth';
import classes from './Login.module.scss';

const Login: React.FC = () => {
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [remember, setRemember] = useState<boolean>(true);
	const [inputType, setInputType] = useState('password');
	const { login, loadingLogin } = useAuth();
	const navigate = useNavigate();
	const { user, setItemsIsChecked } = useUser();
	const { t } = useTranslation(['login', 'form']);
	const [userDontHaveRoleMenu, setUserDontHaveRoleMenu] = useState(false);

	const backToInputLogin = () => setUserDontHaveRoleMenu(false);

	const handleLogin = (event: React.ChangeEvent<HTMLInputElement>) => {
		setEmail(event.target.value);
	};

	const handlePassword = (event: React.ChangeEvent<HTMLInputElement>) => {
		setPassword(event.target.value);
	};

	const handleSubmit = (event: React.MouseEvent<HTMLButtonElement>) => {
		event.preventDefault();
		if (email && password) {
			login(email, password, remember);
		}
	};

	const toggleRememer = () => {
		setRemember((state) => !state);
	};

	const handleVisiblePassword = () => {
		setInputType(inputType === 'password' ? 'text' : 'password');
	};

	useEffect(() => {
		if (user) {
			if (getRolesFromToken()?.includes(ERoles.MENUTOOL)) {
				navigate(RoutesEnum.PRODUCTS);
			} else if (!!getRolesFromToken()) {
				setUserDontHaveRoleMenu(true);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [user]);

	useEffect(() => {
		storage.removeItem('storeId');
		setItemsIsChecked(0);

	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div className={classes.wrapper}>
			{loadingLogin && <Loader />}
			<div className={classes.imageSection}>
				<div className={classes.headrLine}>
					<GlovoLogo />
				</div>
				<div className={classes.imageContent}>
					<Illustration />
					<Trans t={t} i18nKey="welcome" components={{
						// eslint-disable-next-line jsx-a11y/heading-has-content
						h1: <h1 />,
					}} />
				</div>
				<Background className={classes.backgraund} />
			</div>
			<form className={classes.loginSection}>
				{userDontHaveRoleMenu
					? <UserDontHaveRoleMenu backToInputLogin={backToInputLogin} />
					: <div className={classes.loginWrapper}>
						<h1>{t('title')}</h1>
						<p><Trans t={t} i18nKey="subtitle" /></p>
						<div className={classes.inputSection}>
							<span>{t('form:email')}</span>
							<input
								className={classes.input}
								placeholder="name@example.com"
								type="email"
								value={email}
								onChange={handleLogin}
							/>
						</div>
						<div className={classes.inputSection}>
							<span>{t('form:password')}</span>
							<input
								className={classes.input}
								placeholder="Password"
								type={inputType}
								value={password}
								onChange={handlePassword}
							/>
							<span onClick={handleVisiblePassword}>
								{inputType === 'password' ? (<EyeIco />) : (<EyeIcoVisible />)}
							</span>
						</div>
						<Checkbox isChecked={remember} onChange={toggleRememer} rootClassName={classes.checkboxSection}>
							{t('form:rememberMe')}
						</Checkbox>
						<button className={classes.btn} type="submit" onClick={handleSubmit}>
							{t('buttonText')}
						</button>
					</div>}


			</form>
		</div>
	);
};

export default Login;
