import IconButton from 'components/IconButton';
import { getLangFromToken } from 'helpers/jwt';
import React from 'react';
import { ReactComponent as InfoIcon } from 'static/imgs/info.svg';

import classes from './PresentationLink.module.scss';

const PresentationLink: React.FC = () => {
	const lang = getLangFromToken();

	if (lang !== 'uk') return <div className={classes.root} />;

	return (
		<IconButton
			elementType="a"
			href={`${process.env.PUBLIC_URL}/presentation/MENU TOOL FOR PARTNERS.pdf?v=1.2`}
			className={classes.root}
			data-testid="presentation-link-placeholder"
		>
			<InfoIcon className={classes.icon} />
		</IconButton>
	);
};

export default PresentationLink;
