import { importMenu } from 'api/import-menu';
import { ERoles } from 'const';
import { getLang, getRolesFromToken } from 'helpers/jwt';
import storage from 'helpers/localStorage';
import { useMultiLang } from 'hooks/useMultiLang';
import { useProducts } from 'hooks/useProducts';
import { useStoreAddress } from 'hooks/useStoreAddress';
import { useUser } from 'hooks/useUser';
import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

const FetchCoreData: React.FC = () => {
	const { currentStoreId, fetchStoreAddresses, currentStoreAddressId,
		isMultiStore, storeAddressesData, addressesIdsInStore,
		fetchStoreAddressSendingCount,
	} = useStoreAddress();
	const { user, fetchLastSyncData } = useUser();
	const { i18n } = useTranslation();
	const { fetchMultiLang, getMultiLangStatus } = useMultiLang();
	const { fetchProductsSettings } = useProducts();

	const fetchData = useCallback(async () => {
		const storeId = isMultiStore ? currentStoreId : currentStoreAddressId;
		if (storeId && storeAddressesData.length) {
			await importMenu(storeId, isMultiStore, addressesIdsInStore).catch(() => {
				if (storeId === 'undefined') { // if we come from fincab
					storage.removeItem('storeId');
					window.location.reload();
				}
			});
			fetchMultiLang();
			fetchStoreAddressSendingCount();
			getMultiLangStatus();
			fetchLastSyncData();
			fetchProductsSettings();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentStoreId, isMultiStore, storeAddressesData]);

	useEffect(() => {
		fetchStoreAddresses();
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [user]);

	useEffect(() => {
		if (user && getRolesFromToken()?.includes(ERoles.MENUTOOL)) fetchData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [user, currentStoreId, isMultiStore, storeAddressesData]);

	useEffect(() => {
		if (i18n.language !== getLang()) {
			i18n.changeLanguage(getLang());
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return null;
};

export default FetchCoreData;
