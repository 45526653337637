import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { changeChildEntity, changeEntity, createEmptyChildEntity } from 'helpers';
import { getEmptyOption, getEmptyOptionGroup } from 'helpers/mock';
import { IOption, IOptionGroup } from 'models/IOption';
import { ITranslations } from 'models/IProduct';

import { fetchOptions } from './actions';
export interface IState {
	optionGroups: IOptionGroup[];
	initialOptionGroups: IOptionGroup[];
	loadingOptions: boolean;
	isValidOptions: boolean;
	optionsPageScrollPosition: number;
}

const initialState: IState = {
	optionGroups: [],
	initialOptionGroups: [],
	loadingOptions: true,
	isValidOptions: true,
	optionsPageScrollPosition: 0,
};

export const optionsSlice = createSlice({
	name: 'options',
	initialState,
	reducers: {
		setOptionsPageScrollPosition(state: IState, action: PayloadAction<number>) {
			state.optionsPageScrollPosition = action.payload;
		},
		createEmptyGroup(state: IState, action: PayloadAction<ITranslations | undefined>) {
			if (!state.optionGroups.length) {
				const initialEmptyGroup = getEmptyOptionGroup(action.payload);
				state.initialOptionGroups.push(initialEmptyGroup);
				state.optionGroups.push(initialEmptyGroup);
				return;
			}
			state.optionGroups.push(getEmptyOptionGroup(action.payload));
		},
		createEmptyOption(state: IState, action: PayloadAction<{groupId: string, translations?: ITranslations}>) {
			const { groupId, translations } = action.payload;
			state.optionGroups = createEmptyChildEntity<IOption, IOptionGroup>(state.optionGroups, groupId, getEmptyOption(translations));
		},
		removeOptionGroup(state: IState, action: PayloadAction<{ groupId: IOptionGroup['id'] }>) {
			state.optionGroups = state.optionGroups.filter((group) => group.id !== action.payload.groupId);
		},
		removeOptionItem(state: IState, action: PayloadAction<{ groupId: IOptionGroup['id'], itemId: IOption['id'] }>) {
			state.optionGroups = state.optionGroups.map((group) => {
				if (group.id === action.payload.groupId) {
					return {
						...group,
						items: [
							...group.items.filter(item => item.id !== action.payload.itemId),
						],
					};
				}
				return group;
			});
		},
		updateGroup(state: IState, action: PayloadAction<{ groupId: IOptionGroup['id'], key: keyof IOptionGroup, value: any }>) {
			const { groupId, key, value } = action.payload;
			state.optionGroups = changeEntity(state.optionGroups, groupId, key, value);
		},
		updateOption(state: IState, action: PayloadAction<{ groupId: IOptionGroup['id'], id: IOption['id'], key: keyof IOption, value: any }>) {
			const { groupId, key, value, id } = action.payload;
			state.optionGroups = changeChildEntity<IOption, IOptionGroup>(state.optionGroups, groupId, id, key, value);
		},
		update(state: IState, action: PayloadAction<IOptionGroup[]>) {
			state.optionGroups = action.payload;
			state.initialOptionGroups = action.payload;
		},
		setIsValid(state: IState, action: PayloadAction<boolean>) {
			state.isValidOptions = action.payload;
		},
		addOptionGroup(state: IState, action: PayloadAction<IOptionGroup>) {
			state.optionGroups.push(action.payload);
		},
		patchOptionGroup(state: IState, action: PayloadAction<IOptionGroup>) {
			state.optionGroups = state.optionGroups.map((group) => {
				if (group.id === action.payload.id) {
					return action.payload;
				}
				return group;
			});
		},
		clear(state: IState) {
			state.optionGroups = [];
			state.loadingOptions = true;
			state.isValidOptions = true;
		},
		clearValidation(state: IState) {
			state.optionGroups = state.optionGroups.map((group: IOptionGroup) => ({
				...group,
				validation: undefined,
				items: group.items.map((item) => ({
					...item,
					validation: undefined,
				})),
			}));
		},
		updateInitialOptions(state: IState) {
			state.initialOptionGroups = state.optionGroups;
		},
	},
	extraReducers: {
		[fetchOptions.fulfilled.type]: (state, action: PayloadAction<IOptionGroup[]>) => {
			state.loadingOptions = false;
			state.optionGroups = action.payload;
			state.initialOptionGroups = action.payload;
		},
		[fetchOptions.pending.type]: (state) => {
			state.loadingOptions = true;
		},
		[fetchOptions.rejected.type]: (state) => {
			state.loadingOptions = false;
		},
	},
});

export default optionsSlice.reducer;
