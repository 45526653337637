import LangDropDown from 'components/LangDropDown';
import MenuLanguagesItem from 'containers/MenuLanguagesItem';
import clsx from 'helpers/clsx';
import { ILangItemData } from 'models/IMultiLang';
import React, { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { ReactComponent as ArrowIco } from 'static/imgs/arrow-image.svg';
import { ReactComponent as Attention } from 'static/imgs/attention.svg';
import { ReactComponent as InfoIcon } from 'static/imgs/info.svg';

import classes from './SelectDefaultLang.module.scss';
import { IProps } from './types';

const SelectDefaultLang: React.FC<IProps> = ({ placeholder, onClose, setAreYouSureData, disableGeneration }) => {
	const [showLangDropDown, setShowLangDropDown] = useState<boolean>(false);
	const [defaultLangState, setDefaultLangState] = useState<ILangItemData | null>(null);
	const { t } = useTranslation(['form', 'multiLanguages']);

	const handleOpen = () => { setShowLangDropDown(true); };
	const handleClose = () => { setShowLangDropDown(false); };

	const handleSetLang = (shortLang: string, lang: string) => {
		setDefaultLangState({shortLang,lang});
		handleClose();
	};

	const saveDefaultLang = () => {
		if (defaultLangState) {
			setAreYouSureData(defaultLangState);
		}
	};

	return(
		<>
			<div className={classes.root}>
				<div className={clsx(
					classes.selectDefaultLangWrap,
					{[classes.disable]: disableGeneration},
				)}
				data-testid="test_selectLang"
				onClick={handleOpen}>
					{
						defaultLangState === null
							? placeholder
							: <MenuLanguagesItem shortLang={defaultLangState.shortLang} lang={defaultLangState.lang} />
					}
					<span className={classes.arr}><ArrowIco /></span>
				</div>
				{showLangDropDown && (
					<div className={classes.langDropDownWrap}>
						<LangDropDown handleClose={handleClose} handleSetLang={handleSetLang} />
					</div>
				)}
			</div>
			{disableGeneration
				? (
					<div className={classes.disableGenerationText}>
						<Attention />
						<span>{t('multiLanguages:disableGeneration')}</span>
					</div>
				)
				: (
					<div className={classes.warningText}>
						<span className={classes.infoIco}><InfoIcon/></span>
						<Trans t={t} i18nKey="multiLanguages:setDefaultLangWarning" />
					</div>
				)
			}
			<div className={classes.buttons}>
				<span data-testid="test_selectLangSave" onClick={onClose}>{t('form:cancel')}</span>
				<span
					className={clsx(classes.save, {[classes.disabled]: (!defaultLangState || disableGeneration)})}
					data-testid="test_selectLangSave"
					onClick={saveDefaultLang}
				>
					{t('form:save')}
				</span>
			</div>
		</>
	);
};

export default SelectDefaultLang;
