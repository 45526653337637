import ErrorValidation from 'components/ErrorValidation';
import InputComponent from 'components/InputComponent';
import PromptIco from 'components/PromptIco';
import PromptView from 'components/PromptView';
import React, { useState } from 'react';

import { ReactComponent as IIco } from '../../static/imgs/i.svg';
import classes from './ScopeInput.module.scss';
import { IProps } from './types';

const ScopeInput: React.FC<IProps> = ({
	placeholder, description, showPrompt = false, title,
	promptInfo, onChange, value, setNextPromptOption, maxNumber, validation,
}) => {
	const [showTooltip, setShowTooltip] = useState<boolean>(true);

	return (
		<ErrorValidation errors={validation} className={classes.errors}>
			<div className={classes.scopeInputWrap}>
				{title && <div className={classes.title}>{title}</div>}
				<div className={classes.scopeInput}>
					{description && <div className={classes.description}>
						<IIco/>
						<div className={classes.descriptionText} dangerouslySetInnerHTML={{__html: description}}/>
					</div>}
					<InputComponent
						value={value || ''}
						placeholder={placeholder}
						type="number"
						maxNumber={maxNumber}
						onChange={onChange}
						setNextPromptOption={setNextPromptOption}
					/>
					{showPrompt && promptInfo && (
						<>
							{showTooltip &&!value && (
								<div data-id="promptWrap">
									<PromptIco />
									<div className={classes.promptWrap}>
										<PromptView
											title={promptInfo.title}
											text={promptInfo.text}
											show={() => setShowTooltip(false)}
										/>
									</div>
								</div>
							)}
						</>
					)}
				</div>
			</div>
		</ErrorValidation>
	);
};

export default ScopeInput;
