import { deleteOption, deleteOptionsGroup, patchOptions } from 'api/options';
import equal from 'fast-deep-equal/react';
import { excludeIdEntities, removePropertiesForCheck } from 'helpers';
import { getTranslations } from 'helpers/mock';
import { removeSpacesAtTheEndOptions } from 'helpers/products';
import { checkIsValidEntities, checkValidTranslationsGroup } from 'helpers/validation';
import { IOption, IOptionGroup } from 'models/IOption';
import { ITranslations } from 'models/IProduct';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { RootState } from 'store';
import { optionsSlice } from 'store/reducers/options';
import { fetchOptions as fetchOptionsAction } from 'store/reducers/options/actions';

import { useAppDispatch, useAppSelector } from './redux';
import { useProducts } from './useProducts';
import { useStoreAddress } from './useStoreAddress';
import useToast from './useToast';

export enum OptionsIdsEnum {
	GROUP = 'Options-group',
	OPTION_ITEM = 'Options-item'
};

type OptionsType = RootState['options'];
interface IUseOptions extends OptionsType {
	activeOptionGroups: IOptionGroup[];
	createEmptyOption(groupId: IOptionGroup['id']): void;
	createEmptyGroupOptions(): void;
	updateOptionGroup(groupId: IOptionGroup['id'], key: keyof IOptionGroup, value: any): void;
	updateOption(groupId: IOptionGroup['id'], id: IOption['id'], key: keyof IOption, value: any): void;
	fetchOptions(): void;
	getOptionGroup(groupId: IOptionGroup['id']): void;
	getActiveOptionGroupsByIds(groupId: IOptionGroup['id'][]): IOptionGroup[];
	addOptionGroup(group: IOptionGroup): void;
	patchOptionGroup(group: IOptionGroup): void;
	removeOptionGroup(groupId: IOptionGroup['id']): void;
	removeOptionItem(groupId: IOptionGroup['id'], itemId: IOption['id']): void;
	sendOptions(): void;
	clearOptions(): void;
	optionsPageScrollPosition: number;
	setOptionsPageScrollPosition(): void;
	getUserChanges(): boolean;
	clearOptionsValidation(): void;
	checkValidOptions(optionGroups?: IOptionGroup[], checkTranslations?: boolean): [IOptionGroup[], boolean];
};

export const useOptions = (): IUseOptions => {
	const { optionGroups, initialOptionGroups, loadingOptions, isValidOptions, optionsPageScrollPosition } = useAppSelector((state) => state.options);
	const activeOptionGroups = useMemo(() => optionGroups.filter(({ active, isNew }) => active && !isNew), [optionGroups]);
	const { currentStoreId, isMultiStore, currentStoreAddressId } = useStoreAddress();
	const { checkDeletedOptionsGroup } = useProducts();
	const dispatch = useAppDispatch();
	const { errorToast, successToast } = useToast();
	const { t } = useTranslation(['errors', 'success']);
	const { multiLangData } = useAppSelector((state) => state.multiLang);
	const { userCountry } = useAppSelector(state => state.user);

	const fetchOptions = () => {
		const storeId = isMultiStore ? currentStoreId : currentStoreAddressId;
		if (storeId) dispatch(fetchOptionsAction({storeAddressId: storeId, isMultiStore}));
	};

	const createEmptyGroupOptions = () => {
		const newTranslations: ITranslations = getTranslations(multiLangData.languages);
		return dispatch(optionsSlice.actions.createEmptyGroup(multiLangData.languages.length ? newTranslations : undefined));
	};

	const updateOptionGroup = (groupId: IOptionGroup['id'], key: keyof IOptionGroup, value: any) => {
		dispatch(optionsSlice.actions.updateGroup({ groupId, key, value }));
	};

	const updateOption = (groupId: IOptionGroup['id'], id: IOption['id'], key: keyof IOption, value: any) => {
		dispatch(optionsSlice.actions.updateOption({ groupId, id, key, value }));
	};

	const createEmptyOption = (groupId: IOptionGroup['id']) => {
		const newTranslations: ITranslations = getTranslations(multiLangData.languages);
		dispatch(optionsSlice.actions.createEmptyOption({groupId, translations: multiLangData.languages.length ? newTranslations : undefined}));
	};

	const addOptionGroup = (group: IOptionGroup) => {
		const newOptions = [
			...optionGroups,
			group,
		];
		dispatch(optionsSlice.actions.update(newOptions));
		sendOptions(newOptions);
	};

	const patchOptionGroup = (newGroup: IOptionGroup) => {
		const newOptions = optionGroups.map((group) => {
			if (group.id === newGroup.id) {
				return newGroup;
			}
			return group;
		});
		dispatch(optionsSlice.actions.update(newOptions));
		sendOptions(newOptions);
	};

	const removeOptionGroup = (groupId: IOptionGroup['id']) => {
		dispatch(optionsSlice.actions.removeOptionGroup({ groupId: groupId }));
		const storeId = isMultiStore ? currentStoreId : currentStoreAddressId;
		if (storeId) {
			deleteOptionsGroup(storeId, groupId, isMultiStore).then(() => {
				checkDeletedOptionsGroup(groupId);
			});
		}
	};

	const removeOptionItem = (groupId: IOptionGroup['id'], itemId: IOption['id']) => {
		dispatch(optionsSlice.actions.removeOptionItem({ groupId: groupId, itemId: itemId }));
		const storeId = isMultiStore ? currentStoreId : currentStoreAddressId;
		if (storeId) deleteOption(storeId, groupId, itemId, isMultiStore);
	};

	const getOptionGroup = (groupId: IOptionGroup['id']) => optionGroups.find(({ id }) => groupId === id);

	const getActiveOptionGroupsByIds = (groupId: IOptionGroup['id'][]) => activeOptionGroups.filter(({ id }) => groupId.includes(id));

	const checkValidOptions = (groups = optionGroups, checkTranslations = false): [IOptionGroup[], boolean] => {
		const optionsWithoutSpaces: IOptionGroup[] = removeSpacesAtTheEndOptions(groups);
		const [newOptionsAfterValidation, isValid] = checkIsValidEntities(optionsWithoutSpaces, true, true, userCountry);
		if (checkTranslations) {
			const isValidTranslationsGroup = checkValidTranslationsGroup(groups);
			return [newOptionsAfterValidation, (isValid && isValidTranslationsGroup)];
		}

		return [newOptionsAfterValidation, isValid];
	};

	const sendOptions = async (groups = optionGroups) => {
		const storeId = isMultiStore ? currentStoreId : currentStoreAddressId;

		if (storeId) {
			const [newOptionsAfterValidation, isValid] = checkValidOptions(groups);
			if (!isValid) {
				errorToast(t('errors:send.options'));
				dispatch(optionsSlice.actions.update(newOptionsAfterValidation));
			} else {
				const newOptions = await patchOptions(storeId, excludeIdEntities<IOption, IOptionGroup>(groups) as any, isMultiStore);
				if (newOptions && Array.isArray(newOptions)) {
					dispatch(optionsSlice.actions.update(newOptions));
					successToast(t('success:send.options'));
					dispatch(optionsSlice.actions.updateInitialOptions());
				}
				else errorToast(t('errors:send.options'));
			}
		}
	};

	const clearOptions = () => {
		dispatch(optionsSlice.actions.clear());
	};

	const setOptionsPageScrollPosition = () => {
		dispatch(optionsSlice.actions.setOptionsPageScrollPosition(window.scrollY));
	};

	const getUserChanges = (): boolean => !equal(removePropertiesForCheck(optionGroups), removePropertiesForCheck(initialOptionGroups));

	const clearOptionsValidation = () => {
		dispatch(optionsSlice.actions.clearValidation());
	};

	return {
		activeOptionGroups,
		optionGroups,
		initialOptionGroups,
		loadingOptions,
		isValidOptions,
		createEmptyOption,
		createEmptyGroupOptions,
		updateOptionGroup,
		updateOption,
		fetchOptions,
		getOptionGroup,
		getActiveOptionGroupsByIds,
		addOptionGroup,
		patchOptionGroup,
		removeOptionGroup,
		removeOptionItem,
		sendOptions,
		clearOptions,
		optionsPageScrollPosition,
		setOptionsPageScrollPosition,
		getUserChanges,
		clearOptionsValidation,
		checkValidOptions,
	};
};
