import SwitchComponent from 'components/SwitchComponent';
import { maxSendingCount } from 'const';
import AddSuperCollection from 'containers/AddSuperCollection';
import { scrollToNewItem } from 'helpers/scroll';
import { useAppSelector } from 'hooks/redux';
import { useCollections } from 'hooks/useCollections';
import { useSuperCollections } from 'hooks/useSuperCollections';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as Plus } from 'static/imgs/plus.svg';

import classes from './AddCollection.module.scss';
import { IProps } from './types';

const AddCollection: React.FC<IProps> = ({ minifiedVersion = false, onOpenPopup, collapseAll, superCollectionClosestId }) => {
	const { t } = useTranslation('collections');
	const { createEmptyCollection } = useCollections();
	const buttonText = t('addCollectionButtonText');
	const instructionSendCount = t('instructionSendCount', { count: maxSendingCount });
	const { tieCollectionToSupCollection, showSuperCollections } = useSuperCollections();
	const { productGroups } = useAppSelector(state => state.products);

	const handleClick = () => {
		const newCollection = createEmptyCollection(productGroups.length);
		if (superCollectionClosestId) tieCollectionToSupCollection(superCollectionClosestId, newCollection.id);
		setTimeout(() => scrollToNewItem(newCollection.id, true), 0);
		if (collapseAll) collapseAll(false, true);
	};

	if (minifiedVersion) return (
		<div className={classes.minifiedRoot}>
			<div className={classes.button} data-testid="test_addCollectionButtonText" onClick={handleClick}>
				{buttonText}<span data-testid="test_plus"><Plus /></span>
			</div>
		</div>
	);

	return (
		<div className={classes.root}>
			<div className={classes.text} data-testid="test_instructionSendCount" >{instructionSendCount}</div>
			{showSuperCollections ?
				<AddSuperCollection productGroupsCount={productGroups.length} />
				: (
					<div className={classes.rightBar}>
						<div className={classes.button} onClick={handleClick}>
							<span><Plus /></span>{buttonText}
						</div>
						<span className={classes.separator} />
						<div className={classes.toggleActive} data-testid="test_includeCollections" >
							{onOpenPopup && (
								<SwitchComponent
									revert
									ico
									isActive={true}
									onChange={onOpenPopup}
									titleAct={t('includeCollections')}
								/>
							)}
						</div>
					</div>
				)}
		</div>
	);
};

export default AddCollection;
