import { fetch } from 'helpers/fetch';
import { ISuperCollection } from 'models/ICollection';
import { IAvailability } from 'models/IProduct';

export const fetchSuperCollections = (storeAddressId: string, isMultiStore: boolean) => {
	const url = isMultiStore ? `/${storeAddressId}/supercollections/` : `/menu-supercollections/${storeAddressId}/`;
	return fetch<ISuperCollection[]>(url);
};

export const fetchSupCollectionsAvailability = (storeAddressId: string, addressesIdsInStore: string) => fetch<IAvailability[]>(`/${storeAddressId}/supercollections/availability?store-addresses=${addressesIdsInStore}`);

export const deleteSuperCollection = (storeAddressId: string, collectionId: string, isMultiStore: boolean) => {
	const url = isMultiStore ? `/${storeAddressId}/supercollections/${collectionId}/` : `/menu-supercollections/${storeAddressId}/${collectionId}/`;
	return fetch<ISuperCollection[]>(url, { method: 'DELETE' }).catch((e) => { console.log(e); });
};

export const patchSuperCollections = (storeAddressId: string, collections: Omit<ISuperCollection, 'id'>[], isMultiStore: boolean) => {
	const url = isMultiStore ? `/${storeAddressId}/supercollections/` : `/menu-supercollections/${storeAddressId}/`;
	return fetch<ISuperCollection[]>(url, {
		method: 'PATCH',
		body: JSON.stringify(collections),
	});
};

export const patchSupCollectionsAvailability = (storeAddressId: string, availability: IAvailability[], addressesIdsInStore: string) => fetch(`/${storeAddressId}/supercollections/availability?store-addresses=${addressesIdsInStore}`, {
	method: 'PATCH',
	body: JSON.stringify({store_availability: availability}),
});
