import { Rules } from './Rules';
import { IValidator, ValidationTypeEnum } from './types';

export class Validator extends Rules implements IValidator {

	private getRulesByType(type: ValidationTypeEnum) {
		const allRules = this.getRules();
		const entries = Object.entries(allRules);
		const rulesByType = entries.find(([key]) => key === type);
		if (rulesByType) return rulesByType[1];
		return undefined;
	}

	make(type: ValidationTypeEnum, value: any, minMultiPrice?: number, allProductGroups?: any[], currentCountry?: string): string[] {
		const rules = this.getRulesByType(type);
		if (!rules) return [];
		return rules.filter((validator) => !validator.passes(value,  minMultiPrice, allProductGroups, currentCountry))
			.map((validator) => validator.message());
	}
}
