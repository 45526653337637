
import { patchLangs } from 'api/multiLang';
import { getMultiLangStatus as getMultiLangStatusReq } from 'api/multiLang';
import { ILangsPatchData, IMultiLangData } from 'models/IMultiLang';
import { RootState } from 'store';
import { multiLangSlice } from 'store/reducers/multiLang';
import { fetchMultiLang as fetchMultiLangAction } from 'store/reducers/multiLang/actions';

import { useAppDispatch, useAppSelector } from './redux';
import { useCollections } from './useCollections';
import { useOptions } from './useOptions';
import { useProducts } from './useProducts';
import { useStoreAddress } from './useStoreAddress';
import { useSuperCollections } from './useSuperCollections';

type MultiLangType = RootState['multiLang'];

interface IUseMultiLang extends MultiLangType {
	fetchMultiLang(): void;
	setDefaultLang(defaultLang: IMultiLangData['defaultLanguage']): void;
	setLangList(langs: IMultiLangData['languages']): void;
	getMultiLangStatus(): void;
};

export const useMultiLang = (): IUseMultiLang => {
	const { currentStoreAddressId, isMultiStore, currentStoreId, addressesIdsInStore } = useStoreAddress();
	const dispatch = useAppDispatch();
	const { fetchOptions } = useOptions();
	const { fetchProducts } = useProducts();
	const { fetchCollections } = useCollections();
	const { fetchSuperCollections } = useSuperCollections();
	const { multiLangData, translationProcess } = useAppSelector(state => state.multiLang);

	const fetchMultiLang = () => {
		const storeId = isMultiStore ? currentStoreId : currentStoreAddressId;
		if (storeId) dispatch(fetchMultiLangAction({storeAddressId: storeId, isMultiStore}));
	};

	const setDefaultLang = (defaultLang: IMultiLangData['defaultLanguage']) => {
		dispatch(multiLangSlice.actions.setDefaultLang(defaultLang));

		const sendLangData: ILangsPatchData = {
			...multiLangData,
			...(isMultiStore && {storeAddressIds: addressesIdsInStore}),
			defaultLanguage: defaultLang,
			storeType: isMultiStore ? 'store' : 'store_address',
		};

		const storeId = isMultiStore ? currentStoreId : currentStoreAddressId;
		if (storeId) patchLangs(storeId, sendLangData).then(() => {
			fetchSuperCollections();
		});
	};

	const setLangList = (langs: IMultiLangData['languages']) => {
		dispatch(multiLangSlice.actions.setLanguagesList(langs));

		const sendLangData: ILangsPatchData = {
			...multiLangData,
			languages: langs,
			...(isMultiStore && {storeAddressIds: addressesIdsInStore}),
			storeType: isMultiStore ? 'store' : 'store_address',
		};

		const storeId = isMultiStore ? currentStoreId : currentStoreAddressId;
		if (storeId) {
			dispatch(multiLangSlice.actions.setMultiLangStatus(true));
			patchLangs(storeId, sendLangData).then(() => {
				getMultiLangStatus();
			});
		};
	};

	const updateMenu = () => {
		fetchOptions();
		fetchProducts();
		fetchCollections();
		fetchSuperCollections();
		dispatch(multiLangSlice.actions.setMultiLangStatus(false));
	};

	const startIntervalForMultiLangStatus = (storeId: string) => {
		const interval = setInterval( async () => {
			const result = await getMultiLangStatusReq(storeId, isMultiStore).catch(() => undefined);
			if (result?.translationProcess !== undefined) dispatch(multiLangSlice.actions.setMultiLangStatus(result.translationProcess));
			if (result?.translationProcess === false) {
				clearInterval(interval);
				updateMenu();
			}
		}, 5000);
	};

	const getMultiLangStatus = async () => {
		const storeId = isMultiStore ? currentStoreId : currentStoreAddressId;
		if (storeId) {
			const result = await getMultiLangStatusReq(storeId, isMultiStore).catch(() => undefined);
			if (result?.translationProcess === true) {
				dispatch(multiLangSlice.actions.setMultiLangStatus(result.translationProcess));
				startIntervalForMultiLangStatus(storeId);
			} else updateMenu();
		};
	};

	return {
		fetchMultiLang,
		multiLangData,
		setDefaultLang,
		setLangList,
		getMultiLangStatus,
		translationProcess,
	};
};
