import PromptIco from 'components/PromptIco';
import PromptView from 'components/PromptView';
import { useAppSelector } from 'hooks/redux';
import { ESectionPrompts } from 'models/IPrompts';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as PlusIco } from '../../static/imgs/plus.svg';
import classes from './AddElementButton.module.scss';
import { IProps } from './types';

const AddElementButton: React.FC<IProps> = ({ buttonText, onClick }) => {
	const { sectionsPrompt } = useAppSelector(state => state.getStarted);
	const [showTooltip, setShowTooltip] = useState<boolean>(true);
	const { t } = useTranslation('hintTexts');

	return (
		<div className={classes.container}>
			<div className={classes.addElementButton} data-testid="test_buttonText" onClick={onClick}>
				<span><PlusIco /></span>
				{buttonText}
			</div>
			{sectionsPrompt === ESectionPrompts.ADD_PRODUCT && (
				<>
					{showTooltip && (
						<div data-id="promptWrap">
							<div className={classes.icoWrap}><PromptIco /></div>
							<div className={classes.promptWrap}>
								<PromptView
									title={t('addProduct.title')}
									text={t('addProduct.title')}
									show={() => setShowTooltip(false)}
								/>
							</div>
						</div>
					)}
				</>
			)}
		</div>
	);
};

export default AddElementButton;
