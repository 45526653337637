import { prefixApiAuthUrl, prefixApiUrl } from 'const';

import fetchWithToken from './fetchWithToken';

interface IOptions extends RequestInit {
	auth?: boolean;
};

export const fetch = <T>(url: string, options: IOptions = {}, forcePrefix?: string): Promise<T> => {
	const prefix = options.auth ? prefixApiAuthUrl : prefixApiUrl;
	return new Promise((resolve, reject) => {
		fetchWithToken(`${forcePrefix ? forcePrefix : prefix}${url}`, {
			...options,
			headers: {
				...options?.headers,
				Accept: 'application/json',
				'Content-Type': 'application/json',
			},
			credentials: 'omit',
		}).then((res) => resolve(res.json())).catch(reject);
	});
};

export const addSendToGlovoHeader = (sendToGlovo: boolean) => {
	return {
		'X-Send-To-Glovo': sendToGlovo.toString(),
	};
};
