import { deleteSuperCollection, patchSupCollectionsAvailability, patchSuperCollections } from 'api/superCollections';
import equal from 'fast-deep-equal/react';
import { changeAvailability, createAvailability, getAvailableBlockByEntityId, getItemAvailableStatus, removePropertiesForCheck } from 'helpers';
import { getEmptySuperCollection } from 'helpers/mock';
import { checkIsValidEntities } from 'helpers/validation';
import { ICollection, ISuperCollection } from 'models/ICollection';
import { IAvailability, IProductGroup } from 'models/IProduct';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { collectionsSlice } from 'store/reducers/collections';
import { productsSlice } from 'store/reducers/products';
import { superCollectionsSlice } from 'store/reducers/superCollectionsData';
import {
	fetchSupCollectionsAvailability as fetchSupCollectionsAvailabilityAction,
	fetchSuperCollections as fetchSuperCollectionsAction} from 'store/reducers/superCollectionsData/actions';

import { useAppSelector } from './redux';
import { useCollections } from './useCollections';
import { useProducts } from './useProducts';
import { useStoreAddress } from './useStoreAddress';
import useToast from './useToast';


interface IUseCollections {
	fetchSuperCollections(): void;
	createEmptySuperCollection(productGroupsCount: number, name?: string, collectionsIds?: string[]): void;
	tieCollectionToSupCollection(superCollectionId: ISuperCollection['id'], collectionId: ICollection['id']): void;
	removeCollectionFromSupCollections(collectionId: ICollection['id']): void;
	updateSuperCollection(collectionId: ISuperCollection['id'], key: keyof ISuperCollection, value: any): void;
	updatePositionSuperCollections(): void;
	removeSuperCollection(collectionId: ISuperCollection['id']): void;
	sendSuperCollections(productGroups: IProductGroup[], sendToGlovo: boolean): void;
	removeValidationSup(): void;
	showSuperCollections: boolean;
	superCollections: ISuperCollection[];
	supCollectionsAvailability: IAvailability[];
	updateSuperCollectionAvailability(storeId: string, status: boolean, superCollectionId: ISuperCollection['id']): void;
	getUserChanges(): boolean;
	getSuperCollectionById(superCollectionId: ISuperCollection['id']): ISuperCollection | undefined;
	getSupCollectByChildCollectionId(collectionId: string): ISuperCollection | undefined;
	checkValidSupCollections(): [ISuperCollection[], boolean];
};

export enum SuperCollectionsIdsEnum {
	SUPER_COLLECTION = 'super-collection-ids',
	SUP_COLLECTION_HEAD = 'super-collection-title'
};

export const useSuperCollections = (): IUseCollections => {
	const dispatch = useDispatch();
	const { createEmptyCollection, sendCollections, getCollectionById } = useCollections();
	const { clearSearchResult } = useProducts();
	const { errorToast, successToast } = useToast();
	const { currentStoreId, isMultiStore, currentStoreAddressId, addressesIdsInStore, currentStoreData, setDisableSendButton } = useStoreAddress();
	const {
		superCollections,
		initialSuperCollections,
		showSuperCollections,
		supCollectionsAvailability,
	} = useAppSelector(state => state.superCollectionsData);
	const { t } = useTranslation(['errors', 'success']);
	const { userCountry } = useAppSelector(state => state.user);

	const fetchSuperCollections = () => {
		const storeId = isMultiStore ? currentStoreId : currentStoreAddressId;
		if (storeId) {
			dispatch(fetchSuperCollectionsAction({storeAddressId: storeId, isMultiStore}));
			if (isMultiStore) {
				dispatch(fetchSupCollectionsAvailabilityAction({storeAddressId: storeId, addressesIdsInStore: addressesIdsInStore.join(',')}));
			}
		}
	};

	const updateSuperCollectionAvailability = (storeId: string, status: boolean, superCollectionId: ISuperCollection['id']) => {
		const newAvailability = changeAvailability(supCollectionsAvailability, superCollectionId, status, storeId);
		dispatch(superCollectionsSlice.actions.updateAvailability(newAvailability));

		const currentAvailableSuperCollection = getAvailableBlockByEntityId(newAvailability, superCollectionId);
		const superCollectionAvailableStatus = getItemAvailableStatus(currentAvailableSuperCollection);
		updateSuperCollection(superCollectionId, 'active', superCollectionAvailableStatus);
	};

	const createEmptySuperCollection = (productGroupsCount: number, name?: string) => {
		const newSupCollection = getEmptySuperCollection(superCollections.length + 1, [createEmptyCollection(productGroupsCount).id], name);

		dispatch(superCollectionsSlice.actions.createEmptySupCollectionStore(newSupCollection));
		createAvailabilityForSupCollection(newSupCollection.id);
		clearSearchResult();
	};

	const createAvailabilityForSupCollection = (id: ISuperCollection['id']) => {
		const createAvailabilityItem = createAvailability(currentStoreData);

		if (createAvailabilityItem) {
			dispatch(superCollectionsSlice.actions.updateAvailability([...supCollectionsAvailability, {
				entity_id: id,
				availability: createAvailabilityItem,
			}]));
		}
	};

	const removeValidationSup = () => {
		dispatch(superCollectionsSlice.actions.removeValidation());
	};

	const tieCollectionToSupCollection = (superCollectionId: ISuperCollection['id'], collectionId: ICollection['id']) => {
		dispatch(superCollectionsSlice.actions.tieCollectionToSupCollectionStore({superCollectionId, collectionId}));

	};

	const removeCollectionFromSupCollections = (collectionId: ICollection['id']) => {
		dispatch(superCollectionsSlice.actions.removeCollectionFromSupCollectionsStore(collectionId));
	};

	const updateSuperCollection = useCallback((collectionId: ISuperCollection['id'], key: keyof ISuperCollection, value: any) => {
		dispatch(superCollectionsSlice.actions.updateSupCollectionStore({collectionId, key, value}));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const getSuperCollectionById = (collectionId: ICollection['id']) => superCollections.find(({ id }) => id === collectionId);

	const updatePositionSuperCollections = () => {
		setTimeout(() => {
			const collectionsEL = document.querySelectorAll(`.${SuperCollectionsIdsEnum.SUPER_COLLECTION}`);
			const newResult: ISuperCollection[] = [];
			Array.from(collectionsEL).forEach((collectionEl, index) => {
				const idCollection = collectionEl.getAttribute('id');
				if (!idCollection) return;
				const collection = getSuperCollectionById(idCollection);
				if (!collection) return;
				newResult.push({
					...collection,
					position: index + 1,
				});
			});

			dispatch(superCollectionsSlice.actions.update({ items: newResult, changedPosition: true}));
		}, 0);
	};

	const removeSuperCollection = (collectionId: ISuperCollection['id']) => {
		const deletedSuperCollection = getSuperCollectionById(collectionId);

		deletedSuperCollection?.menuCollections.forEach((childCollectionId) => {
			const childCollection = getCollectionById(childCollectionId);

			dispatch(collectionsSlice.actions.removeCollectionStore({ collectionId: childCollectionId }));
			childCollection?.productGroups.forEach((childProductGroupsId) => {
				dispatch(productsSlice.actions.removeProductGroup({ groupId: childProductGroupsId }));
			});
		});

		dispatch(superCollectionsSlice.actions.removeSupCollectionStore({ collectionId }));

		const storeId = isMultiStore ? currentStoreId : currentStoreAddressId;
		if (storeId && !deletedSuperCollection?.isNew) {
			deleteSuperCollection(storeId, collectionId, isMultiStore);
			if (isMultiStore) {
				dispatch(fetchSupCollectionsAvailabilityAction({storeAddressId: storeId, addressesIdsInStore: addressesIdsInStore.join(',')}));
			}
		}
	};

	const showErrorToast = (sendToGlovo: boolean) => {
		if (sendToGlovo) return errorToast(t('errors:send.superCollections'));
		errorToast(t('errors:saveAsDraft.superCollections'));
	};

	const showSuccessToast = (sendToGlovo: boolean) => {
		if (sendToGlovo) return successToast(t('success:send.superCollections'));
		successToast(t('success:saveAsDraft.superCollections'));
	};

	const checkValidSupCollections = () => checkIsValidEntities(superCollections, false, false, userCountry);

	const sendSuperCollections = async (productGroups: IProductGroup[], sendToGlovo: boolean) => {
		const storeId = isMultiStore ? currentStoreId : currentStoreAddressId;
		if (storeId) {
			const [newSupCollectionsAfterValidation, isValid] = checkValidSupCollections();
			if (!isValid) {
				setDisableSendButton(false);
				showErrorToast(sendToGlovo);
				dispatch(superCollectionsSlice.actions.update({items: newSupCollectionsAfterValidation}));
			} else {
				const newSuperCollections = await patchSuperCollections(storeId, superCollections, isMultiStore);
				if (isMultiStore) patchSupCollectionsAvailability(storeId, supCollectionsAvailability, addressesIdsInStore.join(','));

				if (newSuperCollections && Array.isArray(newSuperCollections)) {
					sendCollections(productGroups, sendToGlovo, newSupCollectionsAfterValidation);
					showSuccessToast(sendToGlovo);
					dispatch(superCollectionsSlice.actions.updateInitialSupCollections());
				}
				else {
					setDisableSendButton(false);
					showErrorToast(sendToGlovo);
				}
			}
		}
	};

	const getUserChanges = (): boolean => !equal(removePropertiesForCheck(superCollections), removePropertiesForCheck(initialSuperCollections));

	const getSupCollectByChildCollectionId = (collectionId: ICollection['id']) => superCollections.find(({ menuCollections }) => menuCollections.find(
		(groupId) => groupId === collectionId),
	);

	return {
		showSuperCollections,
		superCollections,
		createEmptySuperCollection,
		tieCollectionToSupCollection,
		removeCollectionFromSupCollections,
		updateSuperCollection,
		updatePositionSuperCollections,
		removeSuperCollection,
		fetchSuperCollections,
		sendSuperCollections,
		removeValidationSup,
		supCollectionsAvailability,
		updateSuperCollectionAvailability,
		getUserChanges,
		getSuperCollectionById,
		getSupCollectByChildCollectionId,
		checkValidSupCollections,
	};
};
