import InputComponent from 'components/InputComponent';
import { useAppSelector } from 'hooks/redux';
import { useProducts } from 'hooks/useProducts';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as IIco } from 'static/imgs/i.svg';
import { ReactComponent as SearchIco } from 'static/imgs/search.svg';

import classes from './ProductsSearch.module.scss';

const ProductsSearch: React.FC = () => {
	const { handleSearchProducts } = useProducts();
	const { productGroups, searchValue } = useAppSelector(state => state.products);
	const [showEmptyTooltip, setShowEmptyTooltip] = useState<boolean>(false);
	const { t } = useTranslation(['menu', 'products']);

	const handleChange = (value: string) => {
		if (value.length > 2 || value === '') { // search if value > 2 symbols
			const resultsFound = handleSearchProducts(value, productGroups);

			if (!!value.length && !resultsFound) {
				setShowEmptyTooltip(true);
				setTimeout(() => setShowEmptyTooltip(false), 2000);
			}
		}
	};

	const handleClearSearchQuery = () => {
		handleSearchProducts('');
	};

	return (
		<>
			<div className={classes.search}>
				<div className={classes.search_icon}><SearchIco /></div>
				<InputComponent
					showClearButton
					value={searchValue}
					onChange={handleChange}
					placeholder={t('searchPlaceholder')}
					handleClearSearchQuery={handleClearSearchQuery}
				/>
			</div>
			{showEmptyTooltip && <div className={classes.emptySearch}><IIco/>{t('products:emptySearch', {searchValue})}</div>}
		</>
	);
};

export default ProductsSearch;
