import SiteNavigationItem from 'components/SiteNavigationItem';
import { fincabUrl, RoutesEnum } from 'const';
import { saveTempTokens } from 'helpers/token';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as FincabIco } from 'static/imgs/fincab.svg';
import { ReactComponent as HomeIco } from 'static/imgs/homeIco.svg';
import { ReactComponent as Logo } from 'static/imgs/logo.svg';
import { ReactComponent as OptionsIco } from 'static/imgs/optionsIco.svg';

import classes from './SiteNavigation.module.scss';

const SiteNavigation: React.FC = () => {
	const { t } = useTranslation('menu');

	const handleLoginFincab = () => {
		saveTempTokens();
		setTimeout(() => {
			window.location.href = fincabUrl;
		}, 0);
	};

	return (
		<div data-testid="nav-root-div" className={classes.root}>
			<span className={classes.logo}><Logo /></span>
			<SiteNavigationItem
				href={RoutesEnum.PRODUCTS}
				text={t('menu')}
				ico={<HomeIco />}
			/>
			<SiteNavigationItem
				href={RoutesEnum.OPTIONS}
				text={t('options')}
				ico={<OptionsIco />}
			/>
			<SiteNavigationItem
				text={t('fincab')}
				ico={<FincabIco />}
				onClick={handleLoginFincab}
			/>
			{/* <SiteNavigationItem
				href="/support"
				text="Support"
				ico={<SupportIco/>}
			/> */}
		</div>
	);
};

export default SiteNavigation;
