
import { createAsyncThunk } from '@reduxjs/toolkit';
import { fetchDateSaveDraft as fetchDateSaveDraftReq } from 'api/products';

export const fetchDateSaveDraft = createAsyncThunk(
	'dateSaveDraft/fetchDateSaveDraft',
	async ({storeAddressId, isMultiStore}: {storeAddressId: string, isMultiStore: boolean}, thunkAPI) => {
		try {
			return await fetchDateSaveDraftReq(storeAddressId, isMultiStore);
		} catch (e) {
			return thunkAPI.rejectWithValue('Failure');
		}
	},
);
