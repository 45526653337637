import 'cropperjs/dist/cropper.css';

import Modal from 'components/Modal';
import PromptIco from 'components/PromptIco';
import { isValidURL, removeSpacesAtTheEndOfLangsValues, removeSpacesAtTheEndOfTitle } from 'helpers';
import clsx from 'helpers/clsx';
import { checkIsValidEntities, checkmultiLangTitlesIsUnique, multiLangTitlesValidation, removeValidationFromLags } from 'helpers/validation';
import { useAppSelector } from 'hooks/redux';
import { useCollections } from 'hooks/useCollections';
import { useSuperCollections } from 'hooks/useSuperCollections';
import { ICollection, ISuperCollection } from 'models/ICollection';
import { ECollectionsPrompts } from 'models/IPrompts';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ImageValidator } from 'services/Validator/ImageValidator';

import classes from './EditCollectionPopup.module.scss';
import DefaultTemplate from './templates/default.template';
import MultiLangTemplate from './templates/multiLang.template';
import { IProps } from './types';

const EditCollectionPopup: React.FC<IProps> = ({ open, onClose, collection, isSuperCollection = false }) => {
	const [image, setImage] = useState<string>(collection.image);
	const [uploadImgSize, setUploadImgSize] = useState<number>(0);
	const [error, setError] = useState<string[]>([]);
	const [isActive, setIsActive] = useState(collection.active);
	const [name, setName] = useState(collection.title);
	const { collectionsPrompt } = useAppSelector((state) => state.getStarted);
	const { updateCollection, removeValidation } = useCollections();
	const { collections } = useAppSelector(state => state.collections);
	const { t } = useTranslation(['collections', 'requirements', 'form', 'hintTexts', 'validation']);
	const { updateSuperCollection, removeValidationSup } = useSuperCollections();
	const [langsValues, setLangsValues] = useState(collection.translations);
	const [cropper, setCropper] = useState<any>();
	const { userCountry } = useAppSelector(state => state.user);
	const [generateIsProgress, setGenerateIsProgress] = useState<boolean>(false);

	useEffect(() => { setLangsValues(collection.translations); }, [collection.translations]);

	const handleCloseAfterSave = () => {
		onClose();
		update(
			collection.id,
			'validation',
			undefined,
		);;
		setLangsValues(removeValidationFromLags(langsValues));
		setError([]);
		setUploadImgSize(0);
	};

	const handleClose = () => {
		handleCloseAfterSave();
		setIsActive(collection.active);
		setImage(collection.image);
		setLangsValues(collection.translations);
		setName(collection.title);
		if (cropper) cropper.destroy();
	};

	useEffect(() => {
		setIsActive(collection.active);
		setName(collection.title);
		setImage(collection.image);
	}, [collection.active, collection.title, collection.image]);

	// const handleActiveState = () => setIsActive(!isActive);

	const update = (id: ICollection['id'] | ISuperCollection['id'], key: keyof ICollection | keyof ISuperCollection, value: any) => {
		if (isSuperCollection) {
			updateSuperCollection(id, key as keyof ISuperCollection, value);
		} else updateCollection(id, key as keyof ICollection, value);
	};

	const saveChanges = async () => {
		const nameForSave = removeSpacesAtTheEndOfTitle(name);
		setName(nameForSave);
		const langsValuesForSave = removeSpacesAtTheEndOfLangsValues(langsValues);
		setLangsValues(langsValuesForSave);

		const validator = new ImageValidator(isSuperCollection ? 1240 : 690, 690, uploadImgSize, !isSuperCollection);
		const type = image.split(';')[0].split('/')[1];
		const cropImg = !!image.length ? cropper.getCroppedCanvas().toDataURL(`image/${type}`) : '';
		const validatorPasses = !!image.length ? await validator.passes(cropImg) : true;

		if (!isValidURL(cropImg) && !validatorPasses && cropImg) {
			setError(validator.message());
		} else {
			setError([]);
			setUploadImgSize(0);
		}

		const [newCollectionAfterValidation, isValid] = checkIsValidEntities([{...collection, title: nameForSave}], false, false, userCountry);
		// if super collections is enabled, name uniqueness is ignored
		const nameIsUnique = isSuperCollection ? true : !collections.find((item) => (item.id !== collection.id && item.title === nameForSave));

		if (!isValid || !nameIsUnique || langsValuesForSave) {
			update(
				collection.id,
				'validation',
				nameIsUnique ? newCollectionAfterValidation[0].validation : {title: [t('validation:isNotUniqueName')]},
			);
			if (langsValuesForSave) {
				const [isValidMultilang, itemsAfterValidation] = multiLangTitlesValidation(langsValuesForSave, false, undefined, userCountry);
				const [isUnique, itemsAfterUniqueTest] = checkmultiLangTitlesIsUnique(
					itemsAfterValidation,
					collections,
					collection.id,
					nameForSave,
					[t('validation:isNotUniqueName')],
				);

				if (!isValidMultilang || (!isUnique && !isSuperCollection)) {
					setLangsValues(itemsAfterUniqueTest);
					return false;
				}
			}
		} else {
			isSuperCollection ? removeValidationSup() : removeValidation();
		}

		if (isValid && nameIsUnique && (validatorPasses || isValidURL(cropImg))) {
			update(collection.id, 'active', isActive);
			update(collection.id, 'image', cropImg);
			update(collection.id, 'title', nameForSave);

			if (!!Object.keys(collection.translations || {}).length) {
				update(collection.id, 'translations', langsValuesForSave);
			}

			handleCloseAfterSave();
		}
	};

	return (
		<Modal open={open} onClose={handleClose}>
			<div className={classes.popupUploadImg}>
				<div className={classes.title}>
					{isSuperCollection ? t('settingsSup') : t('settings')}
					{/* temporarily hide */}
					{/* <div className={classes.toggleActive}>
						<SwitchComponent
							revert
							isActive={isActive}
							onChange={handleActiveState}
							titleAct={t('includeCollectionInEdit')}
							titleDis={t('includeCollectionInEdit')}
						/>
					</div> */}
				</div>
				<div className={classes.popupUploadBody}>
					{!!Object.keys(collection.translations || {}).length ? (
						<MultiLangTemplate
							collection={collection}
							isSuperCollection={isSuperCollection}
							setName={setName}
							setImage={setImage}
							setError={setError}
							name={name}
							image={image}
							error={error}
							collectionsPrompt={collectionsPrompt}
							langsValues={langsValues}
							setLangsValues={setLangsValues}
							setCropper={setCropper}
							setUploadImgSize={setUploadImgSize}
							generateIsProgress={generateIsProgress}
							setGenerateIsProgress={setGenerateIsProgress}
						/>
					) : (
						<DefaultTemplate
							collection={collection}
							isSuperCollection={isSuperCollection}
							setName={setName}
							setImage={setImage}
							setError={setError}
							name={name}
							image={image}
							error={error}
							collectionsPrompt={collectionsPrompt}
							setCropper={setCropper}
							setUploadImgSize={setUploadImgSize}
						/>
					)}
					<div className={classes.buttons}>
						<button onClick={handleClose}>{t('form:cancel')}</button>
						<button
							className={clsx(classes.savePromp, {[classes.generateIsProgress]: generateIsProgress})}
							onClick={saveChanges}
						>
							{collectionsPrompt === ECollectionsPrompts.COLLECTIONS_POPUP_SAVE && <span><PromptIco /></span>}
							{t('form:save')}
						</button>
					</div>
				</div>
			</div>
		</Modal>
	);
};

export default EditCollectionPopup;
