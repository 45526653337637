import Modal from 'components/Modal';
import clsx from 'helpers/clsx';
import { calcErrCountInItem, getGroupById, getProductById } from 'helpers/products';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { useCollections } from 'hooks/useCollections';
import { useOptions } from 'hooks/useOptions';
import { useProducts } from 'hooks/useProducts';
import { useSuperCollections } from 'hooks/useSuperCollections';
import { useUser } from 'hooks/useUser';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { modalsSlice } from 'store/reducers/modals';

import { ReactComponent as RemoveProductIco } from '../../static/imgs/deleteProductIco.svg';
import classes from './PopupProductRemove.module.scss';
import { IProps, IPropsState, IState } from './types';

const PopupProductRemove: React.FC<IProps> = ({ isOptionsPage, removeGroupId, removeItemId, ...stateStore }) => {
	const { openRemovePopup, optionOnProductPage } = useAppSelector((state) => state.modals);
	const { removeGroup, removeProduct } = stateStore as IPropsState;

	const { setRemovePopupState, setDefaultForForce } = modalsSlice.actions;
	const dispatch = useAppDispatch();
	const { removeOptionGroup, removeOptionItem } = useOptions();
	const { removeProductGroup, removeProductItem, updateProductGroup, clearProductsValidation } = useProducts();
	const { removeSectionFromCollections, getCollectionByChildSectionId, updateCollection, removeValidation } = useCollections();
	const { getSupCollectByChildCollectionId, updateSuperCollection, removeValidationSup } = useSuperCollections();
	const { t } = useTranslation(['options', 'products', 'form']);
	const isExternalId = useAppSelector(state => state.products.isExternalId);
	const { itemsIsChecked, setItemsIsChecked, decItemsIsChecked, sectionsCheckCount, setTypeOfCheckItems, productsCheckCount } = useUser();

	const closePopup = () => {
		dispatch(setRemovePopupState(false));
		dispatch(setDefaultForForce());
	};

	const recalcItemsErrorsCountForCollections = (errCountInItem: number) => {
		const parentCollection = getCollectionByChildSectionId(removeGroupId);

		if (parentCollection?.itemsErrorsCount) {
			updateCollection(parentCollection?.id, 'itemsErrorsCount', parentCollection?.itemsErrorsCount - errCountInItem);

			const parentSupCollections = getSupCollectByChildCollectionId(parentCollection?.id);
			if (parentSupCollections?.itemsErrorsCount) {
				updateSuperCollection(parentSupCollections?.id, 'itemsErrorsCount', parentSupCollections?.itemsErrorsCount - errCountInItem);
			}
		}
	};

	const calcErrorsCountInParentsProduct = () => {
		const productErrCount = calcErrCountInItem(removeProduct);

		if (removeGroup.itemsErrorsCount) {
			updateProductGroup(removeGroupId, 'itemsErrorsCount', removeGroup.itemsErrorsCount - productErrCount);
		}

		recalcItemsErrorsCountForCollections(productErrCount);
	};

	const removeItem = () => {
		if (removeItemId) {
			if (isOptionsPage) removeOptionItem(removeGroupId, removeItemId);
			else {
				removeProductItem(removeGroupId, removeProduct);
				calcErrorsCountInParentsProduct();
				if (removeProduct.checked) decItemsIsChecked('product');
			}
		} else {
			if (isOptionsPage) {
				removeOptionGroup(removeGroupId);
			} else {
				removeProductGroup(removeGroup);
				removeSectionFromCollections(removeGroupId);
				recalcItemsErrorsCountForCollections((calcErrCountInItem(removeGroup) + (removeGroup.itemsErrorsCount || 0)));

				//  TheTotalNumberOfItemsSelected - (TheNumberOfSelectedProductsInTheGroup + productGroup)
				const childIsChecked = removeGroup.items.find(product => product.checked);
				if (removeGroup.checked || !!childIsChecked) {
					setItemsIsChecked(itemsIsChecked - (removeGroup.items.length + 1));

					setTypeOfCheckItems('section', sectionsCheckCount - 1);
					setTypeOfCheckItems('product', productsCheckCount - removeGroup.items.length);
				}
			};
		}
		if (isExternalId) {
			clearProductsValidation();
			removeValidation();
			removeValidationSup();
		}

		dispatch(setRemovePopupState(false));
		dispatch(setDefaultForForce());
	};

	return (
		<Modal open={openRemovePopup} onClose={closePopup}>
			<div className={classes.productRemovePopup}>
				<RemoveProductIco />
				<span className={classes.title}>
					<Trans t={t} i18nKey="form:deleteTitle" />&nbsp;
					{removeItemId ? (!isOptionsPage ? t('products:delete.itemSingle') : t('options:delete.item')
					) : (
						optionOnProductPage
							? t('options:delete.section')
							: !isOptionsPage ? t('products:delete.section') : t('options:delete.section')
					)}?
				</span>
				{removeItemId ? (
					<span className={classes.text}>
						{t('form:deleteItem', { item: !isOptionsPage ? t('products:delete.item') : t('options:delete.item') })}
					</span>
				) : (
					<span className={classes.text}>{isOptionsPage ? t('form:deleteOptionGroup') : t('form:deleteSection')}</span>
				)}

				<div className={classes.buttons}>
					<div className={classes.button} onClick={closePopup}>{t('form:cancel')}</div>
					<div onClick={removeItem} className={clsx(classes.button, classes.confirm)}>{t('form:yesDelete')}</div>
				</div>
			</div>
		</Modal>
	);
};

const mapStateToProps = (state: IState, ownProps: IProps) => ({
	removeGroup: getGroupById(state.products.productGroups || [], ownProps.removeGroupId),
	removeProduct: getProductById(state.products.productGroups || [], ownProps.removeGroupId, ownProps.removeItemId),
});

export default connect(mapStateToProps)(PopupProductRemove);
