import { ECountry } from 'const';
import { useUser } from 'hooks/useUser';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as ChangeIco } from 'static/imgs/change.svg';

import classes from './SelectedOptionGroup.module.scss';
import { IProps } from './types';

const SelectedOptionGroup: React.FC<IProps> = ({ optionsGroup, handleOpen }) => {
	const { t } = useTranslation('options');
	const { userCountry } = useUser();

	return (
		<>
			<div className={classes.root}>
				<div className={classes.title}>{optionsGroup.title}</div>
				{!!optionsGroup.min && <div className={classes.count}>{t('min')}: {optionsGroup.min}</div>}
				{!!optionsGroup.max && <div className={classes.count}>{t('max')}: {optionsGroup.max}</div>}
				{optionsGroup.items.map((option) => {
					if (!option.active) return null;
					return (
						<div className={classes.option} key={option.id}>
							{option.title} <span>+{option.price}{userCountry === ECountry.Ukrainian && ' грн'}</span>
						</div>
					);
				})}
				<div className={classes.changeButtonWrap}>
					<div className={classes.changeButton} onClick={handleOpen} data-testid="editOption">
						<ChangeIco/>{t('editSection')}
					</div>
				</div>
			</div>
		</>
	);
};

export default SelectedOptionGroup;
